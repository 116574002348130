export function UuidCRC(uuid) {
    let suma = 0;
    for (var i = 0; i < uuid.length; i++) {
        if (uuid[i] != '-') {            
    	    suma += parseInt(uuid[i], 16);
        }
    }
    
	let caracterControl = suma % 36;
	if (caracterControl < 10) {
  	    caracterControl = caracterControl.toString();
    } else {
  	    caracterControl = String.fromCharCode(caracterControl + 55);
    }    
    return uuid + caracterControl;
}

export function CheckUuid(uuidC) {
    var uuid = uuidC.substring(0, uuidC.length - 1);
    return uuidC == UuidCRC(uuid);
}

export function getEnumFieldName(enumType, value) {
    for (let key in enumType) {
        if (enumType[key] === value) {
            return key;
        }
    }
    return null;
}

export async function callAsyncOrNot(func, ...params) {
    // Comprobar si la funci�n es as�ncrona
    if (func.constructor.name === 'AsyncFunction') {
        return await func(...params);
    } else {
        return func(...params);
    }
}