
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { MultiSelect } from "react-multi-select-component";
import { minFeeAgency, maxFeeAgency } from '../../global.js'
import { Button, TextField, Box, Grid, TableCellItem, TableCell } from '@material-ui/core'
import { Send, Delete, Visibility, AttachMoney, Edit, FileCopy } from '@styled-icons/material';
import { commService } from '../../services/api/list';
import AddNewButton from '../../structure/common/add-new-button/add-new-button.component.jsx';
//\\192.168.2.186\powerads\Sandbox\Frontend\src\structure\common\add-new-button\add-new-button.component.jsx
import UserUtils from '../../utils/userUtils'
import { confAddItem, CommButtonSave, CommItemTextField, CommItemTextFieldInList, CommListGen, CommLinkButton, CommDeleteButtonLink, confListField, confList, CommList, CommItemField, CommItemOfItems, CommButtonLinkItem, ListFieldButtonLink } from '../../structure/common/com-List/comm-list.components'
import { fieldNameFunction, CommItemFieldLink, CommSimpleTextButton, strfieldvalue, fnfRequireNumber } from '../../structure/common/com'
import { getStreamingScene } from '../../structure/streamingAccount/streamingAccount-toStream.components'
import { BrowserView, MobileView, isBrowser, isMobile } from '../../utils/platformsUtils';
import ComTooltip from '../../structure/common/comTooltip/comTooltip.jsx';
import ServicesBase from "../../services/base"
import DeleteItemDialog from '../../structure/common/deleteItemDialog/deleteItemDialog.component.jsx';

import { LangStrs, Lang } from '../../utils/languageUtils'

import CommHelpLink, { getDocUrl } from '../../structure/common/comHelpLink/comHelpLink.jsx'

const strs: LangStrs = new LangStrs(new Map(
    [
        ['UserName', new Map(
            [
                [Lang.ES, 'Nombre de usuario'],
                [Lang.EN, 'Username']
            ])
        ],

        ['Email', new Map(
            [
                [Lang.ES, 'Email'],
                [Lang.EN, 'Email']
            ])
        ],

        ['FeePercent', new Map(
            [
                [Lang.ES, 'Porcentaje de tarifa'],
                [Lang.EN, 'Fee percent']
            ])
        ],
        ['Accept', new Map(
            [
                [Lang.ES, 'Aceptar'],
                [Lang.EN, 'Accept']
            ])
        ],

        ['Deny', new Map(
            [
                [Lang.ES, 'Denegar'],
                [Lang.EN, 'Deny']
            ])
        ],

        ['Break', new Map(
            [
                [Lang.ES, 'Romper relación contractual'],
                [Lang.EN, 'Break contractual relationship']
            ])
        ],
        ['Actions', new Map(
            [
                [Lang.ES, 'Acciones'],
                [Lang.EN, 'Actions']
            ])
        ],
        ['AgencyStatus', new Map(
            [
                [Lang.ES, 'Estado con la agencia'],
                [Lang.EN, 'Status with the agency']
            ])
        ],

        ['PendingApproval', new Map(
            [
                [Lang.ES, 'Por aprobar'],
                [Lang.EN, 'Pending approval']
            ])
        ],

        ['Denied', new Map(
            [
                [Lang.ES, 'Denegado'],
                [Lang.EN, 'Denied']
            ])
        ],

        ['Accepted', new Map(
            [
                [Lang.ES, 'Aceptado'],
                [Lang.EN, 'Accepted']
            ])
        ],
        ['TitleListAgencyStreamers', new Map(
            [
                [Lang.ES, 'Creadores de contenido'],
                [Lang.EN, 'Content creators']
            ])
        ],
        ['HowLink', new Map(
            [
                [Lang.ES, 'Los creadores de contenido deben enlazarse a tu agencia desde su página de perfil, mediante la id '],
                [Lang.EN, 'Content creators must link to your agency from their profile page, using id ']
            ])
        ],
        ['DenyRequest', new Map(
            [
                [Lang.ES, '¿Estás seguro de que quieres denegar la solicitud de {1}?'],
                [Lang.EN, 'Are you sure you want to deny the request of {1}?']
            ])
        ],

        ['BreakRelationship', new Map(
            [
                [Lang.ES, '¿Estás seguro de que quieres romper la relación con {1}?'],
                [Lang.EN, 'Are you sure you want to break the relationship with {1}?']
            ])
        ]
    ]
));

export class AgencyStreamersList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            itemToDelete: null
        };

        const buttonChangeState = (str, initState, toState, color=null) => {
            var r = new confListField("", CommSimpleTextButton, { style: { backgroundColor: "red" } }, str).addField("Condition", (item) => item.agencyState == initState).addField("OnClick", async (item) => this.ChangeUser(item, { agencyState: toState }));
            if (color)
                r.addField("color", color)
            return r;
        }

        this.conf = [
            new confListField(strs.get('UserName'), CommItemField, {}, "username"),
            new confListField(strs.get('Email'), CommItemField, {}, "email"),
            new confListField(strs.get('FeePercent'), CommItemTextField, { className: "list-field bi-name", ...isMobile ? {} : { style: { paddingTop: "25px", disabled: true } } }, fnfRequireNumber("feePercent", minFeeAgency, maxFeeAgency, true)).addField("tLabel", false),
            new confListField(strs.get('AgencyStatus'), AgencyStatusComp, { className: "list-field bi-size" }),
            new confListField(strs.get('Actions'), CommItemOfItems).addField("Comp", [
                new confListField(strs.get("Save"), CommButtonSave),
                buttonChangeState(strs.get('Accept'), AgencyStatus.ToApprove, AgencyStatus.Approved),
                buttonChangeState(strs.get('Deny'), AgencyStatus.ToApprove, AgencyStatus.Denied, "rgb(204, 102, 102)"),
                buttonChangeState(strs.get('Break'), AgencyStatus.Approved, "Break", "rgb(204, 102, 102)"),
            ])
        ];
    }

    render = () => {
        return (
            <>
                <div>
                    <h1 style={{ display: 'block' }}>{strs.get('TitleListAgencyStreamers')}<CommHelpLink infoUrl={getDocUrl("como-vincular-mis-creadores-de-contenido-a-mi-cuenta-de-agencia")} /></h1>
                    <p style={{ display: 'block' }}>
                        {strs.get('HowLink')}
                        <br />
                        <b>{UserUtils.GetUserData().id}</b>
                    </p>
                </div>

                <CommList ref={(c) => this.ListStreamers = c} title={""} confComm={new confList(CommListGen, new commService("/advertisers/:id/AgencyStreamers"), this.conf)} />
                {this.state.itemToDelete && <DeleteItemDialog title={this.state.itemToDelete.msg} labelMoreText=" " labelDelete="SI" onDelete={async () => { this.ChangeUser(this.state.itemToDelete.item, this.state.itemToDelete.data, true); await this.setState({ itemToDelete: null }); }} onCancel={() => { this.setState({ itemToDelete:null }) }} />}
            </>
        );
    }

    async ChangeUser(item, data, sure=false) {
        if (!sure) {
            if (data.agencyState == AgencyStatus.Denied || data.agencyState == "Break") {
                let msg = strs.get('DenyRequest', item.username);
                if (data.agencyState == AgencyStatus.Denied)
                    msg = strs.get('BreakRelationship', item.username);

                data.agencyState = AgencyStatus.Denied;
                this.setState({ itemToDelete: { msg: msg, item: item, data: data, msg } });

                return;
            }
        }

        let sb: ServicesBase = new ServicesBase("#server#" + "/advertisers/" + UserUtils.GetUserData().id + "/AgencyStreamers/" + item.id);
        let r = await sb.ServiceRequest("", 'PUT', { 'Content-Type': 'application/json' }, true, data);
        if (r.httpCode < 400) {
            this.ListStreamers.QueryItems();
        }
    }
}

export class AgencyStatus {
    static None = 0;
    static ToApprove = 1;
    static Approved = 2;
    static Denied = 3;
}

export class AgencyStatusComp extends React.Component {
    constructor(props) {
        super(props);
    }

    render = () => {
        let text = "NONE";
        let colortext = "black";

        const confField: confField = this.props.confFieldItem.confField;
        if (this.props.item.agencyState == AgencyStatus.Denied) {
            text = strs.get('Denied');
            colortext = "red";
        } else if (this.props.item.agencyState == AgencyStatus.Approved) {
            text = strs.get('Accepted');
            colortext = "green";
        } else if (this.props.item.agencyState == AgencyStatus.ToApprove) {
            text = strs.get('PendingApproval');
            colortext = "orange";
        } 

        return (
            <TableCell className="list-field" {...confField.fieldprops}>
                <p style={{ color: colortext }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span>{text}</span>                        
                    </div>
                </p>
            </TableCell>
        )
    }
}