import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
    palette: {
        primary: {
          main: '#dd0000',
          contrastText: '#fff',
        },
        secondary: {
          main: '#F48024',
          contrastText: '#fff',
        },
    },
});