import React, { useState, useEffect, useRef } from 'react';
import { Snackbar, Slide, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

let ids = 0;
let nextDelay = 0;
let msgHeights = [];

function SlideTransition(props) {
    return <Slide {...props} direction={props.direction || "up"} />;
}

const ComAditionalInfo = ({ message, id, position }) => {
    const contentRef = useRef(null);
    const [init, setInit] = useState(true);
    const [nMsg, setNMsg] = useState(() => id ?? ids++);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (init) {
            const timeoutId = setTimeout(() => {
                setOpen(true);
                setInit(false);
            }, nextDelay);

            nextDelay += 500;

            return () => clearTimeout(timeoutId);
        }
        if (contentRef.current) {
            msgHeights[id] = contentRef.current.clientHeight + 40;
        }
    }, [init, message, nMsg]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const action = (
        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
            <CloseIcon fontSize="small" />
        </IconButton>
    );

    const totalHeight = msgHeights.slice(0, nMsg).reduce((accumulator, currentValue) => accumulator + currentValue, 0);

    let style = {
        position: 'fixed',
        zIndex: 1000,
    };

    if (position?.vertical === 'top') {
        style.top = `${totalHeight}px`;
    } else {
        style.bottom = `${totalHeight}px`;
    }

    if (position?.horizontal === 'center') {
        style.left = '50%';
        style.transform = 'translateX(-50%)';
    } else if (position?.horizontal === 'right') {
        style.right = '0px';
    } else {
        style.left = '0px';
    }

    return (
        <Snackbar
            open={open}
            onClose={handleClose}
            TransitionComponent={SlideTransition}
            message={<div ref={contentRef}>{message}</div>}
            action={action}
            autoHideDuration={120000}
            anchorOrigin={{
                vertical: position?.vertical || 'bottom',
                horizontal: position?.horizontal || 'left',
            }}
            style={style}
            ContentProps={{
                style: {
                    backgroundColor: 'white',
                    border: '2px solid #8CD0F1',
                    color: 'black',
                },
            }}
        />
    );
};

export default ComAditionalInfo;
