import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

import GlobalState from '../../store/globalState'
import { isBrowser, isMobile, MobileView } from '../../utils/platformsUtils';
import { compEncapsulation, ExpandableComponent, CountDown, IconFromSVG, InputSelectVideo } from '../../components/common/commonComponents.jsx'
import DescriptionIcon from '@material-ui/icons/Description';
import { Grid, Button, TextField, TextareaAutosize, FormControlLabel, FormControl, Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import LinearProgress from '@material-ui/core/LinearProgress';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { Redirect } from 'react-router-dom';
import MuiAlert from '@material-ui/lab/Alert';
import Streamers from '../../services/api/streamers';
import UserUtils from '../../utils/userUtils'
import { commService } from '../../services/api/list';
import { CommInputField, CommForm, CommFormGen, confForm, RadioButtonList, CommRadioButtonList } from '../common/com-Form/comm-form.components'
import { CommButton, confCOM, confField, CommTextField, fieldNameFunction, temporalItem, fnfRequireText, CommDateTimePicker, InputSource, CountDownComm, FileButtonComm, CommSimpleTextButton } from '../common/com'
import { CommListSimpleTextButton, ListFieldButtonLink, CommButtonSave, CommItemOfItems, confAddItem, CommListGen, CommLinkButton, CommDeleteButtonLink, confListField, confList, CommList, CommItemField, CommItemTextField, CommItemFieldTextLink, CommListFlat, CommItemTextLink, ListFilesComp } from '../common/com-List/comm-list.components'
import { ModalButtons } from '../../components/common/modal/modal.jsx'
import { changeUrl, changeUrlByReact, Urls, refreshByReact } from '../../utils/routerUtils'
import { RenderBannerType } from '../../structure/ads-form/ads-form.component.jsx'
import { mapGenericStateToProps, openTab } from '../../utils/routerUtils'
import { withStyles } from '@material-ui/core/styles';
import { LangStrs, Lang } from '../../utils/languageUtils'
import { OptionsToCheckRequired } from '../options/options.component'
import { useHistory } from 'react-router-dom'
import { ContractStates, SDeliveryStatesToStr, showReject } from '../../structure/common/branded/commonBranded.jsx'
import { PostFileButton } from '../../components/common/commonUpFile.jsx'
import { dateStrToReadDateStr } from '../../utils/timeUtils'
import { strs as bStrs, SDeliveryStates, SponsorshipType } from '../../structure/common/branded/commonBranded.jsx'
import { strs as upfStrs } from '../../components/common/commonUpFile.jsx'

import MoveToInboxIcon from '@material-ui/icons/MoveToInbox';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { VideoEditor } from '../../components/video/videoEditor.jsx'
import ModalDialog, { ModalAlert }from '../../components/common/modal/modal'

import { GenerateVideoForm } from '../../structure/generatevideo-form/generateVideo-form.jsx'

import { StreamStreamerCard, ToCheckStreamsService, confStreams } from '../../structure/streams-grid/streams-grid.component.jsx'

import { confListStatic, CommListStatic } from '../common/com-List/com-List-Static/comm-list-static-component'

import { Chat } from '../../components/chat/chat-component.jsx'

export let strs: LangStrs = new LangStrs(new Map(
    [
        ['Title', new Map(
            [
                [Lang.ES, 'Campaña de Contenido de Marca'],
                [Lang.EN, 'Branded Content Campaign']
            ])
        ],
        ['Name', new Map(
            [
                [Lang.ES, 'Nombre'],
                [Lang.EN, 'Name']
            ])
        ],
        ['duration', new Map(
            [
                [Lang.ES, 'Duración (en segundos)'],
                [Lang.EN, 'Duration (in seconds)']
            ])
        ],
        ['description', new Map(
            [
                [Lang.ES, 'Descripción de lo que espera la marca'],
                [Lang.EN, 'Description of what the brand expects']
            ])
        ],
        ['Resources', new Map(
            [
                [Lang.ES, 'Archivos'],
                [Lang.EN, 'Resources']
            ])
        ],
        ['Actions', new Map(
            [
                [Lang.ES, 'Acciones'],
                [Lang.EN, 'Actions']
            ])
        ],
        ['File', new Map(
            [
                [Lang.ES, 'Archivo'],
                [Lang.EN, 'File']
            ])
        ],
        ['SelectFile', new Map(
            [
                [Lang.ES, 'Subir nuevo archivo'],
                [Lang.EN, 'Upload new file']
            ])
        ],
        ['FileUploaded', new Map(
            [
                [Lang.ES, 'Archivo subido'],
                [Lang.EN, 'File uploaded']
            ])
        ],
        ['Uploading', new Map(
            [
                [Lang.ES, 'Subiendo: '],
                [Lang.EN, 'Uploading: ']
            ])
        ],
        ['UpError', new Map(
            [
                [Lang.ES, 'Error al subir, intenta más tarde'],
                [Lang.EN, 'Upload error, try again later']
            ])
        ],
        ['UpErrorSize', new Map(
            [
                [Lang.ES, 'Archivo demasiado grande'],
                [Lang.EN, 'File too large']
            ])
        ],
        ['SponsoredContent', new Map(
            [
                [Lang.ES, 'Contenido Patrocinado'],
                [Lang.EN, 'Sponsored Content']
            ])
        ],
        ['SponsoredSegment', new Map(
            [
                [Lang.ES, 'Segmento Patrocinado'],
                [Lang.EN, 'Sponsored Segment']
            ])
        ],
        ['ContentType', new Map(
            [
                [Lang.ES, 'Tipo de contenido de marca'],
                [Lang.EN, 'Content Type']
            ])
        ],
        ['createSegment', new Map(
            [
                [Lang.ES, 'Generar video con segmento patrocinado'],
                [Lang.EN, 'Generate video with sponsored segment']
            ])
        ],
        ['SelBrandedCreators', new Map(
            [
                [Lang.ES, 'Seleccionar creadores de contenido'],
                [Lang.EN, 'Select Content Creators']
            ])
        ],
        ['SelBrandedCreatorsNoInfo', new Map(
            [
                [Lang.ES, 'Completa la información para poder seleccionar'],
                [Lang.EN, 'Complete the information to be able to select']
            ])
        ],
        ['DateLimit', new Map(
            [
                [Lang.ES, 'Fecha Límite'],
                [Lang.EN, 'Deadline']
            ])
        ],
        ['expiredIn', new Map(
            [
                [Lang.ES, 'Expira en'],
                [Lang.EN, 'Expires in']
            ])
        ],
        ['expired', new Map(
            [
                [Lang.ES, 'Lo siento, la campaña ya ha expirado'],
                [Lang.EN, 'Sorry, the campaign has already expired']
            ])
        ],
        ['Accept', new Map(
            [
                [Lang.ES, 'Aceptar Oferta'],
                [Lang.EN, 'Accept Offer']
            ])
        ],
        ['Refuse', new Map(
            [
                [Lang.ES, 'Rechazar'],
                [Lang.EN, 'Refuse']
            ])
        ],
        ['SectionDescription', new Map(
            [
                [Lang.ES, 'Descripción'],
                [Lang.EN, 'Description']
            ])
        ],
        ['ContentDelivery', new Map(
            [
                [Lang.ES, 'Realizar entrega de contenido'],
                [Lang.EN, 'Content Delivery']
            ])
        ],
        ['DeliveryDate', new Map(
            [
                [Lang.ES, 'Fecha de Entrega'],
                [Lang.EN, 'Delivery Date']
            ])
        ],
        ['View', new Map(
            [
                [Lang.ES, 'Ver'],
                [Lang.EN, 'View']
            ])
        ],
        ['state', new Map(
            [
                [Lang.ES, 'Estado'],
                [Lang.EN, 'State']
            ])
        ],
        ['TitleDeliveries', new Map(
            [
                [Lang.ES, 'Entregas'],
                [Lang.EN, 'Deliveries']
            ])
        ],
        ['OtherDelivery', new Map(
            [
                [Lang.ES, `¿Seguro que quieres realizar otra entrega? Actualmente ya tienes una entrega aceptada, si realizas una nueva, la actual se invalidará y deberás esperar a que se acepte la nueva para poder usarla.`],
                [Lang.EN, `Are you sure you want to make another delivery? You currently have an accepted submission. If you make a new one, the current one will be invalidated, and you will have to wait for the new one to be accepted before you can use it.`]
            ])
        ],
        ['IncompatibleRes', new Map(
            [
                [Lang.ES, `La resolución del segmento es de {1}x{2}, y tu contenido de {3}x{4}. Tu contenido debería tener una resolución igual o superior a la del segmento para poder fusionar los videos y poder mantener la calidad del segmento.`],
                [Lang.EN, `The segment's resolution is {1}x{2}, and your content is {3}x{4}. Your content should have an equal or higher resolution than the segment to merge the videos while maintaining the segment's quality.`]
            ])
        ],
    ]
));

const DivPoiter = (props) => <div style={{ pointerEvents: "auto", display: "contents"  }} {...props} />
const compWithPointer = (WrappedComponent) => compEncapsulation(WrappedComponent, DivPoiter);

const Div = (props) => <div {...this.props} />
export const StyledDivButton = (color, hoverColor) => withStyles({
    root: {
        backgroundColor: color,
        marginBottom: '15px',
        marginLeft: '1px',
        color: '#fff',
        '&:hover': {
            backgroundColor: hoverColor,
            color: '#fff',
        },
    }
})(Div);
const DivButtonSubmit = StyledDivButton('#35749E', '#8CD0F1');
const CreateSegmentIcon = IconFromSVG(
    <>
        <mask id="ipSAddMusic0"><g fill="none" stroke="#fff" stroke-linejoin="round" stroke-width="4"><path stroke-linecap="round" d="M24 44C12.954 44 4 35.046 4 24S12.954 4 24 4s20 8.954 20 20" /><path fill="#fff" d="M20 24v-6.928l6 3.464L32 24l-6 3.464l-6 3.464V24Z" /><path stroke-linecap="round" d="M37.05 32v10M42 36.95H32" /></g></mask><path fill="currentColor" d="M0 0h48v48H0z" mask="url(#ipSAddMusic0)" />
    </>
    , "0 0 60 48");

let delRender = 0;
class BrandedStreamerForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}

        const typeBranded = [
            { label: strs.get('SponsoredContent'), value: 0 },
            { label: strs.get('SponsoredSegment'), value: 1 },
        ];

        this.conf = [
            //new confField(strs.get('dateLimit'), compEncapsulation(CountDownComm, DivCenter), { passDateMsg: strs.get("expired"), remainingMsg: strs.get("expiredIn"), className: "list-field bi-amount not-mobile", style: { width: "220px" } }, "dateLimit"),
            new confField(strs.get('Name'), CommTextField, { className: "list-field bi-name" }, "sName"),
            new confField(strs.get('ContentType'), CommRadioButtonList, { options: typeBranded, orientation: "row" }, "type"),
            new confField(bStrs.get('moneyPerCollaboration'), CommTextField, { className: "list-field bi-amount not-mobile", endAdornment: "$", style: { width: "220px", paddingRight: "20px" } }, "moneyPerCollaboration"),
            new confField(bStrs.get('CollaborationTimes'), CommTextField, { className: "list-field bi-amount not-mobile", style: { width: "220px", paddingRight: "20px" } }, "collaborationTimes"),
            new confField(strs.get('duration'), CommTextField, { className: "list-field bi-amount not-mobile", style: { width: "220px" } }, "duration"),            
            new confField(strs.get('description'), compWithPointer(CommTextField), { className: "list-field bi-name", readOnly:true }, "sDesc").addField("rows", 4),
        ];

        this.confResources = [
            new confListField(strs.get("Delete"), FileButtonComm, { }, (item) => { return { fileName: item.name, url: item.path }; })
        ];

        this.confSubmissions = [
            new confListField("", CommListSimpleTextButton, {}, strs.get('View')).addField("OnClick", async (item) => { openTab(`/api/streamers/${UserUtils.GetUserData().id}/sponsorship/${this.props.match.params.id}/deliveryMedia/${item.id}`) }).addField("Icon", VisibilityIcon),
            new confListField(strs.get('state'), CommItemField, {}, (item) => SDeliveryStatesToStr(item.state)),
            new confListField(strs.get('DeliveryDate'), CommItemField, {}, (item) => dateStrToReadDateStr(item.createdAt, true)),
            new confListField("", CommItemField, {}, (item) => this.DeliveryDetails(item)),
        ];
    }

    InputVideoId = 0;

    DeliveryDetails(item) {
        if (item.state == SDeliveryStates.Rejected) {
            return showReject(item.rejectReason);
        } else if (item.state == SDeliveryStates.Accepted) {
            const OkButton = ModalButtons(true);
            return <>
                { item.sponsorshipType == SponsorshipType.SponsoredSegment && this.state.item && this.state.item.state > 0 && this.state.lastDelivery && item.id == this.state.lastDelivery.id &&
                    <>
                    <InputSelectVideo ref={(r) => this.inputFile = r} OnSelect={async (file, props, error) => {
                            let delAttrs = JSON.parse(this.state.lastDelivery.videoAttributes)
                            if (error != undefined) {
                                //this.setState({ formCorrect: false });
                                //await this.setState({ error: strs.get('ETooSize') });                                
                                this.mAlert.show(upfStrs.get(error));
                            }
                            else if (delAttrs.width > props.width || delAttrs.height > props.height) {
                                this.mAlert.show(strs.get("IncompatibleRes", delAttrs.width, delAttrs.height, props.width, props.height));
                            }
                            else {
                                //await this.setState({ error: null });
                                await this.setState({ fileSelect: { file: file, props: props }, sponDelivery:item.id });
                                this.videoEditorModal.setOpen(true);
                            }
                        }} />
                        {this.state.item.streamingType == 0 && <OkButton disabled={this.state.item.canDelivery === false} variant="contained" size="large" color="primary" component="span" onClick={() => this.inputFile.selectFile()}>
                                <CreateSegmentIcon style={{ fontSize: 32 }} />
                                {strs.get("createSegment")}
                            </OkButton>
                        }
                    </>
                }
                <CommList ref={(m) => { this.setupStreamsList(m) }} key={`deliveries ${item.id}${(delRender++)}`} headers={false} ItemList={StreamStreamerCard} /*itemFilter={(item) => item.state != 2 || item.remaingTime > 0}*/ confComm={new confList(CommListGen, new ToCheckDeliveredStreamsService(deliveryInfoUrl(this.props.match.params.id, item), this, item), confStreams)} />
            </>
        }
        return <></>
    }

    setupStreamsList(comp) {
        if (comp!=null) this.streamsList = comp;
    }

    async OnConfigDone(config) {
        this.videoEditorModal.setOpen(false);
        config.sponDelivery = this.state.sponDelivery;
        this.setState({ selScene: config });
        this.genVideoModal.setOpen(true);
    }

    async deliveriesRecived(items) {
        let lastD = items.reduce((prev, current) => {
            return (prev.createdAt > current.createdAt) ? prev : current;
        });
        await this.setState({ lastDelivery: lastD });
    }

    render() {
        const OkButton = ModalButtons(true);
        const CancelButton = ModalButtons(false);
        const disableContentDelivery = this.state.item && this.state.item.canDelivery === false; //this.state.item.sponsorshipType == SponsorshipType.SponsoredContent &&
        return (
            <Grid direction="column">
                <Grid className="form-column">
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <h1>{strs.get("Title")}</h1>
                        {this.state.item?.state == ContractStates.Offer &&
                            <div>
                                <OkButton variant="contained" size="large" color="primary" style={{ margin: '5px' }} onClick={(e) => { this.ChangeOfferStatus(true) }}>{strs.get("Accept")}</OkButton>
                                <CancelButton variant="contained" size="large" color="primary" style={{ margin: '5px' }} onClick={(e) => { this.ChangeOfferStatus(false) }}>{strs.get("Refuse")}</CancelButton>
                            </div>
                        }                        
                    </div>
                    {this.state.item && <p style={{ width: '100%', textAlign: 'right', marginTop: "0px", marginBottom: "0px" }}><CountDown dateTime={this.state.item.dateLimit} remainingMsg={strs.get("expiredIn")} passDateMsg={strs.get("expired")} /></p>}
                </Grid>
                <Grid child container className={'brandedContent-form ' + this.props.className} direction="row" justify="flex-start" alignItems="flex-start">
                    {this.state.item?.state == ContractStates.StreamerAccepted &&
                        <>
                        <Grid child container direction="row" xs="12" style={disableContentDelivery ? { pointerEvents: "none" } : {}}style={disableContentDelivery ? { pointerEvents: "none" } : {}}>
                                <PostFileButton
                                filetype={"video/*"}
                                postUrl={"/api/streamers/" + UserUtils.GetUserData().id + "/sponsorship/" + this.props.match.params.id + "/delivery"}
                                customFormData={(formData, file) => {
                                    formData.append("entity", this.props.match.params.id);
                                    formData.append("media", file);
                                    return formData;
                                }}
                                OnSuccess={() => {
                                    refreshByReact(this.props);
                                }}
                                checkFile={async (f) => {
                                    if (this.state.deliveries.find(d => d.sponsorshipType == SponsorshipType.SponsoredSegment && d.state == SDeliveryStates.Accepted)) {
                                        return await this.surePostFileModal.showAndGetResult();
                                    }
                                    return true;
                                }}
                                >
                                    <OkButton variant="contained" size="large" color="primary" component="span" disabled={disableContentDelivery}>
                                        <MoveToInboxIcon style={{ fontSize: 32 }} />
                                        {strs.get("ContentDelivery")}
                                    </OkButton>
                                </PostFileButton>
                            </Grid>                   
                        </>
                    }
                    {this.state.item?.state != ContractStates.Offer &&
                        <Grid child container direction="row" xs="12">
                            <ExpandableComponent title={strs.get("TitleDeliveries")} style={{ marginBottom: "10px", width: '100%', display: this.state.showDeliveris ? "block" : "none" }} defaultExpanded>
                                <Grid child container direction="row" xs="12">
                                    <CommList key={UserUtils.GetUserData().id + "submissions"} ref={(r) => this.submissionsList = r} confComm={new confList(CommListGen, new commService(`/streamers/:id/sponsorship/${this.props.match.params.id}/delivery`), this.confSubmissions)} headers={false} OnQuery={async (comp, data) => { data && data.length && await this.deliveriesRecived(data); await this.setState({ showDeliveris: true, deliveries:data }) }} />
                                </Grid>
                            </ExpandableComponent>
                        </Grid>
                    }
                    <Grid child container direction="row" xs="12">
                        <ExpandableComponent title={strs.get("SectionDescription")} defaultExpanded>
                            <Grid child container className={'brandedContent-form ' + this.props.className} direction="row" justify="flex-start" alignItems="flex-start" style={{marginTop:"10px"}}>
                                <Grid child container direction="row" xs="12">
                                    <div style={{ pointerEvents: "none", display: "contents" }}>
                                        <CommForm ref={(r) => this.DescriptionForm = r } confComm={new confForm(CommFormGen, new commService("/streamers/:id/sponsorship"), this.conf, this.props.match.params.id)} OnQuery={(comp, data) => this.setState({ item: data })} noSubmitButton/>
                                    </div>
                                </Grid>
                                <Grid child container direction="row" xs="12">
                                    <ListFilesComp ref={(c) => this.commlistResources = c} resourcesURL={"/streamers/:id/sponsorship/" + this.props.match.params.id + "/resource"} />                                    
                                </Grid>
                            </Grid>
                        </ExpandableComponent>
                        <ExpandableComponent title={bStrs.get("SectionChat")} defaultExpanded>
                            <Grid child container className={'brandedContent-form ' + this.props.className} direction="row" justify="flex-start" alignItems="flex-start" style={{ marginTop: "10px" }}>
                                <Chat messagesUrl={`/streamers/:id/sponsorship/${this.props.match.params.id}/message`} postResourcesUrl={`/api/streamers/${UserUtils.GetUserData().id}/sponsorship/${this.props.match.params.id}/message/:idMsg/resource`} />
                            </Grid>
                        </ExpandableComponent>
                    </Grid>                    
                </Grid>
                <ModalDialog ref={(m) => { this.videoEditorModal = m; }} windowsSyle={true} btnNOVisible={false} btnOKVisible={false}>
                    <VideoEditor ref={(m) => { if (m != null) { this.videoEditor = m; m.setVideo(this.state.fileSelect.file); } }} SponDelivery={this.state.sponDelivery} OnSelect={(item) => { this.OnSelectScene(item) }} OnConfigDone={this.OnConfigDone.bind(this)} />
                </ModalDialog>
                <ModalDialog ref={(m) => { this.genVideoModal = m; }} windowsSyle={true} btnNOVisible={false} btnOKVisible={false}>
                    <GenerateVideoForm ref={(m) => { this.genVideoForm = m; this.setVideoInfoToGen(); }} OnStreamsChanged={(state: number) => { state == 0 && this.genVideoModal.handleClose(); this.submissionsList && this.submissionsList.QueryItems(); }} noSelectVideo scene={this.state.selScene} streamingAccount={this.state.item?.cStreamingAccount}/>
                </ModalDialog>
                <ModalDialog ref={(m) => { this.surePostFileModal = m; }} windowsSyle={true} btnNOVisible={true} btnOKVisible={true}>
                    {strs.get("OtherDelivery")}
                </ModalDialog>
                <ModalAlert ref={(r) => this.mAlert = r} />
            </Grid>
        )
    }

    setVideoInfoToGen = () => {
        if (this.genVideoForm) this.genVideoForm.setVideo(this.state.fileSelect.file, this.state.fileSelect.props);
    }

    async ChangeOfferStatus(Accept = true) {
        await this.DescriptionForm.UpdateData({ id: this.state.item.id, newData: { state: Accept ? ContractStates.StreamerAccepted : ContractStates.CanceledByStreamer } });
        if (Accept)
            refreshByReact(this.props);
        else
            changeUrlByReact(this.props, Urls.StreamersBranded + "/offer");
    }
}

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 10,
        borderRadius: 5,
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
        borderRadius: 5,
        backgroundColor: '#1a90ff',
    },
}))(LinearProgress);

const deliveryInfoUrl = (contract, item) => `/streamers/:id/sponsorship/${contract}/delivery/${item.id}`;

class ToCheckDeliveredStreamsService extends ToCheckStreamsService {
    constructor(uri, component, item) {
        super(uri);
        this.cmp = component;        
        this.item = item;
    }
    
    Get = async (userId = "", itemId = false, loading = true) => {
        this.changeBaseUrl(deliveryInfoUrl(this.item.sponsorshipContractId, this.item));
        let r = await commService.prototype.Get.call(this, userId, itemId, loading);
        r.body = r.body[0].streamsDelivered;
        return r;
    };
    async Update (userId, item, loading = true) {
        this.changeBaseUrl("/streamers/:id/stream");
        return await super.Update(userId, item, loading);
    }
};

export default connect(mapGenericStateToProps, null)(withRouter(BrandedStreamerForm));