
import IBAN from 'iban';

import React from 'react';
import './add-payment-method.styles.scss'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

import {Grid, Button, TextField, Select, InputLabel, TextareaAutosize, FormControlLabel, FormControl} from '@material-ui/core';

import {Delete, Link as LinkIcon, Visibility, AttachMoney, Edit, FileCopy, AccountBalance, CheckCircle, Error} from '@styled-icons/material';

import UserUtils from '../../utils/userUtils'
import { Urls } from '../../utils/routerUtils'
import { commService } from '../../services/api/list';
import { bannerStreamerListService } from '../../services/api/bannerStreamer';
import { CommInputField, CommForm, CommFormGen, confForm } from '../common/com-Form/comm-form.components'
import { CommButton, confCOM, confField, CommTextField, fieldNameFunction, temporalItem, fnfRequireText, checkMailField, checkIBANField } from '../common/com'
import { CommItemOfItems, confAddItem, CommListGen, CommLinkButton, CommDeleteButtonLink, confListField, confList, CommList, CommItemField, CommItemTextField } from '../common/com-List/comm-list.components'

import { mapGenericStateToProps } from '../../utils/routerUtils'

import { LangStrs, Lang } from '../../utils/languageUtils'

import CommHelpLink, { getDocUrl } from '../../structure/common/comHelpLink/comHelpLink.jsx'

let strs: LangStrs = new LangStrs(new Map(
    [
        ['IBAN', new Map(
            [
                [Lang.ES, 'IBAN'],
                [Lang.EN, 'IBAN']
            ])
        ],
        ['Address', new Map(
            [
                [Lang.ES, 'Dirección'],
                [Lang.EN, 'Address']
            ])
        ],
        ['Description', new Map(
            [
                [Lang.ES, 'Descripción'],
                [Lang.EN, 'Description']
            ])
        ]
    ]
));

class AddPaymentMethod extends React.Component {    
    constructor(props) {
        super(props);
        this.state = {
            method: null,
            iban: null,
            name: null
        }

        //Si estoy haciendo dos que se parecen mucho, pero lo dejo preparado porque en un futuro no creo que compartan casi nada
        this.confBANK = [
            new confListField(strs.get('IBAN'), CommTextField, {}, new fieldNameFunction("address", checkIBANField))
        ];

        this.confPaypal = [
            new confListField(strs.get('Address'), CommTextField, {}, new fieldNameFunction("address", checkMailField))
        ];
    }

    handleNameChange = (e) => {
        this.setState({name: e.target.value});
    }
    
    handleIbanChange = (e) => {
        this.setState({iban: e.target.value});
    }

    render() {
        this.conf = [
            new confListField(strs.get('Description'), CommTextField, {}, 'description')
        ];

        this.conf.unshift(...this.state.method == "Paypal" ? this.confPaypal : this.confBANK);
        this.title = this.state.method == "Paypal" ? "Paypal" : "Bank Account";

        return (<React.Fragment>
            <Grid container className={"add-payment-method " + this.props.className} direction="column">
                <h1>{this.props.title}<CommHelpLink infoUrl={getDocUrl("como-agregar-un-metodo-de-pago")} /></h1>
                { !this.state.method && <Grid container direction="column">
                    <Button container className="paymethod-button paymethod-paypal" onClick={() => this.setState({ method: "Paypal" })}>
                    </Button>
                    <Button container className="paymethod-button" alignItems="center" justify="center" onClick={() => this.setState({ method: "Bank" })}>
                        <AccountBalance className="paymethod-icon" color="#D80"/> Bank account
                    </Button>
                </Grid>}
                {this.state.method && <CommForm title={this.title} confComm={new confForm(CommFormGen, new commService("/users/:id/moneyAccount"), this.conf, undefined)} Owner={this} OnChange={this.OnNewAccount.bind(this)} RedirectOnNew={Urls.MoneyAccountsList} />}
            </Grid>
        </React.Fragment>)
    }

    OnNewAccount(Comp) {
        let c: CommForm = Comp;
        c.addNewData({ moneyAccountType: this.state.method });
    }
}

export default connect(mapGenericStateToProps, null)(withRouter(AddPaymentMethod));