
import React from 'react';
import {Route} from 'react-router-dom'

import AdsList from '../../structure/ads-list/ads-list.components'
import AdsForm from '../../structure/ads-form/ads-form.component'
import BrandedContentForm from '../../structure/brandedContent-form/brandedContent-form.component'
import BrandedContentStreamersForm from '../../structure/brandedContent-form/brandedContentStreamers-form.component'
import BrandedContentOnGoing from '../../structure/brandedContent-form/brandedContentOnGoig-list.component'
import TransactionsAdsStream from '../../structure/transactions-ads-stream/transactions-ads-stream'
import TransactionForm from '../../structure/transaction-form/transaction-form.component'

import { adsStrs } from '../../templates/languages/ads-strs.js'

const AdsRouter = (props) => (    
    <React.Fragment>        
        <Route exact path='/dashboard/advertisements/brandedContent/add' component={() => <BrandedContentForm />} />
        <Route exact path='/dashboard/advertisements/brandedContent/edit/:id' component={() => <BrandedContentForm />} />
        <Route exact path='/dashboard/advertisements/brandedContent/streamers/:id' component={() => <BrandedContentStreamersForm />} />
        <Route exact path='/dashboard/advertisements/brandedContent/ongoing/:id' component={() => <BrandedContentOnGoing />} />
        <Route exact path='/dashboard/advertisements/brandedContent/ongoing/:id/:contract' component={() => <BrandedContentOnGoing />} />
        <Route exact path='/dashboard/advertisements/addBrandedContent' component={() => <BrandedContentForm />} />
        <Route exact path='/dashboard/advertisements/add' component={() => <AdsForm title={adsStrs.get('TNewAd')} />} />
        <Route exact path='/dashboard/advertisements/edit/:id/:fullscreen' component={() => <AdsForm title={adsStrs.get('TEditAd')} />} />
        <Route exact path='/dashboard/advertisements/edit/:id' component={() => <AdsForm title={adsStrs.get('TEditAd')} />} />
        <Route exact path='/dashboard/advertisements' component={() => <AdsList title={adsStrs.get('TAdvertisements')} />} />
        <Route exact path='/dashboard/advertisements/stats/:id' component={() => <TransactionsAdsStream />} />
        <Route exact path='/dashboard' component={() => <AdsList title={adsStrs.get('TAdvertisements')} />} />
    </React.Fragment>
)

export default AdsRouter;