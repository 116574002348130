export default [
    {
        key: "MyDbTest",
        translations: {
            en: "My Db great test",
            es: "Mi Db maravilloso test",
            de: "Mein Db großartiger Test"
        }
    },
    {
        key: "STREAMS",
        translations: {
            en: "Streams",
            es: "Streams",
        }
    },
    {
        key: "USERS",
        translations: {
            en: "Users",
            es: "Usuarios",
        }
    },
    {
        key: "CLAUSES",
        translations: {
            en: "Clauses",
            es: "Clausulas",
        }
    },
    {
        key: "MYSTREAMS",
        translations: {
            en: "My streams",
            es: "Mis emiciones",
        }
    },
    {
        key: "BRAND_COLLABORATIONS",
        translations: {
            en: "Brand Collaborations",
            es: "Colaboraciones con Marcas"
        }
    },
    {
        key: "WALLET",
        translations: {
            en: "Wallet",
            es: "Cartera"
        }
    },
    {
        key: "SGROUP",
        translations: {
            en: "Content creators",
            es: "Creadores de contenido"
        }
    },
    {
        key: "AGENCYSTREAMERS",
        translations: {
            en: "Manage my content creators",
            es: "Gestionar mis creadores de contenido"
        }
    },
    {
        key: "SGROUPS",
        translations: {
            en: "Content creator groups",
            es: "Grupos de creadores de contenido"
        }
    },
    {
        key: "RECORDS",
        translations: {
            en: "Records",
            es: "Registros"
        }
    },
    {
        key: "REPORTS",
        translations: {
            en: "Reports",
            es: "Reportes"
        }
    },
    {
        key: "CURRENT",
        translations: {
            en: "Current",
            es: "Actuales"
        }
    },
    {
        key: "HISTORY",
        translations: {
            en: "History",
            es: "Histórico"
        }
    },
    {
        key: "NEW_REQUESTS",
        translations: {
            en: "New requests",
            es: "Nuevas peticiones"
        }
    },
    {
        key: "EARNINGS",
        translations: {
            en: "Earnings",
            es: "Ganancias"
        }
    },
    {
        key: "SETTINGS",
        translations: {
            en: "Settings",
            es: "Ajustes"
        }
    },
    {
        key: "PROFILE",
        translations: {
            en: "Profile",
            es: "Perfil",
        }
    },
    {
        key: "LINKED_ACCOUNTS",
        translations: {
            en: "Linked accounts",
            es: "Cuentas enlazadas"
        }
    },
    {
        key: "PAYMENT_INFO",
        translations: {
            en: "Payment information",
            es: "Información de pago",
        }
    },
    {
        key: "BANNERS",
        translations: {
            en: "Banners",
            es: "Banners"
        }
    },
    {
        key: "LIST_BANNERS",
        translations: {
            en: "List Banners",
            es: "Listar Banners"
        }
    },
    {
        key: "NEW_BANNER",
        translations: {
            en: "New Banner",
            es: "Nuevo Banner"
        }
    },
    {
        key: "SCENES",
        translations: {
            en: "Scenes",
            es: "Escenas"
        }
    },
    {
        key: "STREAMINGACCOUNTS",
        translations: {
            en: "Streaming Accounts",
            es: "Streaming Accounts"
        }
    },
    {
        key: "ADS",
        translations: {
            en: "Advertisements",
            es: "Anuncios"
        }
    },
    {
        key: "LIST_ADS",
        translations: {
            en: "List Advertisements",
            es: "Listar Anuncios"
        }
    },
    {
        key: "NEW_AD",
        translations: {
            en: "New Advertisement",
            es: "Nuevo Anuncio"
        }
    },
    {
        key: "LOGOUT",
        translations: {
            en: "Log out",
            es: "Cerrar sesión"
        }
    },
    {
        key: "PENDINGPAYMENTS",
        translations: {
            en: "Pending payments",
            es: "Pagos por hacer"
        }
    },
    {
        key: "PLATFORMWALLET",
        translations: {
            en: "Wallet platform",
            es: "Cartera de la plataforma"
        }
    },
    {
        key: "WEBUSE",
        translations: {
            en: "Web use",
            es: "Uso de la web"
        }
    },
    {
        key: "GUESTS",
        translations: {
            en: "Create guest",
            es: "Crear invitado"
        }
    },
    {
        key: "LINK",
        translations: {
            en: "Create link",
            es: "Crear link"
        }
    },
    {
        key: "BD",
        translations: {
            en: "BD manager",
            es: "Administrador de BD"
        }
    },
    {
        key: "MAILCONFIG",
        translations: {
            en: "Notifier Email Configuration",
            es: "Configuración de correos notificadores"
        }
    },
];