
import React, { PureComponent, useState, useEffect, Fragment } from 'react';
import { Grid, CardContent, Card, Typography, CardActions, Button, Link } from '@material-ui/core'
import Box from "@material-ui/core/Box";
import { withStyles } from '@material-ui/core/styles';
import { CommItemListAux, confAddItem, CommButtonSave, CommItemTextField, CommListGen, CommLinkButton, CommDeleteButtonLink, confListField, confList, CommList, CommItemField, CommItemOfItems, CommButtonLinkItem } from '../../structure/common/com-List/comm-list.components'
import { CommSimpleTextButton } from '../../structure/common/com'
import { commService } from '../../services/api/list';
import { LangStrs, Lang } from '../../utils/languageUtils'
import ServicesBase from "../../services/base"

const buttonStyles = () => ({
    root: {
      '& > *': {
        margin: "0.5em"
      },
    },
});

const strs: LangStrs = new LangStrs(new Map(
    [
        ['Select', new Map(
            [
                [Lang.ES, 'Seleccionar'],
                [Lang.EN, 'Select']
            ])
        ],
        ['Delete', new Map(
            [
                [Lang.ES, 'Borrar'],
                [Lang.EN, 'Delete']
            ])
        ],
        ['Actions', new Map(
            [
                [Lang.ES, 'Acciones'],
                [Lang.EN, 'Actions']
            ])
        ],
        ['Name', new Map(
            [
                [Lang.ES, 'nombre'],
                [Lang.EN, 'name']
            ])
        ]
    ]
));

export class BDManager extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }

        this.conf = [
            new confListField(strs.get('Name'), CommItemTextField, { className: "list-field bi-name" }, "name").addField("tLabel", false),
            new confListField(strs.get('Actions'), CommItemOfItems).addField("Comp", [
                new confListField(strs.get("Save"), CommButtonSave),
                new confListField(strs.get('Select'), CommSimpleTextButton, {}, strs.get('Select')).addField("OnClick", async (item) => { this.selectDB(item) }),
                new confListField(strs.get('Delete'), CommDeleteButtonLink, null, "name")
            ])
            //new confListField('url', CommItemField, {}, "url"),
            //new confListField('state', CommItemField, { className: "list-field bi-date not-mobile" }, (item) => { return <b>{this.stateToStr(item.state)} </b> }),
        ];
    }

    render = () => {
        if (!process.env.REACT_APP_BD_MANAGER) return (<></>);

        return (
            <React.Fragment>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', }}>
                    <div style={{ flexGrow: 1, textAlign: 'left' }}>
                        <h1>
                            BD Manager
                        </h1>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button variant="contained" color="primary" onClick={async (e) => this.newDB("DB_Stage")} style={{ marginRight: '20px' }}>
                            BD Stage
                        </Button>
                        <Button variant="contained" color="orange" onClick={async (e) => this.newDB("Prod_Backup", true)}>
                            Copy from Prod
                        </Button>
                        </div>
                </div>
                <CommList ref={(r) => this.bdList = r} title={""} confComm={new confList(CommListGen, new commService("/db"), this.conf)} />
            </React.Fragment>
        )
    }

    async newDB(name:string = "DbStage", prod=undefined) {
        let sb: ServicesBase = new ServicesBase("#server#" + "/db");
        await sb.ServiceRequest("", 'POST', { 'Content-Type': 'application/json' }, true, {
            name: name,
            prod: prod
        });
        await this.bdList.QueryItems();
    }

    selectDB(item) {
        let sb: ServicesBase = new ServicesBase("#server#" + "/db/" + item.id);
        sb.ServiceRequest("", 'POST', { 'Content-Type': 'application/json' }, true, { });
    }
}

export default withStyles(buttonStyles)(BDManager);