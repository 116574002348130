import React from 'react';

import './registerpage.styles.scss';
import {Grid, Link, Button} from '@material-ui/core'
import Particles from 'react-particles-js';

import RegisterForm from '../../components/registerform/registerform.component'
import {Link as RouterLink} from 'react-router-dom'
import Loading from '../../structure/common/loading/loading.component';
//import X from '../../components/x/x.component';

const RegisterPage = () => (
    <div className='register-page full-with-header'>
            <Grid className="z10 full-height auto-height-xs" container
            alignItems="center"
            direction="column"
            justify="center">
                <RegisterForm></RegisterForm>
            </Grid>
            {<Loading className="loading"></Loading>}
    </div>
)

export default RegisterPage;