
import React from 'react';
import {Route} from 'react-router-dom'
import GuestForm from '../guest/guest-form.component'
import CreateLinkForm from '../create-link/create-link.component'

const webUseRouter = (props) => (    
    <React.Fragment>
        <Route exact path='/dashboard/webUse/guests' component={() => <GuestForm/>} />
        <Route exact path='/dashboard/webUse/createLink' component={() => <CreateLinkForm/>} />
    </React.Fragment>
)

export default webUseRouter;