
import React from 'react';
import { Grid } from '@material-ui/core'

import './banners-preview.styles.scss'
import { temporalItem, COMMComp, invalidItem } from '../common/com'

import { isBrowser, isMobile, MobileView } from '../../utils/platformsUtils';


const maxDist = 20;

export const getCanvasSize = (canvasResW = 1920, canvasResH = 1080, screenToHtml = 0.0002) => {
    let canvasWidth = window.innerWidth * screenToHtml * canvasResW;
    let canvasHeight = window.innerWidth * screenToHtml * canvasResH;
    if (!isBrowser) {
        canvasWidth = window.innerHeight * screenToHtml * canvasResW;
        canvasHeight = window.innerHeight * screenToHtml * canvasResH;
    }
    return { width: canvasWidth, height: canvasHeight };
}

class MouseTouchTracker {
    constructor(canvas, callback, width, height) {
        this.canvas = canvas;
        this.callback = callback;

        this.width = width;
        this.height = height;

        this.canvas.ontouchmove = this.onMove.bind(this);
        this.canvas.onmousemove = this.onMove.bind(this);

        this.canvas.ontouchstart = this.onDown.bind(this);
        this.canvas.onmousedown = this.onDown.bind(this);
        this.canvas.ontouchend = this.onUp.bind(this);
        this.canvas.onmouseup = this.onUp.bind(this);
    }

    processEvent(evt) {
        var rect = this.canvas.getBoundingClientRect();
        var offsetTop = rect.top;
        var offsetLeft = rect.left;

        if (evt.touches) {
            return {
                x: (evt.touches[0].clientX - offsetLeft) / rect.width * this.width,
                y: (evt.touches[0].clientY - offsetTop) / rect.height * this.height
            }
        } else {
            return {
                x: (evt.clientX - offsetLeft) / rect.width * this.width,
                y: (evt.clientY - offsetTop) / rect.height * this.height
            }
        }
    }

    onDown(evt) {
        evt.preventDefault();
        var coords = this.processEvent(evt);
        this.callback('down', coords.x, coords.y);
    }

    onUp(evt) {
        evt.preventDefault();
        this.callback('up');
    }

    onMove(evt) {
        evt.preventDefault();
        var coords = this.processEvent(evt);
        this.callback('move', coords.x, coords.y);
    }
}

export class Rectangle {
    constructor(x, y, width, height, color = '#404080') {
        this.x = x;
        this.y = y;

        this.width = width;
        this.height = height;
        this.isDragging = false;
        this.color = color;
    }

    render(ctx, relWidth = 1, relHeight = 1) {
        ctx.save();

        ctx.beginPath();
        ctx.rect(this.x * relWidth, this.y * relHeight, this.width * relWidth, this.height * relHeight);
        ctx.fillStyle = this.color;
        ctx.fill();

        ctx.restore();
    }

    isHit(x, y, padx = 0, pady = 0) {
        return (x >= this.x - padx && y >= this.y - pady && x <= this.x + this.width + padx && y <= this.y + this.height + pady);
    }
}

class BannerRect extends Rectangle {
    constructor(x, y, width, height, name, color = '#404080') {
        super(x, y, width, height, color);
        this.name = name;
    }

    render(ctx, relWidth = 1, relHeight = 1) {
        super.render(ctx, relWidth, relHeight);

        ctx.font = `{70*relWidth}px Arial`;
        ctx.textAlign = 'center';
        ctx.fillText(this.name, (this.x + this.width / 2) * relWidth, (this.y + this.height / 2) * relHeight);
    }
}

//var Arc = function (x, y, radius, radians) {
//    this.x = x;
//    this.y = y;
//    this.radius = radius;
//    this.radians = radians;
//    this.isDragging = false;

//    this.render = function (ctx) {
//        ctx.save();

//        ctx.beginPath();
//        ctx.arc(this.x, this.y, this.radius, 0, this.radians, false);
//        ctx.fillStyle = '#404080';
//        ctx.fill();

//        ctx.restore();
//    }
//}

class ValidateRects {
    rects: Rectangle[];
    constructor(width, height, rects) {
        this.setDim(width, height);
        this.setRects(rects);
    }

    setDim = (width, height) => {
        this.width = width;
        this.height = height;
    }

    setRects = (rects) => {
        this.rects = rects;
    }

    colRects() {
        for (let x1 = 0; x1 < this.rects.length; x1++) {
            for (let x2 = 0; x2 < this.rects.length; x2++) {
                if (x1 != x2) {
                    if (this.rects[x2].isHit(this.rects[x1].x, this.rects[x1].y) ||
                        this.rects[x2].isHit(this.rects[x1].x + this.rects[x1].width, this.rects[x1].y) ||
                        this.rects[x2].isHit(this.rects[x1].x, this.rects[x1].y + this.rects[x1].height) ||
                        this.rects[x2].isHit(this.rects[x1].x + this.rects[x1].width, this.rects[x1].y + this.rects[x1].height) ||
                        (this.rects[x2].x < this.rects[x1].x && this.rects[x2].x + this.rects[x2].width > this.rects[x1].x + this.rects[x1].width && this.rects[x2].y > this.rects[x1].y && this.rects[x2].y < this.rects[x1].y + this.rects[x1].height)) {

                        return { r1: this.rects[x1], r2: this.rects[x2] };
                    }
                }
            }
        }

        return false;
    }

    checkBounds(rD) {
        return !(rD.x < 0 || rD.x + rD.width >= this.width || rD.y < 0 || rD.y + rD.height >= this.height);
    }

    checkRect(rD) {
        return !(this.colRects() || !this.checkBounds(rD));
    }

    checkRects() {
        if (this.colRects()) return false;

        for (let r = 0; r < this.rects.length; r++) {
            if (!this.checkBounds(this.rects[r])) return false;
        }
        return true;
    }
}

class ValidateRectsPreviewBanners extends ValidateRects {
    checkRects() {
        let col = this.colRects();
        if (col) {
            let rD: Rectangle;
            let rS: Rectangle;

            if (col.r1.isDragging) {
                rD = col.r1;
                rS = col.r2;
            } else {
                rS = col.r1;
                rD = col.r2;
            }
            let xCD = rD.x + rD.width / 2;
            let yCD = rD.y + rD.height / 2;
            let xCS = rS.x + rS.width / 2;
            let yCS = rS.y + rS.height / 2;

            let dx = rD.x - rS.x;
            let dy = rD.y - rS.y;

            if (Math.abs(dx) > Math.abs(dy)) {
                if (xCD > xCS) rD.x = rS.x + rS.width + 1;
                else rD.x = rS.x - rD.width - 1;
            } else {
                if (yCD > yCS) rD.y = rS.y + rS.height + 1;
                else rD.y = rS.y - rD.height - 1;
            }
            return super.checkRect(rD);
        }
        return true;
    }
}

export class BannersPreview extends React.Component {
    validateRects: ValidateRectsPreviewBanners;
    listBannersComp: COMMComp;
    width;
    height;

    constructor(props) {
        super(props);

        this.state = {
            cursor: "default"
        }
    }

    ctx = {};
    rects: Rectangle[] = [];
    banners = [];

    getCursorAction(r, X, Y) {
        if (!this.props.allowResize) return Array(8).fill(false);

        const dist = (x1, y1, x2, y2) => Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));        
        return [
            dist(r.x, r.y, X, Y) < maxDist,
            dist(r.x + r.width, r.y, X, Y) < maxDist,
            dist(r.x, r.y + r.height, X, Y) < maxDist,
            dist(r.x + r.width, r.y + r.height, X, Y) < maxDist,

            dist(X, r.y, X, Y) < maxDist,
            dist(r.x, Y, X, Y) < maxDist,
            dist(r.x + r.width, Y, X, Y) < maxDist,
            dist(X, r.y + r.height, X, Y) < maxDist,
        ];
    }
    
    onMouseMove(e) {
        const componentRect = e.target.getBoundingClientRect();
        const X = (e.clientX - componentRect.left) * (1 / this.relWidth);
        const Y = (e.clientY - componentRect.top) * (1 / this.relHeight);
        let h = this.rects.find(r => r.isHit(X, Y, maxDist, maxDist));

        let c = "default";
        if (h ) {
            c = "pointer";            
            let p: [] = this.getCursorAction(h, X, Y);
            if (p[0]) c = "nwse-resize";
            else if (p[1]) c = "nesw-resize";
            else if (p[2]) c = "nesw-resize";
            else if (p[3]) c = "nwse-resize";
            else if (p[4]) c = "n-resize";
            else if (p[5]) c = "e-resize";
            else if (p[6]) c = "w-resize";
            else if (p[7]) c = "s-resize";
        }
        this.setState({ cursor: c });
    }

    refCanvas = React.createRef();

    render = () => {
        this.canvasInitialized = false;

        this.width = this.props.width;
        this.height = this.props.height;
        this.relWidth = this.props.canvasWidth / this.props.width;
        this.relHeight = this.props.canvasHeight / this.props.height;
        //if (this.refCanvas && this.refCanvas.current) {
        //    this.relWidth = this.refCanvas.current.offsetWidth / this.props.width;
        //    this.relHeight = this.refCanvas.current.offsetHeight / this.props.height;
        //}
        let style = { "background-color": this.props.bgColor ? this.props.bgColor : "#c0c0e0" };

        Object.assign(style, { cursor: this.state.cursor });
    //    style = {{ width: '200px', height: '200px', backgroundColor: 'gray', cursor: hovered ? 'pointer' : 'default' }
    //}
        //if (isBrowser)
        //    Object.assign(style, { height: "50vh" });
        //else
        //    Object.assign(style, { width: "100%" });

        return (
            <Grid className={this.props.className + " banners-preview"} child container justify="center" xs="12" >
                <canvas ref={this.refCanvas} id="previewCanvas" width={this.props.canvasWidth} height={this.props.canvasHeight} style={style} onMouseMove={this.onMouseMove.bind(this)}/>
            </Grid>
        )
    }

    initCanvas() {
        this.canvas = document.getElementById('previewCanvas');
        if (this.canvas == null) return;

        this.ctx = this.canvas.getContext('2d');
        var startX = 0;
        var startY = 0;
        var recStartX = 0;
        var recStartY = 0;

        this.validateRects = new ValidateRectsPreviewBanners(this.props.width, this.props.height, this.rects);

        new MouseTouchTracker(this.canvas, (evtType, x, y) => {
            //console.log(`MOUSE POS ${x} ${y}`)
            this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

            switch (evtType) {
                case 'down':
                    startX = x;
                    startY = y;

                    let Dragging = false;
                    for (let i = 0; i < this.rects.length; i++) Dragging |= this.rects[i].isDragging;

                    if (!Dragging) {
                        for (let i = 0; i < this.rects.length; i++) {
                            if (this.rects[i].isHit(x, y, maxDist, maxDist)) {
                                this.rects[i].isDragging = true;
                                recStartX = this.rects[i].x;
                                recStartY = this.rects[i].y;
                                this.rects[i].dragAction = this.getCursorAction(this.rects[i], x, y);
                                break;
                            }
                        }
                    }
                    break;

                case 'up':
                    for (let i = 0; i < this.rects.length; i++) {
                        if (this.rects[i].isDragging) {
                            if (this.rects[i].x < 0) this.rects[i].x = 0;
                            if (this.rects[i].x + this.rects[i].width > this.width) this.rects[i].x = this.width - this.rects[i].width - 1;
                            if (this.rects[i].y < 0) this.rects[i].y = 0;
                            if (this.rects[i].y + this.rects[i].height > this.height) this.rects[i].y = this.height - this.rects[i].height - 1;

                            if (!this.validateRects.checkRects()) {
                                this.rects[i].x = recStartX;
                                this.rects[i].y = recStartY;
                            } else {                                
                                if (this.listBannersComp != undefined) this.listBannersComp.addNewValidatedData(this.banners[i], { posX: Math.floor(this.rects[i].x), posY: Math.floor(this.rects[i].y) });
                                if (this.props.OnChange != undefined) this.props.OnChange(this.banners[i], { posX: Math.floor(this.rects[i].x), posY: Math.floor(this.rects[i].y), sizeX: Math.floor(this.rects[i].width), sizeY: Math.floor(this.rects[i].height) });
                            }
                            this.rects[i].isDragging = false;
                        }
                    }
                    break;

                case 'move':
                    var dx = x - startX;
                    var dy = y - startY;
                    startX = x;
                    startY = y;

                    for (let i = 0; i < this.rects.length; i++) {
                        if (this.rects[i].isDragging) {
                            const minSize = 200;
                            if (this.rects[i].dragAction[0]) {
                                this.rects[i].x += dx;
                                this.rects[i].y += dy;

                                this.rects[i].width = Math.max(minSize, this.rects[i].width - dx);
                                this.rects[i].height = Math.max(minSize, this.rects[i].height - dy);
                            } else if (this.rects[i].dragAction[1]) {                                
                                this.rects[i].y += dy;

                                this.rects[i].width = Math.max(minSize, this.rects[i].width + dx);
                                this.rects[i].height = Math.max(minSize, this.rects[i].height - dy);
                            } else if (this.rects[i].dragAction[2]) {
                                this.rects[i].x += dx;

                                this.rects[i].width = Math.max(minSize, this.rects[i].width - dx);
                                this.rects[i].height = Math.max(minSize, this.rects[i].height + dy);
                            }else if (this.rects[i].dragAction[3]) {
                                this.rects[i].width = Math.max(minSize,this.rects[i].width + dx);
                                this.rects[i].height = Math.max(minSize, this.rects[i].height + dy);
                            } else if (this.rects[i].dragAction[4]) {
                                this.rects[i].y += dy;
                                this.rects[i].height = Math.max(minSize, this.rects[i].height - dy);
                            } else if (this.rects[i].dragAction[5]) {
                                this.rects[i].x += dx;
                                this.rects[i].width = Math.max(minSize, this.rects[i].width - dx);
                            } else if (this.rects[i].dragAction[6]) {
                                this.rects[i].width = Math.max(minSize, this.rects[i].width + dx);
                            } else if (this.rects[i].dragAction[7]) {
                                this.rects[i].height = Math.max(minSize, this.rects[i].height + dy);
                            } else {
                                //console.log(`MOUSE MOVE ${dx} ${dx} => ${this.rects[i].x + dx} ${this.rects[i].y + dy}`)
                                this.rects[i].x += dx;
                                this.rects[i].y += dy;
                            }
                        }
                    }

                    break;
            }
            for (let i = 0; i < this.rects.length; i++)  this.rects[i].render(this.ctx, this.relWidth, this.relHeight);
        }, this.props.width, this.props.height);
    }

    canvasInitialized = false;
    setBanners(comp = null, banners = null) {
        this.listBannersComp = comp;

        if (!this.canvasInitialized) {
            this.initCanvas();
            if (this.canvas == null) return;
            this.canvasInitialized = true;
        }

        this.banners = banners;

        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

        if (banners == null)
            this.rects = this.props.banners;
        else
            this.rects = this.bannersToRects(banners);
        this.validateRects.setRects(this.rects);

        for (let i = 0; i < this.rects.length; i++) {
            this.rects[i].render(this.ctx, this.relWidth, this.relHeight);
        }
    }
    FindBannerPlace(bSizeX, bSizeY) {
        const b = new BannerRect(0, 0, bSizeX, bSizeY, "");
        const tempRects: [] = this.rects.concat();
        tempRects.push(b);
        const vr = new ValidateRects(this.width, this.height, tempRects);
        while (true) {
            if (vr.checkRect(b)) return { posX: b.x, posY: b.y };
            if (b.x + b.width < this.width)
                b.x += 1; //TODO: Si es lento, sumar x del rectangulo con el que a colisionado
            else if (b.y + b.height < this.height) {
                b.x = 0;
                b.y += 1;                
            }
            else break;
        }
        return null;
    }
    bannerToRect(banner) {
        let b = temporalItem(banner);
        return new BannerRect(+b.posX, +b.posY, +b.sizeX, +b.sizeY, b.name, banner.color ? banner.color: undefined);
    }
    bannersToRects(banners, validate = false) {
        let rects: BannerRect = [];
        for (let i = 0; i < banners.length; i++) {
            rects.push(this.bannerToRect(banners[i]));
        }
        return rects;
    }

    ValidateRects(items) {
        const validateRects = new ValidateRects(this.width, this.height, this.bannersToRects(items, true));
        return validateRects != null && validateRects.checkRects();
    }

    componentDidMount() {
        if (this.props.banners != undefined) setTimeout(() => {
            console.log(this.props.banners);
            this.setBanners();
        }, 200);
    }
}