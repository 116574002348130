import React from 'react';
import { LangStrs, Lang } from '../../utils/languageUtils'
import { BrowserView, MobileView, isBrowser, isMobile } from '../../utils/platformsUtils';
import './cookies.styles.scss';
import { Button, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles'
//import X from '../../components/x/x.component';

export const strs: LangStrs = new LangStrs(new Map(
    [
        ['info', new Map(
            [
                [Lang.ES
                    , "\
<h1>Política de cookies</h1><br/>\
<br/>\
Powerads informa acerca del uso de las cookies en su página web: powerads.tv<br/>\
<br/>\
<br/>\
<b>¿Qué son las cookies?</b><br/>\
<br/>\
<br/>\
Las cookies son archivos que se pueden descargar en su equipo a través de las páginas web. Son herramientas que tienen un papel esencial para la prestación de numerosos servicios de la sociedad de la información. Entre otros, permiten a una página web almacenar y recuperar información sobre los hábitos de navegación de un usuario o de su equipo y, dependiendo de la información obtenida, se pueden utilizar para reconocer al usuario y mejorar el servicio ofrecido.<br/>\
<br/>\
<br/>\
<b>Tipos de cookies</b><br/>\
<br/>\
 <br/>\
Según quien sea la entidad que gestione el dominio desde donde se envían las cookies y trate los datos que se obtengan se pueden distinguir dos tipos:<br/>\
<br/>\
    Cookies propias: aquéllas que se envían al equipo terminal del usuario desde un equipo o dominio gestionado por el propio editor y desde el que se presta el servicio solicitado por el usuario.<br/>\
<br/>\
    Cookies de terceros: aquéllas que se envían al equipo terminal del usuario desde un equipo o dominio que no es gestionado por el editor, sino por otra entidad que trata los datos obtenidos través de las cookies.<br/>\
<br/>\
<br/>\
Esta web utiliza Google Analytics, un servicio de análisis de web proporcionado por Google Inc. (1600 Amphitheatre Parkway, Mountain View, CA 94043, Estados Unidos; en lo sucesivo, «Google»). Google Analytics utiliza «cookies», que son archivos de texto almacenados en el ordenador de los visitantes, para ayudar a PowerAds a analizar su uso del sitio web. La información generada por las «cookies» sobre el uso que hacen los visitantes de la web se enviará a Google, quien la almacenará en servidores situados en los Estados Unidos.<br/>\
<br/>\
En esta web, se ha activado la anonimización de la IP. Las direcciones IP de los usuarios de Google de países miembros de la Unión Europea, o de otros países participantes en el acuerdo del Espacio Económico Europeo, serán acortadas. Solo en casos excepcionales se enviará la dirección IP completa a los servidores de Google en Estados Unidos y se acortará allí. Google utilizará esta información en nombre de PowerAds con el propósito de evaluar el sitio web para PowerAds, para así compilar información sobre la actividad del sitio web y ofrecer otros servicios relacionados con la actividad del sitio web y el uso de internet.<br/>\
<br/>\
Google no asociará las direcciones de IP transferidas en el contexto de Google Analytics con ningún otro dato en su posesión. Para más información, visita https://support.google.com/analytics/answer/6004245?hl=es.<br/>\
<br/>\
Los usuarios pueden evitar la recogida de datos sobre el uso del sitio web generados por las «cookies» (incluida la dirección IP), así como el tratamiento de datos por parte de Google, descargando e instalando el plug-in del navegador a través del siguiente enlace: http://tools.google.com/dlpage/gaoptout?hl=es.<br/>\
<br/>\
La base legal para usar Google Analytics y para analizar el uso del sitio web es el artículo 6, párrafo 1 (f) del RGPD. El interés legítimo que PowerAds persigue es mejorar y optimizar el sitio web para beneficiar al visitante.<br/>\
<br/>\
Podrás, en cualquier momento, ejercer tu derecho de objeción por motivos relacionados con tu situación particular, para lo cual podrás descargar e instalar el ya mencionado plug-in del navegador.<br/>\
<br/>\
<br/>\
En el caso de que las cookies sean instaladas desde un equipo o dominio gestionado por el propio editor pero la información que se recoja mediante éstas sea gestionada por un tercero, no pueden ser consideradas como cookies propias.<br/>\
<br/>\
<br/>\
Existe también una segunda clasificación según el plazo de tiempo que permanecen almacenadas en el navegador del cliente, pudiendo tratarse de:<br/>\
<br/>\
    Cookies de sesión: diseñadas para recabar y almacenar datos mientras el usuario accede a una página web. Se suelen emplear para almacenar información que solo interesa conservar para la prestación del servicio solicitado por el usuario en una sola ocasión (p.e. una lista de productos adquiridos).<br/>\
<br/>\
    Cookies persistentes: los datos siguen almacenados en el terminal y pueden ser accedidos y tratados durante un periodo definido por el responsable de la cookie, y que puede ir de unos minutos a varios años.<br/>\
<br/>\
<br/>\
Por último, existe otra clasificación con cinco tipos de cookies según la finalidad para la que se traten los datos obtenidos:<br/>\
<br/>\
    Cookies técnicas: aquellas que permiten al usuario la navegación a través de una página web, plataforma o aplicación y la utilización de las diferentes opciones o servicios que en ella existan como, por ejemplo, controlar el tráfico y la comunicación de datos, identificar la sesión, acceder a partes de acceso restringido, recordar los elementos que integran un pedido, realizar el proceso de compra de un pedido, realizarla solicitud de inscripción o participación en un evento, utilizar elementos de seguridad durante la navegación, almacenar contenidos para la difusión de vídeos o sonido o compartir contenidos a través de redes sociales.<br/>\
<br/>\
    Cookies de personalización: permiten al usuario acceder al servicio con algunas características de carácter general predefinidas en función de una serie de criterios en el terminal del usuario como por ejemplo serian el idioma, el tipo de navegador a través del cual accede al servicio, la configuración regional desde donde accede al servicio, etc.<br/>\
<br/>\
    Cookies de análisis: permiten al responsable de las mismas, el seguimiento y análisis del comportamiento de los usuarios de los sitios web a los que están vinculadas. La información recogida mediante este tipo de cookies se utiliza en la medición de la actividad de los sitios web, aplicación o plataforma y para la elaboración de perfiles de navegación de los usuarios de dichos sitios, aplicaciones y plataformas, con el fin de introducir mejoras en función del análisis de los datos de uso que hacen los usuarios del servicio.<br/>\
<br/>\
    Cookies publicitarias: permiten la gestión, de la forma más eficaz posible, de los espacios publicitarios.<br/>\
<br/>\
    Cookies de publicidad comportamental: almacenan información del comportamiento de los usuarios obtenida a través de la observación continuada de sus hábitos de navegación, lo que permite desarrollar un perfil específico para mostrar publicidad en función del mismo.<br/>\
<br/>\
    Cookies de redes sociales externas: se utilizan para que los visitantes puedan interactuar con el contenido de diferentes plataformas sociales (facebook, youtube, twitter, linkedIn, etc..) y que se generen únicamente para los usuarios de dichas redes sociales. Las condiciones de utilización de estas cookies y la información recopilada se regula por la política de privacidad de la plataforma social correspondiente.<br/>\
<br/>\
 <br/>\
<b>Desactivación y eliminación de cookies</b><br/>\
<br/>\
 <br/>\
Tienes la opción de permitir, bloquear o eliminar las cookies instaladas en tu equipo mediante la configuración de las opciones del navegador instalado en su equipo. Al desactivar cookies, algunos de los servicios disponibles podrían dejar de estar operativos. La forma de deshabilitar las cookies es diferente para cada navegador, pero normalmente puede hacerse desde el menú Herramientas u Opciones. También puede consultarse el menú de Ayuda del navegador donde puedes encontrar instrucciones. El usuario podrá en cualquier momento elegir qué cookies quiere que funcionen en este sitio web. Puede usted permitir, bloquear o eliminar las cookies instaladas en su equipo mediante la configuración de las opciones del navegador instalado en su ordenador:<br/>\
<br/>\
 <br/>\
<b>Aceptación de la Política de cookies</b><br/>\
<br/>\
powerads.tv asume que usted acepta el uso de cookies. No obstante, muestra información sobre su Política de cookies en la parte inferior o superior de cualquier página del portal con cada inicio de sesión con el objeto de que usted sea consciente.<br/>\
<br/>\
Ante esta información es posible llevar a cabo las siguientes acciones:<br/>\
<br/>\
    Aceptar cookies. No se volverá a visualizar este aviso al acceder a cualquier página del portal durante la presente sesión.<br/>\
	<br/>\
	Cerrar. Se oculta el aviso en la presente página.<br/>\
	<br/>\
    Modificar su configuración. Podrá obtener más información sobre qué son las cookies, conocer la Política de cookies de powerads.tv y modificar la configuración de su navegador.<br/>\
<br/>\
<br/>\
"],
                [Lang.EN, '\
<h1>Cookie Policy</h1><br/>\
<br/>\
Powerads informs about the use of cookies on its website: powerads.tv<br/>\
<br/>\
<br/>\
<b>What are cookies?</b><br/>\
<br/>\
<br/>\
Cookies are files that can be downloaded to your computer by websites. They are tools that play an essential role in the provision of many services of the information society. Among others, they allow a website to store and retrieve information about the browsing habits of a user or their computer and, depending on the information obtained, can be used to recognize the user and improve the service offered.<br/>\
<br/>\
<br/>\
<b>Types of cookies</b><br/>\
<br/>\
 <br/>\
Depending on who is the entity that manages the domain from which cookies are sent and treat the data obtained, two types can be distinguished:<br/>\
<br/>\
    Own cookies: those that are sent to the user\'s terminal equipment from a computer or domain managed by the editor itself and from which the service requested by the user is provided.<br/>\
<br/>\
    Third-party cookies: those that are sent to the user\'s terminal equipment from a computer or domain that is not managed by the editor, but by another entity that processes the data obtained through the cookies.<br/>\
 <br/>\
<br/>\
This website uses Google Analytics, a web analytics service provided by Google Inc. (1600 Amphitheatre Parkway, Mountain View, CA 94043, United States; hereinafter "Google"). Google Analytics uses "cookies", which are text files stored on your computer, to help PowerAds analyze your use of the website. The information generated by the cookie about your use of the website will be sent to and stored by Google on servers in the United States.<br/>\
<br/>\
On this website, IP anonymization has been activated. IP addresses of Google users from member states of the European Union or other countries participating in the European Economic Area agreement will be shortened. Only in exceptional cases will the full IP address be sent to and shortened by Google servers in the USA. Google will use this information on behalf of PowerAds for the purpose of evaluating the website for PowerAds, compiling information about website activity for website operators and providing other services relating to website activity and internet usage.<br/>\
<br/>\
Google will not associate the IP addresses transferred in the context of Google Analytics with any other data held by Google. For more information, visit https://support.google.com/analytics/answer/6004245?hl=es.<br/>\
<br/>\
Users can prevent the collection of data on the use of the website generated by cookies (including IP address) as well as the processing of data by Google by downloading and installing the browser plug-in via the following link: http://tools.google.com/dlpage/gaoptout?hl=es.<br/>\
<br/>\
The legal basis for using Google Analytics and for analyzing the use of the website is Article 6, paragraph 1 (f) of the GDPR. The legitimate interest that PowerAds pursues is to improve and optimize the website for the benefit of the visitor.<br/>\
<br/>\
You may, at any time, exercise your right to object on grounds relating to your particular situation by downloading and installing the aforementioned browser plug-in.<br/>\
 <br/>\
<br/>\
In the event that the cookies are installed from a computer or domain managed by the editor itself but the information collected through them is managed by a third party, they cannot be considered as own cookies.<br/>\
<br/>\
 <br/>\
There is also a second classification according to the length of time they remain stored in the customer\'s browser, being able to be:<br/>\
<br/>\
    Session cookies: designed to collect and store data while the user accesses a web page. They are usually used to store information that is only interesting to keep for the provision of the service requested by the user on a single occasion (e.g. a list of products purchased).<br/>\
<br/>\
    Persistent cookies: the data remains stored in the terminal and can be accessed and processed for a period defined by the cookie manager, which can range from a few minutes to several years.<br/>\
<br/>\
 <br/>\
Finally, there is another classification with five types of cookies according to the purpose for which the data obtained are processed:<br/>\
<br/>\
Technical Cookies: those that allow the user to navigate through a website, platform or application and the use of the different options or services it exist as, for example, control traffic and data communication, identify the session, access parts of restricted access, remember the elements that make up an order, make the buying process of an order, make the application for registration or participation in an event, use security features while browsing, store content for broadcast video or sound or share content via social networks.<br/>\
<br/>\
    Customization Cookies: allow the user to access the service with some general characteristics predefined according to a series of criteria in the user\'s terminal such as the language, the type of browser through which you access the service, the locale from which you access the service, etc..<br/>\
<br/>\
    Analysis Cookies: allow the responsible for them, monitoring and analyzing the behavior of users of the websites to which they are linked. The information collected through this type of cookies is used to measure the activity of the websites, application or platform and for the elaboration of browsing profiles of the users of these sites, applications and platforms, in order to introduce improvements based on the analysis of the usage data of the users of the service.<br/>\
<br/>\
    Advertising cookies: allow the management, in the most effective way possible, of advertising spaces.<br/>\
<br/>\
    Behavioral advertising cookies: store information on user behavior obtained through the continuous observation of their browsing habits, allowing the development of a specific profile to display advertising based on the same.<br/>\
<br/>\
    Cookies from external social networks: they are used so that visitors can interact with the content of different social platforms (facebook, youtube, twitter, linkedIn, etc..) and that are generated only for users of these social networks. The conditions of use of these cookies and the information collected is regulated by the privacy policy of the corresponding social platform.<br/>\
<br/>\
 <br/>\
<b>Disabling and deleting cookies</b><br/>\
<br/>\
 <br/>\
You have the option to allow, block or delete cookies installed on your computer by configuring the browser options installed on your computer. By disabling cookies, some of the available services may no longer be operational. The way to disable cookies is different for each browser, but can usually be done from the Tools or Options menu. You can also consult the browser\'s Help menu where you can find instructions. You may at any time choose which cookies you want to operate on this website. You can allow, block or delete cookies installed on your computer by configuring the browser options installed on your computer:<br/>\
<br/>\
 <br/>\
<b>Acceptance of the Cookies Policy</b><br/>\
<br/>\
powerads.tv assumes that you accept the use of cookies. However, it displays information about its Cookie Policy at the bottom or top of any page of the portal with each login in order to make you aware of it.<br/>\
<br/>\
In view of this information it is possible to carry out the following actions:<br/>\
<br/>\
    Accept cookies. This notice will not be displayed again when accessing any page of the portal during the current session.<br/>\
	<br/>\
	Close. The warning is hidden on this page.<br/>\
	<br/>\
    Modify your settings. You can find out more about what cookies are, learn about the powerads.tv Cookie Policy and change your browser settings.<br/>\
']
            ])
        ],
    ]
));

const StreamerPage = () => {    
    return (
        <div dangerouslySetInnerHTML={{ __html: strs.get('info') }} style={{ "padding": "20px" }}/>
    )
}

export default StreamerPage;