
import React from 'react';
import { connect } from 'react-redux';

import ContentNavigation from '../../../structure/content-navigation/content-navigation.component'
import Streamers from '../../../services/api/streamers';
import { commService } from '../../../services/api/list';
import GlobalState from '../../../store/globalState'
import DeleteItemDialog from '../../common/deleteItemDialog/deleteItemDialog.component.jsx';
import AddNewButton from '../../common/add-new-button/add-new-button.component.jsx';
import UserUtils from '../../../utils/userUtils'
import { callAsyncOrNot } from '../../../utils/utils'
import { requestChangePassword } from '../../../utils/userUtils'
import { withStyles } from '@material-ui/core/styles'
import { isBrowser, isMobile, MobileView } from '../../../utils/platformsUtils';
import { Link as RouterLink } from 'react-router-dom';
import { FormLabel, RadioGroup, FormControlLabel, Radio, Tooltip, Fab, Grid, Snackbar, Button, Link, Table, TableCell, TableRow, TableHead, TableFooter, TableBody, TableContainer } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add';
import MuiAlert from '@material-ui/lab/Alert';
import { MuiThemeProvider, TextField, Select, InputLabel, FormControl } from '@material-ui/core/'
import { Save, Delete, Link as LinkIcon, Visibility, AttachMoney, Edit, FileCopy } from '@styled-icons/material';
import ErrorWarnTheme from '../../../styles/ErrorWarnTheme';

import { myReactTaggedStateComponent, InLineComponent, ConditionalComponent } from '../../common/extComponents'
import { BroadCastTimePicker, standarValueBroadcastTime, CountDown } from '../../../components/common/commonComponents'
import moment from 'moment';

import { COMMComp, confCOM, confField, confFieldItem, fieldNameFunction, strfieldvalue, strvalue, CommButton, CommItemFieldLink, confGroup, addNewData, disabledContainerStyles, CommTextField } from '../com'

import { LangStrs, Lang } from '../../../utils/languageUtils'

let strs: LangStrs = new LangStrs(new Map(
    [
        ['Save', new Map(
            [
                [Lang.ES, 'GUARDAR'],
                [Lang.EN, 'SAVE']
            ])
        ],
        ['Create', new Map(
            [
                [Lang.ES, 'Crear'],
                [Lang.EN, 'CREATE']
            ])
        ],
        ['ChangePassword', new Map(
            [
                [Lang.ES, 'Cambiar contraseña'],
                [Lang.EN, 'Change password']
            ])
        ]
    ]
));

export class confForm extends confCOM {
    groups: confGroup[] = [];

    constructor(listComponentBase, service: commListService, fields: confField[] | confGroup[], id) {
        super(listComponentBase, service, fields);
        this.id = id;
        if (!(fields[0] instanceof confGroup)) {
            this.groups = [new confGroup("", 0, fields)];
        } else
            this.groups = fields;
        
    }
}

export class CommForm extends COMMComp {
    confForm: confForm;

    constructor(props) {
        super(props);
        this.confForm = this.props.confComm;        
        this.state = Object.assign(this.state, {
            item: {},
            loaded: false,
            error: false,
            dataSave: false
        });
    }

    componentDidMount = async () => {
        GlobalState.set("loading", true);
        if (!this.state.loaded) {
            if (this.confForm.id == undefined) {
                GlobalState.set("loading", false);
                await this.setState({ loaded: true });
            }else
                this.QueryItem();
        }
    }

    creatingItem = () => {
        return this.getItem().id == undefined;
    }

    DelItem = (item) => {
        this.setState({ itemToDelete: item });
    }

    SaveData = async () => {
        if (this.newDataToSave(this.state.item)) await this.UpdateData(this.state.item);
    }

    DelItemResult = async (result) => {
        if (result) {
            let serviceResponse = await this.confList.service.Del(UserUtils.GetUserData().id, this.state.itemToDelete.id);
            await this.QueryItem();
        }
        this.setState({ itemToDelete: null });
    }

    QueryItem = async () => {
        let serviceResponse = await this.confForm.service.Get(UserUtils.GetUserData().id, this.confForm.id);
        let data = serviceResponse.body[0];

        this.confFieldItems = []; // Se tienen que volver a crear, xk los busco por item y aqui se destruye
        await this.setState({ error: serviceResponse.error, item: data || [], loaded: true });

        this.OnQuery(data);
    }

    render = () => {
        if (!this.state.loaded) return null; //Si exteriorment es cambia l'estat del component es faria un render abans del componentDidMount, aixo ho preve

        super.render();
        this.confForm = this.props.confComm;
        const BaseComponent = this.confForm.listComponentBase;

        return (
            <BaseComponent {...this.props} fields={this.props.confComm.fields } currentPage={this.state.currentPage} parentComm={this} item={this.state.item} loaded={this.state.loaded} itemToDelete={this.state.itemToDelete} error={this.state.error} opentoast={this.state.opentoast} />
        )
    }

    getState = () => {
        return this.state;
    }

    newItem = async () => {
        let serviceResponse = await this.confList.service.Add(UserUtils.GetUserData().id, this.confList.addItem.defaultData);
        this.state.items.push(serviceResponse.body);
        this.setState({ error: serviceResponse.error, items: this.state.items || [], loaded: true });
    }

    addNewData(data) {
        addNewData(this.getItem(), data);
    }

    showError = async (errorstr) => {
        this.setState({ error: { error: errorstr } });
    }

    dataIsInvalid(cfi: confFieldItem) {
        if (!cfi.invalidData) {
            cfi.invalidData = true;
            this.setState({ dataSave: true }); // solo para hacer los renders i que vean que newData esta undefined
        }
    }
}

export class CommFormGen extends React.Component {
    confComm: confCOM;
    parent: CommForm;

    constructor(props) {
        super(props);        
        this.parent = this.props.parentComm;
    }

    render = () => {        
        this.confComm = this.props.confComm;

        const SubmitButtonComp = this.props.customSubmitButton ? this.props.customSubmitButton : Button;

        let lcolumns = Math.max.apply(Math, this.confComm.groups.map((f: confGroup) => f.column)) + 1;
        return (
            <Grid container direction="column" className={"profile"}>
                { this.props.title &&
                    <Grid className="form-column">
                        <h1>{this.props.title}</h1>
                    </Grid>
                }                
                {this.props.error && <MuiAlert severity="error">{this.props.error.error.toString()}</MuiAlert>}
                { /*this.state.created && <Redirect to={"/dashboard/banners/" + this.props.match.params.streamingAccount} />*/}
                <Grid child container className={'banner-form ' + this.props.className} direction="row" justify="flex-start" alignItems="flex-start">

                    <Grid child container direction="row">
                        {
                            this.confComm.groups.map((g: confGroup) => {
                                return (
                                    <Grid {...lcolumns == 1 ? { child: true, container: true, xs: this.props.xsColumn || "12" } : { xs:12, md:6, className:"profile-block",container:true, direction:"column"}}  >
                                        <h2> {g.title} </h2>
                                        <Grid container direction="row" justify="flex-start">
                                            <Grid item md={12} xs={12}>
                                                <Grid {...lcolumns == 1 ? {} : { container: true, justify: "space-between", style: { height: "100%" }, direction: "column" }}>
                                                    {
                                                        !(this.props.item == null || this.props.item == {}) && g.Fields.map(c => {
                                                            let conf: confFieldItem = this.parent.newconfFieldItem(c, this.props.item);
                                                            if (conf != null) {
                                                                const NC = conf.confField.field ? conf.confField.field : "noComponent";

                                                                let disabled = !conf.confField.noCommonDisabled && conf.confField.disabled && conf.confField.disabled(this.props.item);
                                                                return <ConditionalComponent TagComponent={"div"} Condition={disabled} bodyConditional={false} style={disabledContainerStyles}>
                                                                    <NC ref={(c) => { conf.component = c; conf.confField.ref && conf.confField.ref(c); } } parentComm={this.parent} confFieldItem={conf} item={this.props.item}></NC>
                                                                </ConditionalComponent>
                                                            }
                                                        })
                                                    }
                                                    { this.parent.setDefaultData(this.props.item.newData) }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>);
                            })
                        }
                        {!this.props.noSubmitButton &&
                            <Grid container xs={12} {...isMobile && { aligment: "center", alignItems: "center", justify: "center" }}>{
                                <SubmitButtonComp disabled={this.parent.isNewDataInvalid() || !this.parent.newDataToSave()} variant="contained" size="large" color="primary" onClick={() => { this.SaveData() }}>
                                    {this.props.submitButtonTitle || (this.parent.creatingItem() ? strs.get('Create') : strs.get('Save'))}
                                </SubmitButtonComp>
                            }
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    async SaveData() {
        if (this.props.BeforeSaveData == undefined || await callAsyncOrNot(this.props.BeforeSaveData)) this.parent.SaveData();
    }
}

export class CreatePasswordComponent extends React.Component {
    parent: COMMComp;
    confFieldItem: confFieldItem;

    constructor(props) {
        super(props);

        this.state = {
            validPass1: false,
            validPass2: false,
        }
    }
    
    render = () => {
        this.parent = this.props.parentComm;
        this.confFieldItem = this.props.confFieldItem;
        
        const confField: confField = this.confFieldItem;

        if (!this.state.validPass1 || !this.state.validPass2) {
            this.parent.dataIsInvalid(this.confFieldItem);
        }

        return (
            <Grid item>
                <TextField {...!this.state.validPass1 && { error: true, helperText: this.pass1Val!=undefined?"Minimum 8 characters":"" }} type="Password" className="form-input" fullWidth id="name-input" label="New Password" variant="outlined" InputLabelProps={{ shrink: true }} onChange={this.onPassword1Change}/>
                <TextField {...(!this.state.validPass1 || !this.state.validPass2) && { error: true, helperText: this.pass2Val && !this.state.validPass2?"The passwords are differents":"" }} type="Password" className="form-input" fullWidth id="name-input" label="Repeat new Password" variant="outlined" InputLabelProps={{ shrink: true }} onChange={this.onPassword2Change}/>
            </Grid>
        )
    }

    onPassword1Change = (e) => {
        this.pass1Val = e.target.value;

        this.onPasswordChange(this.pass1Val, this.pass2Val);
    }

    onPassword2Change = (e) => {
        this.pass2Val = e.target.value;
        //this.parent.DataChange(this, this.pass2Val);
        this.onPasswordChange(this.pass1Val, this.pass2Val);
    }

    onPasswordChange(pass1: string, pass2: string) {
        const confFieldItem: confFieldItem = this.props.confFieldItem;
        const confField: confField = confFieldItem.confField;

        let invPass1 = false;
        invPass1 = !pass1 || pass1.length < 8;

        this.setState({ validPass1: !invPass1, validPass2: pass2 != "" && pass1 == pass2 });
        this.parent.DataChange(this, this.pass1Val);
    }
}

export class ChangePasswordComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            changePassword: false
        }
    }

    render = () => {
        let parent: COMMComp = this.props.parentComm;
        const confFieldItem: confFieldItem = this.props.confFieldItem;
        const confField: confField = confFieldItem.confField;
        return (
            <Grid item>
                <Button className="profile-btn" size="large" color="secondary" variant="contained" onClick={(async () => { await requestChangePassword(this.props.item.email); this.props.confFieldItem.confField.onRequestDone(); }).bind(this)}>{ strs.get("ChangePassword") }</Button>
                {/*<Button className="profile-btn" size="large" color="secondary" variant="contained" onClick={(() => { this.setState({ changePassword: !this.state.changePassword }) }).bind(this)}>Change Password</Button>*/}
                {this.state.changePassword && <Grid item>
                    <TextField className="form-input" fullWidth id="name-input" label="Current Password" variant="outlined" />
                    <TextField className="form-input" fullWidth id="name-input" label="New Password" variant="outlined" />
                    <TextField className="form-input" fullWidth id="name-input" label="Repeat new Password" variant="outlined" />
                </Grid>}
            </Grid>
        )
    }
}

export class EndPointSelectorConf {
    constructor(endPoint, value = "id", title = null) {
        this.endPoint = endPoint;
        this.value = value;
        this.title = title;
    }

    getStringOrCall(atr, o) {
        if (typeof atr === 'function') {
            return atr(o);
        }
        return o[atr];
    }

    getValue(o) {
        return this.getStringOrCall(this.value, o);
    }
    getTitle(o) {
        if (this.title == null) return o.name.str[0];
        return this.getStringOrCall(this.title, o);
    }
}
export class EndPointSelectorComponent extends React.Component {
    epsConf: EndPointSelectorConf;
    lastQuery: string = "";

    constructor(props) {
        super(props);
        this.state = { options: null };

        this.epsConf = this.props.confFieldItem.confField.epsConf;
    }

    componentDidMount = async () => {
        await this.getOptions();
    }

    async getOptions() {
        if (this.lastQuery != this.epsConf.endPoint) {
            this.lastQuery = this.epsConf.endPoint
            let c = new commService(this.epsConf.endPoint);
            let r = await c.Get();
            this.setState({ options: r.body });
        }
    }

    render = () => {
        let parent: COMMComp = this.props.parentComm;
        const confFieldItem: confFieldItem = this.props.confFieldItem;
        const confField: confField = confFieldItem.confField;
        this.epsConf = this.props.confFieldItem.confField.epsConf;

        this.getOptions();
        return (
            <FormControl className="form-input" variant="outlined" error={confFieldItem.isInvalidData()}>
                <InputLabel htmlFor="filled-age-native-simple">{confField.name}</InputLabel>
                <Select
                    native
                    onChange={(e) => { parent.DataChange(this, e.target.value); }}
                    inputProps={{
                        name: confField.name,
                        id: 'filled-country',
                    }}
                >
                    <option aria-label="None" value="none"></option>
                    {
                        this.state.options != null && this.state.options.map(o => {
                            let selected = false;
                            if (this.epsConf.getValue(o) == strfieldvalue(this.props.confFieldItem, this.props.item))
                                selected = true;
                            return <option {...selected && { selected: "selected" }} truearia-label="None" value={this.epsConf.getValue(o)}>{this.epsConf.getTitle(o)}</option>
                        })
                    }
                </Select>
            </FormControl>
        )
    }
}

export class UploadVideoMedia extends React.Component {    
    constructor(props) {
        super(props);
        this.state = { selected:false }
    }

    componentDidMount = async () => {
        
    }

    render = () => {
        let parent: CommForm = this.props.parentComm;
        const confFieldItem: confFieldItem = this.props.confFieldItem;
        const confField: confField = confFieldItem.confField;

        return (            
            <div className="upload-ad-media">
                {/*(this.props.item.file || this.props.item.mediaUrl) && <div className="selected-media">
                    {this.props.item.mediaType == "img" && <img width={this.props.item.sizeX} height={this.props.item.sizeY} src={this.props.item.file ? URL.createObjectURL(this.props.item.file) : this.props.item.mediaUrl} alt={this.props.item.name} />}
                    {this.props.item.mediaType == "video" && <video controls poster="/images/w3html5.gif" poster={"data:image/png;base64," + this.props.item.thumbnail}>
                        <source src={this.props.item.file ? URL.createObjectURL(this.props.item.file) : this.props.item.mediaUrl} />
                    </video>}
                    {this.props.item.mediaUrl && this.props.item.file && <div className="media-close-button" onClick={() => { this.setState({ mediaType: this.props.item.mediaType ? this.props.item.mediaType : "img", mediaFile: {} }) }}>✕</div>}
                </div>*/}
                <div className="select-media">
                    <input style={{ display: "none" }} id="upload-photo" name="upload-photo" onChange={async (e) => {
                        parent.DataChange(this, { file: e.target.files[0] } );
                        this.setState({ selected: true });
                    }} type="file" />                        
                    <label htmlFor="upload-photo">
                        <Button color="secondary" variant="contained" component="span">
                            {confField.name}
                        </Button><span className="upload-label-text">{ this.state.selected && "File ready" }</span>
                    </label>
                </div>
            </div>            
        )
    }
}

export class RadioButtonList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedValue: this.getValue() !== undefined ? this.getValue() : '',
            hover: false
        };
        this.handleChange = this.handleChange.bind(this);

        this.compData = confField.concatProps(this.props);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if ('SelectedValue' in nextProps && nextProps.SelectedValue !== prevState.selectedValue) {
            return { selectedValue: nextProps.SelectedValue };
        }
        return null;
    }

    getValue() {
        return this.props.SelectedValue;
    }

    changeSelect(value) {        
        this.setState({ selectedValue: value });
    }

    handleChange(event) {
        this.changeSelect(event.target.value);
    }

    handleMouseEnter = () => {
        this.setState({ hover: true });
    };

    handleMouseLeave = () => {
        this.setState({ hover: false });
    };

    render() {       
        let { title, options, orientation } = this.compData;
        const { selectedValue, hover } = this.state;

        if (title == undefined) {
            title = confField.name;
        }

        const flexDirection = orientation === 'row' ? 'row' : 'column';

        const borderColor = selectedValue !== '' ? (hover ? '#000' : '#999') : (hover ? '#d00' : '#FF8899');
        const titleColor = selectedValue !== '' ? '#000' : (hover ? '#d00' : '#FF8899');

        return (
            <div
                style={{
                    border: `1px solid ${borderColor}`,
                    position: 'relative',
                    borderRadius: '5px',
                    padding: '10px',
                    ///marginTop: '10px',
                    marginBottom: '30px'
                }}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
            >
                <FormLabel
                    component="legend"
                    style={{
                        position: 'absolute',
                        top: '-10px',
                        left: '10px',
                        backgroundColor: '#fff',
                        padding: '0 5px',
                        color: titleColor
                    }}
                >
                    {title}
                </FormLabel>
                <FormControl component="fieldset">
                    <RadioGroup
                        aria-label={title}
                        name={title}
                        value={""+selectedValue}
                        onChange={this.handleChange}
                        style={{ flexDirection: flexDirection }}
                    >
                        {options.map((option, index) => (
                            <FormControlLabel
                                key={index}
                                value={""+option.value}
                                control={<Radio color="primary" />}
                                label={option.label}
                            />
                        ))}
                    </RadioGroup>
                </FormControl>
            </div>
        );
    }
}

export class CommRadioButtonList extends RadioButtonList {
    constructor(props) {
        super(props);

        let parent: COMMComp = this.props.parentComm;
        this.confFieldItem = this.props.confFieldItem;
        const confField: confField = this.confFieldItem.confField;
        //this.fieldProps = confField.fieldprops;
        this.compData.title = confField.name;       
    }

    getValue() {
        return strfieldvalue(this.props.confFieldItem, this.props.item);        
    }

    handleChange = (event) => {
        super.handleChange(event);

        let parent: COMMComp = this.props.parentComm;
        let valid = parent.DataChange(this, event.target.value);

        this.confFieldItem.confField.OnChange && this.confFieldItem.confField.OnChange(this, event.target.value);
    };
}

export class BroadCastTimePickerComm extends BroadCastTimePicker {
    constructor(props) {
        super(props);        

        let value = standarValueBroadcastTime(strfieldvalue(this.props.confFieldItem, this.props.item));
        this.state = {
            dateTime: value,
        };

        if (!this.props.confFieldItem.confField.checkvalue(strfieldvalue(this.props.confFieldItem, this.props.item))) {
            this.setError(this.state);
        }
        
        const fieldProps = this.props.confFieldItem.confField.fieldprops;
        this.remainingMsg = fieldProps.remainingMsg;
        this.passDateMsg = fieldProps.passDateMsg;
        this.title = this.props.confFieldItem.confField.name;

        this.style = fieldProps.style;
    }

    setError = (state=null) => {
        let e = this.props.confFieldItem.confField.fieldprops.errMsg;
        if (e) {
            if (state) state.errormsg = e;
            else this.setState({ errormsg: e });
        }
    }

    onDataChange(e) {
        let parent: COMMComp = this.props.parentComm;
        const momentUtc = moment(this.state.dateTime).utc();
        const r = parent.DataChange(this, momentUtc.unix() * 1000);
        if (!r) this.setError();
        if (r && this.state.errormsg) this.setState({ errormsg: undefined });
    }
}

export class ToggleButtonComm extends React.Component {
    constructor(props) {
        super(props);

        let value = strfieldvalue(this.props.confFieldItem, this.props.item);
        this.fieldProps = this.props.confFieldItem.confField.fieldprops;

        this.state = {
            currentValue: value,
            currentColor: value === this.fieldProps.value1 ? this.fieldProps.colorValue1 : this.fieldProps.colorValue2,
            currentText: value === this.fieldProps.value1 ? this.fieldProps.strValue1 : this.fieldProps.strValue2,
        };        
    }

    // Método para manejar el clic en el botón
    handleClick = () => {
        this.setState((prevState, props) => {
            // Determina el nuevo valor, color y texto basado en el valor actual y las props
            const newValue = prevState.currentValue === this.fieldProps.value1 ? this.fieldProps.value2 : this.fieldProps.value1;
            const newColor = newValue === this.fieldProps.value1 ? this.fieldProps.colorValue1 : this.fieldProps.colorValue2;
            const newText = newValue === this.fieldProps.value1 ? this.fieldProps.strValue1 : this.fieldProps.strValue2;

            let parent: COMMComp = this.props.parentComm;
            parent.DataChange(this, newValue);

            // Actualiza el estado con los nuevos valores
            return {
                currentValue: newValue,
                currentColor: newColor,
                currentText: newText,
            };
        });
    };

    render() {
        const { currentColor, currentText } = this.state;
        return (
            <Grid container direction="row" style={{ paddingBottom: '10px'}}>
                <Grid item xs={5} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', paddingLeft: '10px', paddingTop: '10px', height: '100%' }}>
                    {this.fieldProps && this.fieldProps.text}
                </Grid>
                <Grid item xs={6}>
                    <Button onClick={this.handleClick} style={{ backgroundColor: currentColor, color: 'white', width: '100%', height: '100%' }} >
                        {currentText}
                    </Button>
                </Grid>
            </Grid>
        );
    }
}