import React from 'react';
import { LangStrs, Lang } from '../../utils/languageUtils'
import { BrowserView, MobileView, isBrowser, isMobile } from '../../utils/platformsUtils';
import './advertiserpage.styles.scss';
import { Button, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles'
//import X from '../../components/x/x.component';

const StyledButton = (selected) => withStyles({
    root: {
        backgroundColor: selected ? '#8CD0F1' : '#1347be',
        marginBottom: '15px',
        marginLeft: '15px',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#8CD0F1',
            color: '#fff',
        },
    }
})(Button);

const SButton = StyledButton(false);

const descCard = (image, title, desc) => {
    return(
        <Grid item xl={2} justify="center" >
            <Grid container direction="column"  >
                <Grid item justify="center" style={{ "width": "250px", "height": "150px", margin:"auto" }}>
                    <img src={image} justify="center" style={{ "width": "100%", "height": "100%" }}/>
                </Grid>
                <Grid item justify="center">
                    <h2 justify="center">{title}</h2>
                </Grid>
                <Grid item justify="center">
                    <h3>{desc}</h3>
                </Grid>
            </Grid>
        </Grid>
    )
}

const AdvertiserPage = () => {
    let strs: LangStrs = new LangStrs(new Map(
        [
            ['title1', new Map(
                [
                    [Lang.ES, 'Un nuevo canal que te va hará llegar a tu publico de la forma mas directa !'],
                    [Lang.EN, 'A new channel that will allow you to reach your audience in the most direct way !']
                ])
            ],
            ['desc1', new Map(
                [
                    [Lang.ES, 'Difunde tu marca a través de los creadores de contenido, llega a tu target desde los directos o vídeos. El nuevo canal de comunicación que te acerca a tu audiencia'],
                    [Lang.EN, 'Spread your brand through content creators, reach your target with live streaming or recorded videos. The new communication channel that brings you closer to your audience']
                ])
            ],
            ['howDo', new Map(
                [
                    [Lang.ES, 'Que me ofrece PowerAds ?'],
                    [Lang.EN, 'What Does PowerAds offer me ?']
                ])
            ],
            ['howDoTitle1', new Map(
                [
                    [Lang.ES, 'Tu campaña en marcha ahora mismo'],
                    [Lang.EN, 'Your marketing campaign up and running']
                ])
            ],
            ['howDoDesc1', new Map(
                [
                    [Lang.ES, 'Tan pronto crees tu campaña esta se difundirá en el momento que desees, incluso hacer que aparezca en los directos/nuevos vídeos, de manera inmediata'],
                    [Lang.EN, 'As soon as you create your marketing campaign, it will be broadcast whenever you wish, including making it appear in live/new videos immediately']
                ])
            ],
            ['howDoTitle2', new Map(
                [
                    [Lang.ES, 'Establece quien quieres alcanzar'],
                    [Lang.EN, 'Establish who you want to reach']
                ])
            ],
            ['howDoDesc2', new Map(
                [
                    [Lang.ES, 'Configura tu campaña para filtrar tanto los creadores de contenido como la audiencia a la que quieres acceder'],
                    [Lang.EN, 'Set up your campaign to filter both the content creators and the audience you want to access']
                ])
            ],
            ['howDoTitle3', new Map(
                [
                    [Lang.ES, 'Trackeo del impacto'],
                    [Lang.EN, 'Impact tracking']
                ])
            ],
            ['howDoDesc3', new Map(
                [
                    [Lang.ES, 'Ofrecemos gadgets para poder conocer de donde, cuando y que perfil tiene sus nuevos usuarios'],
                    [Lang.EN, "We offer gadgets to find who and where your new users are and also when they joined"]
                ])
            ],
            ['howDoTitle4', new Map(
                [
                    [Lang.ES, 'Verificación de la campaña'],
                    [Lang.EN, 'Campaign verification']
                ])
            ],
            ['howDoDesc4', new Map(
                [
                    [Lang.ES, 'Se verifica una a una la aparición de su campaña en los vídeos y directos, los resultados se ofrecen a golpe de clic para una máxima transparencia, mostrando el momento exacto donde hace aparición su marca y enlazando el video/directo'],
                    [Lang.EN, "The Ads of your marketing campaign in the videos and live streams are verified one by one, the results are offered for maximum transparency, showing the exact moment and place your ad appears and is linked to video or stream"]
                ])
            ]
        ]
    ));

    return (
        <div className='homepage' style={{'alignItems': 'center', width: '100%' }} >
            <div>                
                <Grid container xl={12} xs={12} direction="column" justify="center" alignItems="center" wrap style={{ "padding": "20px" }} >
                    <Grid item justify="center">
                        <Grid container xl={12} xs={12} direction="row" justify="center"  wrap style={{ "padding": "0px" }} >
                            <Grid item xl={6} justify="center">
                                <img src="./img/advertiser/broadcast.png" width='100%' style={{ 'max-width': '600px', 'display': 'block', 'margin': 'auto auto' }} />
                            </Grid>
                            <Grid item xl={6} justify="center" style={{ "margin": "auto" }} >
                                <h1>{strs.get('title1')}</h1>
                                <h2>{strs.get('desc1')}</h2>
                            </Grid>
                        </Grid>
                        <Grid container xl={12} xs={12} direction="row" justify="center" wrap style={{ "padding": "0px" }} >
                            <h1>{strs.get('howDo')}</h1>
                        </Grid>
                        <Grid className='how-do-it' container xl={12} xs={12} direction="row" justify="center" wrap style={{ "padding": "0px" }} spacing={40}>
                            {descCard('./img/advertiser/rocket.svg', strs.get('howDoTitle1'), strs.get('howDoDesc1'))}
                            {descCard('./img/advertiser/selection.svg', strs.get('howDoTitle2'), strs.get('howDoDesc2'))}
                            {descCard('./img/advertiser/graphAnalisys.svg', strs.get('howDoTitle3'), strs.get('howDoDesc3'))}
                            {descCard('./img/advertiser/eyeCheck.svg', strs.get('howDoTitle4'), strs.get('howDoDesc4'))}
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default AdvertiserPage;