
import React from 'react';

import './payment-methods-list.styles.scss'
import { Grid, TableCell, Button} from '@material-ui/core';
import DeleteItemDialog from '../common/deleteItemDialog/deleteItemDialog.component.jsx'
import AddNewButton from '../common/add-new-button/add-new-button.component.jsx'
import PaymentMethodItem from '../payment-method-item/payment-method-item.component.jsx'

import { changeUrl, Urls } from '../../utils/routerUtils'

import { confAddItem, CommListGen, CommLinkButton, CommDeleteButtonLink, confListField, confList, CommList, CommItemField, CommItemOfItems, CommButtonLinkItem, CommItemTextField, CommButtonSave   } from '../common/com-List/comm-list.components'
import { ConditionalComponent, myReactTaggedComponent, ReactComponentTag, CompWithParent } from '../common/extComponents'
import { CommItemFieldLink } from '../common/com'
import BannersPreview from '../banners-preview/banners-preview.component'
import { commService } from '../../services/api/list';
import { Delete, Link as LinkIcon, Visibility, AttachMoney, Edit, FileCopy, AccountBalance, CheckCircle, Error } from '@styled-icons/material';
import { isBrowser, isMobile, MobileView } from '../../utils/platformsUtils';
import UserUtils, { UserTypes } from '../../utils/userUtils'
import { LangStrs, Lang } from '../../utils/languageUtils'
import { Sandbox } from '../../index'

const strs: LangStrs = new LangStrs(new Map(
    [
        ['Account', new Map(
            [
                [Lang.ES, 'Cuenta'],
                [Lang.EN, 'Account']
            ])
        ],
        ['Actions', new Map(
            [
                [Lang.ES, 'Acciones'],
                [Lang.EN, 'Actions']
            ])
        ],
        ['PaymentMethods', new Map(
            [
                [Lang.ES, 'Metodos de pago'],
                [Lang.EN, 'Payment Methods']
            ])
        ],
        ['AddPayAccount', new Map(
            [
                [Lang.ES, 'Agregar cuenta de pago'],
                [Lang.EN, 'Add payment account']
            ])
        ],
        ['PaypalAccount', new Map(
            [
                [Lang.ES, 'Cuenta PayPal / Targeta de credito a través de Paypal'],
                [Lang.EN, 'PayPal Account / Credit card through PayPal']
            ])
        ],
        ['BankAccount', new Map(
            [
                [Lang.ES, 'Cuenta bancaria'],
                [Lang.EN, 'Bank Account']
            ])
        ],
        ['UserW', new Map(
            [
                [Lang.ES, 'Cartera de usuario'],
                [Lang.EN, 'User Wallet']
            ])
        ],
        ['PayPalFeeds', new Map(
            [
                [Lang.ES, 'Tasas de paypal'],
                [Lang.EN, 'PayPal feeds']
            ])
        ],
        ['PaymentsHere', new Map(
            [
                [Lang.ES, 'Hacer los pagos a esta cuenta'],
                [Lang.EN, 'Make payments into this account']
            ])
        ],
        ['PaymentsIncomplete', new Map(
            [
                [Lang.ES, 'Completa tu información personal para poder recibir pagos'],
                [Lang.EN, 'Complete your personal information in order to receive payments']
            ])
        ],
        ['AccountSel', new Map(
            [
                [Lang.ES, 'Los pagos se realizaran a esta cuenta'],
                [Lang.EN, 'Payments will be made to this account']
            ])
        ],
        ['Description', new Map(
            [
                [Lang.ES, 'Descripción'],
                [Lang.EN, 'Description']
            ])
        ],
        ['AdminTransfer', new Map(
            [
                [Lang.ES, (Sandbox() ? "Sandbox " : "") + "Transferencia recibida"],
                [Lang.EN, (Sandbox() ? "Sandbox " : "") + "Transfer received"]
            ])
        ]
    ]
));

const addPaymentURL = "/dashboard/settings/payment/add";

export class PaymentMethodsList extends React.Component {    
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            user: UserUtils.GetUserData()
        }

        this.conf = [
            new confListField(strs.get('Account'), MoneyAccountTypeComponent, { className: "list-field bi-name" }, "moneyAccountType"),
            new confListField(strs.get('Description'), CommItemTextField, {}, "description").addField("tLabel", false),
            new confListField("", MoneyAccountActiveIndicator, { className: "list-field bi-name" }, "payDefault", null, isBrowser ? { style: { "text-align": "center" } } : {"xs":"0"}),
            new confListField(strs.get('Actions'), CommItemOfItems).addField("Comp", [
                new confListField(strs.get("Save"), CommButtonSave),
                new confListField("Delete", CommDeleteButtonLink, null, (i)=> "the " + i.address + " account")
            ])
        ];
    }

    render() {
        if (this.state.user.id != UserUtils.GetUserData().id && this.walletList)
            this.walletList.QueryItems();

        let isAdmin = UserUtils.GetUserData(true).usertype == UserTypes.Admin;
        return  <React.Fragment>
            <CommList ref={ (r)=> this.walletList=r } {...isAdmin ? { canSel: true, optionalSelect: true, customCheck: (item, checkbox) => { if (item.payDefault) checkbox.setChecked(true) } } : {}} OnChangeSelection={this.props.OnChangeSelection} OnQuery={this.OnQueryList} title={strs.get('PaymentMethods')} confComm={new confList(CommListGen, new commService("/users/:id/moneyAccount"), this.conf, new confAddItem(addPaymentURL, strs.get('AddPayAccount')))} {...!isAdmin ? {noItemsRedirect: Urls.addPaymentMethod } : {}} />
        </React.Fragment>
    }
}

export class MoneyAccountTypeComponent extends React.Component {
    constructor(props) {
        super(props);
    }

    render = () => {
        const confField: confField = this.props.confFieldItem.confField;
        return (
            <Grid className={"payment-method "} item xs={12}>
                <Grid container direction="row" alignItems="center" justify="flex-start">
                    <Grid item>
                        {this.props.item.moneyAccountType == "Paypal" ?
                            <img src="/img/paypal-icon.png" className="method-icon" alt="PayPal icon" /> : this.props.item.moneyAccountType == "Bank" ?
                                <AccountBalance class="bankaccount-icon" className="method-icon" title="Validated" alt="bank icon" color="#D80" /> :
                                    this.props.item.moneyAccountType == "Admin" ? <img src="/img/fund-transfer-icon.png" className="method-icon" alt="PayPal icon" /> :
                                        <img src="/img/userwallet.png" className="method-icon" alt="PayPal icon" />}
                    </Grid>
                    <Grid item>
                        {this.props.item.validated ?
                            <Error class="error-icon" className="method-icon" alt="bank icon" color="#D33" /> :
                            <CheckCircle class="error-icon" className="method-icon" alt="bank icon" title="Waiting for validation" color="#3D3" />}
                    </Grid>
                    <Grid item>
                        <span className="payment-title">
                            {this.props.item.moneyAccountType == "Paypal" ?
                                strs.get('PaypalAccount') : this.props.item.moneyAccountType == "Bank" ?
                                    strs.get('BankAccount') : this.props.item.moneyAccountType == "Admin" ?
                                        strs.get('AdminTransfer') : strs.get('UserW')}
                        </span>
                    </Grid>
                    <Grid>
                        <span className="payment-title data-cell">
                            {this.props.item.address}
                        </span>
                    </Grid>
                </Grid>
                {this.props.item.moneyAccountType == "Paypal" && <a href="https://www.paypal.com/webapps/mpp/paypal-fees">{strs.get('PayPalFeeds')}</a>}
            </Grid>
        )
    }
}

export class MoneyAccountActiveIndicator extends React.Component {
    constructor(props) {
        super(props);
    }

    render = () => {
        const confField: confField = this.props.confFieldItem.confField;
        let parent: CommList = this.props.parentComm;
        let active = this.props.item.payDefault;
        return (
            <ConditionalComponent TagComponent={TableCell} Condition={isBrowser} bodyConditional={false} {...confField.fieldprops}>
                <Grid container justify="center" direction="row">
                    <Grid item {...isMobile && { style: { margin: "10px" } }} >
                        {!this.props.item.BankInformation && <Button Button variant="contained" size="large" color="primary" onClick={(e) => { changeUrl(Urls.Profile) }}>{strs.get('PaymentsIncomplete')}</Button>}
                        {this.props.item.BankInformation && !active &&
                            <Button variant="contained" size="large" color="primary" onClick={async () => { parent.DataChange(this, true); await parent.UpdateData(this.props.item); await parent.QueryItems(); }}>
                                {strs.get('PaymentsHere')}
                            </Button>
                        }
                        {
                            this.props.item.BankInformation && active && <b>{strs.get('AccountSel')}</b>
                        }
                    </Grid>
                </Grid>
            </ConditionalComponent>
        )
    }
}

export default PaymentMethodsList;