export const getUserLang = () => {
    return navigator.language || navigator.userLanguage;
}

export const Lang = {
    ES: "es",
    EN: "en"
}

export const LangToBackIndex: { [key: string]: number } = {
    [Lang.EN]: 0,
    [Lang.ES]: 1,
}

export const getUserLangStd = () => {
    let userLang = getUserLang();
    let sep = userLang.indexOf("-");
    if (sep >= 0) userLang = userLang.substring(0, sep);
    return userLang;
}

export class LangStrs {
    userLang = navigator.language || navigator.userLanguage;
    strs: Map<string, Map<string, string>>;

    constructor(strs: Map<string, Map<string, string>>) {
        this.strs = strs;

        this.userLang = getUserLangStd();
    }

    get(key: Lang, ...args) {
        let e = this.strs.get(key);
        if (e == undefined)
            return "#NoStrKey# " + key;

        let s = e.get(this.userLang);
        if (s == undefined) 
            s = e.get(Lang.EN);
        
        if (s == undefined)
            return "#NoStrLang#";
        else {
            for (let i = 0; i < args.length; i++) {
                let reg = new RegExp(`\\{${i + 1}\\}`, 'g');
                s = s.replace(reg, args[i]);
            }
        }

        return s;
    }
}

export const getBackText = (MLstr) => {
    if (typeof MLstr === 'string') return MLstr;
    else {
        let s = LangToBackIndex[getUserLangStd()];

        if (s != undefined && MLstr.str.length>s && MLstr.str[s] != null)
            return MLstr.str[s];
        else
            return MLstr.str[0];
    }
}