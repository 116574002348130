import React from 'react';
import { connect } from 'react-redux'
import { LangStrs, Lang } from '../../utils/languageUtils'
import ServicesBase from '../../services/base';
import { changeUrl, Urls, mapGenericStateToProps } from '../../utils/routerUtils'
import ActionTypes from '../../store/actions/actiontypes'
import { Redirect } from 'react-router-dom';
//import X from '../../components/x/x.component';

function mapDispatchToProps(dispatch) {
    return {
        DoLogin: userData => dispatch({ type: ActionTypes.WWW_USER_LOGIN, payload: userData }),
        dispacher: dispatch
    };
}

export const strs: LangStrs = new LangStrs(new Map(
    [
        ['info', new Map(
            [
                [Lang.ES, ""],
                [Lang.EN, '']
            ])
        ],
    ]
));

class AuthMail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            state: 0
        }
        this.i18n = this.props.i18n;
    }

    async postAuth() {
        let sbAuthMail: ServicesBase = new ServicesBase("#server#" + "/users/authMail/" + this.props.match.params.userId + "/" + this.props.match.params.token);
        let rAuthMail = await sbAuthMail.ServiceRequest("", 'GET');

        if (rAuthMail.httpCode == 200) {
            //await this.setState({ state: 1 });
            //this.props.dispacher({ type: ActionTypes.WWW_USER_LOGIN_RESPONSE, payload: { status: 1, response: rAuthMail.body.jwt } });
            changeUrl("/login"); //Comentado lo de arriba xk ahora mismo los usuarios no se aprueban directamente
        } else {
            changeUrl("/");
        }
    }

    render = () => {
        if (this.state.state == 0)
            this.postAuth();
        return (
            <h1>{this.state.state == 1 && <Redirect to="/dashboard" push />} </h1>
        )
    }
}

export default connect(mapGenericStateToProps, mapDispatchToProps)(AuthMail);