import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

import { Hidden, Grid, Button, TextField, Fab, Select, InputLabel, MenuItem, TextareaAutosize, FormControlLabel, FormControl } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles'

import { Redirect } from 'react-router-dom';
import MuiAlert from '@material-ui/lab/Alert';

import { commService } from '../../services/api/list';
import { CommInputField, CommForm, CommFormGen, confForm, UploadVideoMedia, CreatePasswordComponent } from '../../structure/common/com-Form/comm-form.components'
import { CommButton, confCOM, confField, CommTextField, fieldNameFunction, temporalItem, fnfRequireText, fnfRequireText2, checkMailField } from '../../structure/common/com'
import { CommItemOfItems, confAddItem, CommListGen, CommLinkButton, CommDeleteButtonLink, confListField, confList, CommList, CommItemField, CommItemTextField } from '../../structure/common/com-List/comm-list.components'
import { mapGenericStateToProps } from '../../utils/routerUtils'
import { LangStrs, Lang, getUserLang } from '../../utils/languageUtils'
import Loading from '../../structure/common/loading/loading.component';

import ModalDialog from '../../components/common/modal/modal'

export class FormFrame extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className='register-page full-with-header'>
                <Grid className="z10 full-height auto-height-xs" container
                    alignItems="center"
                    direction="column"
                    justify="center">
                    <Grid className='register-form'>
                        <Grid container direction="row" justify="space-between" alignContent="space-between">
                            <Grid item xs={12} >
                                <img src="/LogoPowerAdsPubOld3.svg" width="100%" alt="PowerAds logo" />
                            </Grid>
                            <Grid item xs={12} justify="center">
                                {this.props.children}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {<Loading className="loading"></Loading>}
            </div>
        )
    }
}

export default connect(mapGenericStateToProps, null)(withRouter(FormFrame));