import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {Drawer, List, Divider, ListItem, ListItemIcon, ListItemText, Collapse, Link } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom';
import withWidth from '@material-ui/core/withWidth';
import GlobalState from '../../store/globalState';
import { NotificationBadget } from '../../components/common/commonComponents'
import {Brightness1, Brightness2} from '@styled-icons/material'
import {connect} from 'react-redux'

import './sidemenu.styles.scss';

import clsx from 'clsx';

import { mapGenericStateToProps } from './../../utils/routerUtils'
import { BrowserView, MobileView, isBrowser, isMobile } from '../../utils/platformsUtils';
import Badge from '@material-ui/core/Badge';
const drawerWidth = 240;

const useStyles = (theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    backgroundColor: "#000"
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  drawerStyle: {
    backgroundColor: "#f0f0f0",
    border: 0
  },
  toolbar: theme.mixins.toolbar,
});

class SideMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        menuOptions: [],
        open: false
    }
    this.isSmall = (() => {
      return this.props.width == "xs" || this.props.width == "sm";
    }).bind(this);

      this.ClickMenu = ((index, item) => {
          console.log(item);
          if (item.children) {
              this.UpdateMenu(index);
          }
          if (!item.children || item.children.length == 0) {
              //this.setState({ open: false });
              GlobalState.set("sidemenu", false);
          }
      }).bind(this);

    this.UpdateMenu = ((index) => {
      let obj = {};
      obj[index] = !this.state.menuOptions[index];
        this.setState({ menuOptions: Object.assign(this.state.menuOptions, obj) });        
    }).bind(this);

    GlobalState.add("sidemenu", (val) => {
      this.setState({open: val});
    });

    this.GenerateList = (() => (
      <div
        className={this.props.classes.list}
        role="presentation"
      >
      <List>
      { this.props.options.map( (option, index) => {
        return (
        <React.Fragment key={option.title}>
                <Link component={RouterLink} {...(isBrowser || option.children == undefined || option.children.length == 0) ? { to: option.path } : {}}><ListItem className="sidemenu-item" button index={index} onClick={() => { this.ClickMenu(index, option); }}>
                    {option.notification && <NotificationBadget notificationKey={option.notification} color="secondary" style={{ top: -10, right: -32 }} />}
                    <ListItemIcon>
                        {option.icon.render({ size: 32, color: "#35749E" })}
                    </ListItemIcon>
                <ListItemText className="sidemenu-label" primary={this.props.i18n[option.title]} />
            </ListItem></Link>
            {option.children ? (
            <Collapse in={this.state.menuOptions[index]} timeout={300} unmountOnExit>
            <Divider />
                        <List component="div" disablePadding>
                            {
                                option.children.filter(o => !o.onlyMobile || isMobile).map(child => {
                                    return (
                                        <Link component={RouterLink} to={child.path} key={option.title + "-" + child.title}><ListItem className="sidemenu-item" button onClick={() => { this.ClickMenu(index, child); }}>
                                            <ListItemIcon >{child.icon.render({ size: 28, color: "#009CDD" })}</ListItemIcon>
                                            <ListItemText className="sidemenu-label" inset secondary={this.props.i18n[child.title]} /></ListItem>
                                        </Link>
                                    )
                                })
                            }
                        </List>
          </Collapse>) : null }
        </React.Fragment>
        ) 
        }) 
      }
      </List>  
      </div>
    )).bind(this);
  }


  render() {
    return (
    <div className={"sidemenu " + this.props.className}>
          <Drawer variant="permanent"
        className={clsx(this.props.classes.drawer, {
          'sidemenu-open': this.state.open,
          [this.props.classes.drawerOpen]: this.state.open,
          [this.props.classes.drawerClose]: !this.state.open,
          'sidemenu-closed': !this.state.open,
        })}
        classes={{
          paper: clsx({
            [this.props.classes.drawerOpen]: this.state.open,
            [this.props.classes.drawerClose]: !this.state.open,
            [this.props.classes.drawerStyle]: true
          }),
        }}>
          <div className={this.props.classes.toolbar} />
            {this.GenerateList()}
          </Drawer>
    </div>
    );
  };
}

export default connect(mapGenericStateToProps)(withWidth()(withStyles(useStyles, { withTheme: true })(SideMenu)));