import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import './ad-item.styles.scss';
import {Grid, Snackbar, Button, Link, Table, TableCell, TableRow, TableHead, TableFooter, TableBody, TableContainer} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert';
import {MuiThemeProvider} from '@material-ui/core/'
import {withStyles} from '@material-ui/core/styles'
import {Delete, Link as LinkIcon, Visibility, AttachMoney, Edit, FileCopy, Insights} from '@styled-icons/material';
import ErrorWarnTheme from '../../../styles/ErrorWarnTheme';


const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

class AdItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            toastOpen: false
        }
    }

    handleCloseToast() {
        this.setState({toastOpen: false});
    }

    componentDidMount() {
    }

    render() {
        return (
            <React.Fragment>
                {this.props.header && <TableHead item xs={9} className="list-details">
                <StyledTableRow container className="advertisement-item" alignItems="flex-start" justify="flex-start" direction="row">
                    <StyledTableCell className="list-field bi-name">Media</StyledTableCell>
                    <StyledTableCell className="list-field bi-name">Name123</StyledTableCell>
                    <StyledTableCell className="list-field bi-size">Size</StyledTableCell>
                    <StyledTableCell className="list-field bi-amount not-mobile">Total Views</StyledTableCell>
                    <StyledTableCell className="list-field bi-date not-mobile">Creation date</StyledTableCell>
                    <StyledTableCell className="list-field">Actions</StyledTableCell>
                    </StyledTableRow>
                </TableHead>}
                {!this.props.header &&
                <StyledTableRow className="advertisement-item" container alignItems="flex-start" direction="row">
                    <TableCell className="list-field bi-media">
                        {this.props.ad.mediaType == "img" || true ? 
                        <img src={"data:image/png;base64,"+this.props.ad.thumbnail} alt={this.props.ad.name+" media"} title={this.props.ad.name+" media"} /> :
                        <video controls poster="/images/w3html5.gif" poster={"data:image/png;base64,"+this.props.ad.thumbnail}>
                            <source src={this.props.ad.media} />
                        </video>}
                    </TableCell>
                    <TableCell className="list-field bi-name">{this.props.ad.name}</TableCell>
                    <TableCell className="list-field bi-size">{this.props.ad.sizeX}x{this.props.ad.sizeY}</TableCell>
                    <TableCell className="list-field bi-amount not-mobile">{this.props.ad.views} ({"~" + this.props.ad.views*this.props.ad.time} seconds)</TableCell>
                    <TableCell className="list-field bi-date not-mobile">{(new Date(this.props.ad.createdAt)).toLocaleString() || "?"}</TableCell>
                    <TableCell   className="list-actions">
                        <Grid container direction="row">
                            <Link component={RouterLink} to={"/dashboard/advertisements/stats/"+this.props.ad.id}>
                                <Button color="primary" variant="contained" className="list-button">
                                    <Insights size={25}></Insights>
                                </Button>
                            </Link>
                            <Link component={RouterLink} to={"/dashboard/advertisements/edit/"+this.props.ad.id}>
                                <Button color="primary" variant="contained" className="list-button">
                                    <Edit size={25}></Edit>
                                </Button>
                            </Link>
                            <MuiThemeProvider theme={ErrorWarnTheme}>
                                <Link><Button color="primary" variant="contained" className="list-button" onClick={(e) => {
                                    this.props.onDelete(this.props.ad);
                                }}>
                                    <Delete size={25}></Delete>
                                </Button></Link>
                            </MuiThemeProvider>
                        </Grid>
                    </TableCell>
                    </StyledTableRow>}
            </React.Fragment>
        )
    }
}
export default AdItem;