import { getFragmentParameter } from '../../utils/routerUtils'

export class handleAuthRedirect {
    callback: (token) => any;
    singupUrl: string;
    singupWindowsName: string;
    eventField: string;

    constructor(singupUrl: string, singupWindowsName: string, callback: (token) => any) {
        this.singupUrl = singupUrl;
        this.singupWindowsName = singupWindowsName;
        this.eventField = this.singupWindowsName + "_token";
        this.callback = callback;
    }

    OpenSignup() {
        this.#openSignInWindow(this.singupUrl, this.singupWindowsName);
    }

    #windowObjectReference = null;
    #previousUrl = null;
    #openSignInWindow = (url, name) => {
        window.removeEventListener('message', this.receiveMessage);
        const strWindowFeatures = 'toolbar=no, menubar=no, width=600, height=700, top=100, left=100';

        if (this.#windowObjectReference === null || this.#windowObjectReference.closed) {
            this.#windowObjectReference = window.open(url, name, strWindowFeatures);
        } else if (this.#previousUrl !== url) {
            this.#windowObjectReference = window.open(url, name, strWindowFeatures);
            this.#windowObjectReference.focus();
        } else {
            this.#windowObjectReference.focus();
        }

        window.addEventListener('message', event => this.receiveMessage(event), false);
        this.#previousUrl = url;
    };

    receiveMessage(event) {
        const credentials = event.data[this.eventField];
        if (credentials != undefined) {
            this.callback(credentials);
        }
    }

    postCredentials(credentials) {
        window.opener.postMessage({ [this.eventField]: credentials }, window.location.origin);
        setTimeout(window.close, 1000); //En chrome, si tanco rapid les imatges de la pagina pare petan?!!?
        //window.close();
    }
}