
import React from 'react';

import './payment-method-item.styles.scss'
import {Route} from 'react-router-dom'
import {Grid, Link, Avatar, Button, TextField, Select, InputLabel, TextareaAutosize, FormControlLabel, FormControl} from '@material-ui/core';
import {} from '@styled-icons/material'
import MuiAlert from '@material-ui/lab/Alert';
import {MuiThemeProvider} from '@material-ui/core/'
import {withStyles} from '@material-ui/core/styles'
import {Link as RouterLink} from 'react-router-dom'
import {Delete, Link as LinkIcon, Visibility, AttachMoney, Edit, FileCopy, AccountBalance, CheckCircle, Error} from '@styled-icons/material';
import ErrorWarnTheme from '../../styles/ErrorWarnTheme';

class PaymentMethodItem extends React.Component {    
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return <Grid container className={"payment-method " + this.props.className} direction="row">
            <Grid container direction="row" justify="space-between">
                <Grid item sm={9} xs={9} md>
                <Grid container direction="row" alignItems="center" justify="flex-start">
                    <Grid item>
                    {this.props.method.type == "paypal" ?
                        <img src="/img/paypal-icon.png" className="method-icon" alt="PayPal icon" /> :
                        <AccountBalance class="bankaccount-icon" className="method-icon" title="Validated" alt="bank icon" color="#D80" /> }
                    </Grid>
                    <Grid item>
                        {this.props.method.validated ?
                        <Error class="error-icon" className="method-icon" alt="bank icon" color="#D33" /> :
                        <CheckCircle class="error-icon" className="method-icon" alt="bank icon" title="Waiting for validation" color="#3D3" /> }
                    </Grid>
                    <Grid item>
                        <span className="payment-title">
                        {this.props.method.type == "paypal" ?
                        "PayPal Account" :
                        "Bank Account" }
                        </span>
                    </Grid>
                    <Grid item class="data-cell">
                        <span>{this.props.method.number}</span>
                    </Grid>
                </Grid>
                </Grid>
                    <Grid item sm={3} xs={3} md>
                        <Grid container justify="flex-end" alignItems="center">
                            <MuiThemeProvider theme={ErrorWarnTheme}>
                                <Link><Button color="primary" variant="contained" className="list-button" onClick={(e) => {this.props.onDelete(this.props.method)}}>
                                    <Delete size={25}></Delete>
                                </Button></Link>
                            </MuiThemeProvider>
                        </Grid>
                    </Grid>
            </Grid>
        </Grid>
    }
}

export default PaymentMethodItem;