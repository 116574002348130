import { confListField } from './comm-list.components.jsx'
import { CommSimpleTextButton } from '../../common/com'
import ServicesBase from '../../../services/base';

export const buttonPost = (label: string, endpoint: (item:any)=>string, method: string = 'GET', data: any = null, loading: boolean = false, afterPost:(resp:any)=>null = null) => {
    return new confListField("", CommSimpleTextButton, {}, label).addField("OnClick", async (item) => {
        let url = endpoint(item);
        let sb: ServicesBase = new ServicesBase(url);
        let resp = null;
        if (method == 'GET')
            await sb.ServiceRequest("", method);
        else
            resp = await sb.ServiceRequest("", method, { 'Content-Type': 'application/json' }, loading, data);
        afterPost(resp);
    });
}