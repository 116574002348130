
import React from 'react';

import './dashboard-content.styles.scss'
import {Route} from 'react-router-dom'
import {Grid, Button} from '@material-ui/core'
import StreamGrid from '../streams-grid/streams-grid.component'
import StreamGridAdmin from '../streams-grid-admin/streams-grid-admin.component'
import Profile from '../profile/profile.component'
import BannersRouter from '../banners-router/banners-router.component'
import AdsRouter from '../ads-router/ads-router.component'
import BrandedStreamerRouter from '../branded-streamer-router/branded-streamer.component'
import TransactionsRouter from '../transactions-router/transactions-router.component'
import SettingsRouter from '../settings-router/settings-router.component'
import WebUseRouter from '../webUse-router/webUse-router.component'
import Logout from '../logout/logout.component'
import { StreamSceneList } from '../../structure/streamScene/streamScene-list.components'
import StreamSceneForm from '../../structure/streamScene/streamScene-form.component'
import StreamingAccountAdd from '../../structure/streamingAccount/streamingAccount-add'
import GenerateVideoForm from '../generatevideo-form/generateVideo-form';
import ProfileAdmin from '../../structure/profile-admin/profile-admin.component'
import AdsListAdmin from '../ads-list-admin/ads-list-admin.component'
import { VideoEditor } from '../../components/video/videoEditor.jsx';
import { StreamerGroupList } from '../../components/StreamersGroup/StreamerGroup-list.component.jsx';
import { AgencyStreamersList } from '../../components/StreamersGroup/AgencyStreamers-list.component';
import  BrandedOfferList from '../../structure/brandedOffer/brandedOffer-list.component.jsx'
import { BDManager } from '../../pages/bd/bd-manager.component.jsx'

import { LangStrs, Lang } from '../../utils/languageUtils'

let strs: LangStrs = new LangStrs(new Map(
    [
        ['Streams', new Map(
            [
                [Lang.ES, 'Streams'],
                [Lang.EN, 'Streams']
            ])
        ],
        ['Scenes', new Map(
            [
                [Lang.ES, 'Escenas'],
                [Lang.EN, 'Scenes']
            ])
        ],
        ['Scene', new Map(
            [
                [Lang.ES, 'Escena'],
                [Lang.EN, 'Scene']
            ])
        ],
        ['Banners', new Map(
            [
                [Lang.ES, 'Banners'],
                [Lang.EN, 'Banners']
            ])
        ],
        ['Advertisements', new Map(
            [
                [Lang.ES, 'Anuncios'],
                [Lang.EN, 'Advertisements']
            ])
        ],
        ['Transactions', new Map(
            [
                [Lang.ES, 'Transacciones'],
                [Lang.EN, 'Transactions']
            ])
        ],
        ['Settings', new Map(
            [
                [Lang.ES, 'Ajustes'],
                [Lang.EN, 'Settings']
            ])
        ],
        ['Logout', new Map(
            [
                [Lang.ES, 'Cerrar sesión'],
                [Lang.EN, 'Logout']
            ])
        ],
        ['Profile', new Map(
            [
                [Lang.ES, 'Perfil'],
                [Lang.EN, 'Profile']
            ])
        ]
    ]
));

const DashboardContent = (props) => (    
    <Grid container className={"dashboard-content " + props.className} direction="row">
        <Route path='/dashboard/streamingAccount/add' component={() => <StreamingAccountAdd></StreamingAccountAdd>} />
        <Route exact path='/dashboard/streams' component={() => <StreamGrid title={strs.get('Streams')}></StreamGrid>} />
        <Route path='/dashboard/streamers/sponsorships' component={() => <BrandedStreamerRouter/>} />
        <Route exact path='/dashboard/admin/streams' component={() => <StreamGridAdmin title={strs.get('Streams')}></StreamGridAdmin>} />
        <Route path='/dashboard/streamScenes' component={() => (
            <React.Fragment>                
                <Route exact path='/dashboard/streamScenes' component={() => <StreamSceneList title={strs.get('Scenes')}></StreamSceneList>} />
                <Route exact path='/dashboard/streamScenes/:id' component={() => <StreamSceneForm title={strs.get('Scene')}></StreamSceneForm>} />
            </React.Fragment>
        )} />
        <Route path='/dashboard/genVideo' component={() => (
            <React.Fragment>
                <Route exact path='/dashboard/genVideo/:streamingAccount/:scene' component={() => <GenerateVideoForm></GenerateVideoForm>} />
            </React.Fragment>
        )} />        
        <Route path='/dashboard/banners' component={() => <BannersRouter title={strs.get('Banners')}></BannersRouter>} />
        <Route path='/dashboard/advertisements' component={() => <AdsRouter title={strs.get('Advertisements')}></AdsRouter>} />
        <Route path='/dashboard/streamersGroups' component={() => <StreamerGroupList />} />
        <Route path='/dashboard/agencyStreamers' component={() => <AgencyStreamersList />} />
        <Route path='/dashboard/transactions' component={() => <TransactionsRouter title={strs.get('Transactions')}></TransactionsRouter>} />
        <Route path='/dashboard/webUse' component={() => <WebUseRouter></WebUseRouter>} />
        <Route path='/dashboard/bd' component={() => <BDManager/>} />
        <Route path='/dashboard/settings' component={() => <SettingsRouter title={strs.get('Settings')}/>} />
        <Route path='/dashboard/logout' component={() => <Logout title={strs.get('Logout')} />} />        
        <Route exact path='/dashboard' component={() => { return (props.role == "advertiser" || props.role == "admin") ? <Profile title={strs.get('Profile')} /> : <StreamGrid title={strs.get('Streams')}/>} } />
    </Grid>
)

export default DashboardContent;