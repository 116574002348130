import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { Grid, Button, TextField, TextareaAutosize, FormControlLabel, FormControl } from '@material-ui/core';
import { SelectFromConst, SelectFromGet } from '../../components/common/commonComponents.jsx'

import { mapGenericStateToProps } from '../../utils/routerUtils'
import { getServerUrl } from '../../services/base';

import { LangStrs, Lang } from '../../utils/languageUtils'

import { cesarCipher, decodeUrl } from '../redirect-url/redirect-url.component.jsx'

let strs: LangStrs = new LangStrs(new Map(
    [
        ['createLink', new Map(
            [
                [Lang.ES, 'Crear link'],
                [Lang.EN, 'Create link']
            ])
        ],
        ['copyLink', new Map(
            [
                [Lang.ES, 'Copiar link'],
                [Lang.EN, 'Copy link']
            ])
        ],
        ['StreamersInfo', new Map(
            [
                [Lang.ES, 'Información para los streamers'],
                [Lang.EN, 'Streamers info']
            ])
        ],
        ['AdvertisersInfo', new Map(
            [
                [Lang.ES, 'Información para los anunciantes'],
                [Lang.EN, 'Advertisers info']
            ])
        ],
        ['AgencyInfo', new Map(
            [
                [Lang.ES, 'Información para las agencias'],
                [Lang.EN, 'Agency Info']
            ])
        ],
        ['AgencyPPT', new Map(
            [
                [Lang.ES, 'PPT para las agencias'],
                [Lang.EN, 'Agency PPT']
            ])
        ],
        ['CommonLinks', new Map(
            [
                [Lang.ES, 'Links comunes'],
                [Lang.EN, 'Common links']
            ])
        ],
        ['Guest', new Map(
            [
                [Lang.ES, 'Invitado'],
                [Lang.EN, 'Guest']
            ])
        ],
        ['CustomLink', new Map(
            [
                [Lang.ES, 'Link personalizado'],
                [Lang.EN, 'Custom link']
            ])
        ],
        ['Result', new Map(
            [
                [Lang.ES, 'Resultado'],
                [Lang.EN, 'Result']
            ])
        ],
    ]
));

class SelectGuest extends SelectFromGet {
    labelFields = ['name', 'email', 'id'];
    baseUrl = "/guests";
    typeFilter = "";

    async updateItems() {
        let items: { id: string, name: string, email: string, description: string }[] = await super.updateItems();
        return items.map(i => { return { label: i, value: i.id } });
    }
}

export const Urls = [
    { label: strs.get('StreamersInfo'), value: `${window.location.origin}/streamers` },
    //{ label: strs.get('AdvertisersInfo'), value: `${window.location.origin}/advertiser` },
    { label: strs.get('AgencyInfo'), value: `${window.location.origin}/agency` },
    { label: strs.get('AgencyPPT'), value: `${window.location.origin}/agencyInfo` }
];


class CreateLinkForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            gUrl: "",
            customText:""
        }
    }
    idSelect: string = "";
    OnSelectUser(user) {
        this.idSelect = user.label.email;
    }

    generateUrl(id, url) {
        const encodedId = btoa(id);
        const encodedUrl = btoa(url);

        const cipheredId = cesarCipher(encodedId, 5);
        const cipheredUrl = cesarCipher(encodedUrl, 5);

        const gUrl = `${window.location.origin}/r/${cipheredId}/${cipheredUrl}`;

        this.setState({ gUrl: gUrl });

        const eUrl = decodeUrl(gUrl);
    }

    formRow = (title, comp) => {
        return (
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={1}>
                    {title}
                </Grid>
                <Grid item xs={11}>
                    {comp}
                </Grid>
            </Grid>
        );
    }

    handleTextChange = async (text) => {        
        await this.setState({ customText: text});
    };

    render() {
        return (
            <Grid direction="column" xs="12">
                <Grid className="form-column" xs="12">
                    <h1>{strs.get('createLink')}</h1>
                </Grid>                
                <Grid child container direction="row" xs="12">
                    <div style={{ paddingLeft: "10px", width:"100%" }}>                        
                        {this.formRow(strs.get('Guest'),
                            <SelectGuest ref={(r) => this.selectGuest = r} onSelect={(event, newValue) => { this.OnSelectUser(newValue); this.generateUrl(this.idSelect, this.state.customText); } } typeFilter={""} />
                        )}
                        {this.formRow(strs.get('CommonLinks'),
                            <SelectFromConst Values={Urls} onSelect={async (event, newValue) => { this.generateUrl(this.idSelect, newValue.value); this.handleTextChange(newValue.value);}} />
                        )}
                        {this.formRow(strs.get('CustomLink'),
                            <TextField style={{ width: "100%" }} variant="outlined" value={this.state.customText} onChange={async (e) => { await this.handleTextChange(e.target.value); this.generateUrl(this.idSelect, e.target.value); } } />
                        )}
                        {this.formRow(strs.get('Result'),
                            <TextField style={{ width: "100%" }} variant="outlined" disabled value={this.state.gUrl} />
                        )}
                        <div style={{ display: 'flex', justifyContent: 'flex-end', 'padding-top':'10px' }}>
                            <Button variant="outlined" color="primary" onClick={() => navigator.clipboard?.writeText(this.state.gUrl)} disabled={ this.idSelect == "" || this.state.customText=="" }>
                                {strs.get('copyLink')}
                            </Button>
                        </div>
                    </div>
                </Grid>                
            </Grid>
        )
    }
}

export default connect(mapGenericStateToProps, null)(withRouter(CreateLinkForm));