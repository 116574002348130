
import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import '../banner-list/banner-list.components'
import { Button, TextField } from '@material-ui/core'
import { Send, Delete, Visibility, AttachMoney, Edit, FileCopy } from '@styled-icons/material';
import MuiAlert from '@material-ui/lab/Alert'
import ContentNavigation from '../../structure/content-navigation/content-navigation.component'
import Streamers from '../../services/api/streamers';
import { commService } from '../../services/api/list';
import GlobalState from '../../store/globalState'
import DeleteItemDialog from '../common/deleteItemDialog/deleteItemDialog.component.jsx';
import AddNewButton from '../common/add-new-button/add-new-button.component.jsx';
import UserUtils from '../../utils/userUtils'
import { withStyles } from '@material-ui/core/styles'
import { confAddItem, CommButtonSave, CommItemTextField, CommItemTextFieldInList, CommListGen, CommLinkButton, CommDeleteButtonLink, confListField, confList, CommList, CommItemField, CommItemOfItems, CommButtonLinkItem, ListFieldButtonLink, componentToItemFieldListRender } from '../common/com-List/comm-list.components'
import { CommTextField, fieldNameFunction, CommItemFieldLink, CommSimpleTextButton, strfieldvalue, EnumComboBox, fnfRequireNumber, fnfRequireText, fieldNameInOut } from '../common/com'
import { myReactTaggedComponent, ReactComponentTag } from '../common/extComponents'
import { Link as RouterLink } from 'react-router-dom';
import { getStreamingScene } from '../../structure/streamingAccount/streamingAccount-toStream.components'
import { BrowserView, MobileView, isBrowser, isMobile } from '../../utils/platformsUtils';
import { SelectComponent, SelectFromConst, SelectFromGet } from '../../components/common/commonComponents.jsx'
import ServicesBase from '../../services/base';

import SelectUser, { UsersFilterTypes } from '../../components/admin/select-user/select-user.component.jsx'

import { LangStrs, Lang } from '../../utils/languageUtils'

const strs: LangStrs = new LangStrs(new Map(
    [
        ['Actions', new Map(
            [
                [Lang.ES, 'Acciones'],
                [Lang.EN, 'Actions']
            ])
        ],
        ['Save', new Map(
            [
                [Lang.ES, 'Guardar'],
                [Lang.EN, 'Save']
            ])
        ],
        ['ValidUntil', new Map(
            [
                [Lang.ES, 'Válido hasta'],
                [Lang.EN, 'Valid until']
            ])
        ],

        ['CreateNewClause', new Map(
            [
                [Lang.ES, 'Crear nueva cláusula'],
                [Lang.EN, 'Create new clause']
            ])
        ]
    ]
));

export class TransferClauseList extends React.Component {
    constructor(props) {
        super(props);

        const enumValues = [{ label: 'Not defined', value: 'None' }, { label: 'Advertiser', value: 'adToStreamerClauseOnAdvertiser' }, { label: 'Streamer', value: 'adToStreamerClauseOnStreamer' }, { label: 'Ad', value: 'adToStreamerClauseOnAd' }, { label: 'Agency', value: 'adToStreamerClauseOnAgentAdvertiser' }];

        this.conf = [
            new confListField('transferType', EnumComboBox, { enumValues: enumValues, style: { width: "100%" } }, "transferType").addField("tLabel", false).addField("formStyle", { width: "90%", paddingTop: '2rem', paddingLeft: '1rem' }),
            new confListField('user', CommItemTextField, { className: "list-field bi-name" }, new fieldNameFunction(new fieldNameInOut("userId", "user"), (value) => { return value!="" })).addField("tLabel", false),
            new confListField('outEntity', SelectOutEntities, { className: "list-field bi-name" }, "outEntity").addField("tLabel", false),
            new confListField('feePercent', CommItemTextField, { className: "list-field bi-name" }, fnfRequireNumber("feePercent", 0,1,true)).addField("tLabel", false),
            new confListField('feeFix', CommItemTextField, { className: "list-field bi-name" }, fnfRequireNumber("feeFix", 0, undefined, true)).addField("tLabel", false),
            new confListField('subtract From *', EnumComboBox, { enumValues: [{ label: 'true', value: 'true' }, { label: 'false', value: 'false' }], style: { width: "100%" } }, "subtractFromGen").addField("tLabel", false).addField("formStyle", { width: "90%", paddingTop: '2rem', paddingLeft: '1rem' }),
            new confListField(strs.get('ValidUntil'), CommTextField, { type: "date", InputLabelProps: { shrink: true }, ComponentToItemListComp: true }, "validity").addField("tLabel", false),
            new confListField(strs.get('Actions'), CommItemOfItems, { ComponentToItemListComp: true }).addField("Comp", [
                new confListField(strs.get("Save"), CommButtonSave),
                //ListFieldButtonLink(Send, (item) => "/dashboard/streamScenes/" + item.id),
                new confListField(strs.get("Delete"), CommDeleteButtonLink, null, "name")
            ])
        ];
    }

    render = () => {

        return (
            <React.Fragment>
                <SelectUser filter={UsersFilterTypes} OnSelectUser={async (user) => { if (this.clausesList) this.clausesList.QueryItems(); }} />
                <CommList ref={(r) => this.clausesList = r} title={'Clauses'} confComm={new confList(CommListGen, new commService("/transfers/transferClause/:id"), this.conf, new confAddItem(null, strs.get('CreateNewClause'), () => { return { transferType: "None", outEntity: "*", feePercent: 0, feeFix: 0, "user": UserUtils.GetUserData().id } }))} />
            </React.Fragment>
        );
    }
}

class SelectOutEntities extends SelectComponent {
    labelFields = ['field1', 'field2', 'field3'];

    constructor(props) {
        super();

        this.state = {
            transferType: "NONE"
        };
    }
    transferType = "NONE";

    async handleOptionChange(event, newValue) {
        await super.handleOptionChange(event, newValue);

        let parent: COMMComp = this.props.parentComm;
        parent.DataChange(this, newValue.value);
    };

    OnChangeItem() {
        this.checkItemTypeChange();
    }

    async updateItems() {
        this.baseUrl = this.props.baseUrl || this.baseUrl;
        this.typeFilter = this.props.typeFilter || this.typeFilter;

        let sb = new ServicesBase("#server#" + '/transfers/transferClauseElegibleOutEntities/' + this.transferType);
        let resp = await sb.ServiceRequest("", 'GET', {}, false, null);


        //if (this.transferType == "bannerToStreamerClauseOnAdvertiser") {
        //    resp.body = resp.body.map(item => {
        //        return Object.assign({}, { label: item.label.username + " " + , value: item.id });
        //    });
        //}

        return resp.body;
    }

    checkItemTypeChange() {        
        const confField: confField = this.props.confFieldItem;
        let type = confField.item.transferType;       
        if (confField.item.newData && confField.item.newData.transferType) type = confField.item.newData.transferType;
        console.log(type);
        if (this.state.transferType != type) {
            this.transferType = type;
            this.update();
            this.setState({ transferType: type, selectedOption: confField.item.outEntity });                        
        }
    }

    render = () => {
        this.checkItemTypeChange();
        
        return (
            componentToItemFieldListRender(super.render(), { "margin-top": "-35px" })
        );
    }
}