import { LangStrs, Lang } from '../../utils/languageUtils'

export const bannerStrs: LangStrs = new LangStrs(new Map(
    [
        ['Name', new Map(
            [
                [Lang.ES, 'Nombre'],
                [Lang.EN, 'Name']
            ])
        ],
        ['Size', new Map(
            [
                [Lang.ES, 'Tamaño'],
                [Lang.EN, 'Size']
            ])
        ],
        ['MAmount', new Map(
            [
                [Lang.ES, 'Beneficio mínimo por segundo'],
                [Lang.EN, 'Minimum profit per second']
            ])
        ],
        ['Actions', new Map(
            [
                [Lang.ES, 'Acciones'],
                [Lang.EN, 'Actions']
            ])
        ],
        ['Delete', new Map(
            [
                [Lang.ES, 'Borrar'],
                [Lang.EN, 'Delete']
            ])
        ],
        ['Banners', new Map(
            [
                [Lang.ES, 'Banners'],
                [Lang.EN, 'Banners']
            ])
        ],
        ['Banner', new Map(
            [
                [Lang.ES, 'Banner'],
                [Lang.EN, 'Banner']
            ])
        ],
        ['NewBanner', new Map(
            [
                [Lang.ES, 'Nuevo Banner'],
                [Lang.EN, 'New Banner']
            ])
        ],
        ['ETime', new Map(
            [
                [Lang.ES, 'Tiempo entre muestras'],
                [Lang.EN, 'Elapse time']
            ])
        ],
        ['TEditBanner', new Map(
            [
                [Lang.ES, 'Editar banner'],
                [Lang.EN, 'Edit banner']
            ])
        ],
        ['TNewBanner', new Map(
            [
                [Lang.ES, 'Nuevo banner'],
                [Lang.EN, 'New banner']
            ])
        ],
        ['BWidth', new Map(
            [
                [Lang.ES, 'Anchura pixels'],
                [Lang.EN, 'Width pixels']
            ])
        ],
        ['BHeight', new Map(
            [
                [Lang.ES, 'Altura pixels'],
                [Lang.EN, 'Height pixels']
            ])
        ]
    ]
));