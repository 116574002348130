
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

import UserUtils from '../../utils/userUtils'
import { UserTypes } from '../../utils/userUtils'
import { changeUrl, Urls, changeUrlByReact } from '../../utils/routerUtils'
import { toMStr } from '../../utils/moneyUtils.jsx'
import { Grid, Button, TableCell, TableRow } from '@material-ui/core'
import { Delete, Visibility, AttachMoney, Edit, FileCopy, CreditCard } from '@styled-icons/material';
import MuiAlert from '@material-ui/lab/Alert'
import AdItem from '../../components/advertiser/ad-item/ad-item.component'
import ContentNavigation from '../../structure/content-navigation/content-navigation.component'
import Advertisers from '../../services/api/advertisers/';
import GlobalState from '../../store/globalState'
import DeleteItemDialog from '../common/deleteItemDialog/deleteItemDialog.component.jsx';
import AddNewButton from '../common/add-new-button/add-new-button.component.jsx';

import { confAddItem, CommListGen, CommLinkButton, CommDeleteButtonLink, confListField, confList, CommList, CommItemField, CommItemOfItems, CommButtonLinkItem, CommItemFieldTextLink, ListFieldButtonLink } from '../common/com-List/comm-list.components'
import { confListStatic, CommListStatic } from '../common/com-List/com-List-Static/comm-list-static-component'
import { myReactTaggedComponent, ReactComponentTag, CompWithParent } from '../common/extComponents'
import { CommItemFieldLink, CommSimpleTextButton } from '../common/com'
import { commService } from '../../services/api/list';

import { withStyles } from '@material-ui/core/styles'
import AdsList from '../ads-list/ads-list.components';

import { mapGenericStateToProps } from '../../utils/routerUtils'

import { DurationTime } from '../../utils/timeUtils';

import { LangStrs, Lang } from '../../utils/languageUtils'
import { bannerStrs } from '../../templates/languages/banner-strs.js'

import CommHelpLink, { getDocUrl } from '../../structure/common/comHelpLink/comHelpLink.jsx'

import SelectUser, { UsersFilterTypes } from '../../components/admin/select-user/select-user.component.jsx'

export let strs: LangStrs = new LangStrs(new Map(
    [
        ['Date', new Map(
            [
                [Lang.ES, 'Fecha'],
                [Lang.EN, 'Date']
            ])
        ],
        ['Quantity', new Map(
            [
                [Lang.ES, 'Cantidad'],
                [Lang.EN, 'Quantity']
            ])
        ],
        ['Details', new Map(
            [
                [Lang.ES, 'Detalles'],
                [Lang.EN, 'Details']
            ])
        ],
        ['WalletAmount', new Map(
            [
                [Lang.ES, 'Cantidad total de la cartera : '],
                [Lang.EN, 'Total wallet amount : ']
            ])
        ],
        ['Transactions', new Map(
            [
                [Lang.ES, 'Transacciones'],
                [Lang.EN, 'Transactions']
            ])
        ],
        ['TWallet', new Map(
            [
                [Lang.ES, 'Cartera'],
                [Lang.EN, 'Wallet']
            ])
        ],
        ['PayedTransferTo', new Map(
            [
                [Lang.ES, 'Pago Paypal a'],
                [Lang.EN, 'Paypal payment to ']
            ])
        ],
        ['PayedTransferFrom', new Map(
            [
                [Lang.ES, 'Pago PayPal desde'],
                [Lang.EN, 'PayPal payment from']
            ])
        ],
        ['BankTransferTo', new Map(
            [
                [Lang.ES, 'Pago bancario a'],
                [Lang.EN, 'Bank payment to ']
            ])
        ],
        ['Sponsorship', new Map(
            [
                [Lang.ES, 'Campaña de Contenido de Marca'],
                [Lang.EN, 'Branded Content Campaign']
            ])
        ]
    ]
));

const isStreamer = () => UserUtils.GetUserData().usertype == UserTypes.Streamer;

export const TransferDetailsItemField = new confListField(strs.get('Details'), CommItemField, {}, (item) => {
    if (item.detailsType == "streams")
        return <a href={item.details.url} target="_blank">{item.details.title}</a>
    if (item.detailsType == "banners")
        return <a href={Urls.AdvStats + item.details.id}> {bannerStrs.get("Banner")} {item.details.name}</a>
    if (item.detailsType == "Sponsorship")
        return <a href={Urls.StreamersBranded + "/view/" + item.details.SponId}> {strs.get("Sponsorship")}: {item.details.SponName}</a>
    if (item.detailsType == "StreamAndBanner")
        return <div><a href={item.details.url} target="_blank">{item.details.title}</a> <a href={Urls.AdvStats + item.details.BannerId}> {bannerStrs.get("Banner")} {item.details.BannerName}</a> </div>
    if (item.detailsType == "PaypalOut")
        return <p>{strs.get('PayedTransferTo')} {item.details.AccountName}</p>
    if (item.detailsType == "PaypalIn")
        return <p>{strs.get('PayedTransferFrom')} {item.details.AccountName}</p>
    if (item.detailsType == "BankOut") {
        if (item.details.reference!=undefined)
            return <p>{item.details.reference}</p>
        else
            return <p>{strs.get('BankTransferTo')} {item.details.address}</p>
    }
})

class UserWallet extends React.Component {
    constructor(props) {
        super(props);

        this.state = { WalletAmount: 0 };

        this.conf = [
            new confListField(strs.get('Date'), CommItemField, {}, "date"),
            new confListField(strs.get('Quantity'), CommItemField, {}, (item) => toMStr(item.quantity, true)).addField("textStyle", (item) => { return { style: { color: item.paid || item.chargeable ? "black" : "orange" } } }),
            TransferDetailsItemField
        ];

        if (this.props.paymentComp) this.props.paymentComp.setRefItemSelector(this);
    }    

    render = () => {
        let walleturl = this.props.platformWallet ? "/Transfers/platformWallet/:id" : "/Transfers/wallet/:id";

        return (
            <Grid xs="12">
                <SelectUser filter={UsersFilterTypes} OnSelectUser={async (user) => { if (this.transList) this.transList.QueryItems(); if (this.props.OnSelectUser) this.props.OnSelectUser(user); }} />
                <h1>{strs.get('TWallet')}<CommHelpLink infoUrl={getDocUrl(isStreamer ? "como-anadir-un-metodo-de-cobro" : "como-anadir-un-metodo-de-pago")} /></h1>
                {strs.get('WalletAmount')}<b>{toMStr(this.state.WalletAmount, false)}</b>{UserUtils.GetUserData().usertype != UserTypes.Streamer && <Button style={{ marginLeft: "5px" }} color="primary" variant="contained" className="list-button" onClick={() => changeUrlByReact(this.props, Urls.AddFounds + '/wallet/0')}>Add founds</Button>}
                <CommList ref={(r) => { this.transList = r; this.list = r; }} {...this.props.platformWallet ? { canSel: true, setRefItemSelector: this.props.paymentComp.setRefItemSelector, OnChangeSelection: this.props.paymentComp.OnChangeItemSelection, customCheck: (item, checkbox) => { console.log(item); checkbox.disable(item.paid || !item.chargeable); } } : {}} Owner={this} title={strs.get('Transactions')} confComm={new confList(CommListGen, new commService(walleturl, this.OnGet.bind(this)), this.conf)} />
            </Grid>
        )
    }

    async OnGet(r: Response) {
        const total = r.body?.total ? r.body.total: 0
        await this.setState({ WalletAmount: total });

        r.body = r.body?.Transfer;
    }
}

export default connect(mapGenericStateToProps, null)(withRouter(UserWallet));