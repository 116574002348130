const actionTypes = {
    TOGGLE_LOADING: "TOGGLE_LOADING",
    CHANGE_LANGUAGE: "CHANGE_LANGUAGE",
    WWW_USER_LOGIN: "WWW_USER_LOGIN",
    WWW_USER_LOGIN_RESPONSE: "WWW_USER_LOGIN_RESPONSE",
    WWW_USER_REGISTER: "WWW_USER_REGISTER",
    WWW_USER_REGISTER_RESPONSE: "WWW_USER_REGISTER_RESPONSE",
    WWW_EXISTING_SESSION: "WWW_EXISTING_SESSION",
    WWW_STREAMERS_STREAMS_GET: "WWW_STREAMERS_STREAMS_GET",
    WWW_STREAMERS_STREAM_GET_RESPONSE: "WWW_STREAMERS_STREAM_GET_RESPONSE",
    WWW_CREATE_STR_BANNER: "WWW_CREATE_STR_BANNER",
    WWW_CREATE_STR_BANNER: "WWW_CREATE_STR_BANNER_RESPONSE"
};

export default actionTypes;