import React from 'react'
import {Link as RouteLink} from 'react-router-dom'
import AddIcon from '@material-ui/icons/Add';
import { Tooltip, Link, Fab, Button } from '@material-ui/core'
import { ConditionalComponent } from '../extComponents'


let AddNewButton = (props) =>
(<Tooltip title="Add" aria-label="add" {...props.float && { className: "add-new-btn" }}>
    <ConditionalComponent TagComponent={Link} component={RouteLink} to={props.routeTo} Condition={props.NoLink !== true}>
        {
            (() => {
                if (props.caption) {
                    return (
                        <Button {...props.style && { style: props.style }} variant="contained" size="large" color="primary" {...props.onClick && { onClick: props.onClick }}>
                            {props.caption}
                        </Button>
                    );
                } else {
                    return (
                        <Fab color="secondary" {...props.onClick && { onClick: props.onClick }} >
                            <AddIcon />
                        </Fab>
                    );
                }
            })()
        }
    </ConditionalComponent>
</Tooltip>);

export default AddNewButton;