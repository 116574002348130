import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link as RouterLink } from 'react-router-dom'

import GlobalState from '../../store/globalState'
import UserUtils from '../../utils/userUtils'

import { Hidden, Grid, Button, TextField, Fab, Select, InputLabel, MenuItem, TextareaAutosize, FormControlLabel, FormControl,  } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles'

import { Redirect } from 'react-router-dom';
import MuiAlert from '@material-ui/lab/Alert';

import { commService } from '../../services/api/list';
import { CommInputField, CommForm, CommFormGen, confForm, UploadVideoMedia, CreatePasswordComponent } from '../../structure/common/com-Form/comm-form.components'
import { CommButton, confCOM, confField, CommTextField, fieldNameFunction, temporalItem, fnfRequireText, fnfRequireText2, checkMailField } from '../../structure/common/com'
import { CommItemOfItems, confAddItem, CommListGen, CommLinkButton, CommDeleteButtonLink, confListField, confList, CommList, CommItemField, CommItemTextField } from '../../structure/common/com-List/comm-list.components'
import { mapGenericStateToProps } from '../../utils/routerUtils'
import { LangStrs, Lang, getUserLang } from '../../utils/languageUtils'

import { ModalPolicy } from '../../components/common/modal/modalpolicy'

import { BrowserView, MobileView, isBrowser, isMobile } from '../../utils/platformsUtils';
import { changeUrl, Urls } from './../../utils/routerUtils';

import { Box, Typography, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { HashAndEncrypt } from './../../utils/UtilsEnc.js'

import './registerform.styles.scss';

const StyledButton = (selected) => withStyles({
    root: {
        backgroundColor: selected ? '#8CD0F1' : '#35749E',
        marginBottom: '15px',
        marginLeft: '1px',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#8CD0F1',
            color: '#fff',
        },
    }
})(Button);

const StyledSButton = (selected) => withStyles({
    root: {
        backgroundColor: selected ? '#8CD0F1' : '#1347be',
        marginBottom: '15px',
        marginLeft: '15px',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#8CD0F1',
            color: '#fff',
        },
    }
})(Button);

const StyledBButton = () => withStyles({
    root: {
        backgroundColor: '#1347be',
        color: 'white',
        '&:hover': {
            backgroundColor: '#8CD0F1',
            color: '#fff',
        }
    }
})(IconButton);

const BButton = StyledBButton();

const SButton = StyledSButton(false);

let strs: LangStrs = new LangStrs(new Map(
    [
        ['slogan', new Map(
            [
                [Lang.ES, 'Eleva tu contenido a otro nivel !'],
                [Lang.EN, 'Power up your content to another level !']
            ])
        ],
        ['knowMore', new Map(
            [
                [Lang.ES, 'Quiero saber más'],
                [Lang.EN, 'I want to know more']
            ])
        ],
        ['iAmStreamer', new Map(
            [
                [Lang.ES, 'Soy streamer'],
                [Lang.EN, 'I\'m a streamer']
            ])
        ],
        ['iAmBrand', new Map(
            [
                [Lang.ES, 'Soy un anunciante'],
                [Lang.EN, 'I\'m an advertiser']
            ])
        ],
        ['iAmAgency', new Map(
            [
                [Lang.ES, `Soy una agencia`],
                [Lang.EN, `I am an agency`]
            ])
        ],
        ['Register', new Map(
            [
                [Lang.ES, `Registrarse`],
                [Lang.EN, `Sign in`]
            ])
        ],
    ]
));

export class RegisterForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = { rType: "none" };

        this.conf = [
            fnfRequireText2(new confListField("User name", CommTextField, {}, "username"), "username"),
            new confListField("E-Mail", CommTextField, {}, new fieldNameFunction("email", checkMailField)),
            new confListField("Password", CreatePasswordComponent, {}, "password")
        ];
    }

    render() {
        this.setType();

        const rootStyle = {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        };

        const titleStyle = {
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center',
        };

        return (
            <Grid className='register-form'>
                <Grid container direction="row" justify="space-between" alignContent="space-between">
                    <Grid item xs={12} >

                        <img src="/LogoPowerAdsPubOld3.svg" width="100%" alt="PowerAds logo" />
                    </Grid>
                    
                        <Grid item xs={12} justify="center">
                        <Box style={rootStyle}>
                            <Typography variant="h2" style={titleStyle}>
                                <b>
                                    {strs.get('Register')}
                                </b>
                            </Typography>
                            {this.state.rType != "none" &&
                                <BButton onClick={() => this.setState({ rType: "none" })} style={{ "margin-top": "-20px" }}>
                                    <ArrowBackIcon />
                                </BButton>
                            }
                        </Box>
                        {this.state.rType == "none" &&
                            <Grid container xl={12} xs={12} direction="row" justify="center" alignItems="center" wrap style={{ "padding": "0px" }} >
                                <SButton onClick={(e) => { this.setState({ rType: "streamer" }) }} style={{ width: isMobile ? "40%" : "40%", height:"170px" }} >
                                    <Grid item justify="center">
                                        <img src="./img/home/streamerIcon.png" width='80px' style={{ 'display': 'block', 'margin': 'auto auto' }} />
                                        <h3 style={{ 'textAlign': 'center' }}>{strs.get('iAmStreamer')}</h3>
                                    </Grid>
                                </SButton>
                                <SButton onClick={(e) => { this.setState({ rType: "advertiser" }) }} style={{ width: isMobile ? "40%" : "40%", height: "170px" }}>
                                    <Grid item justify="center" >
                                        <img src="./img/home/brandIcon.png" width='80px' style={{ 'display': 'block', 'margin': 'auto auto' }} />
                                        <h4 style={{ 'textAlign': 'center', size: "2px" }}>{strs.get('iAmAgency')}</h4>
                                    </Grid>
                                </SButton>
                            </Grid>
                        }
                        {this.state.rType != "none" && 
                            <CommForm ref={(r) => { this.form = r; this.setType() }} title="" xsColumn="12" confComm={new confForm(CommFormGen, new commService("/users"), this.conf, undefined)} OnDataChange={(comp, newVal) => { this.newdata = true }} RedirectOnNew="/login" BeforeSaveData={() => {                                
                                if (this.newdata === true) {
                                    let i = this.form.getItem();
                                    i.newData.password = HashAndEncrypt(i.newData.password);
                                    this.newdata = false;
                                }
                                this.modal.setOpen(true);
                                return false;
                            }} />
                        }
                        </Grid>
                </Grid>
                <ModalPolicy ref={(m) => { this.modal = m; }} OnResult={(r) => { r && this.form.SaveData(); }}/>
            </Grid>
        )
    }

    setType() {
        if (this.form != null) {
            this.form.addData({ usertype: this.state.rType });
            this.form.addData({ lang: getUserLang() });
        }
    }

}

export class RegTypeButton extends React.Component {
    constructor(props) {
        super(props);
        this.parent = this.props.parent;
    }

    render() {
        const SButton = StyledButton(this.parent.state.rType == this.props.type);

        return (
            <SButton onClick={(e) => { this.parent.setState({ rType: this.props.type }) }}> {this.props.children } </SButton>
        );
    }
}

export default connect(mapGenericStateToProps, null)(withRouter(RegisterForm));