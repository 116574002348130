import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

import { Hidden, Grid, Button, TextField, Fab, Select, InputLabel, MenuItem, TextareaAutosize, FormControlLabel, FormControl } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles'

import { Redirect } from 'react-router-dom';
import MuiAlert from '@material-ui/lab/Alert';

import { FormFrame } from '../formframe/formframe.jsx'

import { commService } from '../../services/api/list';
import { CommInputField, CommForm, CommFormGen, confForm, UploadVideoMedia, CreatePasswordComponent } from '../../structure/common/com-Form/comm-form.components'
import { CommButton, confCOM, confField, CommTextField, fieldNameFunction, temporalItem, fnfRequireText, fnfRequireText2, checkMailField } from '../../structure/common/com'
import { CommItemOfItems, confAddItem, CommListGen, CommLinkButton, CommDeleteButtonLink, confListField, confList, CommList, CommItemField, CommItemTextField } from '../../structure/common/com-List/comm-list.components'
import { mapGenericStateToProps } from '../../utils/routerUtils'
import { LangStrs, Lang, getUserLang } from '../../utils/languageUtils'
import Loading from '../../structure/common/loading/loading.component';

import ModalDialog from '../../components/common/modal/modal'

import './changepassword.styles.scss';

import { HashAndEncrypt } from '../../utils/UtilsEnc.js'

let strs: LangStrs = new LangStrs(new Map(
    [
        ['TitleChangePassword', new Map(
            [
                [Lang.ES, `Cambiar contraseña`],
                [Lang.EN, `Change Password`]
            ])
        ],
        ['Change', new Map(
            [
                [Lang.ES, `Cambiar`],
                [Lang.EN, `Change`]
            ])
        ],
    ]
));

const StyledButton = (selected) => withStyles({
    root: {
        backgroundColor: selected ? '#8CD0F1' : '#35749E',
        marginBottom: '15px',
        marginLeft: '1px',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#8CD0F1',
            color: '#fff',
        },
    }
})(Button);

export class ChangePassword extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {        
        return (            
            <FormFrame>
                <h2>{strs.get('TitleChangePassword')}</h2>
                <ChangePasswordForm {...this.props}/>
            </FormFrame>
        )
    }
}

export class ChangePasswordForm extends React.Component {
    constructor(props) {
        super(props);

        this.conf = [
            new confListField("Password", CreatePasswordComponent, {}, 'newPass')
        ];
    }

    render() {
        return (
            <CommForm ref={(r: CommForm) => { this.form = r; r.addNewData({ userId: this.props.match.params.userId, token: this.props.match.params.token }); }} title="" xsColumn="12" confComm={new confForm(CommFormGen, new commService(this.props.uri || "/users/changePassword"), this.conf, undefined)} OnDataChange={(comp, newVal) => { }} RedirectOnNew="/login" submitButtonTitle={this.props.btnStr || strs.get('Change')} BeforeSaveData={async () => {return this.props.beforeSend ? await this.props.beforeSend() : true;}}
                OnChangeDataBeforeUpdate={(i) => {
                    if (i.newData.newPass != undefined)
                        i.newData.new = HashAndEncrypt(i.newData.newPass);
                    delete i.newData.newPass;
                }
                }
            />
        )
    }
}

export default connect(mapGenericStateToProps, null)(withRouter(ChangePassword));