import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

import { MuiThemeProvider, Grid, Button, TextField, TextareaAutosize, FormControlLabel, FormControl, IconButton, Paper  } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import UserUtils from '../../utils/userUtils';
import { DownloadUrlVideo } from '../../utils/fileUtils';
import { isMobile } from '../../utils/platformsUtils';
import ServicesBase from '../../services/base';

import FileCopyIcon from '@material-ui/icons/FileCopy';

import { commService } from '../../services/api/list';

import { mapGenericStateToProps } from '../../utils/routerUtils'

import { confField, CommSimpleButton, InputSource } from '../../structure/common/com'
import { CommListGen, confList, CommList } from '../common/com-List/comm-list.components'
import { CommInputField, CommForm, CommFormGen, confForm } from '../common/com-Form/comm-form.components'
import { StreamStreamerCard, ToCheckStreamsService, confStreams } from '../../structure/streams-grid/streams-grid.component.jsx'
import { Fragment } from 'react';

import { LangStrs, Lang } from '../../utils/languageUtils'

import { strs as upfStrs } from '../../components/common/commonUpFile.jsx'

let strs: LangStrs = new LangStrs(new Map(
    [
        ['TGenerateVideo', new Map(
            [
                [Lang.ES, 'Generar video'],
                [Lang.EN, 'Generate video']
            ])
        ],
        ['CreateDesc', new Map(
            [
                [Lang.ES, 'Crear video desde {1} a la cuenta {2} {3}'],
                [Lang.EN, 'Create video from {1} to streaming account {2} {3}']
            ])
        ],
        ['SelectFile', new Map(
            [
                [Lang.ES, 'Seleccionar archivo del video'],
                [Lang.EN, 'Select video file']
            ])
        ],
        ['NowUpload', new Map(
            [
                [Lang.ES, `Ahora sube el video generado a {1}, obtén el enlace para compartir e introdúcelo aquí`],
                [Lang.EN, `Now upload the video generated to {1}, get the share link and enter it here`]
            ])
        ],
        //['AddLinks', new Map(
        //    [
        //        [Lang.ES, `En {1} agrega también el siguiente texto en la sección de patrocinadores (recuerda que cuantas más visiten tus enlaces, más aumentará tu CRM).`],
        //        [Lang.EN, `In {1}, also add the following text in the sponsors section (remember, the more your links are visited, the more your CRM will increase).`]
        //    ])
        //],
        ['AddLinks', new Map(
            [
                [Lang.ES, `En la descripción o un comentario (fijado si es posible) agrega también el siguiente texto en la sección de patrocinadores (recuerda que cuantas más visiten tus enlaces, más aumentará tu CRM).`],
                [Lang.EN, `In the description or a pinned comment, also add the following text in the sponsors section (remember, the more your links are visited, the higher your CRM will increase).`]
            ])
        ],
        ['AddLinkDesc', new Map(
            [
                [Lang.ES, `la descripción`],
                [Lang.EN, `the description`]
            ])
        ],
        ['AddLinkComment', new Map(
            [
                [Lang.ES, `un comentario fijado`],
                [Lang.EN, `a pinned comment`]
            ])
        ],
        ['UpVideo', new Map(
            [
                [Lang.ES, 'Subiendo video : '],
                [Lang.EN, 'Uploading video : ']
            ])
        ],
        ['GenVideo', new Map(
            [
                [Lang.ES, 'Generando video : '],
                [Lang.EN, 'Generating video : ']
            ])
        ],
        ['ESceneVideo', new Map(
            [
                [Lang.ES, 'La escena no es compatible con la resolución del vídeo'],
                [Lang.EN, "The scene isn't compatible with video resolution"]
            ])
        ],
        ['ENoAds', new Map(
            [
                [Lang.ES, 'No hay anuncios para incorporar. Prueba de nuevo más tarde'],
                [Lang.EN, 'There are no ads to incorporate. Try again later']
            ])
        ],
        ['VTitle', new Map(
            [
                [Lang.ES, 'Titulo del video'],
                [Lang.EN, 'Video title']
            ])
        ],
        ['VDesc', new Map(
            [
                [Lang.ES, 'Descripción del vídeo'],
                [Lang.EN, 'Video Description']
            ])
        ],
        ['Generate', new Map(
            [
                [Lang.ES, 'Generar'],
                [Lang.EN, 'Generate']
            ])
        ],
        ['btnWait', new Map(
            [
                [Lang.ES, 'Wait'],
                [Lang.EN, 'Espere']
            ])
        ],
        ['Required', new Map(
            [
                [Lang.ES, 'requerido'],
                [Lang.EN, 'required']
            ])
        ],
        ['ETooSize', new Map(
            [
                [Lang.ES, 'Lo siento, ahora mismo no se aceptan videos de mas de 2 Gigabyes'],
                [Lang.EN, 'Sorry currently, videos over 2 Gigabytes are not accepted']
            ])
        ],
        ['EBusyServers', new Map(
            [
                [Lang.ES, 'Servidores ocupados, por favor espere'],
                [Lang.EN, 'Servers busy, please wait']
            ])
        ],
        ['ESANotAllowd', new Map(
            [
                [Lang.ES, 'cuenta de streaming no autorizada'],
                [Lang.EN, 'Streaming account not allowed']
            ])
        ]
    ]
));

export class InputSourceToStream extends React.Component {
    render = () => {
        let disabled = this.props.disabled;
        this.props = confField.concatProps(this.props);
        return <CommSimpleButton disabled={disabled===true} color="primary" variant="contained" component="span" onClick={() => this.props.OnSelect(this)}>
            {this.props.CaptionCopyOBS || this.props.children}
        </CommSimpleButton>
    }
}

export const LinksRender = (linkText, streamingAccount) => {
    if (!linkText) return null;
    linkText = linkText.map(l => `${l.AdText} ${l.AdLink}`).join('\n');

    let whereComment = strs.get('AddLinkDesc');
    if (["Tiktok"].find(s => s == streamingAccount)) whereComment = strs.get('AddLinkComment');

    return <div style={{ maxWidth: '600px' }}>
        <p>
            <h4>
                {strs.get('AddLinks', whereComment)}
                </h4>
        </p>
        <p style={{ maxWidth: '550px' }}>
            <div style={{ position: 'relative', 'marginTop': '50px' }}>
                <Paper style={{ width: '100%', overflow: 'hidden', padding: '10px' }}>
                    {/* Contenedor del texto con desplazamiento */}
                    <div
                        //ref={this.textAreaRef}
                        style={{
                            overflow: 'auto', // permite el desplazamiento tanto horizontal como vertical
                            whiteSpace: 'pre', // respeta los espacios en blanco y saltos de línea
                            padding: '10px 14px', // emula el relleno de TextField
                            height: 'calc(1.1876em * 4 + 18.5px * 2)', // emula el alto de un TextField con 4 líneas
                            maxHeight: 'calc(1.1876em * 4 + 18.5px * 2)', // establece un alto máximo
                            cursor: 'text', // muestra el cursor de texto
                        }}
                    >
                        {linkText}
                    </div>
                </Paper>
                <div style={{ position: 'absolute', top: '-36px', right: '-20px' }}>
                    <Button color="primary" variant="contained" className="list-button" onClick={() => navigator.clipboard.writeText(linkText)} style={{ boxShadow: 'none', borderRadius: 0, textTransform: 'none' }}>
                        <FileCopyIcon style={{ marginRight: "5px" }} />
                        Copiar a portapapeles
                    </Button>
                </div>
            </div>
        </p>
    </div>
}

export class GenerateVideoForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            creating: 0,
            created: false,
            updated: false,
            error: null,
            scene: null,
            streamingAccount: null,
            streamid: null,
            correctUrl: false
        }
        this.refreshTime = 1 * 1000;
    }

    async componentDidMount() {
        this.sceneParam = this.props.scene || Number(this.props.match.params.scene);
        this.streamingAccount = this.props.streamingAccount || this.props.match.params.streamingAccount;

        let rSA = await new commService("/streamers/:id/streamingAccount").Get(UserUtils.GetUserData().id, this.streamingAccount);

        let rScene = this.sceneParam;
        if (typeof this.sceneParam === 'number') {
            if (this.sceneParam == -1) rScene = { name: 'default scene', id: -1 };
            else rScene = (await new commService("/streamers/:id/streamScene").Get(UserUtils.GetUserData().id, this.sceneParam)).body[0];
        } else rScene = { name: 'editor scene', id: -2 }
        //let rScene = this.sceneParam == -1 ? { body: [{ name: 'default scene', id: -1 }] } : await new commService("/streamers/:id/streamScene").Get(UserUtils.GetUserData().id, this.sceneParam);

        this.setState({ scene: rScene, streamingAccount: rSA.body[0] })
    }

    async setVideo(videoFile, props) {
        this.newVideoProps = props;
        this.newVideoFile = videoFile;

        await this.setState({ fileSelected: true });
        this.isFormCorrect();
    }

    async isFormCorrect() {
        const title = document.getElementById('videoTitle')?.value ?? "";
        if (title != "" && this.state.fileSelected) {
            this.setState({ formCorrect: true });
            return true;
        }
        this.setState({ formCorrect: false });
        return false;
    }

    newVideoProps = {};
    generateServer = "www.powerads.tv"
    async generateVideo(tries = 0) {
        if (tries > 10) return;

        this.newVideoProps.streamingAccount = this.streamingAccount;
        let sb: ServicesBase = new ServicesBase("/api/streamers/generateVideoServer");
        let resp = await sb.ServiceRequest("", 'POST', { 'Content-Type': 'application/json' }, false, this.newVideoProps);

        if (resp.error || resp.httpCode != 200) {
            if (resp.httpCode == 401)
                await this.setState({ error: strs.get("ESANotAllowd") });
            else {
                await this.setState({ error: strs.get('EBusyServers') });
                setTimeout(() => { this.generateVideo(0); }, 30000);
            }
            return;
        }
        let server = resp.body.url;
        await this.setState({ error: "" });

        const title = document.getElementById('videoTitle').value;
        const desc = document.getElementById('VideoDesc').value;
        //const fileInput = document.getElementById('videoInput');

        const formData = new FormData();

        formData.append("title", title);
        formData.append("description", desc);
        formData.append("streamingAccount", this.streamingAccount);
        formData.append("scene", typeof this.sceneParam === "number" ? (+this.sceneParam) : JSON.stringify(this.sceneParam));
        formData.append("platformAuth", ""); //?!
        //formData.append("media", fileInput.files[0]);
        formData.append("media", this.newVideoFile);

        const options = {
            method: "POST",
            body: formData
            // If you add this, upload won't work
            // headers: {
            //   'Content-Type': 'multipart/form-data',
            // }
        };
        let response, body, error;

        const xhr = new XMLHttpRequest();
        const success = await new Promise((resolve) => {
            xhr.upload.addEventListener("progress", async (event) => {
                if (event.lengthComputable) {
                    await this.setState({ uploading: event.loaded / event.total });
                }
            });
            xhr.addEventListener("loadend", () => {
                resolve(xhr.readyState === 4 && xhr.status === 201);
            });
            xhr.addEventListener("error", () => {
                alert("error");
            });

            xhr.open("POST", "https://" + server + "/api/streamers/" + UserUtils.GetUserData().id + "/generateVideo", true);
            xhr.withCredentials = true;
            xhr.send(formData);
        });

        if (success) {
            setTimeout(() => { this.waitvideocreated(JSON.parse(xhr.response).token, server); }, this.refreshTime);
        } else {
            console.log(xhr);
            if (xhr.status == 401) {
                await this.setState({ error: strs.get("ESANotAllowd") });
            } else {
                if (xhr.response == "")
                    setTimeout(() => { this.generateVideo(++tries); }, 1000);
                else
                    this.waitvideoerror(JSON.parse(xhr.response).error);
            }
        }
        //response = await fetch("/api/streamers/" + UserUtils.GetUserData().id + "/generateVideo", options);
        //try {
        //    let text = await response.text();
        //    response.status < 400 ? (body = text.length != 0 ? JSON.parse(text) : "") : (error = JSON.parse(text));
        //    setTimeout(() => { this.waitvideocreated(body.videoid); }, this.refreshTime);
        //}
        //catch (e) {
        //    error = { error: "An error happened while connecting to server, please try again later." };            
        //}
    }

    async waitvideoerror(error) {
        if (error == "ERROR_GENVIDEO_INCORRECT_VIDEO_WITH_SCENE") {
            await this.setState({ error: strs.get('ESceneVideo'), creating: 0, uploading: 0, updated: false });
        } else if (error == "ERROR_GENVIDEO_NO_ADS") {
            await this.setState({ error: strs.get('ENoAds'), creating: 0, uploading: 0, updated: false });
        } else
            await this.setState({ error: "Error: " + error, creating: 0, uploading: 0, updated: false });
    }

    async waitvideocreated(token, server) {
        //let sb: ServicesBase = new ServicesBase("#server#" + "/streamers/" + UserUtils.GetUserData().id + "/video/" + videoid + "/getstate");
        let sb: ServicesBase = new ServicesBase("https://" + server + "/api/streamers/" + UserUtils.GetUserData().id + "/video/" + token + "/getstate");
        let resp = await sb.ServiceRequest("", 'GET', {}, false, null, UserUtils.GetUserData().id);
        console.log(resp.body);
        if (resp.body.error != undefined && resp.body.error != "" && resp.body.error.error != "") {
            const e = resp.body.error.error ? resp.body.error.error : resp.body.error;
            this.waitvideoerror(e);
        } else {
            try {
                await this.setState({ streamid: resp.body.idVideo, creating: resp.body.creatingProgress, created: resp.body.created, updated: resp.body.updated });
            } catch (e) { }
            if (!this.state.updated) setTimeout(() => { this.waitvideocreated(token, server); }, this.refreshTime);
            //else window.open('https://studio.youtube.com/video/' + resp.body.platformResp.id + '/edit', '_blank', 'toolbar=0,location=0,menubar=0'); //YOUTUBE
            else {
                await this.setState({ downloadUrlVideo: DownloadUrlVideo(resp.body.mediaUrl), videoResp:resp.body });
                window.open(DownloadUrlVideo(resp.body.mediaUrl), '_blank', 'toolbar=0,location=0,menubar=0');
                if (this.props.OnStreamsChanged) this.props.OnStreamsChanged(2);
            }
        }
    }

    render = () => {
        let linkText = "no text";
        if (this.state.links) {
            linkText = this.state.links.map(l => `${l.AdText} ${l.AdLink}`).join('\n')
            //linkText = linkText + "\n" + linkText + "\n" + linkText + "\n" + linkText + "\n" + linkText + "\n" + linkText + "\n" + linkText + "\n" + linkText + "\n";
        }

        return (
            <Grid direction="column">
                <Grid className="form-column">
                    <h1>{strs.get('TGenerateVideo')}</h1>
                </Grid>
                { this.props.error && <MuiAlert severity="error">{this.props.error.error.toString()}</MuiAlert>}
                { this.state.error && <MuiAlert severity="error">{this.state.error}</MuiAlert>}
                { /*this.state.created && <Redirect to={"/dashboard/banners/" + this.streamingAccount} />*/}
                <Grid child container className={'banner-form ' + this.props.className} direction="row" justify="flex-start" alignItems="flex-start">
                    <Grid child container direction="row">
                        <Grid className="form-column" child container xs="12" style={{ 'max-width': '650px' }}>
                            {!this.state.created &&
                                <Fragment>
                                    <Grid item xs={12}>
                                        {this.state.scene != null && this.state.streamingAccount != null && strs.get('CreateDesc', this.state.scene.name, this.state.streamingAccount.streamingType, this.state.streamingAccount.name)}
                                        <br />
                                        <p />
                                    </Grid>
                                    <TextField className="form-input" fullWidth id="videoTitle" label={strs.get('VTitle')} helperText={strs.get('Required')} variant="outlined" onChange={(e) => this.isFormCorrect()} />
                                    <TextField className="form-input" fullWidth multiline rows={4} id="VideoDesc" label={strs.get('VDesc')} helperText="" variant="outlined" />
                                    <Grid item xs={12} {...{ style: { "text-align": "center" } }}>
                                        {
                                            !this.props.noSelectVideo &&
                                            <p>
                                                <InputSource OnSelect={async (comp, file, props, error) => { if (error != undefined) { this.setState({ formCorrect: false }); await this.setState({ error: upfStrs.get(error) }); } else { await this.setState({ error: null }); await this.setVideo(file, props); } }}>{strs.get('SelectFile')}</InputSource>
                                            </p>
                                        }
                                    </Grid>
                                </Fragment>
                            }
                            {
                                this.state.created &&
                                <Fragment>
                                    {!this.state.correctUrl && this.state.videoResp && LinksRender(this.state.videoResp.links, this.state.videoResp.streamingAccount)}
                                    <CommForm noCard confComm={new confForm(StreamStreamerCard, new ToCheckStreamsService("/streamers/:id/stream", this), confStreams, this.state.streamid)} OnCorrectUrlSubmit={() => { this.setState({ correctUrl: true }); this.props.OnStreamsChanged && this.props.OnStreamsChanged(0) }} />
                                </Fragment>
                            }
                            <Grid item xs={12} {...{ style: { "text-align": "center" } }}>
                                {
                                    <p>
                                        <CommSimpleButton disabled={!this.state.downloadUrlVideo && (!this.state.formCorrect ? true : undefined)} variant="contained" size="large" color="primary" onClick={async () => { if (this.state.creating == 0 && !this.state.uploading && this.state.error != strs.get('EBusyServers')) { await this.generateVideo(); } }}>
                                            {
                                                (() => {
                                                    if (this.state.creating == 0 && !this.state.uploading) {
                                                        if (this.state.error == strs.get('EBusyServers')) return strs.get('btnWait');
                                                        return strs.get('Generate');
                                                    }
                                                    else {
                                                        if (this.state.uploading && this.state.uploading != 1 && this.state.creating == 0) return strs.get('UpVideo') + parseInt(this.state.uploading * 100) + "%";
                                                        else if (!this.state.created) return strs.get('GenVideo') + Math.min(99, parseInt(this.state.creating * 100)) + "%";
                                                        //else if (!this.state.updated) return "Video generated! updating to youtube";
                                                        else return <a href={this.state.downloadUrlVideo} target="_blank" {...{ style: { "text-decoration": "none", color: "yellow" } }}> Download video</a>;
                                                    }
                                                })()
                                            }
                                        </CommSimpleButton>
                                    </p>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default connect(mapGenericStateToProps, null)(withRouter(GenerateVideoForm));