import ServicesBase from "../../base"
import UserUtils from '../../../utils/userUtils'

class OptionsService extends ServicesBase {
    constructor() {
        super("#server#");
    }
    Add = async (data, loading = true) => {//refer segons get
        return await this.ServiceRequest("/", 'POST', {
            'Content-Type': 'application/json',
        },
            loading,
            data
        );
    };
    Update = async (id, data, loading = true) => {//refer segons get
        return await (this.ServiceRequest("/" + id, 'PUT', {
            'Content-Type': 'application/json',
        }, loading, data));
    };
    Get = async (uriOptions, loading = true) => {
        return await (this.ServiceRequest(uriOptions+"/:id", 'GET', {}, loading, null, UserUtils.GetUserData().id));
    };
    Delete = async (id, loading = true) => { //refer segons get
        return await (this.ServiceRequest("/" + id, 'DELETE', {}, loading));
    }

    GetSelOptions = async (OptionType, userId, loading = true) => {
        return await this.ServiceRequest(OptionType, 'GET', {
            'Content-Type': 'application/json',
        },
            loading,
            null,
            userId
        );
    };

    changeOptionSelection = async (option, optionUri, addRemove, optionalData = null, loading = true) => {
        let tmpOpData = { optionSel: option };
        return await this.ServiceRequest(optionUri + '/' + option.id, addRemove ? 'POST' : 'DELETE', {
            'Content-Type': 'application/json',
        },
            loading,
            !addRemove ? null : optionalData == null ? tmpOpData : Object.assign({}, tmpOpData, optionalData),
            UserUtils.GetUserData().id
        );
    };
};

export default new OptionsService();