import React, { useState, useEffect } from 'react';

import { MultiSelect } from "react-multi-select-component";
import './profile-tag.styles.scss';
import {Box, Grid, Typography, Checkbox, FormControlLabel} from '@material-ui/core'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary  from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { BrowserView, MobileView, isBrowser, isMobile } from '../../utils/platformsUtils';
import { getBackText } from '../../utils/languageUtils';
import { Block, Lock, LockOpen, PanoramaFishEye, RemoveRedEye } from '@styled-icons/material';
import userUtils, { UserTypes } from '../../utils/userUtils';
import { PermissionService } from '../../services/api/permission'

const pPerimissionToOption = new PermissionService("permissionsToOption");

const PermissionToggle = (props) => {
    let loading = false;
    const currentState = props.obj[props.permissionName];
    let Icon;
    const SetupIcon = () => { Icon = currentState ? [props.iconON, props.iconOFF] : [props.iconOFF, props.iconON] }
    SetupIcon();

    const [icon, setIcon] = useState(Icon[0]);

    useEffect(() => {
        SetupIcon();
        setIcon(Icon[0]);
    }, [currentState]);

    const handleMouseEnter = () => {
        if (loading) return;
        setIcon(Icon[1]);
    };

    const handleMouseLeave = () => {
        if (loading) return;
        setIcon(Icon[0]);
    };

    const onChangePermission = async () => {
        loading = true;
        await pPerimissionToOption.Change(props.obj.idPermission, { optionSet: props.obj.id, user: userUtils.GetUserData().id, [props.permissionName]: !props.obj[props.permissionName] });
        await props.toReload();
        loading = false;
    };

    return (
        <div onClick={onChangePermission} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {icon}
        </div>
    )
}

const ProfileTag = (props) => {
    var typeClass;
    switch(props.tag.type) {
        case "typeStreams": 
            typeClass = "tstreamer";
        break;
        case "typeAudience": 
            typeClass = "taudience";
        break;
    }

    const PermissionToggleRender = (permissionName, iconOFF, iconON) => <PermissionToggle obj={props.tag} permissionName={permissionName} iconOFF={iconOFF} iconON={iconON} toReload={async () => { await props.parent.GetTags(); } }/>
    console.log("props.viewOptions " + props.viewOptions);
    return (
        <Grid className={"profile-tag " + typeClass} item xs={12}>            
            <Accordion onChange={props.onExpand} expanded={true}>
                <AccordionSummary className="panel-header">
                    {
                        userUtils.GetUserData(true).usertype == UserTypes.Admin &&
                        <React.Fragment>
                            {PermissionToggleRender("visible", <Block size="24" />, <RemoveRedEye size="24" />)}
                            {PermissionToggleRender("changeable", <Lock size="24" />, <LockOpen size="24" />)}
                        </React.Fragment>
                    }
                    <Typography>{props.title}</Typography>
                </AccordionSummary >
                <AccordionDetails>
                    { (props.viewOptions==undefined || props.viewOptions) &&
                        <Grid container direction="row" xs={12}>
                            <p>
                                {props.description}
                            </p>
                            {
                                multiComboBox(props, this)
                            }
                            {
                                isBrowser && props.items.length<8 &&
                                props.items.map((item) => {
                                    return <Box key={item.id} className="tag-box"><FormControlLabel
                                        control={<Checkbox onChange={(ev) => { props.onToggleCheck(item) }} data-tag-id={item.id} name={getBackText(item.text)} checked={item.selected===true?true:false} />}
                                        label={getBackText(item.text)}
                                    /></Box>
                                })
                                //JSON.parse(props.items.str[0]).map((item) => {
                                //    let selectedId = -1;
                                //    if(props.tag.selected) {
                                //        for(var i = 0; i < props.tag.selected.length; i++) {
                                //            if(item.id == props.tag.selected[i].selectOption || item.id == props.tag.selected[i].SelectedOption) {
                                //                selectedId = props.tag.selected[i].id;
                                //                break;
                                //            }
                                //        }
                                //    }
                                //    return <Box key={item.id} className="tag-box"><FormControlLabel
                                //    control={<Checkbox onChange={(ev)=>{props.onToggleCheck(props.tag.id, item.id, selectedId, ev.target.checked)}} data-tag-id={item.id} name={item.str}  checked={selectedId != -1 ? true : false }/>}
                                //    label={item.str}
                                //    /></Box>
                                //})                            
                            }
                        </Grid>
                    }
            </AccordionDetails>
        </Accordion>
      </Grid>
    )
}

const multiComboBox = (props, parent) => {
    //console.log("PARENT");
    //console.log(parent);
    let options = props.items.map((item) => {
        return { label: getBackText(item.text), value: item.id, selected: item.selected===true ? true : false, item:item }
    });
    let selected = options.filter(i => { return i.selected; });
    
    return (
        <div style={{ height: "100%" }, { width: "100%" }}>
            <MultiSelect
                options={options}
                value={selected}
                onChange={
                    (items) => {
                        let i = items.find(i => { return i.selected == false });
                        if (i == undefined) {
                            i = selected.find(i => { return !items.some(s => { return s.value == i.value; }) });
                        }
                        props.onToggleCheck(i.item);
                    }
                }
                labelledBy="Select"
                hasSelectAll={false}
                ClearSelectedIcon={null}
                closeOnChangedValue={true}
            />
        </div>
    );
}

export default ProfileTag;