import React from 'react';
import { makeStyles, Card, CardContent, Typography, Avatar } from '@material-ui/core';
import FaceIcon from '@material-ui/icons/Face';
import { ListFilesComp } from '../../structure/common/com-List/comm-list.components.jsx'

const useStyles = makeStyles((theme) => ({
    card: {
        width: '100%',
        margin: theme.spacing(2, 0),
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
    },
    userInfo: {
        display: 'flex',
        alignItems: 'center',
    },
    avatar: {
        marginRight: theme.spacing(1),
        backgroundColor: 'rgb(53, 116, 158)',
    },
    date: {
        fontSize: 14,
        color: theme.palette.text.secondary,
    },
    messageWrapper: {
        marginTop: theme.spacing(1),
        padding: theme.spacing(1, 2),
        border: `2px solid rgb(53, 116, 158)`,
        borderRadius: theme.spacing(1),
    },
    message: {
        whiteSpace: 'pre-line',
    },
    filesWrapper: {
        marginTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        '& > div': {  // selecciona todos los hijos div directos de filesWrapper
            margin: theme.spacing(1),
        }
    },
}));

const MessageCard = (props) => {    
    const classes = useStyles();
    const { fromName, createdAt, message } = props.item;
    const rUrl = props.item.resourceURL;
    return (
        <Card className={classes.card} elevation={3}>
            <CardContent className={classes.content}>
                <div className={classes.header}>
                    <div className={classes.userInfo}>
                        <Avatar className={classes.avatar}>
                            <FaceIcon style={{ color: 'white' }} />
                        </Avatar>
                        <Typography variant="h6">{fromName}</Typography>
                    </div>
                    <Typography className={classes.date} variant="body2">
                        {createdAt}
                    </Typography>
                </div>
                <div className={classes.messageWrapper}>
                    <Typography className={classes.message} variant="body1">
                        {message}
                    </Typography>
                </div>
                <div className={classes.filesWrapper}>
                    <ListFilesComp resourcesURL={rUrl} />
                </div>
            </CardContent>
        </Card>
    );
};

export default MessageCard;
