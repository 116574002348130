import React from 'react';
import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom'

import GlobalState from '../../store/globalState'
import { isBrowser, isMobile, MobileView } from '../../utils/platformsUtils';

import { Grid, Button, TextField, TextareaAutosize, FormControlLabel, FormControl, Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import LinearProgress from '@material-ui/core/LinearProgress';
import {Redirect} from 'react-router-dom';
import MuiAlert from '@material-ui/lab/Alert';
import Streamers from '../../services/api/streamers';
import UserUtils from '../../utils/userUtils'
import { commService } from '../../services/api/list';
import { CommInputField, CommForm, CommFormGen, confForm, RadioButtonList, CommRadioButtonList } from '../common/com-Form/comm-form.components'
import { CommButton, confCOM, confField, CommTextField, fieldNameFunction, temporalItem, fnfRequireText, CommDateTimePicker, InputSource, FileButtonComm } from '../common/com'
import { CommButtonSave, CommItemOfItems, confAddItem, CommListGen, CommLinkButton, CommDeleteButtonLink, confListField, confList, CommList, CommItemField, CommItemTextField, CommItemFieldTextLink, CommListFlat, CommItemTextLink, ListFilesComp } from '../common/com-List/comm-list.components'
import { changeUrl, changeUrlByReact, Urls } from '../../utils/routerUtils'
import { RenderBannerType } from '../../structure/ads-form/ads-form.component.jsx'
import { mapGenericStateToProps } from '../../utils/routerUtils'
import { withStyles } from '@material-ui/core/styles';
import { LangStrs, Lang } from '../../utils/languageUtils'
import { OptionsToCheckRequired } from '../options/options.component'
import { useHistory } from 'react-router-dom';
import { PostFileButton } from '../../components/common/commonUpFile.jsx'
import { strs as bStrs, MoveSponsorButtons } from '../../structure/common/branded/commonBranded.jsx'
import { StateEditor, textLinkFields, AdEntityLogs } from '../../structure/ads-form/ads-form.component.jsx'
import CommHelpLink, { getDocUrl } from '../../structure/common/comHelpLink/comHelpLink.jsx'

import ListIcon from '@material-ui/icons/List';

export let strs: LangStrs = new LangStrs(new Map(
    [
        ['Title', new Map(
            [
                [Lang.ES, 'Campaña de Contenido de Marca'],
                [Lang.EN, 'Branded Content Campaign']
            ])
        ],
        ['Name', new Map(
            [
                [Lang.ES, 'Nombre'],
                [Lang.EN, 'Name']
            ])
        ],
        ['duration', new Map(
            [
                [Lang.ES, 'Duración (en segundos)'],
                [Lang.EN, 'Duration (in seconds)']
            ])
        ],
        ['description', new Map(
            [
                [Lang.ES, 'Detalla las instrucciones para el creador de contenido'],
                [Lang.EN, 'Detail the instructions for the content creator']
            ])
        ],
        ['Resources', new Map(
            [
                [Lang.ES, 'Archivos'],
                [Lang.EN, 'Resources']
            ])
        ],
        ['Actions', new Map(
            [
                [Lang.ES, 'Acciones'],
                [Lang.EN, 'Actions']
            ])
        ],
        ['File', new Map(
            [
                [Lang.ES, 'Archivo'],
                [Lang.EN, 'File']
            ])
        ],
        ['SelectFile', new Map(
            [
                [Lang.ES, 'Subir nuevo archivo'],
                [Lang.EN, 'Upload new file']
            ])
        ],
        ['FileUploaded', new Map(
            [
                [Lang.ES, 'Archivo subido'],
                [Lang.EN, 'File uploaded']
            ])
        ],
        ['Uploading', new Map(
            [
                [Lang.ES, 'Subiendo: '],
                [Lang.EN, 'Uploading: ']
            ])
        ],
        ['UpError', new Map(
            [
                [Lang.ES, 'Error al subir, intenta más tarde'],
                [Lang.EN, 'Upload error, try again later']
            ])
        ],
        ['UpErrorSize', new Map(
            [
                [Lang.ES, 'Archivo demasiado grande'],
                [Lang.EN, 'File too large']
            ])
        ],
        ['SponsoredContent', new Map(
            [
                [Lang.ES, 'Contenido Patrocinado'],
                [Lang.EN, 'Sponsored Content']
            ])
        ],
        ['SponsoredSegment', new Map(
            [
                [Lang.ES, 'Segmento Patrocinado'],
                [Lang.EN, 'Sponsored Segment']
            ])
        ],
        ['ContentType', new Map(
            [
                [Lang.ES, 'Tipo de contenido de marca'],
                [Lang.EN, 'Content Type']
            ])
        ],
        //['defMoneyFactor', new Map(
        //    [
        //        [Lang.ES, 'Multiplicador de Pago'],
        //        [Lang.EN, 'Payment Multiplier']
        //    ])
        //],
        //['defCollaborationTimes', new Map(
        //    [
        //        [Lang.ES, 'Número de Colaboraciones'],
        //        [Lang.EN, 'Number of Collaborations']
        //    ])
        //],
        //['defDateLimit', new Map(
        //    [
        //        [Lang.ES, 'Fecha Límite'],
        //        [Lang.EN, 'Deadline']
        //    ])
        //],
    ]
));

class BrandedContentForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = { }

        const typeBranded = [
            { label: strs.get('SponsoredContent'), value: 0 },
            { label: strs.get('SponsoredSegment'), value: 1 },
        ];

        this.conf = [
            new confField(strs.get('Name'), CommTextField, { className: "list-field bi-name" }, fnfRequireText("name")),
            new confField(strs.get('ContentType'), CommRadioButtonList, { options: typeBranded, orientation: "row"}, fnfRequireText("type")),
            new confField(strs.get('duration'), CommTextField, { className: "list-field bi-amount not-mobile" }, fnfRequireText("duration"), 20),
            ...textLinkFields(),
            new confField(strs.get('description'), CommTextField, { className: "list-field bi-name", AutoSize: true }, fnfRequireText("description")).addField("rows", 4),
            ...StateEditor(this)
            //new confField(strs.get('defMoneyFactor'), CommTextField, { className: "list-field bi-amount not-mobile" }, "defMoneyFactor", 1),
            //new confField(strs.get('defCollaborationTimes'), CommTextField, { className: "list-field bi-amount not-mobile" }, "defCollaborationTimes", 1),
            //new confField(strs.get('duration'), CommTextField, { className: "list-field bi-amount not-mobile" }, "duration", 0),
        ];

        this.confResources = [
            new confListField(strs.get("Delete"), FileButtonComm, { canDelete:true }, (item) => { return { fileName: item.name, url: item.path }; })
        ];

        this.optionsToCheckRequired = new OptionsToCheckRequired();
    }
    /*
    async postFile(file, props, tries = 0) {
        if (tries > 10) return;
        await this.setState({ upError: undefined });

        const formData = new FormData();

        formData.append("entity", this.props.match.params.id);
        formData.append("media", file);

        const options = {
            method: "POST",
            body: formData
            // If you add this, upload won't work
            // headers: {
            //   'Content-Type': 'multipart/form-data',
            // }
        };
        let response, body, error;

        const xhr = new XMLHttpRequest();
        const success = await new Promise((resolve) => {
            xhr.upload.addEventListener("progress", async (event) => {
                if (event.lengthComputable) {
                    await this.setState({ uploading: event.loaded / event.total });
                }
            });
            xhr.addEventListener("loadend", () => {
                resolve(xhr.readyState === 4 && xhr.status === 201);
            });
            xhr.addEventListener("error", () => {
                alert("error");
            });

            xhr.open("POST", "/api/advertisers/" + UserUtils.GetUserData().id + "/sponsorship/" + this.props.match.params.id + "/resource", true);
            xhr.withCredentials = true;
            xhr.send(formData);
        });

        if (success) {
            let r = this.commlistResources?.QueryItems();
        } else {
            console.log(xhr);
            if (xhr.status == 401) {
                await this.setState({ upError: strs.get("ESANotAllowd") });
            } else {
                if (xhr.response == "")
                    setTimeout(() => { this.postFile(file, props, ++tries); }, 1000);
                else
                    await this.setState({ upError: "Error" });
            }
        }
    }*/

    render() {
        //const cantSelCreators = this.state.item == undefined || this.state.item.state != 0 && this.state.requeriments == undefined) || this.state.requeriments === false;
        //Si no cal checkejar el anunci per part del admin, es la versio de sobre
        const cantSelCreators = this.state.item == undefined || this.state.item.state != 3;
        return (            
            <Grid direction="column">                
                <Grid className="form-column">
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <h1>{strs.get("Title")}<CommHelpLink infoUrl={getDocUrl("como-crear-una-campana-de-contenido-de-marca")}/></h1>
                        {MoveSponsorButtons(this.props, this.state.item, this.props.match.params.id, "fromDescription", cantSelCreators, false)}
                    </div>
                </Grid>
                <Grid child container className={'brandedContent-form ' + this.props.className} direction="row" justify="flex-start" alignItems="flex-start">
                    <Grid child container direction="row" xs="12">
                        <CommForm ref={(c) => this.formAd = c} confComm={new confForm(CommFormGen, new commService("/advertisers/:id/sponsorship"), this.conf, this.props.match.params.id)} RedirectOnNew={Urls.AdvBrandedEdit + ":new"} OnQuery={(comp, data) => this.setState({ item: data })} />
                    </Grid>
                    {AdEntityLogs(this)}
                    {this.props.match.params.id && <>
                        <h3>{strs.get('Resources')}</h3>
                        <Grid child container direction="row" xs="12">
                            <ListFilesComp ref={(c) => this.commlistResources = c} resourcesURL={"/advertisers/:id/sponsorship/" + this.props.match.params.id + "/resource"} canDelete={true} />
                            
                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px', width: '100%', justifyContent: "flex-start", marginTop:"10px" }}>                                
                                <PostFileButton
                                    filetype={"video/*,image/*,application/pdf"}
                                    postUrl={"/api/advertisers/" + UserUtils.GetUserData().id + "/sponsorship/" + this.props.match.params.id + "/resource"}
                                    customFormData={(formData, file) => {
                                        formData.append("entity", this.props.match.params.id);
                                        formData.append("media", file);
                                        return formData;
                                    }}
                                    OnSuccess={() => {
                                        let r = this.commlistResources?.comp.QueryItems();
                                    }}
                                />
                            </div>
                        </Grid>
                    </>}
                </Grid>
                {this.props.match.params.id && RenderBannerType(this, this.props.match.params.id, { OnChangedOption: () => this.setState({ requeriments: this.optionsToCheckRequired.checkRequeriments() }) })}
            </Grid>
        )
    }
}


const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 10,
        borderRadius: 5,
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
        borderRadius: 5,
        backgroundColor: '#1a90ff',
    },
}))(LinearProgress);

export default connect(mapGenericStateToProps, null)(withRouter(BrandedContentForm));