
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

import UserUtils from '../../utils/userUtils'
import userUtils, { UserTypes } from '../../utils/userUtils'
import { changeUrl, Urls } from '../../utils/routerUtils'
import { toMStr } from '../../utils/moneyUtils.jsx'
import { Grid, Button, TableCell, TableRow } from '@material-ui/core'
import { Delete, Visibility, AttachMoney, Edit, FileCopy, CreditCard } from '@styled-icons/material';
import MuiAlert from '@material-ui/lab/Alert'
import AdItem from '../../components/advertiser/ad-item/ad-item.component'
import ContentNavigation from '../../structure/content-navigation/content-navigation.component'
import Advertisers from '../../services/api/advertisers/';
import GlobalState from '../../store/globalState'
import DeleteItemDialog from '../common/deleteItemDialog/deleteItemDialog.component.jsx';
import AddNewButton from '../common/add-new-button/add-new-button.component.jsx';

import { confAddItem, CommListGen, CommLinkButton, CommDeleteButtonLink, confListField, confList, CommList, CommItemField, CommItemOfItems, CommButtonLinkItem, CommItemFieldTextLink, ListFieldButtonLink } from '../common/com-List/comm-list.components'
import { confListStatic, CommListStatic } from '../common/com-List/com-List-Static/comm-list-static-component'
import { myReactTaggedComponent, ReactComponentTag, CompWithParent } from '../common/extComponents'
import { CommItemFieldLink, CommSimpleTextButton } from '../common/com'
import { commService } from '../../services/api/list';

import { withStyles } from '@material-ui/core/styles'
import AdsList from '../ads-list/ads-list.components';

import { mapGenericStateToProps } from '../../utils/routerUtils'

import { DurationTime } from '../../utils/timeUtils';

import { LangStrs, Lang } from '../../utils/languageUtils'
import { bannerStrs } from '../../templates/languages/banner-strs.js'

import SelectUser, { UsersFilterTypes } from '../../components/admin/select-user/select-user.component.jsx'
import UserWallet, { TransferDetailsItemField } from '../wallet-form/wallet-form.component'
import ExternalTransferPayment from '../external-transfer-payment/external-transfer-payment.component'

export let strs: LangStrs = new LangStrs(new Map(
    [
        ['Date', new Map(
            [
                [Lang.ES, 'Fecha'],
                [Lang.EN, 'Date']
            ])
        ],
        ['Quantity', new Map(
            [
                [Lang.ES, 'Cantidad'],
                [Lang.EN, 'Quantity']
            ])
        ],
        ['Details', new Map(
            [
                [Lang.ES, 'Detalles'],
                [Lang.EN, 'Details']
            ])
        ],
        ['WalletAmount', new Map(
            [
                [Lang.ES, 'Cantidad total de la cartera : '],
                [Lang.EN, 'Total wallet amount : ']
            ])
        ],
        ['Transactions', new Map(
            [
                [Lang.ES, 'Transacciones'],
                [Lang.EN, 'Transactions']
            ])
        ],
        ['TWallet', new Map(
            [
                [Lang.ES, 'Cartera'],
                [Lang.EN, 'Wallet']
            ])
        ],
        ['PayedTransferTo', new Map(
            [
                [Lang.ES, 'Pago Paypal a'],
                [Lang.EN, 'Paypal payment to ']
            ])
        ],
        ['BankTransferTo', new Map(
            [
                [Lang.ES, 'Pago bancario a'],
                [Lang.EN, 'Bank payment to ']
            ])
        ]
    ]
));

class PlatformWallet extends React.Component {
    constructor(props) {
        super(props);

        this.state = { WalletAmount: 0, userSelected:null };

        this.conf = [
            new confListField(strs.get('Date'), CommItemField, {}, "date"),
            new confListField(strs.get('Quantity'), CommItemField, {}, (item) => toMStr(item.quantity)),
            TransferDetailsItemField
        ];
    }    

    render = () => {        
        return (
            <ExternalTransferPayment key={this.state.userSelected} CompListTransactionSelection={(paymentComp) => { return (<UserWallet platformWallet title={"Platform wallet"} paymentComp={paymentComp} OnSelectUser={(user) => { this.setState({ userSelected: user }); }}></UserWallet>); }} apiUrl={"#server#/Transfers/PlatformPayment/" + UserUtils.GetUserData().id} itemAmount={item => item.quantity} />
        )
    }

    async OnGet(r: Response) {
        const total = r.body?.total ? r.body.total: 0
        await this.setState({ WalletAmount: total });

        r.body = r.body?.Transfer;
    }
}

export default connect(mapGenericStateToProps, null)(withRouter(PlatformWallet));