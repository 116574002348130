import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

import GlobalState from '../../store/globalState'
import { isBrowser, isMobile, MobileView } from '../../utils/platformsUtils';

import { Grid, Button, TextField, TextareaAutosize, FormControlLabel, FormControl } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import MuiAlert from '@material-ui/lab/Alert';
import Streamers from '../../services/api/streamers';
import UserUtils from '../../utils/userUtils'
import { commService } from '../../services/api/list';
import { bannerStreamerListService } from '../../services/api/bannerStreamer';
import { CommInputField, CommForm, CommFormGen, confForm } from '../common/com-Form/comm-form.components'
import { confField, CommTextField, fieldNameFunction, checkMailField } from '../common/com'
import { CommItemOfItems, confAddItem, CommListGen, CommLinkButton, CommDeleteButtonLink, confListField, confList, CommList, CommItemField, CommItemTextField } from '../common/com-List/comm-list.components'

import { BannersPreview, Rectangle, getCanvasSize } from '../banners-preview/banners-preview.component'

import { mapGenericStateToProps } from '../../utils/routerUtils'
import { bannerStrs } from '../../templates/languages/banner-strs.js'

import { LangStrs, Lang } from '../../utils/languageUtils'

let strs: LangStrs = new LangStrs(new Map(
    [
        ['name', new Map(
            [
                [Lang.ES, 'Nombre'],
                [Lang.EN, 'Name']
            ])
        ],
        ['email', new Map(
            [
                [Lang.ES, 'Email'],
                [Lang.EN, 'Email']
            ])
        ],
        ['description', new Map(
            [
                [Lang.ES, 'Descripci�n'],
                [Lang.EN, 'Description']
            ])
        ],
        ['newGuest', new Map(
            [
                [Lang.ES, 'Nuevo invitado'],
                [Lang.EN, 'New guest']
            ])
        ],
    ]
));

class GuestForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }

        this.conf = [
            new confField(strs.get('name'), CommTextField, { className: "list-field bi-name" }, "name"),
            new confField(strs.get('email'), CommTextField, { className: "list-field bi-name" }, new fieldNameFunction("email", checkMailField)),
            new confField(strs.get('description'), CommTextField, { className: "list-field bi-name" }, "descrption"),
        ];
    }

    render() {
        return (
            <Grid direction="column">

                <Grid className="form-column">
                    <h1>{strs.get('newGuest')}</h1>
                </Grid>
                <Grid child container className={'banner-form ' + this.props.className} direction="row" justify="flex-start" alignItems="flex-start">
                    <Grid child container direction="row" xs="12">
                        <div style={{ paddingLeft: "10px" }}>
                            <CommForm confComm={new confForm(CommFormGen, new commService("/guests"), this.conf)} Owner={this} />
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default connect(mapGenericStateToProps, null)(withRouter(GuestForm));