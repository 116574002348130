import React from 'react';
import {FormControlLabel, Link, Grid, Button, Typography, TextField, Checkbox} from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom';

import Loading from "../../structure/common/loading/loading.component"

import './loginform.styles.scss';

import { GApiURL, apiKey, discoveryDocs, clientId, scopes, YoutubeAuthAccount } from '../../structure/streamingAccount/streamingAccount-authYoutube'
import { TwitchAuthAccount } from '../../structure/streamingAccount/streamingAccount-authTwitch'

import { appendScript, removeScript } from '../../utils/jsscripts'

import ServicesBase from '../../services/base';

import { call, put, takeLatest } from 'redux-saga/effects'
import ActionTypes from '../../store/actions/actiontypes'

import { ModalPolicy } from '../../components/common/modal/modalpolicy'
import ModalDialog from '../../components/common/modal/modal'

import { LangStrs, Lang, getUserLang } from '../../utils/languageUtils'

import { strs as strPrivacyPolicy } from '../../pages/privacypolicy/privacypolicypage.component'
import { strs as strCookiesPolicy } from '../../pages/cookiespolicy/cookies.component'

import { getFragmentParameter } from '../../utils/routerUtils'

import { requestChangePassword as rChangePassword } from '../../utils/userUtils'
import { HashAndEncrypt } from '../../utils/UtilsEnc.js'
//import X from '../../components/x/x.component';
/*
const mapStateToProps = state => {
    return { loading: state.isLoading, i18n: state.i18nStrings, loginError: state.loginError, userSession: state.userSession};
};

function mapDispatchToProps(dispatch) {
    return {
      ToggleLoading: isLoading => dispatch(ToggleLoading(isLoading)),
      DoLogin: userData => dispatch({type: ActionTypes.WWW_USER_LOGIN, payload: userData})
    };
}
*/

export async function requestChangePassword(userId) {
    if (!userId) {
        this.setState({ loginError: "NOUSER_TO_CHANGE_PASSW" });
    } else {
        await rChangePassword(userId);

        this.setState({ loginError: "CHANGE_PASSW_OK" });
    }
}

class LoginForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            userId: "",
            password: "", 
            remember: false,
            loginError: ""
        }
        this.i18n = this.props.i18n;
    }

    handleUserIdChange = (e) => {
        if(!e.target.value || e.target.value.length < 3) {
            this.setState({userId: null});
            return;
        };
        this.setState({userId: e.target.value});
    }
    
    handlePasswordChange = (e) => {
        if(!e.target.value || e.target.value.length < 3) {
            this.setState({password: null});
            return;
        };
        this.setState({password: e.target.value});
    }

    DoLogin = () => {
        this.setState({ loginError: "" });
        this.props.loadingEvent(true);
        this.props.submitEvent({ userId: this.state.userId, password: HashAndEncrypt(this.state.password)})
    }

    youtubeAccount: YoutubeAuthAccount = new YoutubeAuthAccount((youtubeTokens) => { this.LoginOrRegisterFromStreamingAccount(youtubeTokens, 'Youtube'); })
    twitchAccount: TwitchAuthAccount = new TwitchAuthAccount((access_token) => { this.LoginOrRegisterFromStreamingAccount(access_token, 'Twitch'); })

    async componentDidMount() {
        await this.twitchAccount.componentDidMount();
        await this.youtubeAccount.componentDidMount();
    }

    componentDidUnmount() {
        this.twitchAccount.componentDidUnmount();
        this.youtubeAccount.componentDidUnmount();
    }

    async LoginFromStreamingAccount(platformToken, platform): Promise<Response> {
        let tSingIn: ServicesBase = new ServicesBase("#server#" + "/auth/platform/" + platform);
        let rtSingIn = await tSingIn.ServiceRequest("", 'POST', { 'Content-Type': 'application/json' }, true, platformToken);

        if (rtSingIn.httpCode == 200) {
            this.props.dispacher({ type: ActionTypes.WWW_USER_LOGIN_RESPONSE, payload: { status: 1, response: rtSingIn.body.jwt } });
        } else {
            if (rtSingIn.error?.error == "TOAPPROVE") {
                this.setState({ loginError: rtSingIn.error.error });
                return false;
            }
        }

        return rtSingIn;
    }

    async CreateFromAccount(handler: handleAuthRedirect) {
        
    }

    async LoginOrRegisterFromStreamingAccount(platformToken, platform) {
        let rtSingIn = await this.LoginFromStreamingAccount(platformToken, platform);

        if (rtSingIn!== false && rtSingIn.httpCode != 200) {
            let r = await this.modalAccount.showAndGetResult();
            if (r) {
                let policyResult = await this.modal.showAndGetResult();
                if (policyResult) {
                    let tSingUp: ServicesBase = new ServicesBase("#server#" + "/users/platform/" + platform);
                    let rtSingUp = await tSingUp.ServiceRequest("", 'POST', { 'Content-Type': 'application/json' }, true, { auth: platformToken, lang: getUserLang() });

                    if (rtSingUp.httpCode == 201) {
                        await this.LoginFromStreamingAccount(platformToken, platform);
                    }
                }
            }
        }
    }
    //<img id="framyr-logo" src="./img/framyr-logo.png" alt="framyr logo" title="logo" width="80%" />
    render = () => {
        let strs: LangStrs = new LangStrs(new Map(
            [
                ['ERROR_UNVERIFY', new Map(
                    [
                        [Lang.ES, 'Porfavor verifique su correo'],
                        [Lang.EN, 'Please verify your email address']
                    ])
                ],
                ['ERROR_TOAPPROVE', new Map(
                    [
                        [Lang.ES, 'Actualmente no aceptamos nuevos miembros, recibira un correo cuando se aprobe su solicitud'],
                        [Lang.EN, 'We are currently not accepting new members, you will receive an email when your application is approved']
                    ])
                ],
                ['ERROR_BANNED', new Map(
                    [
                        [Lang.ES, 'Cuenta baneada'],
                        [Lang.EN, 'Banned account']
                    ])
                ],
                ['ERROR_BADCREDENTIALS', new Map(
                    [
                        [Lang.ES, 'Credenciales incorrectas'],
                        [Lang.EN, 'Wrong credentials']
                    ])
                ],
                ['ERROR_NOUSER_TO_CHANGE_PASSW', new Map(
                    [
                        [Lang.ES, 'Introduce un Email'],
                        [Lang.EN, 'Enter an Email']
                    ])
                ],
                ['ERROR_CHANGE_PASSW_OK', new Map(
                    [
                        [Lang.ES, 'Recibirás un correo electrónico para cambiar tu contraseña'],
                        [Lang.EN, 'You will receive an email to change your password']
                    ])
                ],
                ['ASK_CREATE_ACCOUNT_FROM_PLATFORM', new Map(
                    [
                        [Lang.ES, 'Si ya tiene una cuenta PowerAds, haga clic en cancelar, inicie sesión con su cuenta y vincule la cuenta de streamer desde el menú correspondiente. <br>En caso contrario, haga clic en aceptar y se creará una cuenta de PowerAds a partir de esta cuenta de streamer.'],
                        [Lang.EN, 'If you already have a PowerAds account please click cancel, login with your account and link the streamer account from the corresponding menu. <br> Otherwise, click accept and a PowerAds account will be created from this streamer account.']
                    ])
                ],
                ['ERROR_NOUSERORPASS', new Map(
                    [
                        [Lang.ES, 'El usuario y la contraseña son necesarios'],
                        [Lang.EN, 'User and password are both required']
                    ])
                ],
                ['FORGOTPASS', new Map(
                    [
                        [Lang.ES, 'Olvidó su contraseña?'],
                        [Lang.EN, 'Forgot your password?']
                    ])
                ],
                ['LOGIN', new Map(
                    [
                        [Lang.ES, 'ENTRAR'],
                        [Lang.EN, 'LOGIN']
                    ])
                ],
                ['USEPLATFORM', new Map(
                    [
                        [Lang.ES, 'Accede con'],
                        [Lang.EN, 'Sign in with']
                    ])
                ],
                ['REMEMBER', new Map(
                    [
                        [Lang.ES, 'Recuérdame en este dispositivo'],
                        [Lang.EN, 'Remember me on this device']
                    ])
                ],
            ]
        ));

        let loginError = this.props.loginError;
        if (this.state.loginError != "") loginError = this.state.loginError;

        return (
            <Grid className='login-form' style={{ height: loginError?"500px":"420px"}}>
                <Loading className="loading"></Loading>
                <ModalPolicy ref={(m) => { this.modal = m; }} />
                <ModalDialog ref={(m) => { this.modalAccount = m; }}>
                    <div dangerouslySetInnerHTML={{ __html: strs.get('ASK_CREATE_ACCOUNT_FROM_PLATFORM') }} style={{ "padding": "20px" }} />
                </ModalDialog>
                <Grid container direction="row" justify="space-between" alignContent="space-between">
                    <Grid item xs={12} style={{ "margin-bottom": "8px" }}>
                        <img src="/LogoPowerAdsPubOld3.svg" width="100%" alt="PowerAds logo"/>
                    </Grid>
                    {loginError && !this.props.loading ? <Grid xs="12"><p className="login-error">{strs.get('ERROR_' + loginError)}</p></Grid> : null}
                    <Grid item xs={12}>
                        <TextField
                            className="login-input"
                            fullWidth
                            value={this.state.userId}
                            id="username-input"
                            label="Email"
                            variant="outlined"
                            onChange={this.handleUserIdChange}
                            onKeyPress={(ev) => {
                                //console.log(`Pressed keyCode ${ev.key}`);
                                if (ev.key === 'Enter') {
                                    this.DoLogin();
                                    ev.preventDefault();
                                }
                              }}
                        />
                        <TextField
                            className="login-input"
                            fullWidth
                            value={this.state.password}
                            id="password-input"
                            label="Password"
                            type="Password"
                            variant="outlined"
                            onChange={this.handlePasswordChange}
                            onKeyPress={(ev) => {
                                //console.log(`Pressed keyCode ${ev.key}`);
                                if (ev.key === 'Enter') {
                                    this.DoLogin();
                                    ev.preventDefault();
                                }
                              }}
                        />
                    <Grid container
                        direction="row"
                        alignItems="center"
                        justify="flex-end">
                        <FormControlLabel
                            control={<Checkbox color='primary' name="Remember" value={this.state.remember} onChange={(checked) => {this.setState({remember: checked})}}></Checkbox>}
                                label={strs.get('REMEMBER')}
                        />
                    </Grid>                            
                </Grid> 
            <Grid container alignItems="flex-end">
                <Grid item xs={8}>
                <Typography className="login-links">
                                {!this.props.loading && <Link margin="normal" component={RouterLink} onClick={() => requestChangePassword.bind(this)(document.getElementById('username-input').value)}>
                    {strs.get('FORGOTPASS')}
                </Link>}<br/>
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Button variant="contained" disabled={this.props.loading} size="large" color="primary" fullWidth onClick={this.DoLogin}>
                    {strs.get('LOGIN')}
                </Button>
            </Grid>
            </Grid>
            <Grid item sm={12} xs={12} style={{ "padding": "5px" }}>
                {/*Aqui posava or*/}
            </Grid>
            <Grid item xs={12}>
                <Link onClick={() => this.twitchAccount.OpenSignup() }>
                    <div id="gSignInWrapper">
                        <div id="customBtn" class="customGPlusSignIn" style={{ "background": "#6441a5", "color": "white" }} >
                            <span class="icon2">
                                <i class="fa fa-1x fa-twitch" style={{ "font-size": "23px", padding: "10px" }}></i>
                            </span>
                                    <span class="buttonText" style={{ padding: "0px" }}>{strs.get('USEPLATFORM')} Twitch</span>
                        </div>
                    </div>
                </Link>
            </Grid>
            <Grid item xs={6}>
                <p/>
            </Grid>
            <Grid item xs={12}>
                <Link onClick={() => this.youtubeAccount.OpenSignup()}>
                    <div id="gSignInWrapper">
                        <div id="customBtn" class="customGPlusSignIn">
                            <span class="icon"></span>
                                    <span class="buttonText" style={{ padding: "0px" }}>{strs.get('USEPLATFORM')} Google</span>
                        </div>
                    </div>
                </Link>
            </Grid>
        </Grid>
    </Grid>
    )
}
}

export default (LoginForm);