
import React from 'react';

import { Grid, Avatar, Button, Box, TextField, Checkbox, Select, InputLabel, FormControl, FormControlLabel, Typography} from '@material-ui/core';
import {Save} from '@styled-icons/material'

import GlobalState from '../../store/globalState'
import UserUtils from '../../utils/userUtils'
import userUtils, { UserTypes } from '../../utils/userUtils'

import UserAPI from '../../services/api/users'
import Options from '../options/options.component'

import { OptionsToCheckRequired } from '../options/options.component'
import { RenderOptions } from '../ads-form/ads-form.component.jsx'

import { commService } from '../../services/api/list';
import { getAuthHeader } from '../../services/base';
import { CommInputField, CommForm, CommFormGen, confForm, ChangePasswordComponent, EndPointSelectorComponent, EndPointSelectorConf, ToggleButtonComm } from '../common/com-Form/comm-form.components'
import { CommButton, confCOM, confField, CommTextField, fieldNameFunction, fieldNameInOut, temporalItem, confGroup, strfieldvalue, fnfRequireText, EnumComboBox, strs as ComStrs, CommSimpleTextButton } from '../common/com'
import { CommItemOfItems, confAddItem, CommListGen, CommLinkButton, CommDeleteButtonLink, confListField, confList, CommList, CommItemField, CommItemTextField } from '../common/com-List/comm-list.components'
import { fileUrl } from '../../utils/fileUtils';

import { LangStrs, Lang, getUserLang } from '../../utils/languageUtils'

import ModalDialog, { ModalButtons } from '../../components/common/modal/modal'

import SelectUser, { UsersFilterTypes } from '../../components/admin/select-user/select-user.component.jsx'

import { StreamingAccountListAdmin } from '../streamingAccount/streamingAccount-toStream.components.jsx'

import ServicesBase from "../../services/base"

import { AgencyStatus } from "../../components/StreamersGroup/AgencyStreamers-list.component.jsx"

import CommHelpLink, { getDocUrl } from '../../structure/common/comHelpLink/comHelpLink.jsx'

const strs: LangStrs = new LangStrs(new Map(
    [
        ['TitleMailNotificationsConfig', new Map(
            [
                [Lang.ES, `Configuración de notificaciones por correo`],
                [Lang.EN, `Email Notifications Configuration`]
            ])
        ],
        ['DescMailNotificationsConfig', new Map(
            [
                [Lang.ES, `Configura aquí qué notificaciones quieres que te lleguen a tu correo electrónico`],
                [Lang.EN, `Configure here which notifications you want to receive in your email`]
            ])
        ],
        ['Send', new Map(
            [
                [Lang.ES, `La notificación se enviará`],
                [Lang.EN, `The notification will be sent`]
            ])
        ],
        ['NoSend', new Map(
            [
                [Lang.ES, `La notificación no se enviará`],
                [Lang.EN, `The notification will not be sent`]
            ])
        ],
        ['Account', new Map(
            [
                [Lang.ES, `Actualización de tu cuenta personal`],
                [Lang.EN, `Update of your personal account`]
            ])
        ],
        ['AdState', new Map(
            [
                [Lang.ES, `Cambio del estado del anuncio`],
                [Lang.EN, `Change of ad state`]
            ])
        ],
        ['AdStreamerUpdate', new Map(
            [
                [Lang.ES, `Actualización del influencer`],
                [Lang.EN, `Streamer update`]
            ])
        ],
        ['ContentUpdate', new Map(
            [
                [Lang.ES, `Actualización de contenido a la campaña`],
                [Lang.EN, `Content update to the campaign`]
            ])
        ],
        ['AdAdvertiserUpdate', new Map(
            [
                [Lang.ES, `Actualización del anunciante`],
                [Lang.EN, `Advertiser update`]
            ])
        ],
        ['AdMessage', new Map(
            [
                [Lang.ES, `Nuevo mensaje en anuncio`],
                [Lang.EN, `New message in ad`]
            ])
        ],
        ['NewProposal', new Map(
            [
                [Lang.ES, `Nueva propuesta de colaboración`],
                [Lang.EN, `New collaboration proposal`]
            ])
        ],
        ['NoMails', new Map(
            [
                [Lang.ES, `No recibir ningún correo`],
                [Lang.EN, `Do not receive any emails`]
            ])
        ],
        ['RecivedMails', new Map(
            [
                [Lang.ES, `Se recibirán los correos según la configuración`],
                [Lang.EN, `Emails will be received according to the configuration`]
            ])
        ],
    ]
));

const isAdmin = () => UserUtils.GetUserData(true).usertype == UserTypes.Admin;
const isStreamer = () => UserUtils.GetUserData().usertype == UserTypes.Streamer;
const isAdvertiser = () => UserUtils.GetUserData().usertype == UserTypes.Advertiser;

class MailNotificacionConfig extends React.Component {
    constructor(props) {
        super(props);        

        const newConf = (text, configName) => {
            this.conf = [
                new confListField('', ToggleButtonComm, { text: text, value1: '0', strValue1: configName != "NoMails" ? strs.get("Send") : strs.get("RecivedMails"), colorValue1: "#009CDD", value2: '1', strValue2: configName != "NoMails" ? strs.get("NoSend") : strs.get("NoMails"), colorValue2: "rgb(179, 0, 0)" }, "value"),
            ];
            let form;
            return <CommForm ref={(t) => form = t} confComm={new confForm(CommFormGen, new commService(`/users/${UserUtils.GetUserData().id}/config/` + configName), this.conf, "")} Owner={this} noSubmitButton={true} OnDataChange={(comp) => { form.SaveData() }} noredirect={true}/>
        }

        let confNamesAll = [   
            "AdMessage",    
            "AdState",     
            "Account",
            "NoMails",
        ];

        let confNamesStreamer = [
            "NewProposal",
        ];

        let confNamesAdv = [
            "AdStreamerUpdate",
        ];

        let confNames = (isStreamer() ? confNamesStreamer : isAdvertiser() ? confNamesAdv : []).concat(confNamesAll);

        this.COM = confNames.map(cn => newConf(strs.get(cn), cn))
    }

    render() {        
        return (
            <Grid container direction="column">
                <h1>{strs.get('TitleMailNotificationsConfig')}<CommHelpLink infoUrl={getDocUrl("como-gestionar-las-notificaciones")}/></h1>
                <p>{strs.get('DescMailNotificationsConfig')}</p>
                {this.COM.map(c => c)}
            </Grid>
        );
    }
}

export default MailNotificacionConfig;