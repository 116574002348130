import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

import GlobalState from '../../store/globalState'
import { isBrowser, isMobile, MobileView } from '../../utils/platformsUtils';

import { MuiThemeProvider, Grid, Button, TextField, TextareaAutosize, FormControlLabel, FormControl } from '@material-ui/core';
import ErrorWarnTheme from '../../styles/ErrorWarnTheme';
import { Redirect } from 'react-router-dom';
import MuiAlert from '@material-ui/lab/Alert';
import Streamers from '../../services/api/streamers';
import UserUtils from '../../utils/userUtils'
import { CommInputField, CommForm, CommFormGen, confForm } from '../common/com-Form/comm-form.components'
import { CommButton, confCOM, confField, CommTextField, fieldNameFunction } from '../common/com'
import { commService } from '../../services/api/list';
import { BannersPreview, getCanvasSize } from '../banners-preview/banners-preview.component';
import { CommItemOfItems, confAddItem, CommListGen, CommLinkButton, CommDeleteButtonLink, confListField, confList, CommList, CommItemField, CommItemTextField } from '../common/com-List/comm-list.components'
import { StreamingAccountToStream, SelStreamingAccountActionField } from '../streamingAccount/streamingAccount-toStream.components'
import { mapGenericStateToProps } from '../../utils/routerUtils'

import { LangStrs, Lang } from '../../utils/languageUtils'
import { bannerStrs } from '../../templates/languages/banner-strs.js'

let strs: LangStrs = new LangStrs(new Map(
    [
        ['SceneName', new Map(
            [
                [Lang.ES, 'Nombre de la escena'],
                [Lang.EN, 'Scene name']
            ])
        ],
        ['SceneWidth', new Map(
            [
                [Lang.ES, 'Ancho de escena'],
                [Lang.EN, 'Scene Width']
            ])
        ],
        ['SceneHeight', new Map(
            [
                [Lang.ES, 'Alto de escena'],
                [Lang.EN, 'Scene Height']
            ])
        ],
        ['Name', new Map(
            [
                [Lang.ES, 'Nombre'],
                [Lang.EN, 'Name']
            ])
        ],        
        ['PositionX', new Map(
            [
                [Lang.ES, 'Posición X'],
                [Lang.EN, 'Position X']
            ])
        ],
        ['PositionY', new Map(
            [
                [Lang.ES, 'Posición Y'],
                [Lang.EN, 'Position Y']
            ])
        ],
        ['Delete', new Map(
            [
                [Lang.ES, 'Borrar'],
                [Lang.EN, 'Delete']
            ])
        ],
        ['AddScene', new Map(
            [
                [Lang.ES, 'Agregar a la escena'],
                [Lang.EN, 'Add to Scene']
            ])
        ],
        ['SConfig', new Map(
            [
                [Lang.ES, 'Ajustes de escena'],
                [Lang.EN, 'Scene config']
            ])
        ],
        ['TStreamingAccounts', new Map(
            [
                [Lang.ES, 'Cuentas de streaming'],
                [Lang.EN, 'Streaming Accounts']
            ])
        ],
        ['BScene', new Map(
            [
                [Lang.ES, 'Banners en la escena'],
                [Lang.EN, 'Banners in scene']
            ])
        ],
        ['AddToScene', new Map(
            [
                [Lang.ES, 'Agregar banner a la escena'],
                [Lang.EN, 'Add banner to scene']
            ])
        ],
        ['ADD', new Map(
            [
                [Lang.ES, 'AGREGAR'],
                [Lang.EN, 'ADD']
            ])
        ]        
    ]
));

const fieldNameFunctionRes = (name) => new fieldNameFunction(name, (value) => Number.isInteger(+value) && +value>0);

class bannerStreamerListService extends commService {
    constructor(id) {
        super("#server#" + "/streamers/:id/");
        this.id = id;
    }
    Get = async (userId, bannerId = false, loading = true) => {
        let r = await (this.ServiceRequest("streamScene/"+this.id, 'GET', {}, loading, null, userId));
        if (!r.error) r.body = r.body[0].banners;
        return r;
    };
    Del = async (userId, item, loading = true) => {
        return await (this.ServiceRequest("streamScene/streamSceneBannerStreamer/" + item.streamSceneBannerStreamerId, 'DELETE', {}, loading, null, userId));
    };
    Update = async (userId, item, loading = true) => {
        let sr =  await (this.ServiceRequest("streamScene/streamSceneBannerStreamer/" + item.streamSceneBannerStreamerId, 'PUT', {
            'Content-Type': 'application/json',
        }, loading, item.newData, userId));

        if (sr.httpCode == 500) {
            sr.body = [{ posX: item.posX, posY: item.posY }];
            return sr;
        }
        let r = sr.body[0];
        sr.body = [{ posX: r.posX, posY: r.posY }];
        return sr;
    };
    Add = async (userId, data, loading = true) => {
        return await this.ServiceRequest("streamScene/" + this.id, 'POST', {
            'Content-Type': 'application/json',
        }, loading, {
            bannerStreamer: data.id,
            posX: data.posX,
            posY: data.posY,
            iTime: data.iTime,
            eTime: data.eTime,
        }, userId);
    };
};

const TextPosFieldName = (name) => new fieldNameFunction(name, (n) => Number.isInteger(+n) && +n >= 0);

export class StreamSceneForm extends React.Component {
    bannerList: CommList;
    bannersPreview: BannersPreview = undefined;
    bannerStreamerListService: bannerStreamerListService;

    constructor(props) {
        super(props);

        this.state = {
            prevWidth: 1920, //Xk no em quedo amb tot el item scene?
            prevHeight: 1080,
            sceneKey: "",
        }

        this.bannerStreamerListService = new bannerStreamerListService(this.props.match.params.id);

        this.conf = [
            new confField(strs.get('SceneName'), CommTextField, { className: "list-field bi-name" }, "name"),
            new confField(strs.get('SceneWidth'), CommTextField, { className: "list-field bi-name" }, fieldNameFunctionRes("resX")).addField("tfullWidth", false),
            new confField(strs.get('SceneHeight'), CommTextField, { className: "list-field bi-name" }, fieldNameFunctionRes("resY")).addField("tfullWidth", false),
        ];

        this.confBanner = [
            new confListField(bannerStrs.get('Name'), CommItemField, { className: "list-field bi-name" }, "name"),
            new confListField(bannerStrs.get('MAmount'), CommItemField, { className: "list-field bi-amount not-mobile" }, "minimalBannerAmount"),
            new confListField(bannerStrs.get('ETime'), CommItemField, { className: "list-field bi-amount not-mobile" }, "elapseTime"),
            new confListField(bannerStrs.get('Size'), CommItemField, { className: "list-field bi-size" }, (item) => item.sizeX + "x" + item.sizeY),
            new confListField(strs.get('PositionX'), CommItemTextField, { className: "list-field bi-name" }, TextPosFieldName("posX")),
            new confListField(strs.get('PositionY'), CommItemTextField, { className: "list-field bi-name" }, TextPosFieldName("posY")),
            new confListField("", CommItemOfItems).addField("Comp", [
                new confListField(strs.get('Delete'), CommDeleteButtonLink)
            ])
        ];

        this.confAdd = [
            new confListField(bannerStrs.get('Name'), CommItemField, { className: "list-field bi-name" }, "name"),
            new confListField(bannerStrs.get('MAmount'), CommItemField, { className: "list-field bi-amount not-mobile" }, "minimalBannerAmount"),
            new confListField(bannerStrs.get('ETime'), CommItemField, { className: "list-field bi-amount not-mobile" }, "elapseTime"),
            new confListField(bannerStrs.get('Size'), CommItemField, { className: "list-field bi-size" }, (item) => item.sizeX + "x" + item.sizeY),
            new confListField("", CommItemOfItems).addField("Comp", [
                new confListField("AddButton", AddButton).addField("streamSceneForm", this).addField("caption", strs.get('AddScene'))
            ])
        ];
    }

    componentDidMount() {
    }

    render = () => {
        let canvasResW = this.state.prevWidth;
        let canvasResH = this.state.prevHeight;
        let canvasSize = getCanvasSize(canvasResW, canvasResH, 0.0002);

        return (
            <parent>
                <Grid child container direction="row" justify="flex-start" alignItems="flex-start">
                    <Grid child container direction="row">
                        <Grid className="form-column" child container xs={isBrowser ? "5" : "12"}>
                            <h1>{strs.get('SConfig')}</h1>
                            <CommForm title="" confComm={new confForm(CommFormGen, new commService("/streamers/:id/streamScene"), this.conf, this.props.match.params.id)} OnChange={async (comp) => await this.OnSceneChange(comp)} />
                        </Grid>
                        <Grid className="form-column" child container xs={isBrowser ? "7" : "12"}>
                            <h1>{strs.get('TStreamingAccounts')}</h1>
                            <StreamingAccountToStream title="" scene={this.props.match.params.id} scenekey={this.state.sceneKey} createVideoActionField={SelStreamingAccountActionField(this.props.match.params.id)}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid child container direction="row" justify="flex-start" alignItems="flex-start">
                    <h1>{strs.get('BScene')}</h1>
                    <Grid child container direction="row">
                        <Grid xs={isBrowser ? "6" : "12"} style={{ paddingBottom: "20px" }}>
                            <BannersPreview ref={this.setrefBannersPreview.bind(this)} width={canvasResW} height={canvasResH} canvasWidth={canvasSize.width} canvasHeight={canvasSize.height} OnChange={this.OnBannerPreviewChange.bind(this)}/>
                        </Grid>
                        {/*<Grid className="form-column" child container xs={isBrowser ? "6" : "12"}>*/}
                        {/*    <BannersPreview ref={this.setrefBannersPreview.bind(this)} OnChange={this.OnBannerPreviewChange.bind(this)} className="form-column" width={this.state.prevWidth} height={this.state.prevHeight} />*/}
                        {/*</Grid>*/}
                        <Grid className="form-column" child container xs={isBrowser ? "6" : "12"}>
                            <CommList confComm={new confList(CommListGen, this.bannerStreamerListService, this.confBanner)}
                                className="form-column" OnChange={(comp) => this.OnBannersChange(comp)} ref={(c) => { this.bannerList = c; }} OnFieldChangeGeneralValidation={(items) => { return this.bannersPreview.ValidateRects(items); }} SaveAll
                             />
                        </Grid>
                    </Grid>
                    <h1>{strs.get('AddToScene')}</h1>
                    <Grid child container direction="row">
                        {
                            //new confAddItem("/dashboard/banners/add/" + this.props.match.params.id).setFloat()
                        }
                        <CommList confComm={new confList(CommListGen, new commService("/streamers/:id/banner"), this.confAdd, new confAddItem("/dashboard/banners/add/", bannerStrs.get('NewBanner')))} className="form-column" />
                    </Grid>
                </Grid>
            </parent>
        );
    }

    pendingBanners = null;
    pendingComp = null;
    setrefBannersPreview(c) {
        this.bannersPreview = c;
        if (this.pendingBanners != null) this.OnBannersChange(this.pendingComp, this.pendingBanners);
        this.pendingBanners = null;
    }

    OnBannerPreviewChange(banner) {
        this.bannerList.externalDataChange();
    }

    OnBannersChange(comp) {        
        let banners = comp.state.items;
        if (this.bannersPreview == undefined) {
            this.pendingComp = comp;
            this.pendingBanners = banners;
            return;
        }
        this.bannersPreview.setBanners(comp, banners);
    }

    async OnSceneChange(comp) {            
        if (comp.state.item != null && (this.state.prevWidth != comp.state.item.resX || this.state.prevHeight != comp.state.item.resY || this.state.sceneKey != comp.state.item.key)) {
            await this.setState({ prevWidth: comp.state.item.resX, prevHeight: comp.state.item.resY, sceneKey: comp.state.item.key });
        }
    }

    async OnAddBannerToScene(item) {
        const pos = this.bannersPreview.FindBannerPlace(item.sizeX, item.sizeY);
        if (pos == null) { // TODO: ERROR NO SE ECUENTRA NUEVO ESPACIO PARA EL BANNER
        } else {
            item.iTime = -1;
            item.eTime = -1;
            item.posX = pos.posX;
            item.posY = pos.posY;
            await this.bannerStreamerListService.Add(UserUtils.GetUserData().id, item);
            await this.bannerList.QueryItems();
        }
    }
}

class AddButton extends CommButton {
    confField: confField;

    constructor(props) {
        super(props);
        this.confField = this.props.confFieldItem.confField;
        this.tInnerLink =
            <MuiThemeProvider>
                <Button variant="contained" size="large" color="primary" onClick={async () => { await this.addBannerToScene(this.props.item) }}>
                {this.confField.caption != undefined ? this.confField.caption : strs.get('ADD')}
                </Button>
            </MuiThemeProvider>        
    }

    async addBannerToScene(item) {
        const ssf: StreamSceneForm = this.confField.streamSceneForm;
        await ssf.OnAddBannerToScene(item);
    }
}

export default connect(mapGenericStateToProps, null)(withRouter(StreamSceneForm));