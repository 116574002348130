import React from 'react';

export class ReactComponentTag {
    constructor(tagname, value) {
        this.name = tagname;
        this.value = value;
    }
}

export const myReactTaggedComponent = (ReactComp, Tags: ReactComponentTag) => {
    return class CommTagged extends ReactComp {
        constructor(props) {
            super(props);

            Tags != null && Tags.map(t => { this[t.name] = t.value; });
        }
    }
}

export const myReactTaggedStateComponent = (ReactComp, updateFunc) => { //El componente debe hacer this.update() en el render
    return class CommTagged extends ReactComp {
        update = () => { updateFunc.call(this); };
    }
}

export class InLineComponent extends React.Component {
    render = () => {
        const COMP = this.props.newComp;
        return (
            <COMP {...this.props} />
        )
    }
}

export const CompWithParent = (parent, child, parentProps) => {
    return class extends React.Component {
        render = () => {
            const P = parent;
            const C = child;
            return (
                <P {...parentProps} >
                    <C {...this.props} />
                </P>
            )
        }
    }
}

//export const ConditionalComponent = ({ condition, wrapper, children }) => condition ? wrapper(children) : children;

export class ConditionalComponent extends React.Component {
    render = () => {
        if (this.props.Condition) {
            const TagC = this.props.TagComponent;
            return (
                <TagC {...this.props} />
            )
        } else {
            if (this.props.bodyConditional === true) return null;            
            else return <>{this.props.children}</>
        }
    }
}