import { CommList, confList } from '../comm-list.components'
import ServicesBase from '../../../../services/base'
import GlobalState from '../../../../store/globalState';

export class commNoService extends ServicesBase {
    constructor(items) {
        super("");
        this.items = items;
    }
    Get = async (userId = "", itemId = false, loading = true) => {
        return this.items;
    };
    Del = async (userId, item, loading = true) => {};
    Update = async (userId, item, loading = true) => {};
    Add = async (userId, data, loading = true) => {};
};

export class confListStatic extends confList {    
    constructor(listComponentBase, items, fields: confListField[], addItem: confAddItem = null) {
        super(listComponentBase, new commNoService(items), fields);        
        this.items = items;
    }
}

export class CommListStatic extends CommList {
    confListStatic: confListStatic;
    constructor(props) {
        super(props);
        this.confListStatic = this.confComm;
    }

    QueryItems = async () => {
        GlobalState.set("loading", false);

        this.setState({ error: null, items: this.confListStatic.items || [], loaded: true });

        this.OnQuery(this.confListStatic.items);
        return this.confListStatic.items;
    }
}