import React from 'react';
import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom'

import { minFeeAgency, maxFeeAgency } from '../../global.js'

import GlobalState from '../../store/globalState'
import { isBrowser, isMobile, MobileView } from '../../utils/platformsUtils';

import { ExpandableComponent, ScheduleTimeDiff, standarValueBroadcastTime } from '../../components/common/commonComponents'

import ModalDialog, { ModalAlert, ModalButtons } from '../../components/common/modal/modal'
import { Grid, Button, TextField, TextareaAutosize, FormControlLabel, FormControl, Typography, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, makeStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Tooltip from '@material-ui/core/Tooltip';
import LinearProgress from '@material-ui/core/LinearProgress';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import {Redirect} from 'react-router-dom';
import MuiAlert from '@material-ui/lab/Alert';
import Streamers from '../../services/api/streamers';
import UserUtils from '../../utils/userUtils'
import { commService } from '../../services/api/list';
import { CommInputField, CommForm, CommFormGen, confForm, RadioButtonList, CommRadioButtonList, BroadCastTimePickerComm } from '../common/com-Form/comm-form.components'
import { CommButton, confCOM, confField, CommTextField, fieldNameFunction, temporalItem, fnfRequireText, CommDateTimePicker, InputSource, CommSimpleTextButton, fnfRequireNumber, CommCheckBox } from '../common/com'
import { CommButtonSave, CommItemOfItems, confAddItem, CommListGen, CommLinkButton, CommDeleteButtonLink, confListField, confList, CommList, CommItemField, CommItemTextField, CommItemFieldTextLink, CommListFlat, CommItemTextLink } from '../common/com-List/comm-list.components'
import { changeUrlByReact, Urls } from '../../utils/routerUtils'
import { RenderOptions } from '../../structure/ads-form/ads-form.component.jsx'
import { mapGenericStateToProps } from '../../utils/routerUtils'
import { withStyles } from '@material-ui/core/styles';
import { LangStrs, Lang } from '../../utils/languageUtils'
import { toMStr } from '../../utils/moneyUtils'
import { OptionsToCheckRequired } from '../options/options.component'
import { doTransferToResource, addFoundsUrl } from '../../structure/transaction-form/transaction-form.component.jsx'
import { MoveSponsorButtons } from '../../structure/common/branded/commonBranded.jsx'
import ComTooltip from '../../structure/common/comTooltip/comTooltip.jsx';

import moment from 'moment';

export let strs: LangStrs = new LangStrs(new Map(
    [
        ['Title', new Map(
            [
                [Lang.ES, 'Participantes'],
                [Lang.EN, 'Participants']
            ])
        ],
        ['defMoneyFactor', new Map(
            [
                [Lang.ES, 'Multiplicador de Pago'],
                [Lang.EN, 'Payment Multiplier']
            ])
        ],
        ['defCollaborationTimes', new Map(
            [
                [Lang.ES, 'Número de Colaboraciones'],
                [Lang.EN, 'Number of Collaborations']
            ])
        ],
        ['defDateLimit', new Map(
            [
                [Lang.ES, 'Fecha Límite'],
                [Lang.EN, 'Deadline']
            ])
        ],
        ['AgencyPercentage', new Map(
            [
                [Lang.ES, `Porcentaje para la agencia`],
                [Lang.EN, `Percentage for the agency`]
            ])
        ],
        ['streamerName', new Map(
            [
                [Lang.ES, 'Streamer'],
                [Lang.EN, 'Streamer']
            ])
        ],
        ['SAccount', new Map(
            [
                [Lang.ES, 'Cuenta'],
                [Lang.EN, 'Account']
            ])
        ],
        ['Platform', new Map(
            [
                [Lang.ES, 'Plataforma'],
                [Lang.EN, 'Platform']
            ])
        ],
        ['Actions', new Map(
            [
                [Lang.ES, 'Acciones'],
                [Lang.EN, 'Actions']
            ])
        ],
        ['Update', new Map(
            [
                [Lang.ES, 'Actualizar'],
                [Lang.EN, 'Update']
            ])
        ],
        ['TitleDefValues', new Map(
            [
                [Lang.ES, 'Oferta por defecto'],
                [Lang.EN, 'Default Offer']
            ])
        ],
        ['CCFilters', new Map(
            [
                [Lang.ES, 'Filtros para creadores de contenido'],
                [Lang.EN, 'Content Creator Filters']
            ])
        ],
        ['defDateLimit', new Map(
            [
                [Lang.ES, 'Fecha de Expiración'],
                [Lang.EN, 'Expiration Date']
            ])
        ],
        ['remainingMsg', new Map(
            [
                [Lang.ES, 'La oferta caducará en'],
                [Lang.EN, 'The offer will expire in']
            ])
        ],
        ['passDateMsg', new Map(
            [
                [Lang.ES, 'El límite no puede ser inferior a 14 días'],
                [Lang.EN, 'The deadline cannot be less than 14 days']
            ])
        ],
        ['makeOffer', new Map(
            [
                [Lang.ES, 'Hacer oferta'],
                [Lang.EN, 'Make Offer']
            ])
        ],
        ['moneyPerCollaboration', new Map(
            [
                [Lang.ES, 'Recompensa por Colaboración'],
                [Lang.EN, 'Reward per Collaboration']
            ])
        ],
        ['typeCalc', new Map(
            [
                [Lang.ES, 'Tipo de cálculo preliminar de la oferta'],
                [Lang.EN, 'Preliminary offer calculation type']
            ])
        ],
        ['OFFER_ALREADY_ACTIVE', new Map(
            [
                [Lang.ES, 'Ya tienes una oferta activa para este canal'],
                [Lang.EN, 'You already have an active offer for this channel']
            ])
        ],
        ['InsuficientFounds1', new Map(
            [
                [Lang.ES, 'No hay suficientes fondos para realizar la oferta, la cantidad necesaria es de'],
                [Lang.EN, 'Insufficient funds to make the offer, the required amount is']
            ])
        ],
        ['InsuficientFounds2', new Map(
            [
                [Lang.ES, 'y a la campaña le faltarían'],
                [Lang.EN, 'and the campaign would be short of']
            ])
        ],
        ['fromWallet', new Map(
            [
                [Lang.ES, 'Usar fondos de tu cartera de usuario'],
                [Lang.EN, 'Use funds from the wallet']
            ])
        ],
        ['AddFunds', new Map(
            [
                [Lang.ES, 'Agregar fondos'],
                [Lang.EN, 'Add Funds']
            ])
        ],
        ['Cancel', new Map(
            [
                [Lang.ES, 'Cancelar'],
                [Lang.EN, 'Cancel']
            ])
        ],
        ['NoUpdateDefOffer', new Map(
            [
                [Lang.ES, 'Hay datos sin actualizar en la oferta por defecto'],
                [Lang.EN, 'There are data not updated in the default offer']
            ])
        ],
        ['Selected', new Map(
            [
                [Lang.ES, `Ya seleccionado`],
                [Lang.EN, `Already selected`]
            ])
        ],
        ['LoadingSelectables', new Map(
            [
                [Lang.ES, `Buscando los creadores que se ajusten al filtro`],
                [Lang.EN, `Searching for creators that match the filter`]
            ])
        ],
        ['NoSelectables', new Map(
            [
                [Lang.ES, 'No se encuentran creadores de contenido con este filtro, modifícalo para hacer otra búsqueda'],
                [Lang.EN, 'No content creators found with this filter, modify it to make another search']
            ])
        ],
        ['OverrideFee', new Map(
            [
                [Lang.ES, `Sobreeponer al porcentaje habitual`],
                [Lang.EN, `Override the usual percentage`]
            ])
        ],
        ['OverrideFeeInfo', new Map(
            [
                [Lang.ES, `Si está seleccionado, cuando realices la oferta aparecerá el porcentaje aquí configurado. <br/>Si está deseleccionado, en la oferta, aparecerá el porcentaje que tienes configurado entre tú y el influencer. <br/> <br/>En todo caso, se podrá ajustar antes de enviar la oferta.`],
                [Lang.EN, `If selected, the percentage configured here will appear when you make the offer. <br/>If not selected, the percentage configured between you and the influencer will appear in the offer. <br/> <br/>In any case, it can be adjusted before sending the offer.`]
            ])
        ],
    ]
));

const styles = {
    header: {
        backgroundColor: '#35749E',  // color primary.main predeterminado de Material-UI
        color: 'white',
        borderRadius: '4px 4px 0 0'
    },
    headerContent: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between'
    },
    text: {
        fontWeight: 'bold'
    },
    icon: {
        color: 'white'
    }
};

class BrandedContentStreamersForm extends React.Component {
    updateStreamersList = 0;

    constructor(props) {
        super(props);
        this.state = { }

        const typeCalc = [
            { label: strs.get('moneyPerCollaboration'), value: 0 },
            { label: strs.get('defMoneyFactor'), value: 1 },
        ];

        this.conf = [
            new confField(strs.get('typeCalc'), CommRadioButtonList, { options: typeCalc, orientation: "row" }, "defTypeCalc").addField("OnChange", (comp, value) => this.setState({ typeCalc: value })),
            new confField(strs.get('moneyPerCollaboration'), CommTextField, { className: "list-field bi-amount not-mobile", endAdornment: "$", style: { width: "220px", paddingRight: "50px" } }, fnfRequireNumber("defMoneyPerCollaboration", 1), 1).addField("Condition", (item) => (this.state.typeCalc != undefined ? this.state.typeCalc : item.defTypeCalc) == 0),
            new confField(strs.get('defMoneyFactor'), CommTextField, { className: "list-field bi-amount not-mobile", style: { width: "220px", paddingRight: "50px" } }, fnfRequireNumber("defMoneyFactor", 0.0001), 1).addField("Condition", (item) => (this.state.typeCalc != undefined ? this.state.typeCalc : item.defTypeCalc) == 1),
            new confField(strs.get('AgencyPercentage'), CommTextField, { className: "list-field bi-amount not-mobile", style: { width: "220px", paddingRight: "10px" } }, fnfRequireNumber("defAgencyFee", minFeeAgency, maxFeeAgency, true), 0).addField("Condition", (item) => UserUtils.GetUserData().isAgent),
            new confField(<>{strs.get('OverrideFee')}<ComTooltip text={<div dangerouslySetInnerHTML={{ __html: strs.get('OverrideFeeInfo') }} style={{ "padding": "5px" }} />} /></>, CommCheckBox, { className: "list-field bi-amount not-mobile", style: { width: "220px" } }, "defAgencyFeeOverride").addField("Condition", (item) => UserUtils.GetUserData().isAgent),
            new confField(strs.get('defCollaborationTimes'), CommTextField, { className: "list-field bi-amount not-mobile" }, fnfRequireNumber("defCollaborationTimes", 1), 1),            
            this.dateLimitConfComm(),
        ];

        this.confListStreamers = [
            new confField(strs.get('streamerName'), CommItemField, { className: "list-field bi-name" }, "streamerName"),
            new confListField(strs.get('SAccount'), CommItemFieldTextLink, {}, (item) => item.username).addField("tUrl", (item) => item.url),
            new confField(strs.get('Platform'), CommItemField, { className: "list-field bi-name" }, "platformNameOption"),
            new confListField(strs.get('Actions'), CommItemOfItems).addField("Comp", [
                new confListField("", CommSimpleTextButton, { }, strs.get('Selected')).addField("Condition", (item) => item.runningContract).addField("color", '#f44336'),
                new confListField("", CommSimpleTextButton, {}, strs.get('makeOffer')).addField("Condition", (item) => !item.runningContract).addField("OnClick", async (item) => {
                    if (!this.commDefOffer.isNewDataInvalid() && this.commDefOffer.newDataToSave()) {
                        this.mAlert.show(strs.get('NoUpdateDefOffer'));                        
                    } else {
                        await this.setState({ selItem: item }); this.refModalOffer.setOpen(true);
                    }
                })
            ])
        ];

        this.confOffer = [];
    }

    dateLimitConfComm(filedName = "defDateLimit", defVal = 0) {
        return new confField(strs.get('defDateLimit'), BroadCastTimePickerComm, { className: "list-field bi-amount not-mobile", remainingMsg: strs.get('remainingMsg'), passDateMsg: strs.get('passDateMsg'), errMsg: strs.get('passDateMsg'), style: { padding: "0px 0px 30px 0px" } }, new fieldNameFunction(filedName, (n, cf) => {
            return moment(n).isAfter(moment().add(14, 'days'));
        }), defVal != 0 ? defVal : moment().add(15, 'days'));
    }

    offerdeadLine() {        
        const item = this.state.item;
        let value = moment(standarValueBroadcastTime(item.defDateLimit));
        
        if (value.isBefore(moment().add(14, 'days'))) {
            value = moment().add(15, 'days').startOf('day');
        }
        return value.toISOString();
    }

    calcMoneyPerColaboration() {
        const item = this.state.item;
        return item.defTypeCalc == 0 ? item.defMoneyPerCollaboration : item.defMoneyFactor * 123;
    }

    render() {
        if (this.state.item != undefined && this.state.item.state == 0) changeUrlByReact(this.props, Urls.AdvBrandedEdit + this.state.item.id)

        if (this.state.item != undefined) {
            this.confOffer = [
                new confField(strs.get('moneyPerCollaboration'), CommTextField, { className: "list-field bi-amount not-mobile", endAdornment: "$", style: { width: "220px", paddingRight: "20px" } }, fnfRequireNumber("moneyPerCollaboration", 1), this.calcMoneyPerColaboration()),
                new confField(strs.get('defCollaborationTimes'), CommTextField, { className: "list-field bi-amount not-mobile", style: { width: "220px", paddingRight: "20px" } }, fnfRequireNumber("collaborationTimes", 1), this.state.item.defCollaborationTimes),
                new confField(strs.get('AgencyPercentage'), CommTextField, { className: "list-field bi-amount not-mobile", style: { width: "220px" } }, fnfRequireNumber(null, minFeeAgency, maxFeeAgency, true, (i) => {
                    if (i.AgencyPercentage == undefined) {
                        let v = this.state.item.defAgencyFee;
                        if (this.state.selItem.feePercent != undefined && !this.state.item.defAgencyFeeOverride) {
                            v = this.state.selItem.feePercent;
                        }
                        if (i.newData == undefined) i.newData = {};
                        i.newData.AgencyPercentage = v; i.newData.feePercent = this.state.selItem.feePercent; //S'ha d'inicialitzar, sino no ho enviem, despres ja fa la feina funcOut
                        return v;
                    }                    
                    return i.AgencyPercentage;
                }, (v) => { return { AgencyPercentage: v, feePercent: this.state.selItem.feePercent } })).addField("Condition", (item) => UserUtils.GetUserData().isAgent),
                this.dateLimitConfComm("dateLimit", this.offerdeadLine()),
            ];
        }

        const NormalButton = ModalButtons(true);
        const RedButton = ModalButtons(false);

        return (            
            <Grid direction="column">                
                <Grid className="form-column">
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <h1>{(this.state.item == undefined ? "" : this.state.item.name + ": ") + strs.get("Title")}</h1>                        
                        {MoveSponsorButtons(this.props, this.state.item, this.props.match.params.id, "fromSelectStreamers", false, false)}
                    </div>
                </Grid>
                <Grid child container className={'brandedContent-form ' + this.props.className} direction="row" justify="flex-start" alignItems="flex-start">                    
                    <Grid child container direction="row" xs="12" style={{ paddingBottom: "10px" }}>
                        <ExpandableComponent title={strs.get("TitleDefValues")}>
                            <div style={{ paddingTop: "10px" }} >
                                <CommForm ref={(c) => this.commDefOffer = c} confComm={new confForm(CommFormGen, new commService("/advertisers/:id/sponsorship"), this.conf, this.props.match.params.id)} RedirectOnNew={Urls.AdvBrandedEdit + ":new"} OnQuery={(comp, data) => this.setState({ item: data })} submitButtonTitle={strs.get("Update")} />
                            </div>
                        </ExpandableComponent>
                    </Grid>                    
                    <Grid child container direction="row" xs="12">
                        <ExpandableComponent title={strs.get("CCFilters")}>
                            {this.state.item && RenderOptions(this, this.props.match.params.id, this.state.item.adConfig, null, [], { OnChangedOption: () => { this.commlistStreamers.QueryItems(); this.updateStreamersList++; } })}
                        </ExpandableComponent>
                    </Grid>
                    <Grid child container direction="row" xs="12">
                        <CommList key={this.updateStreamersList} ref={(c) => this.commlistStreamers = c} confComm={new confList(CommListGen, new commService("/advertisers/:id/sponsorship/" + this.props.match.params.id + "/streamers"), this.confListStreamers)} /*canSel={true} customCheck={(item, checkbox) => { if (item.payDefault) checkbox.setChecked(true) } }*/ genItemKey={(i) => i.id + i.platformNameOption} loadingMSG={strs.get('LoadingSelectables')} noDataMSG={strs.get('NoSelectables')}/>
                    </Grid>
                </Grid>
                <ModalDialog ref={(m) => { this.refModalOffer = m; }} windowsSyle={true} btnNOVisible={true} btnOKVisible={false} OnResult={(r) => { }}>
                    <CommForm ref={(r) => { this.formOffer = r; this.initialFormOfferData() }} confComm={new confForm(CommFormGen, new commService("/advertisers/:id/sponsorship/" + this.props.match.params.id + "/offer"), this.confOffer, undefined)} submitButtonTitle={strs.get("makeOffer")} OnError={(e) => this.showError(e)} BeforeSaveData={() => { this.lastOffer = JSON.stringify(this.formOffer.getItem()); return true; }} OnSave={(item) => { this.refModalOffer.setOpen(false); this.commlistStreamers.QueryItems() }}/>
                </ModalDialog>
                <ModalDialog ref={(m) => { this.refModalPay = m; }} windowsSyle={true} btnNOVisible={false} btnOKVisible={false} OnResult={(r) => { if (r) alert(r) }}>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ paddingBottom: "30px" }}>
                        <b>{strs.get('InsuficientFounds1')} {toMStr(this.state.refound?.opAmount, false)} {strs.get('InsuficientFounds2')} {toMStr(this.state.refound?.lack, false)}</b>
                    </Typography>
                    <div style={{ margin: "auto auto", width: "100%", height: "100%", paddingTop: "20px", }}>
                        {this.state.refound?.wallet && <NormalButton variant="contained" size="large" color="primary" style={{ marginRight: '20px' }} onClick={async (e) => { await doTransferToResource(this.state.refound?.lack, "sponsorship", this.props.match.params.id); await this.formOffer.UpdateData(JSON.parse(this.lastOffer)); this.refModalOffer.setOpen(false); this.refModalPay.setOpen(false);  }}>{strs.get('fromWallet')}</NormalButton>}
                        <NormalButton variant="contained" size="large" color="primary" style={{ marginRight: '20px' }} onClick={(e) => { changeUrlByReact(this.props, addFoundsUrl('sponsorship', this.props.match.params.id)); }}>{strs.get('AddFunds')}</NormalButton>
                        <RedButton variant="contained" size="large" color="secondary" style={{}} onClick={(e) => { this.refModalPay.setOpen(false); }}>{strs.get('Cancel')}</RedButton>
                    </div>
                </ModalDialog>
                <ModalAlert ref={(r) => this.mAlert = r } />
            </Grid>
        )
    }

    async showError(error) {                
        if (error.error == "NO_FOUNDS") {
            await this.setState({ refound: error });
            this.refModalPay.setOpen(true);
        }
        else this.mAlert.show(strs.get(error.error));
    }

    initialFormOfferData() {
        if (this.formOffer != null) {
            this.formOffer.addData({ streamer: this.state.selItem.streamerId });
            this.formOffer.addData({ streamingAccount: this.state.selItem.id });
            this.formOffer.addData({ streamingType: this.state.selItem.streamingType });
            this.formOffer.addData({ sponsorship: this.props.match.params.id });
        }
    }
}

export default connect(mapGenericStateToProps, null)(withRouter(BrandedContentStreamersForm));