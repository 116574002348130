
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {connect} from 'react-redux';
import ServicesBase from '../../services/base';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import './ads-list.styles.scss'
import ModalDialog from '../../components/common/modal/modal.jsx'
import InputAdornment from "@material-ui/core/InputAdornment";
import UserUtils, { UserTypes } from '../../utils/userUtils'
import { simpleCurrencyHelpText } from '../../utils/moneyUtils'
import { changeUrl, Urls } from '../../utils/routerUtils'
import { Grid, Button, TableCell, TextField, Box, Link } from '@material-ui/core'
import { Delete, Visibility, AttachMoney, Edit, FileCopy, CreditCard, Insights } from '@styled-icons/material';
import MuiAlert  from '@material-ui/lab/Alert'
import AdItem from '../../components/advertiser/ad-item/ad-item.component'
import ContentNavigation from '../../structure/content-navigation/content-navigation.component'
import Advertisers from '../../services/api/advertisers/';
import GlobalState from '../../store/globalState'
import DeleteItemDialog from '../common/deleteItemDialog/deleteItemDialog.component.jsx';
import AddNewButton from '../common/add-new-button/add-new-button.component.jsx';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { addFoundsUrl } from '../../structure/transaction-form/transaction-form.component.jsx'
import { confAddItem, CommListGen, CommLinkButton, CommDeleteButtonLink, confListField, confList, CommList, CommItemField, CommItemOfItems, CommButtonLinkItem, ListFieldButtonLink } from '../common/com-List/comm-list.components'
import { myReactTaggedComponent, ReactComponentTag, CompWithParent } from '../common/extComponents'
import { CommItemFieldLink, CommSimpleTextButton } from '../common/com'
import { commService } from '../../services/api/list';

import { NotificationEntityType } from '../../store/sagas/notifications'

import { ScheduleTimeDiff, standarValueBroadcastTime, ButtonExport, QueryData } from '../../components/common/commonComponents'

import { mapGenericStateToProps, changeUrlByReact } from '../../utils/routerUtils'
import { toMStr } from '../../utils/moneyUtils.jsx'

import { isDevelop } from '../../index'

import { adsStrs, BannerState } from '../../templates/languages/ads-strs.js'

import SelectUser, { AdsFilterTypes } from '../../components/admin/select-user/select-user.component.jsx'

import ComTooltip from '../../structure/common/comTooltip/comTooltip.jsx';

import { strs as bStrs, ViewCampaignsButtonContent, SelBrandedCreatorsButtonContent } from '../../structure/common/branded/commonBranded.jsx'

import { makeStyles, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Table, TableBody, TableRow, Paper, TableHead } from '@material-ui/core';

import { LangStrs, Lang } from '../../utils/languageUtils'
import Typography from '@material-ui/core/Typography';

import CommHelpLink, { getDocUrl } from '../../structure/common/comHelpLink/comHelpLink.jsx'

let strs: LangStrs = new LangStrs(new Map(
    [
        ['NoApprovedReasonIni', new Map(
            [
                [Lang.ES, "Este banner no ha sido aprobado por la siguiente razón:"],
                [Lang.EN, "This banner has not been approved for the following reason:"]
            ])
        ],
        ['NoApprovedReasonEnd', new Map(
            [
                [Lang.ES, "Ajustalo para que pueda ser revisado nuevamente."],
                [Lang.EN, "Adjust it so it can be reviewed again"]
            ])
        ],
        ['WithdrawFunds', new Map(
            [
                [Lang.ES, "Retirar fondos"],
                [Lang.EN, "Withdraw funds"]
            ])
        ],
        ['BrandedContent', new Map(
            [
                [Lang.ES, 'Campañas de contenido de Marca'],
                [Lang.EN, 'Branded Content Campaigns']
            ])
        ],
        ['NewBranded', new Map(
            [
                [Lang.ES, 'Crear nuevo contenido de marca'],
                [Lang.EN, 'Create New Branded Content']
            ])
        ],
        ['ProgAd', new Map(
            [
                [Lang.ES, 'Publicidad Programática'],
                [Lang.EN, 'Programmatic Advertising']
            ])
        ],
        ['NewProgAd', new Map(
            [
                [Lang.ES, 'Crear nueva publicidad Programática'],
                [Lang.EN, 'Create New Programmatic Advertising']
            ])
        ],
        ['Export', new Map(
            [
                [Lang.ES, 'Exportar datos'],
                [Lang.EN, 'Export data']
            ])
        ],
        ['LinkNum', new Map(
            [
                [Lang.ES, `Visitas al link`],
                [Lang.EN, `Link visits`]
            ])
        ],
        ['TitleModalVisites', new Map(
            [
                [Lang.ES, 'Detalle geográfico de las visitas'],
                [Lang.EN, 'Geographic detail of visits']
            ])
        ],
        ['Displayed', new Map(
            [
                [Lang.ES, 'Mostrado'],
                [Lang.EN, 'Displayed']
            ])
        ],
    ]
));



export const rLinkNum = (fUrl, fTransform=null) => {
    return new confListField(strs.get('LinkNum'), CommItemField, { className: "list-field bi-date not-mobile" }, (item) => {
        return <QueryData url={fUrl(item)} transformQuery={(resp) => fTransform ? fTransform(resp, item) : resp } renderComp={(data) => {
            let modalDetails = null;
            if (!data.detail) return data;
            else if (data.total == 0) return 0;
            else return (
                <>
                    <a href="/" onClick={(e) => { e.preventDefault(); modalDetails.setOpen(true); }}>
                        {data.total ?? 0}
                    </a>
                    <ModalDialog ref={(m) => { modalDetails = m; }} windowsSyle={true} btnNOVisible={false} btnOKVisible={false}>
                        <div
                            style={{
                                maxHeight: '80vh',
                                overflowY: 'auto'
                            }}
                        >
                            <h1>{strs.get('TitleModalVisites')}</h1>
                                <DataComponent data={data} />
                        </div>
                    </ModalDialog>
                </>);
        }} />
    });
}

const linkstatsUri = (item) => `#server#/advertisers/${UserUtils.GetUserData().id}/advertisement/${item.id}/linkStats`;

class AdsList extends React.Component {
    constructor(props) {
        super(props);

        this.isAdmin = UserUtils.GetUserData(true).usertype == UserTypes.Admin;        

        this.confBannerList = [
            new confListField(adsStrs.get('Name'), CommItemField, {}, "name"),
            new confListField(adsStrs.get('Size'), CommItemField, { className: "list-field bi-size" }, (item) => item.sizeX + "x" + item.sizeY),
            new confListField(adsStrs.get('Duration'), CommItemField, { className: "list-field bi-amount not-mobile" }, (i)=> i.time+"''"),
            new confListField(adsStrs.get('Status'), BannerStateInfo, { className: "list-field bi-size" }),
            new confListField(adsStrs.get('Founds'), CommItemField, { className: "list-field bi-date not-mobile" }, (item) => { return <b>{isDevelop ? item.founds : toMStr(item.founds)}</b> }),
            new confListField(strs.get('Displayed'), CommItemField, { className: "list-field bi-date not-mobile" }, (item) => <QueryData url={`#server#/Transfers/${UserUtils.GetUserData().id}/banner/${item.id}`} respOnError={0} transformQuery={(resp) => { return resp.reduce((sum, r) => sum + r.transfers.viewers, 0); }} />),
            rLinkNum(linkstatsUri),
            new confListField(adsStrs.get('Actions'), CommItemOfItems).addField("Comp", [
                ListFieldButtonLink(undefined, (item) => addFoundsUrl('banner', item.id), adsStrs.get("AddFounds"), "Widget URL", (item) => { return { notificationKey: [NotificationEntityType.BannerNoMoney], notificationId: item.idOrg, notificationType: 'attention' } }).addField("Condition", (i) => i.state == BannerState.Approved),
                new confListField(strs.get('WithdrawFunds'), CommSimpleTextButton, { }, strs.get('WithdrawFunds')).addField("Condition", (item) => this.isAdmin && item.founds > 0).addField("color", '#f44336').addField("OnClick", async (item) => { await this.setState({ selItem: item }); this.modalDraw.setOpen(true); } ),
                ListFieldButtonLink(Insights, (item) => Urls.AdvStats + item.id).addField("Condition", () => this.props.id == undefined),
                ListFieldButtonLink(Edit, (item) => Urls.AdvEdit + item.id),
                new confListField("Delete", CommDeleteButtonLink, null, "name").addField("Condition", (i) => this.props.id == undefined && i.founds<=0)
            ])
        ];

        this.confBrandedList = [
            new confListField(adsStrs.get('Name'), CommItemField, {}, "name"),
            new confListField(adsStrs.get('Founds'), CommItemField, { className: "list-field bi-date not-mobile" }, (item) => { return <b>{isDevelop ? item.founds : toMStr(item.founds)}</b> }),
            new confListField(adsStrs.get('Status'), BannerStateInfo, { className: "list-field bi-size" }),
            rLinkNum(linkstatsUri),
            new confListField(adsStrs.get('Actions'), CommItemOfItems).addField("Comp", [
                this.buttonLink(adsStrs.get('AddFounds'), (item) => addFoundsUrl('sponsorship', item.id)).addField("Condition", (i) => i.state == BannerState.Approved),
                new confListField(strs.get('WithdrawFunds'), CommSimpleTextButton, {}, strs.get('WithdrawFunds')).addField("Condition", (item) => this.isAdmin && item.founds > 0).addField("color", '#f44336').addField("OnClick", async (item) => { await this.setState({ selItem: item }); this.modalDraw.setOpen(true); }),
                this.buttonLink(ViewCampaignsButtonContent, (item) => Urls.AdvBrandedOnGoing + item.id).addField("Condition", (i) => i.Contracts === true),
                this.buttonLink(SelBrandedCreatorsButtonContent, (item) => Urls.AdvBrandedStreamers + item.id).addField("Condition", (i) => i.state == BannerState.Approved),
                ListFieldButtonLink(Edit, (item) => Urls.AdvBrandedEdit + item.id),
            ])
        ];
    }

    buttonLink = (text, url, icon) => new confListField("Widget URL", myReactTaggedComponent(CommItemFieldLink, [
        new ReactComponentTag("tInnerLink", (item) => <Button color="primary" variant="contained" className="list-button">{icon}{typeof text === 'function' ? text(item) : text}</Button>)
    ]), null, (item) => { return { to: url(item), fieldName: url(item) } })

    drawBannerFounds = async (amount) => {
        if (amount > 0) {
            let data = {
                "quantity": amount,
                "advertiser": UserUtils.GetUserData().id,
                "banner": this.state.selItem.id,
                "transfertype": "bannerToAdvertiser"
            }

            let sb: ServicesBase = new ServicesBase("#server#" + "/Transfers");
            let resp = await sb.ServiceRequest("", 'POST', { 'Content-Type': 'application/json' }, false, data);
            await this.adsList.QueryItems();
        }

        this.modalDraw.setOpen(false);
    }

    render = () => {
        let isBannerStats:boolean = window.location.href.includes(Urls.AdvStats);
        return React.createElement(() =>
            <React.Fragment>
                {!isBannerStats && <SelectUser filter={AdsFilterTypes} OnSelectUser={this.changeUser} />}
                {!isBannerStats && <CommList key={UserUtils.GetUserData().id + "branded"} ref={(r) => this.adsListBranded = r} title={<>{strs.get("BrandedContent")}<CommHelpLink infoUrl={getDocUrl("como-crear-una-campana-de-contenido-de-marca")} /></>} itemId={this.props.id} confComm={new confList(CommListGen, new commService("/advertisers/:id/sponsorship"), this.confBrandedList, this.props.id == undefined ? new confAddItem("/dashboard/advertisements/brandedContent/add", strs.get("NewBranded")) : null)} />}
                {isBannerStats &&
                    <Grid container direction="row" alignItems="left">
                        <Grid child xs={9}>
                            <h1 style={{ marginRight: "10px" }}>{strs.get("ProgAd")}<CommHelpLink infoUrl={getDocUrl("como-crear-una-campana-de-publicidad-programatica")} /></h1>
                        </Grid>
                        <Grid container item xs={3} aligment="center" alignItems="center" justify="center">
                            <ButtonExport url={`/api/Transfers/${UserUtils.GetUserData().id}/banner/${this.props.id}/export`} str={strs.get('Export')}/>
                        </Grid>
                    </Grid>
                }
                <CommList key={UserUtils.GetUserData().id} title={<>{isBannerStats ? undefined : strs.get('ProgAd')} {this.props.id == undefined && <CommHelpLink infoUrl={getDocUrl("como-crear-una-campana-de-publicidad-programatica")} />}</>} ref={(r) => this.adsList = r} itemId={this.props.id} confComm={new confList(CommListGen, new commService("/advertisers/:id/banner"), this.confBannerList, this.props.id == undefined ? new confAddItem("/dashboard/advertisements/add/", strs.get("NewProgAd")) : null)} />
                <ModalDialog ref={(m) => { this.modalDraw = m; }} windowsSyle={true} btnNOVisible={false} btnOKVisible={false}>
                    <DrawalForm OnDraw={(a) => this.drawBannerFounds(a) } OnCancel={ ()=> this.modalDraw.setOpen(false) }/>
                </ModalDialog>
            </React.Fragment>
        );
    }

    changeUser = async (user) => {
        if (this.adsList) {
            this.adsListBranded.QueryItems();
            this.adsList.QueryItems();
        }
    }
}

let drawStrs: LangStrs = new LangStrs(new Map(
    [
        ['EnterAmount', new Map(
            [
                [Lang.ES, "Cantidad"],
                [Lang.EN, "Amount"]
            ])
        ],
        ['Accept', new Map(
            [
                [Lang.ES, "Aceptar"],
                [Lang.EN, "Accept"]
            ])
        ],
        ['Cancel', new Map(
            [
                [Lang.ES, "Cancelar"],
                [Lang.EN, "Cancel"]
            ])
        ],
        ['WithdrawAmount', new Map(
            [
                [Lang.ES, "Cantidad a retirar"],
                [Lang.EN, "Withdraw amount"]
            ])
        ]
    ]
));

class DrawalForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            amount: '',
        };
    }

    handleInputChange = (event) => {
        this.setState({
            amount: event.target.value,
        });
    };

    handleAccept = () => {
        this.props.OnDraw(this.state.amount)
    };

    handleCancel = () => {
        this.props.OnCancel();
    };

    render() {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
                <Typography variant="h6">{drawStrs.get('WithdrawAmount')}</Typography>
                <TextField
                    label={drawStrs.get('EnterAmount')}
                    type="number"
                    InputProps={{
                        endAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    value={this.state.amount}
                    onChange={this.handleInputChange}
                    style={{ marginBottom: '20px' }}
                    helperText={simpleCurrencyHelpText(this.state.amount)}
                />
                <div>
                    <Button type="button" variant="contained" color="primary" onClick={this.handleAccept} style={{ marginRight: '10px' }}>
                        {drawStrs.get('Accept')}
                    </Button>
                    <Button variant="contained" style={{ backgroundColor: '#f44336', color: 'white' }} onClick={this.handleCancel}>
                        {drawStrs.get('Cancel')}
                    </Button>
                </div>
            </div>
        );
    }
}

export class BannerStateInfo extends React.Component {
    constructor(props) {
        super(props);
    }

    render = () => {
        let text = "NONE";
        let colortext = "black";
        let tooltipText = "";

        const confField: confField = this.props.confFieldItem.confField;
        if (this.props.item.state == BannerState.InsufficientData) {
            text = adsStrs.get('StateNoInfo');
            colortext = "red";
        } else if (this.props.item.state == BannerState.Approved) {
            let t = standarValueBroadcastTime(this.props.item.broadcastTime);
            if (t == 0 || (t != -1 && ScheduleTimeDiff(t) < 0)) {
                text = adsStrs.get('StateApproved');
                colortext = "green";
            } else if (t == -1) {
                text = adsStrs.get('HoldOff');
                colortext = "red";
            } else {
                text = adsStrs.get('AdScheduled');
                colortext = "orange";
            }
        } else if (this.props.item.state == BannerState.ToCheck) {
            text = adsStrs.get('BannerStateToCheck');
            colortext = "orange";
        } else if (this.props.item.state == BannerState.NoApproved) {
            text = adsStrs.get('BannerStateNoApproved');
            colortext = "red";
            tooltipText = this.props.item.NoApprovedReason;
        }

        return (
            <TableCell className="list-field" {...confField.fieldprops}>
                <p style={{ color: colortext }}>
                    
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span>{text}</span>
                        {tooltipText != "" && <ComTooltip text={
                            <React.Fragment>
                                <Typography variant="subtitle2">
                                    <span style={{ fontSize: '.85em' }}>
                                        {strs.get("NoApprovedReasonIni")}
                                    </span>
                                    <br/>
                                    <span style={{ fontSize: '1em' }}>
                                        {tooltipText}
                                    </span>
                                    <br />
                                    <span style={{ fontSize: '.85em' }}>
                                        {strs.get("NoApprovedReasonEnd")}
                                    </span>
                                </Typography>
                            </React.Fragment>
                        } />
                        }
                    </div>
                </p>
            </TableCell>
        )
    }
}

let dataVisites = {
    "total": 15,
    "detail": [
        {
            "CountryCode": "USA",
            "num": 6,
            "state1": [
                {
                    "val": "California",
                    "num": 3,
                    "state2": [
                        {
                            "val": "Sacramento",
                            "num": 3
                        }
                    ]
                },
                {
                    "val": "Texas",
                    "num": 2,
                    "state2": [
                        {
                            "val": "Austin",
                            "num": 2
                        }
                    ]
                },
                {
                    "val": "New York",
                    "num": 1,
                    "state2": [
                        {
                            "val": "Albany",
                            "num": 1
                        }
                    ]
                }
            ],
            "city": [
                {
                    "val": "Los Angeles",
                    "num": 1
                },
                {
                    "val": "San Francisco",
                    "num": 1
                },
                {
                    "val": "San Diego",
                    "num": 1
                },
                {
                    "val": "Dallas",
                    "num": 1
                },
                {
                    "val": "Houston",
                    "num": 1
                },
                {
                    "val": "New York City",
                    "num": 1
                }
            ]
        },
        {
            "CountryCode": "CA",
            "num": 4,
            "state1": [
                {
                    "val": "Ontario",
                    "num": 2,
                    "state2": [
                        {
                            "val": "Toronto",
                            "num": 2
                        }
                    ]
                },
                {
                    "val": "Quebec",
                    "num": 2,
                    "state2": [
                        {
                            "val": "Montreal",
                            "num": 2
                        }
                    ]
                }
            ],
            "city": [
                {
                    "val": "Toronto",
                    "num": 1
                },
                {
                    "val": "Ottawa",
                    "num": 1
                },
                {
                    "val": "Montreal",
                    "num": 1
                },
                {
                    "val": "Quebec City",
                    "num": 1
                }
            ]
        },
        {
            "CountryCode": "MX",
            "num": 5,
            "state1": [
                {
                    "val": "Chihuahua",
                    "num": 1,
                    "state2": [
                        {
                            "val": "Chihuahua",
                            "num": 1
                        }
                    ]
                },
                {
                    "val": "Jalisco",
                    "num": 1,
                    "state2": [
                        {
                            "val": "Guadalajara",
                            "num": 1
                        }
                    ]
                },
                {
                    "val": "Nuevo Leon",
                    "num": 1,
                    "state2": [
                        {
                            "val": "Monterrey",
                            "num": 1
                        }
                    ]
                },
                {
                    "val": "Mexico City",
                    "num": 1,
                    "state2": [
                        {
                            "val": "Mexico City",
                            "num": 1
                        }
                    ]
                },
                {
                    "val": "Oaxaca",
                    "num": 1,
                    "state2": [
                        {
                            "val": "Oaxaca",
                            "num": 1
                        }
                    ]
                }
            ],
            "city": [
                {
                    "val": "Chihuahua",
                    "num": 1
                },
                {
                    "val": "Guadalajara",
                    "num": 1
                },
                {
                    "val": "Monterrey",
                    "num": 1
                },
                {
                    "val": "Mexico City",
                    "num": 1
                },
                {
                    "val": "Oaxaca",
                    "num": 1
                }
            ]
        }
    ]
};

const useStyles = makeStyles(theme => ({
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(1, 2),
        width: '100%',
    },
    countryRow: {
        backgroundColor: 'rgb(53,116,158)',
        color: 'white',
    },
    stateRow: {
        backgroundColor: '#f5f5f5',
    },
    cityRow: {
        backgroundColor: 'white',
    },
    nested: {
        //marginLeft: theme.spacing(2),
        width: 'calc(100% - 5em)',  // Reduce width slightly for hierarchy visualization
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'stretch',
    },
    nested2: {
        marginLeft: theme.spacing(4),
        width: 'calc(100% - 8em)',  // Reduce width slightly for hierarchy visualization
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'stretch',
    },
    expansionPanelRoot: {
        width: '100%', // Esto hará que el panel de expansión ocupe todo el ancho disponible
        '&$expanded': {
            margin: 'auto', // Esto eliminará el margen adicional cuando esté expandido
        },        
    },
    expansionPanelSummaryRoot: {
        borderBottomWidth: '1px',  // Grosor del borde inferior
        borderColor: '#f5f5f5',  // Color del borde
        borderStyle: 'solid',  // Estilo del borde
        backgroundColor: 'rgb(53,116,158)',
        height: '1em'
    },
    expansionPanelDetailsRoot: {
        flexDirection: 'column',  // Esto hará que el contenido dentro del panel de expansión se apile verticalmente
    },
}));

const DataComponent = (props) => {
    const classes = useStyles();

    const data = props.data;
    console.log("ESTO");
    console.log(props);
    
    return (
        <div style={{ width: "600px" }}>
            {data.detail.map((country, index) => (
                <ExpansionPanel classes={{ root: classes.expansionPanelRoot, expanded: classes.expansionPanelExpanded }} key={country.CountryCode}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} classes={{ root: classes.expansionPanelSummaryRoot }}>
                        <div className={`${classes.row} ${classes.countryRow}`}>
                            <Typography>{country.CountryCode}</Typography>
                            <Typography>{country.num} visits</Typography>
                        </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.nested}>
                        {country.state1.map((state1, sIndex) => (
                            <ExpansionPanel className={classes.nested} key={state1.val}>
                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                    <div className={`${classes.row} ${sIndex % 2 === 0 ? classes.stateRow : classes.cityRow}`}>
                                        <Typography>{state1.val}</Typography>
                                        <Typography>{state1.num} visits</Typography>
                                    </div>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails className={classes.nested2}>
                                    {state1.state2.map((state2, stIndex) => (
                                        <div className={`${classes.row} ${stIndex % 2 === 0 ? classes.stateRow : classes.cityRow}`} key={state2.val}>
                                            <Typography>{state2.val}</Typography>
                                            <Typography>{state2.num}</Typography>
                                        </div>
                                    ))}
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        ))}
                        <ExpansionPanel className={classes.nested}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <div className={classes.row}>
                                    <Typography>Cities</Typography>
                                </div>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails className={classes.nested2}>
                                {country.city.map((city, cIndex) => (
                                    <div className={`${classes.row} ${cIndex % 2 === 0 ? classes.stateRow : classes.cityRow}`} key={city.val}>
                                        <Typography>{city.val}</Typography>
                                        <Typography>{city.num}</Typography>
                                    </div>
                                ))}
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            ))}
        </div>
    );
};

export default connect(mapGenericStateToProps, null)(AdsList);