
import React from 'react';
import { Urls, changeUrl } from '../../utils/routerUtils'
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export class Logout extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        //cookies.remove("userSession");
        //window.setTimeout(async () => { await cookies.remove("userSession", { path: "/", domain: Urls.DomainFrontend }); changeUrl(Urls.Login) }, 1);
        //window.setTimeout(() => { changeUrl(Urls.Login) }, 1000);
        //changeUrl(Urls.Login)
        
        new Cookies().remove('userSession', { path: '/' });
        //document.cookie = "userSession" + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;"        

        fetch('/api/auth/logout', { method: 'POST' }).then(response => changeUrl(Urls.Login));

        return {};
    }
}

export default Logout;