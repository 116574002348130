import ServicesBase from "../base"

class Users extends ServicesBase {
  constructor() {
    super("#server#" + "/users/:id");
  }
  Get = async (userId, loading = true) => {
      return await this.ServiceRequest("", 'GET', {
          'Content-Type': 'application/json',
        },
        loading,
        null, userId
      );
  };
  Update = async (userId, data, loading = true) => {
    return await (this.ServiceRequest("", 'PUT', {
      'Content-Type': 'application/json',
    }, loading, data, userId));
  };
};

export default new Users();