import fetch from 'cross-fetch';

const baseURL = ("#server#/");

const Auth = {
    Login: (userData) => {
        let loginCredentials = {
            userId: userData.userId || null,
            password: userData.password || null
        };
        
        if(!loginCredentials.userId || !loginCredentials.password) return (new Error('Missing userId or password'));

        return fetch("api/auth/login", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(loginCredentials),
        })
    },
    Register: (userData) => {
        return fetch("api/users/", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData),
      })
  }
};

export default Auth;