export const DurationTime = (ini, end) => {
    let duration = new Date(new Date(end).getTime() - new Date(ini)).getTime();
    let sec = Math.floor((duration / 1000) % 60);
    sec = sec < 10 ? "0" + sec : sec;
    let min = Math.floor((duration / 1000 / 60) % 60);
    min = min < 10 ? "0" + min : min;
    const hour = Math.floor(duration / 1000 / 3600);
    duration = hour + ":" + min + ":" + sec;
    return duration;
}

export function delay (ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function dateStrToReadDateStr(str: string, withTime: false) {
    if (withTime) return new Date(str).toLocaleString('es-ES').replace(',', '');
    return new Date(str).toLocaleDateString('es-ES')
}