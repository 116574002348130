import React, { useEffect } from 'react';
import {Switch, Route, useLocation} from 'react-router-dom'
import Cookies from 'universal-cookie';

import { Provider, connect } from 'react-redux'

import { Helmet } from "react-helmet";

import { isDevelop, isSandbox } from './index'

import './App.css';

import Main from './templates/main/main.component'
import Dashboard from './templates/dashboard/dashboard.component'
import StreamingSceneComponent from './structure/streamingScene/streamingScene-component'


import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { ChangeLanguage } from './store/actions';

import RedirectUrl, { decode } from './structure/redirect-url/redirect-url.component.jsx'

import UserUtils from './utils/userUtils.js'

const cookies = new Cookies();

if(!cookies.get('lang')) {
  cookies.set('lang', navigator.language.split('-')[0]);
}

function mapDispatchToProps(dispatch) {
  return {
    ChangeLanguage: lang => dispatch(ChangeLanguage(lang))
  };
}

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#8CD0F1',
      main: '#35749E',
      dark: '#65b4FE',
      contrastText: '#fff',
    },
    secondary: {
      light: '#AAAAAA',
      main: '#009CDD',
      dark: '#8CD0F1',
      contrastText: '#fff',
    },
    error: {
      light: '#FFaaee',
      main: '#FF8899',
      dark: '#aa6677',
      contrastText: '#fff',
    },
  },
});

function App(props) {
    const location = useLocation();
    props.ChangeLanguage(cookies.get('lang'));

    let trackUserId = "";
    if (UserUtils.GetUserData(true).email != undefined) {
        trackUserId = `_paq.push(['setUserId', '${UserUtils.GetUserData(true).email}']);`;
    } else if (cookies.get('guestId')) {
        const gId = decode(cookies.get('guestId'));
        trackUserId = `_paq.push(['setUserId', '${gId}']);`;        
    }

    return (
        <>
            <Helmet>
                <script type="text/javascript">
                    {`
                            var _paq = window._paq = window._paq || [];
                            /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
                            ${trackUserId}
                            _paq.push(['trackPageView']);
                            _paq.push(['enableLinkTracking']);
                            (function() {
                                var u="https://${isDevelop?'dev':'www'}.powerads.tv/matomo/";
                                _paq.push(['setTrackerUrl', u+'matomo.php']);
                                _paq.push(['setSiteId', '${isDevelop ? '2' : '1'}']);
                                var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
                                g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
                          })();
                    `}
                </script>
            </Helmet>
            <UseMatomoTracker/>
            <ThemeProvider theme={theme}>
                {(isDevelop || isSandbox) && !window.location.pathname.startsWith("/streamingScene/") && <div style={{ zIndex: "9999", position: "fixed", top: "0", left: "0", pointerEvents: "none" }}><h2 style={{ color: "red", margin: "0px" }}>{isSandbox ? "SANDBOX" : "DEVELOP"}</h2></div>}
            <Switch>
                <Route path='/streamingScene/:sceneKey' component={StreamingSceneComponent} />
                <Route path='/dashboard' component={Dashboard} />
                <Route path='/api' component={() => {
                    window.location.href = window.location.href.replace(':8000', ':3000').replace('http://','https://');
                    return null;
                }} />
                <Route path='/r/:id/:url' component={() => <RedirectUrl />} />
                <Route path='/' component={Main} />                
            </Switch>
            </ThemeProvider>
        </>
  );
}

const UseMatomoTracker = () => {
  const { pathname } = useLocation();

    useEffect(() => {
        if (window.UrlH != undefined) { //No es de Matomo....
            window.UrlPrev = window.UrlH[0];
            window.UrlH.unshift(pathname);
        } else
            window.UrlH = [pathname];

        if (window._paq) {
          window._paq.push(['setCustomUrl', pathname]);
          window._paq.push(['trackPageView']);
        }
  }, [pathname]);

  return null;
};

export default connect(null, mapDispatchToProps)(App);
