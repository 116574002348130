import * as React from 'react';

import { Hidden, Grid, Button, TextField, Fab, Select, InputLabel, MenuItem, TextareaAutosize, FormControlLabel, FormControl } from '@material-ui/core';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { LangStrs, Lang } from '../../../utils/languageUtils'
import { strs as strPrivacyPolicy } from '../../../pages/privacypolicy/privacypolicypage.component'
import { strs as strCookiesPolicy } from '../../../pages/cookiespolicy/cookies.component'
import { withStyles } from '@material-ui/core/styles'
import { isBrowser, isMobile } from '../../../utils/platformsUtils';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',    
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: '0 0 8px rgba(0, 0, 0, 0.3)',
    p: isMobile? 2 : 4,
};

export const StyledButtons = (color, hoverColor) => withStyles({
    root: {
        backgroundColor: color,
        marginBottom: '15px',
        marginLeft: '1px',
        color: '#fff',
        '&:hover': {
            backgroundColor: hoverColor,
            color: '#fff',
        },
    }
})(Button);

export const ModalButtons = (Ok) => StyledButtons(Ok ? '#35749E' : '#cf0d3a', Ok ? '#8CD0F1' : '#ff164c');

export default class ModalDialog extends React.Component {
    setOpen = (open) => this.setState({ open: open });
    handleOpen = () => this.setOpen(true);
    handleClose = () => this.setOpen(false);
    result: number = -1;
    OnResult = (r) => {
        this.result = r ? 1 : 0;
        this.setOpen(false);
        this.props.OnResult && this.props.OnResult(r);
    }

    showAndGetResult () {
        return new Promise(async resolve => {
            this.result = -1;
            this.setOpen(true);
            while (this.result == -1) {
                await new Promise(r => setTimeout(r, 50));
            }
            resolve(this.result? true: false);
        });
    }    

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            btnOKDisable: props.btnOKDisable,
            btnNODisable: props.btnNODisable,
        }
    }

    render() {
        const strs: LangStrs = new LangStrs(new Map(
            [
                ['btnOK', new Map(
                    [
                        [Lang.ES, "Aceptar"],
                        [Lang.EN, "Accept"]
                    ])
                ],
                ['btnNO', new Map(
                    [
                        [Lang.ES, "Cancelar"],
                        [Lang.EN, "Cancel"]
                    ])
                ]
            ]
        ));

        let OkButton = ModalButtons(true);
        let NoButton = ModalButtons(false);

        const CloseHoveringState = (state) => this.setState({ CloseHovering: state });

        let modalStyle = style;
        if (isMobile) {
            modalStyle.width = "85%";
        }

        return (
            <div>
                <Modal open={this.state.open} onClose={this.handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                    <Box sx={style}>
                        {this.modalBody || this.props.children}
                        {this.props.windowsSyle && (
                            <button onClick={() => { CloseHoveringState(false); this.handleClose() }} onMouseEnter={() => { CloseHoveringState(true); }} onMouseLeave={() => { CloseHoveringState(false); }}
                                style={{
                                    position: 'absolute',
                                    top: '-15px',
                                    right: '-15px',
                                    width: '30px',
                                    height: '30px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontWeight: 'bold',
                                    border: 'none',
                                    background: 'white',
                                    cursor: 'pointer',
                                    color: this.state.CloseHovering ? 'red' : 'black',
                                    borderRadius: '10px',
                                    boxShadow: '0 0 8px rgba(0, 0, 0, 0.3)',
                                }}
                            >
                                X
                            </button>
                        )}

                        <div style={{ margin: "auto auto", width: "100%", height: "100%", paddingTop: "20px", }}>
                            {(this.btnOKVisible != undefined ? this.btnOKVisible : this.props.btnOKVisible) !== false && <OkButton style={{ marginRight:'20px' }} {...this.state.btnOKDisable != undefined && { disabled: this.state.btnOKDisable }} onClick={(e) => { this.OnResult(true) }} >{this.props.btnOKText || strs.get('btnOK')} </OkButton>}
                            {(this.btnNOVisible != undefined ? this.btnNOVisible : this.props.btnNOVisible) !== false && <NoButton style={{}} {...this.state.btnNODisable != undefined && { disabled: this.state.btnNODisable }} onClick={(e) => { this.OnResult(false) }}>{this.props.btnNOText || strs.get('btnNO')} </NoButton>}
                        </div>
                    </Box>
                </Modal>
            </div>
        );
    }
}

export class ModalAlert extends ModalDialog {

    constructor(props) {        
        //props = Object.assign({ btnNOVisible: false }, props);

        super(props);
        //this.btnNOVisible = false;
    }

    render() {
        //const strs: LangStrs = new LangStrs(new Map(
        //    [                
        //    ]
        //));

        //this.props = Object.assign({ btnOKText: strs.get('btnOK') }, this.props);
        //this.props = Object.assign({ btnNOText: strs.get('btnNO') }, this.props);
        this.props = Object.assign({ btnNOVisible: false }, this.props);

        this.modalBody = (
            <div>
                <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ paddingBottom: "30px" }}>
                    <b>{this.msg}</b>
                </Typography>
            </div>
        );
        return super.render();
    }

    show(msg) {
        this.msg = msg;
        this.setOpen(true);
    }
}