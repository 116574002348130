import React from 'react';
import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom'

import GlobalState from '../../store/globalState'
import { isBrowser, isMobile, MobileView } from '../../utils/platformsUtils';

import {Grid, Button, TextField, TextareaAutosize, FormControlLabel, FormControl} from '@material-ui/core';
import {Redirect} from 'react-router-dom';
import MuiAlert from '@material-ui/lab/Alert';
import Streamers from '../../services/api/streamers';
import UserUtils from '../../utils/userUtils'
import { commService } from '../../services/api/list';
import { bannerStreamerListService } from '../../services/api/bannerStreamer';
import { CommInputField, CommForm, CommFormGen, confForm } from '../common/com-Form/comm-form.components'
import { CommButton, confCOM, confField, CommTextField, fieldNameFunction, temporalItem, fnfRequireText, CommDateTimePicker } from '../common/com'
import { CommItemOfItems, confAddItem, CommListGen, CommLinkButton, CommDeleteButtonLink, confListField, confList, CommList, CommItemField, CommItemTextField } from '../common/com-List/comm-list.components'

import './banner-form.styles.scss';
import { BannersPreview, Rectangle, getCanvasSize } from '../banners-preview/banners-preview.component'

import { mapGenericStateToProps } from '../../utils/routerUtils'
import { bannerStrs } from '../../templates/languages/banner-strs.js'

const TextSizeFieldName = (name, max) => new fieldNameFunction(name, (n) => Number.isInteger(+n) && +n > 0 && +n<=max);

class BannerForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            item: {},
            iniPosX: 200,
            iniPosY: 200,
            sizeX: 200,
            sizeY: 200
        }
        
        this.conf = [
            new confField(bannerStrs.get('Name'), CommTextField, { className: "list-field bi-name" }, fnfRequireText("name") ),
            new confField(bannerStrs.get('ETime'), CommTextField, { className: "list-field bi-amount not-mobile" }, "elapseTime", 0),
            new confField(bannerStrs.get('MAmount'), CommTextField, { className: "list-field bi-amount not-mobile" }, "minimalBannerAmount", 0),            
            new confField(bannerStrs.get('BWidth'), CommTextField, { className: "list-field bi-size" }, TextSizeFieldName("sizeX", 1920), this.state.sizeX).addField("tfullWidth", false),
            new confField(bannerStrs.get('BHeight'), CommTextField, { className: "list-field bi-size" }, TextSizeFieldName("sizeY", 1080), this.state.sizeY).addField("tfullWidth", false)
        ];
        this.banner = new Rectangle(+this.state.iniPosX, +this.state.iniPosY, +this.state.sizeX, +this.state.sizeY);
    }
    
    render() {
        if (this.state.sizeX != undefined) this.banner.width = +this.state.sizeX;
        if (this.state.sizeY!=undefined) this.banner.height = +this.state.sizeY;

        let canvasResW = 1920;
        let canvasResH = 1280;
        let canvasSize = getCanvasSize(canvasResW, canvasResH);

        return (            
            <Grid direction="column">
                
                <Grid className="form-column">
                    <h1>{this.props.title}</h1>
                </Grid>
                <Grid child container className={'banner-form ' + this.props.className} direction="row" justify="flex-start" alignItems="flex-start">
                    <Grid child container direction="row" xs="12">
                        <Grid xs={isBrowser ? "6" : "12"} style={{ paddingBottom: "20px" }}>
                            <BannersPreview ref={(c) => { this.bprev = c; if (this.bprev) this.bprev.setBanners(); } } width={canvasResW} height={canvasResH} canvasWidth={canvasSize.width} canvasHeight={canvasSize.height} banners={[this.banner]} />
                        </Grid>
                        <Grid child container xs={isBrowser ? "6" : "12"}>
                            <div style={{ paddingLeft: "10px" }}>
                                <CommForm confComm={new confForm(CommFormGen, new commService("/streamers/:id/banner"), this.conf, this.props.match.params.id)} Owner={this} OnDataChange={async (comp, newValue) => { let i = temporalItem(comp.props.item); await this.setState({ sizeX: i.sizeX, sizeY: i.sizeY }); this.bprev.setBanners(); return true; }} OnQuery={async (comp, data) => { await this.setState({ sizeX: data.sizeX, sizeY: data.sizeY }); }}/>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default connect(mapGenericStateToProps, null)(withRouter(BannerForm));