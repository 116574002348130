const _data = {};

const GlobalState = {
  add: (key, evt) => {
      if(!_data[key]) _data[key] = [];
      _data[key].push(evt)
  },
  set: (key, ...val) => {
      if(_data[key]) _data[key].forEach(evt => {
          evt(...val);
      });  
    },
  delete: (key) => {
    _data[key] = null;
  }
}

Object.freeze(GlobalState);
export default GlobalState;
