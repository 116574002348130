import React from 'react';
import { Button } from '@material-ui/core'
import { StreamingAccountAuth } from './streamingAccount-auth'

import { appendScript, removeScript } from '../../utils/jsscripts'

import { getFragmentParameter } from '../../utils/routerUtils'
import { handleAuthRedirect } from './handleAuthRedirect';
import { isDevelop, isSandbox } from '../../index';

export class TwitchAuthAccount extends handleAuthRedirect {
    constructor(callback: (token) => any) {
        let redUrl: string = isSandbox ? 'https://sandbox.powerads.tv' : isDevelop ? 'https://dev.powerads.tv' : 'https://www.powerads.tv';
        super("https://id.twitch.tv/oauth2/authorize?client_id=bsbbdjreqne3b2colcwhxkojy2dg7u&redirect_uri=" +redUrl+"/login&response_type=token&scope=user:read:email&force_verify=true", "redirectTwitchAuth", callback);
    }

    //TODO: Ara nomes cal a login, ja que es l'unica url que s'utilitza per recuperar el callback de google y postejarlo per event
    async componentDidMount() {
        let twitchAccessToken = getFragmentParameter('access_token');
        if (twitchAccessToken !== false) {
            this.postCredentials({ access_token: twitchAccessToken });
        }
    }

    componentDidUnmount() { }
}