import createSagaMiddleware from 'redux-saga'

import { createStore, applyMiddleware } from 'redux'
import reducer from './reducers'
import InitSagas from './sagas'

const sagaMiddleware = createSagaMiddleware()

const store = createStore(
    reducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
    applyMiddleware(sagaMiddleware)
);

InitSagas(sagaMiddleware);

export default store