import React from 'react';
import UserUtils from '../../utils/userUtils'
import ServicesBase from '../../services/base';
import { StreamingAccountAuthYoutube } from './streamingAccount-authYoutube';

export class StreamingAccountAuth extends React.Component {
    static buttonRefreshLogin(sa) {
        if (sa.streamingType == "Youtube") return <StreamingAccountAuthYoutube />
        return null;
    }

    static async addStreamingAccount(platformToken, platform, OnRespAddStreamingAccount=null) {
        let sb: ServicesBase = new ServicesBase("#server#" + "/streamers/" + UserUtils.GetUserData().id + "/streamingAccount/platform/" + platform);
        let resp = await sb.ServiceRequest("", 'POST', { 'Content-Type': 'application/json' }, true, platformToken);

        if (OnRespAddStreamingAccount!=null) OnRespAddStreamingAccount(resp);

        return resp;
    }
}