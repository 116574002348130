
import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import '../banner-list/banner-list.components'
import { Button, TextField, Box } from '@material-ui/core'
import { Send, Delete, Visibility, AttachMoney, Edit, FileCopy } from '@styled-icons/material';
import MuiAlert from '@material-ui/lab/Alert'
import ContentNavigation from '../../structure/content-navigation/content-navigation.component'
import Streamers from '../../services/api/streamers';
import { commService } from '../../services/api/list';
import GlobalState from '../../store/globalState'
import DeleteItemDialog from '../common/deleteItemDialog/deleteItemDialog.component.jsx';
import AddNewButton from '../common/add-new-button/add-new-button.component.jsx';
import UserUtils from '../../utils/userUtils'
import { withStyles } from '@material-ui/core/styles'
import { confAddItem, CommButtonSave, CommItemTextField, CommItemTextFieldInList, CommListGen, CommLinkButton, CommDeleteButtonLink, confListField, confList, CommList, CommItemField, CommItemOfItems, CommButtonLinkItem, ListFieldButtonLink } from '../common/com-List/comm-list.components'
import { fieldNameFunction, CommItemFieldLink, CommSimpleTextButton, strfieldvalue } from '../common/com'
import { myReactTaggedComponent, ReactComponentTag } from '../common/extComponents'
import { Link as RouterLink } from 'react-router-dom';
import { getStreamingScene } from '../../structure/streamingAccount/streamingAccount-toStream.components'
import { BrowserView, MobileView, isBrowser, isMobile } from '../../utils/platformsUtils';
import ComTooltip from '../../structure/common/comTooltip/comTooltip.jsx';
import CommHelpLink, { getDocUrl } from '../../structure/common/comHelpLink/comHelpLink.jsx'

import { LangStrs, Lang } from '../../utils/languageUtils'

const strs: LangStrs = new LangStrs(new Map(
    [
        ['Name', new Map(
            [
                [Lang.ES, 'Nombre'],
                [Lang.EN, 'Name']
            ])
        ],
        ['Title', new Map(
            [
                [Lang.ES, 'Configuración de los anuncios'],
                [Lang.EN, 'Advertisement settings']
            ])
        ],
        ['Resolution', new Map(
            [
                [Lang.ES, 'Resolución'],
                [Lang.EN, 'Resolution']
            ])
        ],
        ['Actions', new Map(
            [
                [Lang.ES, 'Acciones'],
                [Lang.EN, 'Actions']
            ])
        ],
        ['Save', new Map(
            [
                [Lang.ES, 'Guardar'],
                [Lang.EN, 'Save']
            ])
        ],
        ['Scenes', new Map(
            [
                [Lang.ES, 'Escenas'],
                [Lang.EN, 'Scenes']
            ])
        ],
        ['NewScene', new Map(
            [
                [Lang.ES, 'Crear nueva escena'],
                [Lang.EN, 'Create new scene']
            ])
        ],
        ['Select', new Map(
            [
                [Lang.ES, 'Seleccionar'],
                [Lang.EN, 'Select']
            ])
        ],
        ['WhatScene', new Map(
            [
                [Lang.ES, 'Usando una escena'],
                [Lang.EN, 'Using a scene']
            ])
        ],
        ['EditVideo', new Map(
            [
                [Lang.ES, 'Con nuestro editor de video'],
                [Lang.EN, 'With our video editor']
            ])
        ],
        ['UseDefault', new Map(
            [
                [Lang.ES, 'Utilizar la escena por defecto'],
                [Lang.EN, 'Use default scene']
            ])
        ],
        ['OrSelectCustom', new Map(
            [
                [Lang.ES, 'O selecciona una de tus escenas personalizadas'],
                [Lang.EN, 'Or select one of your costom scenes']
            ])
        ],
        ['SelectScene', new Map(
            [
                [Lang.ES, 'Selecciona una escena'],
                [Lang.EN, 'Select a scenes']
            ])
        ],
        ['CCSceneAction', new Map(
            [
                [Lang.ES, 'Crear / Ajustar Escena'],
                [Lang.EN, 'Create / Config Scene']
            ])
        ],
        ['CCScene', new Map(
            [
                [Lang.ES, 'crear tu escena personalizada'],
                [Lang.EN, 'create your custom scene']
            ])
        ],
        ['OrYouCan', new Map(
            [
                [Lang.ES, 'O puedes'],
                [Lang.EN, 'Or you can']
            ])
        ],
        ['WhyConfig', new Map(
            [
                [Lang.ES, "Ahora vamos a establecer dónde queremos que aparezcan los anuncios en este video/stream en concreto"],
                [Lang.EN, "Now we are going to set where we want the ads to appear in this specific video/stream"]
            ])
        ],
        ['WhyOurEditor', new Map(
            [
                [Lang.ES, "Usando nuestro editor, podrás configurar dónde poner los anuncios viendo tu video para una precisión máxima. También, si quieres, podrás establecer dónde poner cortes publicitarios"],
                [Lang.EN, "Using our editor, you will be able to configure where to place the ads while watching your video for maximum precision. Additionally, if you want, you can establish where to insert commercial breaks"]
            ])
        ],
        ['WhyUsingScene', new Map(
            [
                [Lang.ES, "Las escenas son configuraciones estáticas que puedes usar en uno o más videos"],
                [Lang.EN, "The scenes are static configurations that you can use in one or more videos"]
            ])
        ],
        ['WhyUsingDefScene', new Map(
            [
                [Lang.ES, "Se te va a generar una escena automáticamente para este video concreto"],
                [Lang.EN, "An automatic scene will be generated for this specific video"]
            ])
        ],
    ]
));

export class StreamSceneList extends React.Component {
    constructor(props) {
        super(props);

        this.conf = [
            new confListField(strs.get('Name'), CommItemTextField, { className: "list-field bi-name" }, "name").addField("tLabel", false),
            new confListField(strs.get('Resolution'), CommItemTextFieldInList, { className: "list-field bi-name", innerTextComp: ResolutionTextField  }, new fieldNameFunction(null, (value) => { let sv: [] = value.split("x"); console.log((sv.length == 2) + " " + sv[0] + " " + !isNaN(sv[0]) + " " + Number.isInteger(sv[1])); return sv.length == 2 && Number.isInteger(+sv[0]) && +sv[0] > 0 && +sv[1] > 0 && Number.isInteger(+sv[1]) }, (item) => { return item.resX + "x" + item.resY }, (value) => { return { resX: value.split("x")[0], resY: value.split("x")[1] } })).addField("tLabel", false),
            new confListField(strs.get('Actions'), CommItemOfItems).addField("Comp", [
                new confListField(strs.get("Save"), CommButtonSave),
                ListFieldButtonLink(Send, (item) => "/dashboard/streamScenes/" + item.id),
                new confListField(strs.get("Delete"), CommDeleteButtonLink, null, "name")
            ])
        ];
    }

    render = () => {
        return (
            <CommList title={<>{strs.get('Scenes')}<CommHelpLink infoUrl={getDocUrl("como-crear-una-escena-para-publicidad-programatica")} /></>} confComm={new confList(CommListGen, new commService("/streamers/:id/streamScene"), this.conf, new confAddItem(null, strs.get('NewScene'), { "name": "new Scene", "resX": 1920, "resY": 1080 }))} />
        );
    }
}

class ResolutionTextField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            toSave: false,
            invalidValue: false,
            vResX: this.props.item.resX,
            vResY: this.props.item.resY
        }
    }

    handleChange = (fName, event) => {
        const newValue = event.target.value;
        if (/^\d{0,4}$/.test(newValue)) {
            this.setState({ [fName]: newValue });
        }

        let resX = this.state.vResX;
        let resY = this.state.vResY;
        if (fName == "vResX")
            resX = newValue;
        else
            resY = newValue;

        let parent: COMMComp = this.props.parentComm;
        let valid = parent.DataChange(this, resX + "x" + resY);
    };

    inputFile = (fName) => <TextField className="form-input"
        valueField={fName}
        maxLength={4}
        style={{ width: "100px" }}
        onChange={(event) => this.handleChange(fName, event)}
        value={this.state[fName]}
        variant="outlined"
    />

    render = () => {
        let parent: COMMComp = this.props.parentComm;        

        const confFieldItem: confFieldItem = this.props.confFieldItem;
        const confField: confField = confFieldItem.confField;
        //if (this.state.toSave && !parent.newDataToSave(this.props.item)) this.setState({ toSave: false });

        let propsByItem: any = {};
        if (confField.tPropsByItem != undefined) propsByItem = confField.tPropsByItem(this.props.item);

        let parentDivStyle = { display: "flex", alignItems: "center"};
        if (isMobile) {
            parentDivStyle["margin-top"] = "-20px";
            parentDivStyle["padding"] = "0 0 0 0";
        } else {
            parentDivStyle["margin"] = "-47px";
            parentDivStyle["padding"] = "0px 40px";
        }

        return (
            <div style={parentDivStyle}>
                {this.inputFile("vResX")}
                <div style={{ margin: "0 10px", display: "flex", "align-items": "center", "justify-content": "center", height: "50px" }}>X</div>
                {this.inputFile("vResY")}
            </div>
        )
    }

    onDataChange = (e) => {
        let parent: COMMComp = this.props.parentComm;
        let valid = parent.DataChange(this, e.target.value);
        //this.setState({ toSave: valid, invalidValue: !valid });
    }
}

export class StreamSceneListSelector extends React.Component {
    constructor(props) {
        super(props);

        this.state = { noScenes: undefined };

        this.conf = [
            new confListField(strs.get('Name'), CommItemField, { className: "list-field bi-name" }, "name"),
            new confListField(strs.get('Select'), CommItemOfItems).addField("Comp", [
                new confListField(strs.get('Select'), CommSimpleTextButton, {}, strs.get('Select')).addField("OnClick", async (item) => this.props.OnSelect(item)).addField("Condition", (item) => this.props.selectToStream !== true),
                new confListField("LinkButton", CommLinkButton, null, (item) => { return { fieldName: getStreamingScene(`${item.key}_${this.props.streamingAccount}`) } }).addField("OnClick", async (item) => this.props.OnSelect(item)).addField("Condition", (item) => this.props.selectToStream === true).addField("tTitle", "COPY OBS LINK")
            ])
        ];
    }

    render = () => {
        return (
            <p>
                <p style={{ 'margin-bottom': '50px' }}>
                    <Box display="flex" justifyContent="space-between" mb={2}>
                        <h1 style={{ 'font-size': '160%' }}>
                            {strs.get('Title')}
                            {this.props.selectToStream !== true && <CommHelpLink infoUrl={getDocUrl("como-gestionar-una-campana-de-publicidad-programatica")} />}
                            {this.props.selectToStream === true && <CommHelpLink infoUrl={getDocUrl("como-anadir-publicidad-programatica-en-directos")} />}
                        </h1>                        
                    </Box>
                    {this.props.selectToStream !== true &&
                        <Box display="flex" justifyContent="space-between" mb={2}>
                            <Button variant="contained" size="large" color="primary" fullWidth onClick={async () => { this.props.OnQueryVideoEditor(); }} style={{ 'margin': '10px 0' }}>
                                {strs.get('EditVideo')}
                            </Button>
                            <ComTooltip text={strs.get('WhyOurEditor')} />
                        </Box>
                    }
                </p>
                {this.props.selectToStream !== true &&
                    <p>
                        <Box display="flex" justifyContent="space-between" mb={2}>
                            <h1 style={{ 'font-size': '160%' }}>{strs.get('WhatScene')}</h1>
                            <ComTooltip text={strs.get('WhyUsingScene')} />
                        </Box>
                        <Box display="flex" justifyContent="space-between" mb={2}>
                            <Button variant="contained" size="large" color="primary" fullWidth onClick={async () => { this.props.OnSelect(null); }}>
                                {strs.get('UseDefault')}
                            </Button>
                            <ComTooltip text={strs.get('WhyUsingDefScene')} />
                        </Box>
                    </p>
                }
                {this.state.noScenes !== true &&
                    <>                        
                        <p>
                        <h3 style={{ 'font-size': '140%' }}>{strs.get(this.props.selectToStream !== true ? 'OrSelectCustom' : 'SelectScene')}</h3>
                        <CommList title="" OnQuery={(t, data) => { data.length != undefined && data.length == 0 && this.setState({ noScenes: true }) }} confComm={new confList(CommListGen, new commService("/streamers/:id/streamSceneFilter", undefined, this.props.streamingAccount ? { streamingAccount: this.props.streamingAccount, filter: this.props.selectToStream ? "Streams" : "Videos" } : {}), this.conf, new confAddItem('/dashboard/streamScenes', strs.get('CCSceneAction')))} />
                        </p>
                    </>
                }
                {this.state.noScenes === true &&
                    <AddNewButton caption={strs.get('CCScene')} NoLink={false} routeTo='/dashboard/streamScenes' {...{ style: { width: '100%' } }}/>
                }
            </p>
        );
    }
}