
import React, { useState, useEffect, Fragment, useRef } from 'react';
import { strs as upfStrs } from '../../components/common/commonUpFile.jsx'
import './streams-grid.styles.scss'
import { Grid, CardContent, Card, Typography, CardActions, Button, Link, Box } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import ContentNavigation from '../../structure/content-navigation/content-navigation.component'
import { CommListSimpleTextButton, CommItemListAux, confAddItem, CommButtonSave, CommItemTextField, CommListGen, CommLinkButton, CommDeleteButtonLink, confListField, confList, CommList, CommItemField, CommItemOfItems, CommButtonLinkItem, CommItemListCountDown } from '../common/com-List/comm-list.components'
import { commService } from '../../services/api/list';
import { getServerUrl } from '../../services/base';
import './../../components/streamer/stream-card/stream-card.styles.scss';
import { ConfStreamingAccountListCommon } from './../streamingAccount/streamingAccount-toStream.components'
import { myReactTaggedStateComponent, InLineComponent, ConditionalComponent } from '../common/extComponents'
import { CommButton, fieldNameFunction, CommSimpleTextButton } from '../common/com'
import AddNewButton from '../common/add-new-button/add-new-button.component';
import { DurationTime } from '../../utils/timeUtils'
import { changeUrl, Urls, openTab } from '../../utils/routerUtils'

import ServicesBase from '../../services/base';
import UserUtils from '../../../src/utils/userUtils'
import { toMStr } from '../../utils/moneyUtils.jsx'
import { DownloadUrlVideo } from '../../utils/fileUtils'

import { Link as RouterLink } from 'react-router-dom';
import { isBrowser } from '../../utils/platformsUtils';

import { StreamingAccountToStream, SelStreamingAccountActionField } from '../streamingAccount/streamingAccount-toStream.components.jsx'
import ModalDialog from '../../components/common/modal/modal'
import { StreamSceneListSelector } from '../../structure/streamScene/streamScene-list.components'
import { GenerateVideoForm, LinksRender } from '../../structure/generatevideo-form/generateVideo-form.jsx'
import { LangStrs, Lang } from '../../utils/languageUtils'
import { VideoEditor } from '../../components/video/videoEditor.jsx'
import ComTooltip from '../../structure/common/comTooltip/comTooltip.jsx';
import LiveTvIcon from '@material-ui/icons/LiveTv';
import { IconFromSVG, CountDown } from '../../components/common/commonComponents.jsx'
import { CommSimpleButton } from '../../structure/common/com'
import VisibilityIcon from '@material-ui/icons/Visibility';
import ComAditionalInfo from '../../structure/common/comAditionalInfo/comAditionalInfo.jsx'
import CommHelpLink, { getDocUrl } from '../../structure/common/comHelpLink/comHelpLink.jsx'

const StreamIcon = IconFromSVG(<><g>
    <path d="M7.501,41.344c-2.929-4.254-4.652-9.396-4.652-14.953C2.849,11.817,14.661,0,29.236,0
		c14.576,0,26.388,11.817,26.388,26.391c0,5.554-1.725,10.699-4.653,14.953c1.8-3.412,2.824-7.294,2.824-11.422
		c0-13.559-10.99-24.556-24.559-24.556c-13.569,0-24.559,10.997-24.559,24.556C4.677,34.05,5.708,37.932,7.501,41.344z
		 M43.657,27.142c0-7.958-6.455-14.421-14.421-14.421c-7.965,0-14.421,6.463-14.421,14.421c0,2.385,0.631,4.594,1.652,6.572
		c-0.358-1.211-0.613-2.468-0.613-3.794c0-7.386,5.984-13.379,13.382-13.379s13.381,5.991,13.381,13.379
		c0,1.327-0.246,2.583-0.611,3.794C43.028,31.731,43.657,29.525,43.657,27.142z M29.236,22.763c-2.418,0-4.375,1.959-4.375,4.375
		c0,2.42,1.957,4.377,4.375,4.377s4.375-1.957,4.375-4.377C33.611,24.722,31.654,22.763,29.236,22.763z M19.029,58.473l5.195-24.8
		H34.25l5.199,24.8h-2.845l-0.867-4.17H22.746l-0.871,4.17H19.029z M25.292,42.136h7.892l-1.19-5.688H26.48L25.292,42.136z
		 M23.325,51.521h11.824l-1.383-6.602h-9.062L23.325,51.521z"/>
</g></>, "0 0 58.473 58.473");

const strs: LangStrs = new LangStrs(new Map(
    [
        ['AddAccount', new Map(
            [
                [Lang.ES, 'Agregar cuenta'],
                [Lang.EN, 'Add account']
            ])
        ],
        ['TSAccount', new Map(
            [
                [Lang.ES, 'Mis cuentas de streaming'],
                [Lang.EN, 'My streaming accounts']
            ])
        ],        
        ['TStreams', new Map(
            [
                [Lang.ES, 'Mis streams'],
                [Lang.EN, 'My streams']
            ])
        ],
        ['ToReview', new Map(
            [
                [Lang.ES, 'En cola para revisar'],
                [Lang.EN, 'In queue to review']
            ])
        ],
        ['LimitTime', new Map(
            [
                [Lang.ES, 'Limite para introducir el enlace '],
                [Lang.EN, 'Remaing time to submit link ']
            ])
        ],
        ['LimitTimeTip', new Map(
            [
                [Lang.ES, `Debes subir el video a tu plataforma de streaming o red social antes de que finalice el tiempo para que pueda ser validado.`],
                [Lang.EN, `You must upload the video to your streaming platform or social network before the time runs out for it to be validated.`]
            ])
        ],
        ['LastChangeTip', new Map(
            [
                [Lang.ES, `En breve se realizará una última verificación para confirmar si has subido el video a tu red social o plataforma de streaming, en caso de que no se pueda encontrar, se invalidará este video.`],
                [Lang.EN, `Shortly, a final check will be conducted to confirm if you have uploaded the video to your social network or streaming platform. If it cannot be found, this video will be invalidated.`]
            ])
        ],
        ['LastChange', new Map(
            [
                [Lang.ES, `A punto de ser invalidado`],
                [Lang.EN, `About to be invalidated`]
            ])
        ],
        ['VideoLink', new Map(
            [
                [Lang.ES, 'Enlace al video'],
                [Lang.EN, 'Link to video']
            ])
        ],
        ['Save', new Map(
            [
                [Lang.ES, 'Guardar'],
                [Lang.EN, 'Save']
            ])
        ],
        ['SelFullScreenAd', new Map(
            [
                [Lang.ES, `Presiona para seleccionar un anuncio de pantalla completa para emitir`],
                [Lang.EN, `Press to select a full-screen ad to broadcast`]
            ])
        ],
        ['writeToDesc', new Map(
            [
                [Lang.ES, `Ver texto a añadir en la descripción`],
                [Lang.EN, `See text to add in the description`]
            ])
        ],
        ['writeToDescOK', new Map(
            [
                [Lang.ES, `Ver texto para la descripción`],
                [Lang.EN, `See text for description`]
            ])
        ],
        ['writeToDescKO', new Map(
            [
                [Lang.ES, `Texto no añadido (Click para ver)`],
                [Lang.EN, `Text not added (Click to view)`]
            ])
        ],
        ['AddVerifySocialAccounts', new Map(
            [
                [Lang.ES, `Cómo añadir y verificar mis cuentas en redes sociales`],
                [Lang.EN, `How to add and verify my social media accounts`]
            ])
        ],
        ['AddProgrammaticAdsVideos', new Map(
            [
                [Lang.ES, `Cómo añadir publicidad programática en vídeos`],
                [Lang.EN, `How to add programmatic advertising in videos`]
            ])
        ],
        ['AddProgrammaticAdsLive', new Map(
            [
                [Lang.ES, `Cómo añadir publicidad programática en directos`],
                [Lang.EN, `How to add programmatic advertising in live streams`]
            ])
        ],
        ['CampaignTypes', new Map(
            [
                [Lang.ES, `Qué tipos de campaña puedo realizar`],
                [Lang.EN, `What types of campaigns can I run`]
            ])
        ],
    ]
));

const buttonStyles = () => ({
    root: {
      '& > *': {
        margin: "0.5em"
      },
    },
});

const ItemToReviewMSG = strs.get('ToReview');

export class ToCheckStreamsService extends commService {
    constructor(uri, component) {
        super(uri);
        this.cmp = component;
    }
    async Update(userId, item, loading = true) {
        let r = await super.Update(userId, item, loading);
        if (r.httpCode != 200) {
            item.msg = r.error.Error;
        } else {
            item.msg = ItemToReviewMSG;
            item.state = 0;
        }
        return r;
    }
};

export const confStreams = [
    new confListField(strs.get('VideoLink'), CommItemTextField, { className: "bi-name", noTable: true, style: { "margin-left": "10px" } }, new fieldNameFunction(null, (value) => { return true; }, (item) => { return item.url }, (value) => { return { url: value } })).addField("tLabel", true),
    new confListField(strs.get('Save'), CommButtonSave, { style: { "margin-top": "-30px", "margin-left":"15px" } })
];

let OnSelectFSAdv: any;

class StreamsGrid extends React.Component {
    videoSelect = {};

    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1,
            error: undefined,
            file: undefined
        }

        this.btnCtnrclasses = buttonStyles();

        this.options = {
            maxPageItems: 15
        }

        this.changePage = ((pageNum) => {
            this.setState({ currentPage: pageNum });
        }).bind(this);

        this.conf = [];

        OnSelectFSAdv = this.OnSelectAdToStream.bind(this);
    }

    async OnStreamingAccountSelection(selection) {
        if (selection.error != undefined) {
            this.setState({ error: upfStrs.get(selection.error) });
            return;
        }
        this.videoSelect = selection;
        this.setVideoInfoToGen();
        await this.setState({ selStreamingAccount: selection.streamingAccount.id, selectToStream: selection.file == undefined, file: selection.file });
        this.createVideoModal.setOpen(true);
    }

    OnSelectScene(selection) {
        if (!this.state.selectToStream) {
            this.setState({ selScene: selection == null ? -1 : selection.id });
            this.createVideoModal.setOpen(false);
            this.genVideoModal.setOpen(true);
        } else {
            this.createVideoModal.setOpen(false);
        }
    }

    async OnQueryVideoEditor() {
        this.videoEditorModal.setOpen(true);
        this.createVideoModal.setOpen(false);
    }

    async OnConfigDone(config) {
        //alert(JSON.stringify(config));
        this.videoEditorModal.setOpen(false);
        this.setState({ selScene: config });
        this.genVideoModal.setOpen(true);
    }

    async OnSelectAdToStream(stream) {
        await this.setState({ selStreamToAd: stream });
        this.genSelectAdModal.setOpen(true);
    }

    render = () => {
        let infoMsgs = [
            {
                str: "AddVerifySocialAccounts",
                link: "como-verificar-mis-cuentas-en-redes-sociales"
            },
            {
                str: "AddProgrammaticAdsVideos",
                link: "como-gestionar-una-campana-de-publicidad-programatica"
            },
            {
                str: "AddProgrammaticAdsLive",
                link: "como-anadir-publicidad-programatica-en-directos"
            },
            {
                str: "CampaignTypes",
                link: "que-tipos-de-campana-puedo-crear-2"
            },
        ]

        return (
            <React.Fragment>
                <Grid container direction="row" alignItems="left">
                    <Grid child xs={9}>
                        <h1 style={{ marginRight: "10px" }}>{strs.get('TSAccount')}<CommHelpLink infoUrl={getDocUrl("como-verificar-mis-cuentas-en-redes-sociales")} /></h1>
                    </Grid>
                    <Grid container item xs={3} aligment="center" alignItems="center" justify="center">
                        <InLineComponent newComp={myReactTaggedStateComponent(CommButton, function () {
                            this.tInnerLink =
                                <AddNewButton routeTo="/dashboard/streamingAccount/add" float={false} caption={strs.get('AddAccount')} />
                        })
                        } />
                    </Grid>
                </Grid>
                <StreamingAccountToStream title="" createVideoActionField={SelStreamingAccountActionField("", this.OnStreamingAccountSelection.bind(this)) } />
                <CommList ref={(m) => this.streamsList = m} title={strs.get('TStreams')} headers={false} ItemList={StreamStreamerCard} noItemsNoTile={true} /*itemFilter={(item) => item.state != 2 || item.remaingTime > 0 }*/ confComm={new confList(CommListGen, new ToCheckStreamsService("/streamers/:id/stream", this), confStreams)} />
                <ModalDialog ref={(m) => { this.createVideoModal = m; }} windowsSyle={true} btnNOVisible={false} btnOKVisible={false}>
                    <StreamSceneListSelector selectToStream={this.state.selectToStream} streamingAccount={this.state.selStreamingAccount} OnSelect={(item) => { this.OnSelectScene(item) }} OnQueryVideoEditor={this.OnQueryVideoEditor.bind(this)}/>
                </ModalDialog>
                <ModalDialog ref={(m) => { this.videoEditorModal = m; }} windowsSyle={true} btnNOVisible={false} btnOKVisible={false}>
                    <VideoEditor ref={(m) => { if (m != null) { this.videoEditor = m; m.setVideo(this.state.file); } }} OnSelect={(item) => { this.OnSelectScene(item) }} OnConfigDone={this.OnConfigDone.bind(this)}/>
                </ModalDialog>
                <ModalDialog ref={(m) => { this.genVideoModal = m; }} windowsSyle={true} btnNOVisible={false} btnOKVisible={false}>
                    <GenerateVideoForm ref={(m) => { this.genVideoForm = m; this.setVideoInfoToGen(); }} OnStreamsChanged={(state: number) => { state == 0 && this.genVideoModal.handleClose(); this.streamsList.QueryItems() } } noSelectVideo noCard scene={this.state.selScene} streamingAccount={this.state.selStreamingAccount}/>
                </ModalDialog>
                <ModalDialog ref={(m) => { this.genSelectAdModal = m; }} windowsSyle={true} btnNOVisible={false} btnOKVisible={false}>
                    <SelectFullScreen ref={(m) => { this.genSelectAdForm = m; }} stream={this.state.selStreamToAd}/>
                </ModalDialog>
                {this.state.error &&
                    <ModalDialog ref={(m) => { if(m) m.setOpen(true); }} windowsSyle={true} btnNOVisible={false} btnOKVisible={true} OnResult={() => this.setState({ error: undefined })}>
                        {this.state.error}
                    </ModalDialog>
                }
                {
                    infoMsgs.map((imsg,i) => <ComAditionalInfo
                        id={i}
                        message={<><b>{strs.get(imsg.str)}</b><CommHelpLink infoUrl={getDocUrl(imsg.link)} /></>}
                        position={{ vertical: 'bottom', horizontal: 'right' }}
                    />)
                }
            </React.Fragment>
        )
    }

    setVideoInfoToGen = () => {
        if (this.genVideoForm) this.genVideoForm.setVideo(this.videoSelect.file, this.videoSelect.videoInfo);
    }
}

export const StreamStreamerCard = (props) => {
    let fields = props.fields;

    let duration = "LIVE";
    if (props.item.endTime != null) {        
        duration = DurationTime(props.item.startTime, props.item.endTime);
    }

    const [iniTime, setiniTime] = useState(new Date());
    const [ini, setIni] = useState(0);

    const [lastState, setLastState] = useState(props.item.state);

    useEffect(() => {
        const interval = setInterval(() => {
            setIni(new Date() - iniTime);
        }, 1000);
        return () => clearInterval(interval);
    }, [ini]);

    let dt = (props.item.remaingTime * 1000) - ini;
    if (lastState != props.item.state) {
        setLastState(props.item.state);
        if (props.item.state == 0) {
            props.OnCorrectUrlSubmit && props.OnCorrectUrlSubmit();
        }
    }

    let linksModal;

    const inputLink =
        <Fragment>
            {
                props.item.state == 2 &&
                <Fragment>
                    <Grid item xs={12} justify="center" alignItems="center" {...{ style: { width: "100%", "padding": "0px 10px 10px 2px" } }}>
                        <font face="verdana" color="red" >
                            <div style={{ display: 'flex', alignItems: 'center', marginLeft:'5px' }}>
                                {dt > 0 && <>{strs.get('LimitTime')} {DurationTime(ini, props.item.remaingTime * 1000)}<ComTooltip text={strs.get('LimitTimeTip')} /></>}
                                {dt <= 0 && <>{strs.get('LastChange')}<ComTooltip text={strs.get('LastChangeTip')} /></>}
                            </div>
                        </font>
                    </Grid>
                    <Grid container direction="row" justify="center" alignItems="center" wrap style={{ width: "100%", "padding": "0px 10px 10px 2px" }} >
                        <Grid item xs={9} justify="center" alignItems="center">
                            {
                                CommItemListAux.rNewComp(fields[0], props)
                            }
                        </Grid>
                        <Grid item xs={3} justify="center" alignItems="center">
                            {
                                CommItemListAux.rNewComp(fields[1], props)
                            }
                        </Grid>
                    </Grid>
                </Fragment>
            }
            {
                (props.item.msg || props.item.state == 0 || props.item.state == 4) &&
                    <Grid item xs={12} justify="center" alignItems="center">
                        {
                        <font face="verdana" color="blue"> {(props.item.endTime != null && (props.item.state == 0 || props.item.state == 4)) ? ItemToReviewMSG : props.item.msg} </font>
                        }
                    </Grid>
            }
    </Fragment>

    if (props.noCard) return inputLink;
    else
        return (
            <Card className={'stream-card ' + "dashboard-card"}>
                <CardContent {...{ style: { "padding-bottom": 0 } }}>
                    <Link target="_blank" to={{ pathname: props.item.state == 2 ? DownloadUrlVideo(props.item.media) : props.item.url }} component={RouterLink}>
                        <div className="sc-figure">
                            <img {...{ style: { margin: "auto", display: "flex" } }} src={props.item.thumbnail == undefined ? "" : props.item.thumbnail.startsWith("http") ? props.item.thumbnail : getServerUrl() + "/streamers/" + UserUtils.GetUserData().id + "/media/" + props.item.thumbnail} alt="Stream image" title={props.item.title} onError={(e) => { e.target.src = '/StreamDefault.png'; }}></img>
                            {duration == "LIVE" && <div className="sc-duration" style={{ "background-color": "red", display: 'flex', alignItems: 'center' }}> <StreamIcon style={{ marginRight: '8px', width: '32px', height: '32px' }} /><div>{duration}</div></div>}
                            {duration != "LIVE" && <div className="sc-duration"> {duration}</div>}
                            <div className="sc-date">{new Date(new Date(props.item.startTime).getTime() != 0 ? props.item.startTime : props.item.createdAt).toLocaleDateString()}</div>
                            {props.item.amount!=-1 && <div className="sc-earnings">{toMStr(props.item.amount, true)}</div>}
                        </div>
                        <div className="sc-desc" {...{ style: { "padding-bottom": 0 } }}>
                            <Typography color="textSecondary" variant="h5" component="h1" {...{ style: { padding: 0 } }}>
                                {props.item.title}
                            </Typography>
                            <Typography component="h2">
                                {props.item.description}
                            </Typography>                            
                        </div>                        
                    </Link>
                    {duration == "LIVE" && <Link component="button" variant="body2" onClick={() => { OnSelectFSAdv(props.item.id) }}>
                            <div className="sc-duration" style={{ "background-color": "rgb(53,116,158)", display: 'flex', alignItems: 'center', padding: '5px', 'width': '100%' }}> <Typography style={{ color: 'white' }}>{strs.get('SelFullScreenAd')}</Typography></div>
                        </Link>
                    }
                </CardContent>
                <Grid container direction="column" justify="center" alignItems="center">
                    {
                        inputLink
                    }
                </Grid>
                {props.item.isVideo && <>
                    <Link component="button" variant="body2" onClick={() => { linksModal.setOpen(true); }} style={{ 'width': '100%', textDecoration: 'none' }}>
                        <div className="sc-duration" style={{ "background-color": props.item.descState == -1 ? "rgb(198, 0, 0)" : "#009CDD", display: 'flex', alignItems: 'center', padding: '5px', 'width': '100%' }}>
                            <Typography style={{ color: 'white', 'width': '100%' }}>{strs.get(['writeToDescKO', 'writeToDesc', 'writeToDescOK'][props.item.descState+1])}</Typography>
                        </div>
                    </Link>
                    <ModalDialog ref={(m) => { linksModal = m; }} windowsSyle={true} btnNOVisible={false} btnOKVisible={false}>
                        {LinksRender(props.item.links, props.item.streamingPlatform)}
                    </ModalDialog>
                    </>
                }
            </Card>
        )
}

export class SelectFullScreen extends React.Component {
    strs: LangStrs = new LangStrs(new Map(
        [
            ['Title', new Map([
                [Lang.ES, 'Emitir anuncio'],
                [Lang.EN, 'Broadcast Ad']
            ])],
            ['formInfo', new Map([
                [Lang.ES, 'El contenido que selecciones será emitido en tu directo lo más pronto que sea posible. El contenido se emitirá en pantalla completa y con sonido.'],
                [Lang.EN, 'The content you select will be broadcasted in your live stream as soon as possible. The content will be displayed in full screen with sound.']
            ])],
            ['TitleSpon', new Map([
                [Lang.ES, 'Segmentos patrocinados pendientes de ser emitidos'],
                [Lang.EN, 'Sponsored segments pending broadcast']
            ])],
            ['TitleAd', new Map([
                [Lang.ES, 'Anuncios de pantalla completa'],
                [Lang.EN, 'Full-screen ads']
            ])],
            ['Select', new Map([
                [Lang.ES, 'Seleccionar'],
                [Lang.EN, 'Select']
            ])],
            ['Name', new Map([
                [Lang.ES, 'Nombre'],
                [Lang.EN, 'Name']
            ])],
            ['Duration', new Map(
                [
                    [Lang.ES, `Duración`],
                    [Lang.EN, `Duration`]
                ])
            ],
            ['Valid', new Map([
                [Lang.ES, 'Válido hasta'],
                [Lang.EN, 'Valid until']
            ])],
            ['Profit', new Map([
                [Lang.ES, 'Beneficio'],
                [Lang.EN, 'Profit']
            ])],
            ['DisplayStart', new Map(
                [
                    [Lang.ES, `Se emitiría en`],
                    [Lang.EN, `Would be broadcasted on`]
                ])
            ],
            ['View', new Map(
                [
                    [Lang.ES, 'Ver'],
                    [Lang.EN, 'View']
                ])
            ],
            ['NoFullScreenAds', new Map(
                [
                    [Lang.ES, `Actualmente no tienes anuncios de pantalla completa para seleccionar`],
                    [Lang.EN, `You currently have no full-screen ads to select`]
                ])
            ],
        ]
    ));

    constructor(props) {
        super(props);

        this.state = { noScenes: undefined, isPageVisible: !document.hidden };

        this.updateDataIntervalId = null;

        const select = () => new confListField(this.strs.get('Select'), CommSimpleTextButton, {}, this.strs.get('Select')).addField("OnClick", async (item) => {
            let idAd = item.id;
            if (idAd == undefined) idAd = item.bannerConfig.banner.entity.id;
            let sb: ServicesBase = new ServicesBase(`#server#/streamers/${UserUtils.GetUserData().id}/stream/${this.props.stream}/selectFullScreen/select/${idAd}/${item.idConfig}`);
            const data: any = { time: item.bannerConfig ? item.bannerConfig.banner.entity.time : item.duration }
            let resp = await sb.ServiceRequest("", "POST", { 'Content-Type': 'application/json' }, true, data);

            this.updateData();
        });

        this.conf = [
            new confListField(this.strs.get('Name'), CommItemField, { className: "list-field bi-name" }, (i) => i.contract.sName),
            new confListField(this.strs.get('Duration'), CommItemField, { className: "list-field bi-name" }, (i) => Math.ceil(i.duration) + "''"),
            new confListField(this.strs.get('DisplayStart'), CommItemField, { remainingMsg: "remainingMsg", passDateMsg: "passDateMsg", shortText: true }, (i) => {
                return <Countdown dateTime={i.displayStart} minTime={30} />
            }),
            new confListField(this.strs.get('Select'), CommItemOfItems).addField("Comp", [
                select()
            ])
        ];
        this.confB = [
            new confListField(this.strs.get('Name'), CommItemField, { className: "list-field bi-name" }, (i) => i.bannerConfig.banner.entity.name),
            new confListField(this.strs.get('Duration'), CommItemField, { className: "list-field bi-name" }, (i) => Math.ceil(i.bannerConfig.banner.entity.time) + "''"),
            new confListField(this.strs.get('DisplayStart'), CommItemField, { remainingMsg: "remainingMsg", passDateMsg: "passDateMsg", shortText: true }, (i) => {
                return <Countdown dateTime={i.displayStart} minTime={30}/>
            }),
            new confListField(this.strs.get('Profit'), CommItemField, { className: "list-field bi-name" }, (i) => toMStr(i.amount)),
            new confListField(this.strs.get('View'), CommListSimpleTextButton, {}, this.strs.get('View')).addField("OnClick", async (item) => { openTab(item.bannerConfig.banner.entity.mediaUrl) }).addField("Icon", VisibilityIcon),
            new confListField(this.strs.get('Select'), CommItemOfItems).addField("Comp", [
                select()
            ])
        ];
    }

    componentDidMount() {        
        document.addEventListener('visibilitychange', this.handleVisibilityChange);

        if (this.state.isPageVisible) {
            this.startUpdateDataInterval();
        }
        this.getNextFS();
    }

    componentWillUnmount() {
        document.removeEventListener('visibilitychange', this.handleVisibilityChange);
        this.clearInterval();
    }

    handleVisibilityChange = () => {
        const isPageVisible = !document.hidden;
        this.setState({ isPageVisible });

        if (isPageVisible) {
            this.updateData();
            this.startUpdateDataInterval();
        } else {
            this.clearInterval();
        }
    };

    startUpdateDataInterval = () => {
        if (!this.updateDataIntervalId) {
            this.updateDataIntervalId = setInterval(this.updateData, 20000); // 20 segundos
        }
    };

    clearInterval = () => {
        if (this.updateDataIntervalId) {
            clearInterval(this.updateDataIntervalId);
            this.updateDataIntervalId = null;
        }
    };

    updateData = async () => {
        await this.getNextFS();
        this.selectSpon.QueryItems();
        this.selectBanner.QueryItems();
    };

    getNextFS = async () => {
        let sb: ServicesBase = new ServicesBase(`#server#/streamers/${UserUtils.GetUserData().id}/stream/${this.props.stream}/selectFullScreen/next`);
        let resp = await sb.ServiceRequest("", "GET", { 'Content-Type': 'application/json' }, true);
        if (resp.httpCode == 200) {
            await this.setState({ nextFS: resp.body });
        } else
            await this.setState({ nextFS: undefined });
    }

    checkAreThereFS = (strName, data) => {
        const r = data.length != undefined && data.length == 0;
        if (this.state[strName] != r) this.setState({ [strName]: r })
    }

    render = () => {
        return (
            <p>
                <p style={{ 'margin-bottom': '50px' }}>
                    <Box display="flex" justifyContent="space-between" mb={2}>
                        <h1 style={{ 'font-size': '160%' }}>
                            {this.strs.get('Title')}
                        </h1>
                        <ComTooltip text={this.strs.get('formInfo')} />
                    </Box>
                </p>
                {/*{this.state.nextFS && `FullScreen Ad IN ` && <Countdown dateTime={this.state.nextFS.displayAt} />}*/}
                <PendingsFullScreen key={"PendingsFullScreen"} pendings={this.state.nextFS ?? []}/>
                <p>                    
                    <CommList ref={(m) => { this.selectSpon = m; }} title={<h3 style={{ 'font-size': '60%', 'color': 'black' }}>{this.strs.get('TitleSpon')}</h3>} OnQuery={(t, data) => { this.checkAreThereFS("noSpons", data); }} confComm={new confList(CommListGen, new commService(`/streamers/:id/stream/${this.props.stream}/selectFullScreen/spon`), this.conf)} noItemsNoTile={true}/>
                </p>
                <p>
                    <CommList ref={(m) => { this.selectBanner = m; }} title={<h3 style={{ 'font-size': '60%', 'color': 'black' }}>{this.strs.get('TitleAd')}</h3>} OnQuery={(t, data) => { this.checkAreThereFS("noBanner", data); }} confComm={new confList(CommListGen, new commService(`/streamers/:id/stream/${this.props.stream}/selectFullScreen/banner`), this.confB)} noItemsNoTile={true} genItemKey={(i) => { return i.bannerConfig.banner.entity.id + "-" + i.displayStart + "-" + i.amount }} />
                </p>
                {
                    (this.state.noSpons && this.state.noBanner) && <p>
                        {this.strs.get('NoFullScreenAds')}
                    </p>
                }
            </p>
        );
    }
}

function Countdown({ dateTime, minTime }) {
    const strs: LangStrs = new LangStrs(new Map(
        [
            ['minAnd', new Map(
                [
                    [Lang.ES, ` minuto(s) y `],
                    [Lang.EN, ` minute(s) and `]
                ])
            ],
            ['secs', new Map(
                [
                    [Lang.ES, ` segundo(s)`],
                    [Lang.EN, ` second(s)`]
                ])
            ],
        ]
    ));

    const [timeLeft, setTimeLeft] = useState(0);

    // Lógica de inicialización
    useEffect(() => {
        // Convertir el tiempo de inicio a un objeto Date
        const eventTime = new Date(dateTime * 1000);

        // Obtener el tiempo actual
        const currentTime = new Date();

        // Calcular la diferencia en milisegundos
        const initialDifference = eventTime - currentTime;

        // Convertir la diferencia a segundos
        setTimeLeft(Math.floor(initialDifference / 1000));
    }, [dateTime]); // Escuchar cambios en dateTime

    // Lógica del temporizador
    useEffect(() => {
        // Si el tiempo ya ha pasado, no hacer nada
        if (timeLeft <= 0) return;

        // Establecer un intervalo para actualizar el tiempo restante cada segundo
        const interval = setInterval(() => {
            setTimeLeft(prevTime => prevTime - 1);
        }, 1000);

        // Limpiar el intervalo cuando el componente se desmonte
        return () => clearInterval(interval);
    }, [timeLeft]);

    let strLeft: string = "";
    let displayTime = timeLeft;
    if (displayTime < minTime) {
        strLeft = "< " + minTime + strs.get('secs');
    } else {
        const minutes = Math.floor(displayTime / 60);
        const seconds = displayTime % 60;
        strLeft = minutes > 0 ? (minutes + strs.get('minAnd')) : '' + seconds + strs.get('secs');
    }

    return (
        <div>
            {strLeft}
        </div>
    );
}

const PendingsFullScreen = ({ pendings }) => {
    const strs: LangStrs = new LangStrs(new Map(
        [
            ['waitToBroad', new Map(
                [
                    [Lang.ES, `Se emitirá {1} en {2}'', con una duración de {3}''.`],
                    [Lang.EN, `Will broadcast {1} in {2}'', with a duration of {3}''`]
                ])
            ],
            ['broadcasting', new Map(
                [
                    [Lang.ES, `Se está emitiendo {1}, terminará en {2}''`],
                    [Lang.EN, `Broadcasting {1}, will end in {2}''`]
                ])
            ],
        ]
    ));

    let [currentPendings, setCurrentPendings] = useState([]);
    const [_, forceUpdate] = useState(0);

    const getCurrentTime = () => Math.floor(Date.now() / 1000);

    useEffect(() => {
        let currents: [] = [];
        for (let i = 0; i < pendings.length; i++) {
            const cp = pendings[i];
            const ip = currentPendings.findIndex(p => p.id == cp.id);
            if (ip != -1) {
                currentPendings[ip] = cp;
                currents.push(cp.id);
            } else {
                currentPendings.push(cp);
            }
        }
        for (let i = 1; i < currentPendings.length; i++) {
            if (currents.find((c) => c == currentPendings[i].id) == undefined) {
                currentPendings[i].error = true;
            }
        }

        const funcInterval = () => {
            currentPendings = currentPendings.sort((a, b) => {
                if (a.state == b.state)
                    return a.displayAt - b.displayAt;
                else if (a.state == 1) return -1;
                else if (b.state == 1) return 1
                return a.displayAt - b.displayAt;
            });

            const toDel: [] = [];
            for (let i = 0; i < currentPendings.length; i++) {
                const currentTime = getCurrentTime();
                if (currentTime < currentPendings[i].displayAt || currentPendings[i].state == 0) {
                    let timeleft:any = Math.ceil(currentPendings[i].displayAt - currentTime);
                    if (currentPendings[i].state == 0 && timeleft < 30)
                        timeleft = "< 30";
                    currentPendings[i].msg = strs.get('waitToBroad', currentPendings[i].name, timeleft, Math.ceil(currentPendings[i].duration));
                    currentPendings[i].msgState = "WATTING";
                } else {
                    const timeLeft = currentPendings[i].displayAt - currentTime + Number(currentPendings[i].duration);
                    if (timeLeft > 0) {
                        //Caldria asegurarse que l'stat es assignat i no pendent, si es pendent i arribem aqui s'hauria de posar que no sa pogut comunicar encara a l'stream
                        currentPendings[i].msg = <b>{strs.get('broadcasting', currentPendings[i].name, Math.ceil(timeLeft))}</b>
                        currentPendings[i].msgState = "BROADCASTING";
                    }else
                        toDel.push(i);
                }
            }
            setCurrentPendings(currentPendings.filter((p, i) => toDel.find((a, b) => b == i) == undefined));
            forceUpdate(n => n + 1);
        }

        const interval = setInterval(() => {            
            funcInterval();
        }, 1000);
        funcInterval();

        return () => clearInterval(interval);
    }, [pendings]);
    
    return (
        <div>
            {currentPendings
                .map(pending => {
                    return <Card
                        style={{
                            margin: '10px',
                            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                            backgroundColor: pending.msgState == 'WATTING' ? '#009CDD' : '#66CDAA'
                        }}
                    >
                        <CardContent style={{ padding: '10px 20px' }}>
                            <Typography
                                variant="body2"
                                color="textPrimary"
                                component="p"
                                style={{ fontWeight: '500', color: 'white' }}
                            >
                                {pending.msg}
                            </Typography>
                        </CardContent>
                    </Card>
                })}
        </div>
    );
}

export default withStyles(buttonStyles)(StreamsGrid);