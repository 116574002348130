import React from 'react';
import './comm.styles.scss'
import { Link, TextField, Button, Checkbox } from '@material-ui/core'
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Link as RouterLink } from 'react-router-dom';
import { commService } from '../../../services/api/list';
import UserUtils from '../../../utils/userUtils'
import { convertUSDToCurrency } from '../../../utils/moneyUtils'
import { isBrowser, isMobile, MobileView } from '../../../utils/platformsUtils';
import { changeUrl } from '../../../utils/routerUtils'
import { LangStrs, Lang } from '../../../utils/languageUtils'

import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import moment from 'moment';
import { CountDown, standarValueBroadcastTime, InputSelectVideo } from '../../../components/common/commonComponents.jsx'
import { FileButton } from '../../../components/common/commonUpFile.jsx'
import ComTooltip from '../comTooltip/comTooltip.jsx';
import CommHelpLink from '../comHelpLink/comHelpLink.jsx'
import { NotificationBadget } from '../../../components/common/commonComponents'

export let strs: LangStrs = new LangStrs(new Map(
    [
        ['ERROR_ALREADY_EXISTS', new Map(
            [
                [Lang.ES, 'Ya ha sido registrado'],
                [Lang.EN, 'Already registered']
            ])
        ],
        ['NOTSAVED', new Map(
            [
                [Lang.ES, 'no guardado'],
                [Lang.EN, 'not saved']
            ])
        ],
        ['FRequired', new Map(
            [
                [Lang.ES, 'campo requerido'],
                [Lang.EN, 'field required']
            ])
        ],
        ['EIBAN', new Map(
            [
                [Lang.ES, 'IBAN incorrecto'],
                [Lang.EN, 'Incorrect IBAN']
            ])
        ],
        ['ENUM', new Map(
            [
                [Lang.ES, "El valor tiene que ser un numero"],
                [Lang.EN, "The value has to be a number"]
            ])
        ],
        ['ERANK', new Map(
            [
                [Lang.ES, "El numero debe ser entre {1} y {2}"],
                [Lang.EN, "The number has to be between {1} and {2}"]
            ])
        ],
        ['EGR', new Map(
            [
                [Lang.ES, 'El numero tiene que ser mayor o igual a {1}'],
                [Lang.EN, "The number has to be greater or equal than {1}"]
            ])
        ],
        ['ELOW', new Map(
            [
                [Lang.ES, "El numero debe ser menor o igual a {1}"],
                [Lang.EN, "The number has to be lower or equal than {1}"]
            ])
        ],
        ['EADDRESS', new Map(
            [
                [Lang.ES, 'Dirección incorrecta'],
                [Lang.EN, "Incorrect address"]
            ])
        ]
    ]
));

export class COMMComp extends React.Component {
    confComm: confCOM;
    confFieldItems: confFieldItem[] = [];

    constructor(props) {
        super(props);
        this.confComm = this.props.confComm;
        this.Owner = this.props.Owner;

        this.state = {
            dataSave: false,
            invalidData: [] = [],
        }
    }

    externalDataChange = () => {
        this.setState({ dataSave: true });
    }

    getItem() {
        return this.state.item;
    }

    getTemporalItem() {
        let item = this.getItem();
        if (item == undefined) return undefined;

        return temporalItem(item);
    }

    isNewDataInvalid() {
        if (this.props.GeneralValidation != undefined && !this.props.GeneralValidation(this.getItem())) return true;
        //if (this.props.OnFieldChangeGeneralValidation != undefined && !this.props.OnFieldChangeGeneralValidation(this.getItem())) return true;

        for (let i = 0; i < this.confFieldItems.length; i++) {
            if (this.confFieldItems[i].invalidData) {
                return true;
            }
        }
        return false;
    }

    DataChange = (comp, newValue) => {
        const confFieldItem: confFieldItem = comp.props.confFieldItem;
        let props = comp.props;

        //confFieldItem.value = newValue;
        confFieldItem.newData = true;

        addNewData(props.item, strvalue(props.confFieldItem.confField, newValue));
        //console.log(props.item.newData);

        confFieldItem.invalidData = !props.confFieldItem.confField.checkvalue(newValue);

        if (!confFieldItem.invalidData && this.props.OnFieldChangeGeneralValidation != undefined && this.getItem() != undefined) {
            addNewData(props.item, strvalue(props.confFieldItem.confField, newValue));
            confFieldItem.invalidData = !this.props.OnFieldChangeGeneralValidation(this.getItem());
        }

        addNewData(props.item, { invalidData: confFieldItem.invalidData });

        //let iInvalid:number = this.state.invalidData.indexOf(confFieldItem);
        //if (iInvalid == -1) {
        //    if (confFieldItem.invalidData)
        //        this.state.invalidData.push(confFieldItem); //Ala sense setState, xk es referencia (?)            
        //} else if (!confFieldItem.invalidData)
        //    this.state.invalidData = this.state.invalidData.filter(id => id != confFieldItem);

        this.setState({ dataSave: !confFieldItem.invalidData, invalidData: this.state.invalidData });

        this.getconfFieldItem(confFieldItem.confField, confFieldItem.item).invalidData = confFieldItem.invalidData;

        this.iterateConfFieldItemsCondItem(props.item, (confFieldItem: confFieldItem) => {
            confFieldItem.component?.OnChangeItem && confFieldItem.component.OnChangeItem();
        });

        if (confFieldItem.invalidData) {
            return false;
        }

        if (this.props.OnDataChange != undefined) this.props.OnDataChange(comp, newValue);
        return true;
    }

    creatingItem = () => { return false; }

    //isArbitraryItem : Es per si vull fer un update sense tenir en compte els valors del formulari
    UpdateData = async (item, isArbitraryItem = false) => {
        let serviceResponse: Response;

        const formData = new FormData();
        let sendFormData: bool = false;
        if (item.newData != undefined) {
            for (let prop in item.newData) {
                if (item.newData[prop]!=undefined && item.newData[prop].file != undefined) {
                    sendFormData = true;
                    formData.append(prop, item.newData[prop].file);
                } else
                    formData.append(prop, item.newData[prop]);
            }
        }

        this.props.OnChangeDataBeforeUpdate && this.props.OnChangeDataBeforeUpdate(item);

        if (item.id == undefined) { // Estoy haciendo un new
            serviceResponse = await this.confComm.service.Add(UserUtils.GetUserData().id, sendFormData ? formData : temporalItem(item));
            if (serviceResponse.httpCode < 400) {
                if (this.props.noredirect!==true && this.props.RedirectOnNew == undefined && this.props.Owner != undefined) this.props.Owner.props.history.goBack();
                else if (this.props.RedirectOnNew != undefined) {
                    let nUrl: string = this.props.RedirectOnNew;
                    if (nUrl.indexOf(":new") != -1) nUrl = nUrl.replace(":new", serviceResponse.body.id);
                    changeUrl(nUrl);
                } else
                    this.props.OnNewItem && this.props.OnNewItem(serviceResponse.body);
            } else {
                let error = serviceResponse.error;
                if (error instanceof Object && error.field != undefined) {
                    this.iterateConfFieldItemsCondItem(item, (confFieldItem: confFieldItem) => {
                        if (confFieldItem.confField.getfieldName() == serviceResponse.error.field) {
                            confFieldItem.invalidData = true;
                            confFieldItem.confField.tInvalidInfo = strs.get('ERROR_' + error.errorCode);
                            this.setState({ dataSave: true }); // solo para hacer los renders i que vean que newData esta undefined
                        }
                    });
                }
            }
        } else
            serviceResponse = await this.confComm.service.Update(UserUtils.GetUserData().id, item);

        if (serviceResponse.httpCode < 400 || serviceResponse.body instanceof Array) { //instanceof Array -> cuando obtenemos error pero un nuevo valor para el item, o se soobreescribe update y alli metemos el ultimo valor valido
            if (isArbitraryItem) //Si es una llista no te sentit, es per actualitza el formulari amb els cambis arbitraris que he fet, necesito el if, xk si es una llista this.getItem no te sentit
                item = this.getItem();

            Object.assign(item, serviceResponse.body[0]);
            if (item.newData) {
                if (this.defaultData) {
                    item.newData = JSON.parse(JSON.stringify(this.defaultData));
                    this.isNewDataInvalid();
                }else delete item.newData;
            }
            this.confFieldItemClean(item);
            this.setState({ dataSave: true }); // solo para hacer los renders i que vean que newData esta undefined
            if (this.props.OnSave != undefined) this.props.OnSave(item);
        } else if (serviceResponse.error) {
            this.props.OnError && this.props.OnError(serviceResponse.error);
        }
    }

    tryUpdateData(item = null) {
        if (this.newDataToSave(item)) this.UpdateData(item);
    }

    newDataToSave(item = null) {
        if (item == null && this) item = this.getItem();
        return item.newData != undefined && item.newData.invalidData == false;
    }

    callOnChangeCallback() {
        if (this.props.OnChange != undefined) {
            this.props.OnChange(this);
        }
    }

    OnQuery(data) {
        if (this.props.OnQuery != undefined) {
            this.props.OnQuery(this, data);
        }
    }

    render() {
        this.callOnChangeCallback();

        return false;
    }

    getconfFieldItem(confField, Item) {
        for (let i = 0; i < this.confFieldItems.length; i++) {
            if (this.confFieldItems[i].confField == confField && this.confFieldItems[i].item == Item) return this.confFieldItems[i];
        }
        return null;
    }

    newconfFieldItem(confField: confField, Item) {
        let c = confField.Condition == undefined || confField.Condition(Item);
        let n = this.getconfFieldItem(confField, Item);

        //if (confField.disabled != undefined && confField.disabled(Item)) { // aixo no te molt de sentit xk estas canviat el disabled de tots els elements si un item es disabled
        //    if (confField.fieldprops == null) confField.fieldprops = {};
        //    confField.fieldprops.disabled = true;
        //}

        if (confField.itemprops != undefined) {
            if (confField.fieldprops == null) confField.fieldprops = {};
            confField.fieldprops = { ...confField.fieldprops, ...confField.itemprops(Item) };
        }

        if (n == null) {
            if (c) {
                n = new confFieldItem(confField, Item);
                this.confFieldItems.push(n);
            }
        } else if (!c) {
            this.confFieldItems = this.confFieldItems.filter(cf => cf != n);
            return null;
        }
        return n;
    }

    iterateConfFieldItems(func, funccond = null) {
        for (let i = 0; i < this.confFieldItems.length; i++) {
            if (funccond == null || funccond(this.confFieldItems[i])) func(this.confFieldItems[i]);
        }
    }

    iterateConfFieldItemsCondItem(item, func) {
        this.iterateConfFieldItems(func, (cfi) => cfi.item == item);
    }

    confFieldItemClean(item) {
        this.iterateConfFieldItemsCondItem(item, (conFieldItem: confFieldItem) => {
            conFieldItem.invalidData = false;
            conFieldItem.newData = false;
        });
    }

    confFieldAllItemsValidAndEdited(item) {
        this.iterateConfFieldItems((conFieldItem: confFieldItem) => {
            if (conFieldItem.item == item || conFieldItem.invalidData || conFieldItem.newData) {
                conFieldItem.invalidData = false;
                conFieldItem.newData = true;
            }
        });
    }

    confFieldAllItemsClean(item) {
        this.iterateConfFieldItems((conFieldItem: confFieldItem) => {
            if (conFieldItem.item == item || conFieldItem.invalidData || conFieldItem.newData) {
                conFieldItem.invalidData = false;
                conFieldItem.newData = false;
            }
        });
    }

    SomeItemToSave() {
        let r1 = false;
        let r2 = true;
        this.iterateConfFieldItems((conFieldItem: confFieldItem) => {
            r1 |= conFieldItem.newData;
            r2 &= !conFieldItem.isInvalidData();
        });
        return (r1 && r2) == true;
    }

    addNewValidatedData(item, data) { // Este hace que todo el item sea valido
        addNewData(item, data);
        this.confFieldAllItemsValidAndEdited(item);
    }

    addData(data) {
        addNewData(this.getItem(), data);
        this.setState({ dataSave: true });
    }

    setDefaultData(data) {        
        if (this.state.defaultData == undefined) {
            let defaultData = {};
            if (data) {
                let defaultData = JSON.parse(JSON.stringify(data));
                if (defaultData.invalidData != undefined) delete defaultData.invalidData;
            }
            this.setState({ defaultData: defaultData });
        }
        return true;
    }
}

//export const fieldComponent = (Comp) => { //El componente debe hacer this.update() en el render
//    if (Comp.isFieldComponent == undefined) {
//        Comp.isFieldComponent = true;
//        Comp.OrgcomponentDidMount = Comp.prototype.componentDidMount;
//        Comp.prototype.componentDidMount = function () {
//            let confFieldItem: confFieldItem = this.props.confFieldItem;
//            confFieldItem.comp.push(this);
//            Comp.OrgcomponentDidMount();
//        }
//    }
//    return Comp;
//}

export class confCOM {
    service: commService;
    fields: confListField[];

    constructor(listComponentBase, service: commListService, fields: confField[]) {
        this.listComponentBase = listComponentBase;
        this.service = service;
        this.fields = fields;
    }
}

export const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const checkMailField = (n, cf) => {
    if (n == undefined || n == "") {
        cf.tInvalidInfo = n == undefined ? "" : strs.get('FRequired');
        return false;
    }
    if (!validateEmail(n)) {
        cf.tInvalidInfo = strs.get('EADDRESS');
        return false;
    }
    return true;
}

function smellsLikeIban(str) {
    return /^([A-Z]{2}[ \-]?[0-9]{2})(?=(?:[ \-]?[A-Z0-9]){9,30}$)((?:[ \-]?[A-Z0-9]{3,5}){2,7})([ \-]?[A-Z0-9]{1,3})?$/.test(str);
}

function validateIBAN(iban) {
    const ibanStripped = iban.replace(/[^A-Z0-9]+/gi, '') //keep numbers and letters only
        .toUpperCase(); //calculation expects upper-case
    const m = ibanStripped.match(/^([A-Z]{2})([0-9]{2})([A-Z0-9]{9,30})$/);
    if (!m) return false;

    const numbericed = (m[3] + m[1] + m[2]).replace(/[A-Z]/g, function (ch) {
        //replace upper-case characters by numbers 10 to 35
        return (ch.charCodeAt(0) - 55);
    });
    //The resulting number would be to long for javascript to handle without loosing precision.
    //So the trick is to chop the string up in smaller parts.
    const mod97 = numbericed.match(/\d{1,7}/g)
        .reduce(function (total, curr) { return Number(total + curr) % 97 }, '');

    return (mod97 === 1);
};

export const checkIBANField = (n, cf) => {
    if (n == undefined || n == "") {
        cf.tInvalidInfo = n == undefined ? "" : strs.get('FRequired');
        return false;
    }
    if (!smellsLikeIban(n) || !validateIBAN(n)) {
        cf.tInvalidInfo = strs.get('EIBAN');
        return false;
    }
    return true;
}

export const fnfRequireText2 = (clf: confField, name, funcIn = null, funcOut = null) => { //Hace lo mismo que el sin 2, pero creo que lo amplicare
    clf.fieldName = new fieldNameFunction(name, (n, cf) => {
        if (n == undefined || n == "") {
            cf.tInvalidInfo = n == undefined ? "" : strs.get('FRequired');
            return false;
        }
        return true;
    }, funcIn, funcOut);
    return clf;
}
export const fnfRequireText = (name, funcIn = null, funcOut = null) => new fieldNameFunction(name, (n, cf) => {
    if (n !== 0 && (n === undefined || n == "")) {
        cf.tInvalidInfo = n == undefined ? "" : strs.get('FRequired');
        return false;
    }
    return true;
}, funcIn, funcOut);

export const fnfRequireTextAndCheck = (name, funcCheck, funcIn = null, funcOut = null) => new fieldNameFunction(name, (n, cf) => {
    if (n !== 0 && (n === undefined || n == "")) {
        cf.tInvalidInfo = n == undefined ? "" : strs.get('FRequired');
        return false;
    }
    return funcCheck == null || funcCheck(n);
}, funcIn, funcOut);

export const fnfRequireNumber = (name, min = undefined, max = undefined, float = true, funcIn = null, funcOut = null) => new fieldNameFunction(name, (n, cf) => {
    if ((n == undefined || n == "") && n != "0") {
        cf.tInvalidInfo = n == undefined ? "" : strs.get('FRequired');
        return false;
    } else if (isNaN(n)) {
        cf.tInvalidInfo = strs.get('ENUM');
        return false;
    } else if ((min != undefined && n < min) || (max != undefined && n > max)) {
        if (min != undefined && max != undefined) cf.tInvalidInfo = strs.get('ERANK', min, max);
        else if (min != undefined) cf.tInvalidInfo = strs.get('EGR', min);
        else if (max != undefined) cf.tInvalidInfo = strs.get('ELOW', max);
        return false;
    }
    return true;
}, funcIn, funcOut);

export class fieldNameInOut {
    constructor(nameIn: string, nameOut: string) {
        this.nameIn = nameIn;
        this.nameOut = nameOut;
    }
}

export class fieldNameFunction {
    constructor(fieldName, funcValidation = null, funcIN = null, funcOUT = null) {
        this.fieldName = fieldName;
        this.funcIN = funcIN;
        this.funcOUT = funcOUT;
        this.funcValidation = funcValidation;
    }

    strfieldname(IN: bool) {
        if (this.fieldName instanceof fieldNameInOut) {
            let fn: fieldNameInOut = this.fieldName;
            return IN ? fn.nameIn : fn.nameOut;
        }
        return this.fieldName;
    }
}

export class confGroup {
    constructor(title: string, column: number, Fields: confField[]) {
        this.title = title;
        this.column = column;
        this.Fields = Fields;
    }
}

export class confField {
    constructor(name, field, fieldprops, fieldName, defVal = null) {
        this.name = name;
        this.field = field;
        this.fieldprops = fieldprops;
        this.fieldName = fieldName;
        this.defVal = defVal;
    }

    addField(name, value) {
        this[name] = value;
        return this;
    }

    checkvalue(value) {
        if (this.fieldName instanceof fieldNameFunction) {
            let fnf: fieldNameFunction = this.fieldName;
            if (fnf.funcValidation != null) {
                return fnf.funcValidation(value, this);
            }
        }

        return true;
    }

    getfieldName(IN: bool) {
        if (this.fieldName instanceof fieldNameFunction) {
            let fnf: fieldNameFunction = this.fieldName;
            return fnf.strfieldname(IN);
        }

        return this.fieldName;
    }

    static concatProps(props) {
        const confField: confField = props.confFieldItem?.confField;
        if (confField != null) props = { ...props, ...confField.fieldprops };
        return props;
    }
}

export class confFieldItem {
    component: Component;
    confField: confField;

    constructor(confField, item) {
        this.confField = confField;
        this.item = item;

        this.newData = false;

        if (confField.defVal != undefined && this.item[this.getfieldName()] == undefined) {
            addNewData(item, strvalue(confField, confField.defVal));
        }

        this.invalidData = this.isInvalidData();
    }

    getfieldName() {
        return this.confField.getfieldName();
    }

    isInvalidData() {
        let val = strfieldvalue(this, this.item);
        this.invalidData |= !this.confField.checkvalue(val); //Pueder haber sido invalidado por un validador general
        return this.invalidData;
    }
}

const initNewData = (item) => {
    if (item.newData == undefined) item.newData = { invalidData: false };
}

export const addNewData = (item, data) => {
    initNewData(item);
    Object.assign(item.newData, data);
}

export const temporalItem = (item) => {
    let i = {};
    Object.assign(i, item);
    if (item.newData != undefined) {
        Object.assign(i, item.newData);
    }
    return i;
}

export const invalidItem = (item) => {
    return item.newData == undefined || item.newData.invalidData;
}

export const strfieldvalue = (confFieldItem, item) => {
    item = temporalItem(item);

    let func = null;
    const confField: confField = confFieldItem.confField;
    if (confField.fieldName instanceof fieldNameFunction) {
        let fnf: fieldNameFunction = confField.fieldName;
        if (fnf.fieldName != null) func = () => { return item[fnf.strfieldname(false)] != undefined ? item[fnf.strfieldname(false)] : item[fnf.strfieldname(true)]; };
        else func = fnf.funcIN;
    } else if (typeof confField.fieldName === 'function') {
        func = confField.fieldName;
        return confField.fieldName(item);
    }

    if (func != null) return func(item);
    if (confField.fieldName != null) return item[confField.fieldName];
    return "";
}

export const strfieldname = (props, IN: bool) => {
    const confField: confField = props.confFieldItem.confField;
    if (confField.fieldName instanceof fieldNameFunction) {
        let fnf: fieldNameFunction = confField.fieldName;
        return fnf.strfieldname(IN);
    }
    return confField.fieldName;
}

export const strvalue = (confField: confField, value) => {
    if (confField.fieldName instanceof fieldNameFunction) {
        let fnf: fieldNameFunction = confField.fieldName;
        if (fnf.funcOUT != null) return fnf.funcOUT(value);
        return { [confField.getfieldName(false)]: value };
    }

    return { [confField.getfieldName(false)]: value };
}

export class CommItemFieldLink extends React.Component {
    constructor(props) {
        super(props);

        this.strfield = (item) => {
            const confField: confField = this.props.confFieldItem.confField;

            if (typeof confField.fieldName === 'function') {
                return confField.fieldName(item);
            }
            if (confField.fieldName != null) return { to: item[confField.fieldName], fieldName: item[confField.fieldName] };
            return { to: "??", fieldName: "??" };
        }
    }

    update = () => false; // Sobreescribo esta funcion en un componente que herede, ya que si los tags dependen del estado, no se actualizan con el constructor, se deberian setear desde esta funcion

    render = () => {
        this.update();
        return (
            <Link {...this.tNoRealLink === true ? {} : { to: this.strfield(this.props.item).to, component: RouterLink }}>
                { (this.tInnerLink != undefined ? typeof this.tInnerLink === 'function' ? this.tInnerLink(this.props.item) : this.tInnerLink : undefined) || this.strfield(this.props.item).fieldName}
            </Link>
        )
    }
}

const getValueOrFunction = (c, item) => typeof c == "function" ? c(item) : c;

export class CommSimpleTextButton extends React.Component {
    render() {
        const confField: confField = this.props.confFieldItem.confField;
        const propBackgroundColor = getValueOrFunction(this.props.confFieldItem.confField.color, this.props.item);
        const disabled = getValueOrFunction(confField.fieldprops.disabled, this.props.item) || false;
        const buttonStyle = this.props.confFieldItem.confField.color ? { backgroundColor: propBackgroundColor, color: 'white' } : {};
        const OnClick = confField.OnClick || confField.fieldprops.OnClick;
        const Icon = confField.Icon == undefined ? undefined : typeof confField.Icon == "function" ? confField.Icon(this.props.item) : confField.Icon;

        return (
            <Button disabled={disabled} color={"primary"} style={buttonStyle} variant="contained" className="list-button" onClick={(e) => { OnClick && OnClick(this.props.item) }}>
                <>
                    {confField.Icon && <Icon style={{ marginRight: "5px" }} />}
                    {confField.fieldName}
                </>
                {confField.NotificationBadget && <NotificationBadget {...confField.NotificationBadget(this.props.item)} color="secondary" style={{ top: -15, right: -15 }} />}
            </Button>
        )
    }
}

export class CommCheckBox extends React.Component {
    render = () => {
        const confField: confField = this.props.confFieldItem.confField;
        let value = strfieldvalue(this.props.confFieldItem, this.props.item);
        return (
            <FormControlLabel {...this.props}
                control={
                    <Checkbox
                        checked={value}
                        onChange={this.handleChange}
                        color="primary"
                        {...(this.props.confFieldItem.confField.fieldprops.disabled || (this.props.confFieldItem.confField.disabled && this.props.confFieldItem.confField.disabled(this.props.item))) ? { disabled: true } : {}}
                    />
                }
                label={confField.name}
            />
        )
    }
    handleChange = (event) => {
        let parent: COMMComp = this.props.parentComm;

        parent.DataChange(this, event.target.checked);
    };
}

export class CommButton extends CommItemFieldLink {
    constructor(props, update = null) {
        super(props);
        this.tTableCell = false;
        this.tNoRealLink = true;
        if (update != null) this.update = update;
    }
}

export const CommSimpleButton = (props) => {
    let style = Object.assign({}, { textAlign: 'center' }, props.style);
    return (
        <Button {...props} style={style}>
            {props.children}
        </Button>
    );
}

export class CommTextField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            toSave: false,
            invalidValue: false
        }

        this.debounceTimeout = null;
    }

    render = () => {
        let parent: COMMComp = this.props.parentComm;
        const confFieldItem: confFieldItem = this.props.confFieldItem;
        const confField: confField = confFieldItem.confField;
        //if (this.state.toSave && !parent.newDataToSave(this.props.item)) this.setState({ toSave: false });

        let propsByItem: any = {};
        if (confField.tPropsByItem != undefined) propsByItem = confField.tPropsByItem(this.props.item);

        if (confField.fieldprops && confField.fieldprops.type == "date") {
            this.isDateField = true;
        }

        let value = strfieldvalue(this.props.confFieldItem, this.props.item);
        if (value != "0" && !value) value = "";

        if (this.isDateField && value) {
            value = new Date(value).toISOString().slice(0, 10);
        }

        let InputProps = {
            style: {
                backgroundColor: 'white',
            },
        };
        if (confField.fieldprops.endAdornment) {
            InputProps.endAdornment = <InputAdornment position="start">{confField.fieldprops.endAdornment}</InputAdornment>;
        }
        if (confField.fieldprops.readOnly) {
            InputProps.readOnly = true;
        }

        const shouldRenderTooltip = typeof confField.tDangerouslyInfo !== 'undefined';

        let helperText = confFieldItem.isInvalidData() ? (confField.tInvalidInfo != undefined ? confField.tInvalidInfo : "") : (confFieldItem.newData && !parent.creatingItem()) ? strs.get('NOTSAVED') : " ";

        if (confField.fieldprops.endAdornment && !isNaN(value)) {
            let mChange = convertUSDToCurrency(value, false);
            helperText = <>{mChange && <strong>~ {mChange}</strong>}{!(!helperText || !helperText.trim()) && <>{mChange && " - "}{helperText}</>}</>;
        }

        const TextFiledRend = () => {
            return (<TextField className="form-input"
                InputLabelProps={{ shrink: true }}
                {...propsByItem}
                {...confField.fieldprops}
                {...confField.tfullWidth !== false && { fullWidth: true }}
                {...confField.rows != undefined && { multiline: true, rows: confField.rows, rowsMax: 15 }}
                {...confFieldItem.isInvalidData() && { error: true }}
                id="name-input" label={confField.tLabel !== false ? confField.name : ""}
                helperText={helperText}
                variant="outlined"
                value={value}
                onChange={this.onDataChange}
                InputProps={InputProps}
                onBlur={this.handleBlur}
            />);
        }

        if (!shouldRenderTooltip) {
            return TextFiledRend();
        }

        return (
            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                <div style={{ flex: 1, marginRight: '0px' }}>
                    {TextFiledRend()}
                </div>
                <div style={{ "marginTop": "-20px" }}>
                    <ComTooltip text={<div dangerouslySetInnerHTML={{ __html: confField.tDangerouslyInfo }} style={{ "padding": "5px" }} />} />
                    { confField.infoUrl && <CommHelpLink infoUrl={confField.infoUrl}/> }
                </div>
            </div>
        )
    }

    onDataChange = (e) => {
        let parent: COMMComp = this.props.parentComm;
        let valid = parent.DataChange(this, e.target.value);

        if (this.debounceTimeout) clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => {
            this.handleNewText();
        }, 1000);
        //this.setState({ toSave: valid, invalidValue: !valid });
    }

    handleBlur = () => {
        if (this.debounceTimeout) {
            clearTimeout(this.debounceTimeout);
            this.handleNewText();
        }
    };

    handleNewText = () => {
        let parent: COMMComp = this.props.parentComm;
        const confFieldItem: confFieldItem = this.props.confFieldItem;
        const confField: confField = confFieldItem.confField;
        if (confField.autoSave)
            parent.UpdateData(this.props.item);
    }
}

export class EnumComboBox extends React.Component {
    render() {
        let parent: COMMComp = this.props.parentComm;
        const confFieldItem: confFieldItem = this.props.confFieldItem;
        const confField: confField = confFieldItem.confField;

        return (
            <FormControl style={{ paddingBottom: '2rem', width: "100%", ...confField.formStyle }}>
                {confField.tLabel !== false && <InputLabel>{confField.name}</InputLabel>}
                <Select value={strfieldvalue(this.props.confFieldItem, this.props.item)} onChange={this.onDataChange}>
                    {confField.fieldprops.enumValues.map((enumValue) => (
                        <MenuItem key={enumValue.value} value={enumValue.value} disabled={enumValue.disabled != undefined}>
                            {enumValue.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    }

    onDataChange = (e) => {
        let parent: COMMComp = this.props.parentComm;
        let valid = parent.DataChange(this, e.target.value);
        //this.setState({ toSave: valid, invalidValue: !valid });
    }
}

export const disabledContainerStyles = {
    pointerEvents: 'none',
    opacity: 0.5,
    userSelect: 'none',
};

let InputVideoId: number = 0; //Chapuza: com que relacionu label amb el input per id amb la propietat htmlFor, tinc que generar ids diferents

export class InputSource extends React.Component {
    OpenToSelect = () => {
        this.inputFile.selectFile();
    }
    render = () => {
        this.props = confField.concatProps(this.props);
        const filetype = this.props.filetype ?? "video/*";

        return <label className="upload-ad-media" htmlFor={"videoInput" + (++InputVideoId)} style={{ marginRight: !isMobile ? "10px" : "0px", marginBottom: !isMobile ? "0px" : "10px" }}>
            <InputSelectVideo ref={(r) => { this.inputFile = r; }} idInput={ "videoInput" + (InputVideoId) } filetype={filetype} OnSelect={(file,metadata, error)=>this.props.OnSelect(this,file,metadata, error)} />
            {(this.props.children && typeof this.props.children !== "string") ? React.cloneElement(this.props.children, { component: 'span' }) : //{ component: 'span' } es necesita per el boto xk no acapari el click
                <CommSimpleButton {...this.props.buttonProps != undefined ? Object.assign({ component: "span" }, this.props.buttonProps) : { color: "secondary", variant: "contained", component: "span" }} >
                    {this.props.children || this.props.CaptionNewVideo}
                </CommSimpleButton>
            }
        </label>
    }
}

export class CountDownComm extends CountDown {
    constructor(props) {
        let value = standarValueBroadcastTime(strfieldvalue(props.confFieldItem, props.item));
        super(Object.assign({ dateTime: value, ...props.confFieldItem.confField.fieldprops }, props));
    }
}

export class FileButtonComm extends FileButton {
    constructor(props) {
        let value = strfieldvalue(props.confFieldItem, props.item);
        super(Object.assign({ ...value, ...props.confFieldItem.confField.fieldprops }, props));
        this.onDelete = this.onDeleteItem.bind(this);
    }

    onDeleteItem() {
        this.props.parentComm.DelItem(this.props.item);
    }
}

export class CommPrevalueComp extends React.Component {
    constructor(props) {
        super(props);

        const confFieldItem: confFieldItem = this.props.confFieldItem;
        const confField: confField = confFieldItem.confField;
        const { noEditingValue, initialEditingValue } = confField.fieldprops;

        this.state = {
            isEditing: strfieldvalue(this.props.confFieldItem, this.props.item) != noEditingValue,
        };
    }

    toggleEdit = () => {
        const confFieldItem: confFieldItem = this.props.confFieldItem;
        const confField: confField = confFieldItem.confField;
        const { noEditingValue, initialEditingValue } = confField.fieldprops;

        let parent: COMMComp = this.props.parentComm;
        parent.DataChange(this, !this.state.isEditing ? initialEditingValue : noEditingValue);
        this.setState(prevState => ({
            isEditing: !prevState.isEditing,
        }));
    };

    render() {
        let parent: CommForm = this.props.parentComm;
        const confFieldItem: confFieldItem = this.props.confFieldItem;
        const confField: confField = confFieldItem.confField;
        const { buttonText, cancelButtonText, EditComp, compStyle, buttonTextStyle, cancelButtonTextStyle } = confField.fieldprops;
        const { isEditing } = this.state;

        return (
            <div {...compStyle}>
                {!isEditing && (
                    <Button color="primary" variant="contained" className="list-button" onClick={this.toggleEdit} {...buttonTextStyle}>
                        {buttonText}
                    </Button>
                )}

                {isEditing && (
                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                        <div style={{ flex: 1, marginRight: '10px' }}>
                            <EditComp {...this.props} />
                        </div>
                        <Button color="error" variant="contained" className="list-button" onClick={this.toggleEdit} {...cancelButtonTextStyle}>
                            {cancelButtonText}
                        </Button>
                    </div>
                )}
            </div>
        );
    }
}