import { LangStrs, Lang } from '../../utils/languageUtils'

export const adsStrs: LangStrs = new LangStrs(new Map(
    [
        ['Media', new Map(
            [
                [Lang.ES, 'Media'],
                [Lang.EN, 'Media']
            ])
        ],
        ['Name', new Map(
            [
                [Lang.ES, 'Nombre'],
                [Lang.EN, 'Name']
            ])
        ],
        ['Size', new Map(
            [
                [Lang.ES, 'Tamaño'],
                [Lang.EN, 'Size']
            ])
        ],
        ['TViewers', new Map(
            [
                [Lang.ES, 'Espectadores totales'],
                [Lang.EN, 'Total views']
            ])
        ],
        ['CDate', new Map(
            [
                [Lang.ES, 'Fecha de creación'],
                [Lang.EN, 'Creation date']
            ])
        ],
        ['Actions', new Map(
            [
                [Lang.ES, 'Acciones'],
                [Lang.EN, 'Actions']
            ])
        ],
        ['seconds', new Map(
            [
                [Lang.ES, 'segundos'],
                [Lang.EN, 'seconds']
            ])
        ],
        ['Duration', new Map(
            [
                [Lang.ES, 'Duración'],
                [Lang.EN, 'Duration']
            ])
        ],
        ['Status', new Map(
            [
                [Lang.ES, 'Estado'],
                [Lang.EN, 'Status']
            ])
        ],
        ['Founds', new Map(
            [
                [Lang.ES, 'Fondos'],
                [Lang.EN, 'Founds']
            ])
        ],
        ['StateNoInfo', new Map(
            [
                [Lang.ES, 'Información insuficiente'],
                [Lang.EN, 'Insufficient information']
            ])
        ],
        ['StateApproved', new Map(
            [
                [Lang.ES, 'Aprobado'],
                [Lang.EN, 'Approved']
            ])
        ],
        ['Viewers', new Map(
            [
                [Lang.ES, 'Visualizaciones'],
                [Lang.EN, 'Viewers']
            ])
        ],
        ['Amount', new Map(
            [
                [Lang.ES, 'Cantidad'],
                [Lang.EN, 'Amount']
            ])
        ],
        ['StreamTime', new Map(
            [
                [Lang.ES, 'Momento'],
                [Lang.EN, 'Time']
            ])
        ],
        ['StateToApprove', new Map(
            [
                [Lang.ES, 'Por aprobar'],
                [Lang.EN, 'To approve']
            ])
        ],
        ['StateRevoked', new Map(
            [
                [Lang.ES, 'Revocado'],
                [Lang.EN, 'Revoked']
            ])
        ],
        ['AdName', new Map(
            [
                [Lang.ES, 'Nombre del anuncio'],
                [Lang.EN, 'Advertisement name']
            ])
        ],
        ['ETime', new Map(
            [
                [Lang.ES, 'Tiempo hasta siguiente aparicion (mismo stream)'],
                [Lang.EN, 'Time until next appearance (same stream)']
            ])
        ],
        ['SameTime', new Map(
            [
                [Lang.ES, 'Máximo en el mismo tiempo en el mismo stream (negativo significa sin límite)'],
                [Lang.EN, 'Maximum at the same time in the same stream (negative for no limit)']
            ])
        ],
        ['maxPlays', new Map(
            [
                [Lang.ES, 'Número máximo de reproducciones en el mismo vídeo/stream (negativo significa sin límite)'],
                [Lang.EN, 'Maximum number of plays on the same video/stream (negative means no limit)']
            ])
        ],
        ['SecDuration', new Map(
            [
                [Lang.ES, 'Duración en segundos'],
                [Lang.EN, 'Duration in seconds']
            ])
        ],
        ['BroStrategy', new Map(
            [
                [Lang.ES, 'Estrategia de difusión'],
                [Lang.EN, 'Broadcasting strategy']
            ])
        ],
        ['fullScreenAd', new Map(
            [
                [Lang.ES, 'Pantalla completa'],
                [Lang.EN, 'FullScreen']
            ])
        ],
        ['fullScreenInfo', new Map(
            [
                [Lang.ES, "Los anuncios de pantalla completa interrumpen el video o la transmisión y solo se mostrará tu anuncio.<br/>En estos anuncios, se escuchará el sonido del anuncio.<br/>Los anuncios que tienen más de 250k píxeles (altura por anchura) están obligados a ser de pantalla completa"],
                [Lang.EN, "FullScreen ads interrupt the video or stream, and only your ad will be displayed.<br/>In these ads, the sound of the ad will be heard.<br/>Ads that have more than 250k pixels (height * width) are required to be full screen"]
            ])
        ],
        ['fullScreenAlert', new Map(
            [
                [Lang.ES, "Tu anuncio se ha configurado como anuncio de pantalla completa porque sus dimensiones (altura por anchura) son mayores a 250k píxeles."],
                [Lang.EN, "Your ad has been set up as a full-screen ad because its dimensions (height * width) are greater than 250k pixels."]
            ])
        ],
        ['MinViewers', new Map(
            [
                [Lang.ES, 'Número mínimo de espectadores'],
                [Lang.EN, 'Minimal viewers in Stream']
            ])
        ],
        ['AdW', new Map(
            [
                [Lang.ES, 'Anchura en píxeles'],
                [Lang.EN, 'Ad Width in pixels']
            ])
        ],
        ['AdH', new Map(
            [
                [Lang.ES, 'Altura en píxeles'],
                [Lang.EN, 'Ad Height in pixels']
            ])
        ],
        ['AdTarget', new Map(
            [
                [Lang.ES, 'Ajustes del target'],
                [Lang.EN, 'Ad Target Settings']
            ])
        ],
        ['AdProduct', new Map(
            [
                [Lang.ES, 'Descripción del producto anunciado'],
                [Lang.EN, 'Product advertisement type']
            ])
        ],
        ['StreamerDesc', new Map(
            [
                [Lang.ES, 'El streamer que quiero que me anuncie es'],
                [Lang.EN, 'The streamer I want to announce it is']
            ])
        ],
        ['AudDesc', new Map(
            [
                [Lang.ES, 'El público al que quiero llegar es'],
                [Lang.EN, 'The audience I want to impact is']
            ])
        ],
        ['TNewAd', new Map(
            [
                [Lang.ES, 'Nuevo anuncio'],
                [Lang.EN, 'New advertisement']
            ])
        ],
        ['TEditAd', new Map(
            [
                [Lang.ES, 'Ajustes del anuncio'],
                [Lang.EN, 'Edit advertisement']
            ])
        ],
        ['TAdvertisements', new Map(
            [
                [Lang.ES, 'Anuncios'],
                [Lang.EN, 'Advertisements']
            ])
        ],
        ['TAdvertisement', new Map(
            [
                [Lang.ES, 'Anuncio'],
                [Lang.EN, 'Advertisement']
            ])
        ],
        ['AddFounds', new Map(
            [
                [Lang.ES, 'Añadir fondos'],
                [Lang.EN, 'Add founds']
            ])
        ],
        ['UpAd', new Map(
            [
                [Lang.ES, 'Subir anuncio'],
                [Lang.EN, 'Upload Ad']
            ])
        ],
        ['StreamerSel', new Map(
            [
                [Lang.ES, 'Quiero específicamente que mi anuncio aparezca en el Streamer'],
                [Lang.EN, 'I specifically want my ad to appear in the Streamer']
            ])
        ],
        ['HoldOff', new Map(
            [
                [Lang.ES, 'No emitir'],
                [Lang.EN, 'Hold off']
            ])
        ],
        ['AdScheduled', new Map(
            [
                [Lang.ES, 'Planificado'],
                [Lang.EN, 'Scheduled']
            ])
        ],
        ['BannerStateNone', new Map(
            [
                [Lang.ES, 'Sin estado'],
                [Lang.EN, 'None']
            ])
        ],
        ['BannerStateInsufficientData', new Map(
            [
                [Lang.ES, 'Datos insuficientes'],
                [Lang.EN, 'Insufficent data']
            ])
        ],
        ['BannerStateToCheck', new Map(
            [
                [Lang.ES, 'Por verificar'],
                [Lang.EN, 'To check']
            ])
        ],
        ['BannerStateNoApproved', new Map(
            [
                [Lang.ES, 'No aprobado'],
                [Lang.EN, 'No approved']
            ])
        ],
        ['BannerStateApproved', new Map(
            [
                [Lang.ES, 'Aprobado'],
                [Lang.EN, 'Approved']
            ])
        ],
        ['State', new Map(
            [
                [Lang.ES, 'Estado'],
                [Lang.EN, 'State']
            ])
        ],
        ['AdLink', new Map(
            [
                [Lang.ES, `Enlace de la Campaña Publicitaria`],
                [Lang.EN, `Advertising Campaign Link`]
            ])
        ],
        ['AdLinkText', new Map(
            [
                [Lang.ES, `Texto antes del enlace (máximo 100 caracteres)`],
                [Lang.EN, `Text before the link (maximum 100 characters)`]
            ])
        ],
        ['InfoAdLink', new Map(
            [
                [Lang.ES, `Ingrese la dirección web exacta donde los visitantes accederán a su sitio, producto o promoción (Solo se permite protocolo https).`],
                [Lang.EN, `Enter the exact web address where visitors will access your site, product, or promotion (Only https protocol is allowed).`]
            ])
        ],
        ['InfoAdLinkText', new Map(
            [
                [Lang.ES, `Texto que irá antes del enlace, es posible usar hashtags, ejemplos:<br><br>JustBuy:<br>Compra ya los auriculares GreatSound:<br>Usa el enlace y consigue un 2x1 en el concierto de TheGreatest:<br>`],
                [Lang.EN, `Text that will go before the link, hashtags can be used, examples:<br><br>JustBuy:<br>Buy the GreatSound headphones now:<br>Use the link and get a 2x1 at TheGreatest concert:<br>`]
            ])
        ],
    ]
));

export class BannerState {
    static None = -1;
    static InsufficientData = 0;
    static ToCheck = 1;
    static NoApproved = 2;
    static Approved = 3;
}
