import React, { useState, useRef, useEffect, useImperativeHandle  } from "react";
import ReactPlayer from "react-player";
import { BannersPreview } from '../../structure/banners-preview/banners-preview.component.jsx'
import ComTooltip from '../../structure/common/comTooltip/comTooltip.jsx';
import ModalDialog from '../../components/common/modal/modal'
import { isBrowser, isMobile } from '../../utils/platformsUtils';
import { ConditionalComponent } from '../../structure/common/extComponents/index.jsx';

import {
    IconButton,
    Slider,
    TextField,
    Box,
    Typography,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    makeStyles,
    Grid
} from "@material-ui/core";
import {
    PlayArrow,
    Pause,
    AddBox,
    Delete,
    CropFree,
    ColorLens,
} from "@material-ui/icons";

import { LangStrs, Lang } from '../../utils/languageUtils'
const strs: LangStrs = new LangStrs(new Map(
    [
        ['CutsInfo', new Map(
            [
                [Lang.ES, "<p>Si agregas un corte publicitario a un video, este interrumpirá su reproducción en el momento que tú configures para mostrar un anuncio.La duración del mismo nunca superará la que tú configures.</p><p>Nota: No se pueden poner cortes publicitarios al final del video.</p>"],
                [Lang.EN, "<p>If you add an advertising break to a video, it will interrupt its playback at the moment you set to display an ad.The duration of the ad will never exceed the one you set.</p><p>Note: Advertising breaks cannot be placed at the end of the video.</p>"]
            ])
        ],
        ['BannersInfo', new Map(
            [
                [Lang.ES, "<p>El espacio donde coloques los banners será sustituido en el video por publicidad sin sonido.</p><p>Nota: Uno de los factores de redistribución es que los banners aparezcan en la parte central de la duración del video.</p>"],
                [Lang.EN, "<p>The space where you place the banners will be replaced in the video by soundless advertising.</p><p>Note: One of the redistribution factors is that the banners appear in the middle part of the video's duration.</p>"]
            ])
        ],
        ['NoAds', new Map(
            [
                [Lang.ES, "No se ha configurado ningún anuncio"],
                [Lang.EN, "No advertisement has been configured"]
            ])
        ],
        ['BannerError', new Map(
            [
                [Lang.ES, "El banner {1} tiene algún error de configuración."],
                [Lang.EN, "The banner {1} has some configuration error."]
            ])
        ],
        ['BannerErrorSize', new Map(
            [
                [Lang.ES, "Los banners deben tener un tamaño minimo de {1}x{1}"],
                [Lang.EN, "The banners must have a minimum size of {1}x{1}"]
            ])
        ],
        ['CutError', new Map(
            [
                [Lang.ES, "El corte publicitario {1} tiene algún error de configuración"],
                [Lang.EN, "The advertising cut {1} has a configuration error"]
            ])
        ],
        ['CutErrorMax', new Map(
            [
                [Lang.ES, "El máximo para la duración de este video es de {1} cortes"],
                [Lang.EN, "You cannot place any more ad breaks. The maximum number of breaks for the duration of this video is {1}"]
            ])
        ],
        ['ErrorSegmentMax', new Map(
            [
                [Lang.ES, 'Al ser un video patrocinado, solo puede agregar un segmento patrocinado'],
                [Lang.EN, 'Being a sponsored video, you can only add one sponsored segment']
            ])
        ],
        ['BannersOverlap', new Map(
            [
                [Lang.ES, "En el momento {1} hay un banner solapado"],
                [Lang.EN, "At time {1}, there is an overlapping banner"]
            ])
        ],
        ['CutsOverlap', new Map(
            [
                [Lang.ES, "Hay más de un corte en el momento {1}"],
                [Lang.EN, "There is more than one cut at moment {1}"]
            ])
        ],
        ['SelVideo', new Map(
            [
                [Lang.ES, "Seleccionar video"],
                [Lang.EN, "Select video"]
            ])
        ],
        ['AcceptConfig', new Map(
            [
                [Lang.ES, "Aceptar configuración"],
                [Lang.EN, "Accept configuration"]
            ])
        ],
        ['AddBanner', new Map(
            [
                [Lang.ES, "Agregar banner"],
                [Lang.EN, "Add banner"]
            ])
        ],
        ['AddCut', new Map(
            [
                [Lang.ES, "Agregar corte"],
                [Lang.EN, "Add cut"]
            ])
        ],
        ['AddSegment', new Map(
            [
                [Lang.ES, 'Agregar segmento patrocinado'],
                [Lang.EN, 'Add Sponsored Segment']
            ])
        ],
        ['Hide', new Map(
            [
                [Lang.ES, "Ocultar"],
                [Lang.EN, "Hide"]
            ])
        ],
        ['More', new Map(
            [
                [Lang.ES, "Más"],
                [Lang.EN, "More"]
            ])
        ],
        ['Width', new Map(
            [
                [Lang.ES, "Ancho"],
                [Lang.EN, "Width"]
            ])
        ],
        ['Height', new Map(
            [
                [Lang.ES, "Alto"],
                [Lang.EN, "Height"]
            ])
        ],
        ['Color', new Map(
            [
                [Lang.ES, "Color"],
                [Lang.EN, "Color"]
            ])
        ],
        ['CutTime', new Map(
            [
                [Lang.ES, "Momento de corte"],
                [Lang.EN, "Cut moment"]
            ])
        ],
        ['CutDuration', new Map(
            [
                [Lang.ES, "Duración máxima"],
                [Lang.EN, "Maximum duration"]
            ])
        ],
        ['BannerStart', new Map(
            [
                [Lang.ES, "Inicio"],
                [Lang.EN, "Start"]
            ])
        ],
        ['BannerEnd', new Map(
            [
                [Lang.ES, "Fin"],
                [Lang.EN, "End"]
            ])
        ],
    ]
));

const styles = {
    textNumbers: {
        width: '70px'
    }
};

const useStyles = makeStyles((theme) => ({
    actionButton: {
        margin: theme.spacing(1),
        width:"200px"
    },
    blackBackground: {
        background: "black",
        width: "100%",
        height: "100%",
        zIndex: 2,
    },
    gif: {
        zIndex: 3,
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    }
}));

const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${mins.toString().padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
};

const isValidTime = (time) => {
    const regex = /^(\d*):(\d{2})$/;
    const match = time.match(regex);
    if (match) {
        const mins = parseInt(match[1], 10);
        const secs = parseInt(match[2], 10);
        return mins >= 0 && secs >= 0 && secs < 60;
    }
    return false;
};

const parseTime = (time) => {
    const [mins, secs] = time.split(":").map((num) => parseInt(num, 10));
    return mins * 60 + secs;
};

const useComponentObserver = (func=null) => {
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const dimensionsRef = useRef(dimensions);
    const ref = useRef(null);

    useEffect(() => {
        dimensionsRef.current = dimensions;
    }, [dimensions]);

    useEffect(() => {
        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                if (dimensionsRef.current.width !== entry.contentRect.width || dimensionsRef.current.height !== entry.contentRect.height) {
                    const dim = {
                        width: entry.contentRect.width,
                        height: entry.contentRect.height,
                    };
                    if(func!=null)func();
                    setDimensions(dim); //Aixo obliga a renderitzar                    
                }
            }
        });

        if (ref.current) {
            resizeObserver.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                resizeObserver.unobserve(ref.current);
            }
        };
    }, []);

    return [ref, dimensions];
};

export const VideoEditor = React.forwardRef((props, ref) => {
    const classes = useStyles();
    const [url, setUrl] = useState("");
    const [playing, setPlaying] = useState(false);
    const [playedSeconds, setPlayedSeconds] = useState(0);
    const [layers, setLayersReal] = useState([]);
    const [cuts, setCuts] = useState([]);
    const [nLayers, setnLayers] = useState(1);
    const playerRef = useRef(null);
    const [showGif, setShowGif] = useState(false);
    const [lastPlayedSeconds, setlastPlayedSeconds] = useState(-1);
    const [alertError, setAlertError] = useState(undefined);
    let bannersPreview: BannersPreview = null;
    const [isOversize, setIsOversize] = useState(false);

    //const divRef = useRef(null);
    const maxConfigDiv = 0.33;

    useImperativeHandle(ref, () => ({
        setVideo
    }));

    const handlePlayPause = (play = undefined) => {
        let p = (play === true || play === false) ? play : !playing;
        if (p) {
            setlastPlayedSeconds(playedSeconds);
            setShowGif(false);
        }
        setPlaying(p);
    };

    const handleProgress = ({ playedSeconds }) => {
        if (playing) {
            const cut = cuts.find((cut) => lastPlayedSeconds != -1 && (lastPlayedSeconds <= cut.cutTime) && playedSeconds >= cut.cutTime);
            setlastPlayedSeconds(playedSeconds);
            if (cut) {
                handleGifDisplay();
            }
        }

        setPlayedSeconds(playedSeconds);
        setBanners();
    };

    const minSize = 200;

    const handleLayerAdd = () => {
        handlePlayPause(false)
        let iTime = playedSeconds;
        let eTime = playedSeconds + 30;
        setLayers([
            ...layers,
            {
                id: nLayers,
                name: "" + (nLayers),
                startTime: formatTime(iTime),
                iTime: iTime,
                endTime: formatTime(eTime),
                eTime: eTime,
                sizeX: minSize,
                sizeY: minSize,
                posX: 0,
                posY: 0,
                color: "rgba(255, 0, 0, 0.5)",
            },
        ]);
        setnLayers(nLayers + 1);
    };

    const handleLayerDelete = (id) => {
        setLayers(layers.filter((layer) => layer.id !== id));
    };

    let maxCutNum;
    const checkMaxCut = () => {
        if (props.SponDelivery && cuts.length >= 1) return false;

        const d = (playerRef.current?.getDuration() || 0);
        maxCutNum = Math.ceil(d / (5 * 60));
        return cuts.length < maxCutNum;
    };

    const handleCutAdd = () => {
        if (checkMaxCut()) {
            setCuts([...cuts, { id: cuts.length, cutTime: playedSeconds, duration: formatTime(30) }]);
        } else
            modalAlert(strs.get(props.SponDelivery ? "ErrorSegmentMax" : "CutErrorMax", maxCutNum));
        
    };

    const handleCutDelete = (id) => {
        setCuts(cuts.filter((cut) => cut.id !== id));
    };

    const handleGifDisplay = () => {
        if (!showGif) {
            setPlaying(false);
            setShowGif(true);
            setTimeout(() => {
                setShowGif(false);
                setPlaying(true);
            }, 5000);
        }
    };

    const checkTimeValues = (layer, fromEndTime = false) => {
        layer.iTime = parseTime(layer.startTime);
        layer.eTime = parseTime(layer.endTime);

        if (!isValidTime(layer.startTime) || !isValidTime(layer.endTime)) return;

        if (layer.eTime < layer.iTime) {
            if (!fromEndTime) {
                layer.eTime = layer.iTime + 30;
                layer.endTime = formatTime(layer.eTime);
            } else {
                layer.iTime = Math.max(0, layer.eTime - 30);
                layer.startTime = formatTime(layer.iTime);
            }
        }
    }

    const handleChange = (e, value, field, id) => {
        const newLayers = layers.map((layer) => {
            if (layer.id === id) {
                let o = { ...layer, [field]: value };
                checkTimeValues(o, field == "endTime");
                return o;
            }
            return layer;
        });
        setLayers(newLayers);
    };

    const handleCutChange = (id, field, value) => {
        const newCuts = cuts.map((cut) => {
            if (cut.id === id) {
                return { ...cut, [field]: value };
            }
            return cut;
        });
        setCuts(newCuts);
    };

    const handleFileChange = (e) => {
        if (e.target.files.length > 0) {
            setUrl(URL.createObjectURL(e.target.files[0]));
        }
    };

    const setVideo = (e) => {
        if (url == "") {
            setUrl(URL.createObjectURL(e));
        }
    };

    const [videoDimensions, setVideoDimensions] = useState({ width: 0, height: 0 });
    const [playerDimensions, setPlayerDimensions] = useState({ width: 0, height: 0 });
    const [expandedLayerId, setExpandedLayerId] = useState(null);

    const handleVideoReady = () => {
        if (playerRef.current) {
            const videoElement = playerRef.current.getInternalPlayer();

            setVideoDimensions({
                width: videoElement.videoWidth,
                height: videoElement.videoHeight,
            });

            const maxW = window.innerWidth - 100;
            const maxH = window.innerHeight - (isMobile ? 500 : 600);

            let relSV = maxW / videoElement.videoWidth;
            if ((videoElement.videoHeight * relSV) > maxH)
                relSV = maxH / videoElement.videoHeight;

            //let w = videoElement.videoHeight < videoElement.videoWidth ? videoElement.videoWidth * relSV : videoElement.videoHeight * relSV * (videoElement.videoWidth / videoElement.videoHeight);
            //let h = videoElement.videoHeight < videoElement.videoWidth ? videoElement.videoWidth * relSV * (videoElement.videoHeight / videoElement.videoWidth) : videoElement.videoHeight * relSV;
            let w = videoElement.videoWidth * relSV;
            let h = videoElement.videoHeight * relSV;

            setPlayerDimensions({
                width: w,
                height: h,
            });

            //setPlayerDimensions({
            //    width: playerRef.current.wrapper.clientWidth,
            //    height: playerRef.current.wrapper.clientHeight,
            //});
        }
    };

    const setrefBannersPreview = (c) => {
        if (c == null) return;
        bannersPreview = c;
    }

    const timeFilter = (layer, s = undefined) => {
        s = s != undefined ? s : playedSeconds;
        return isValidTime(layer.startTime) && isValidTime(layer.endTime) && s >= parseTime(layer.startTime) && s <= parseTime(layer.endTime);
    }

    const setBanners = (newLayers = null) => {
        if (newLayers == null) newLayers = layers;
        const flayers = newLayers.filter(layer => timeFilter(layer));
        bannersPreview.setBanners(null, flayers);
    }

    const setLayers = (layers) => {
        setLayersReal(layers);
        setBanners(layers);
    }

    const onChangeBannersPreview = (banner, data) => {
        const newLayers = layers.map((layer) => {
            if (layer.id === banner.id) {
                return { ...layer, posX: data.posX, posY: data.posY, sizeX: data.sizeX, sizeY: data.sizeY };
            }
            return layer;
        });
        setLayers(newLayers);
    }

    const cutTimeAllowed = 0.75;

    const handleNewConfiguration = () => {
        if (layers.length == 0 && cuts.length == 0) {
            modalAlert(strs.get("NoAds"));
            return;
        }

        let minSizeIncorrect = false;

        let error = layers.find(l => {
                minSizeIncorrect = l.sizeX < minSize || l.sizeY < minSize;
                return !isValidTime(l.startTime) || !isValidTime(l.endTime) || l.eTime < l.iTime || minSizeIncorrect || l.posX < 0 || l.posY < 0 || l.posX + l.sizeX > videoDimensions.width || l.posY + l.sizeY > videoDimensions.height
            }
        );
        if (error) { 
            modalAlert(`${strs.get("BannerError", error.name)} ${minSizeIncorrect ? strs.get("BannerErrorSize", minSize) : ""}`);
            return;
        }

        error = cuts.find(c => {
            c.sDuration = parseTime(c.duration);
            return c.duration <= 0 || c.cutTimeAllowed > playerRef.current.getDuration()
        });
        if (error) {
            modalAlert(strs.get("CutError", error.id));
            return;
        }

        for (let i = 0; i < playerRef.current?.getDuration(); i++) { 
            const flayers = layers.filter(layer => timeFilter(layer, i));
            if (!bannersPreview.ValidateRects(flayers)) {
                modalAlert(strs.get("BannersOverlap", formatTime(i)));
                return;
            }
        }
        let cOK = null;
        cuts.sort((c1, c2) => {
            if (c1.cutTime == c2.cutTime) cOK = c1;
            return c1.cutTime - c2.cutTime;
        });
        if (cOK != null) {
            modalAlert(strs.get("CutsOverlap", formatTime(cOK.cutTime)));
            return;
        }

        props.OnConfigDone && props.OnConfigDone({ banners: layers, cuts: cuts });
    }

    let refModalAlert: ModalDialog = null;
    const modalAlert = (error) => {
        setAlertError(error);
        refModalAlert.setOpen(true);
    }

    //Es xk quan cambia el tamany, esta mal posicionat el tall fins a render
    const [sliderRef, sliderDimensions] = useComponentObserver();
    const [divRef, configDivDimensions] = useComponentObserver(() => {
        if (divRef.current) {
            if (divRef.current.scrollHeight > window.innerHeight * maxConfigDiv) {
                setIsOversize(true);
            } else {
                setIsOversize(false);
            }
        }
    });

    return (
        <Box>
            <input
                type="file"
                accept="video/*"
                onChange={handleFileChange}
                style={{ display: "none" }}
                id="video-upload"
                config={{
                    file: {
                        attributes: {
                            disablePictureInPicture: true,
                            controlsList: 'nodownload nofullscreen',
                        },
                    },
                }}
            />
            <Box display="flex" justifyContent="space-between" mb={2}>
                <label htmlFor="video-upload">
                    {url == "" &&
                        <Button variant="contained" color="primary" component="span">
                            {strs.get("SelVideo")}
                        </Button>
                    }
                </label>
                <Button variant="contained" color="primary" component="span" onClick={handleNewConfiguration}>
                    {strs.get("AcceptConfig")}
                </Button>
            </Box>
            <Box position="relative" width="100%">
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Box position="relative" width={playerDimensions.width} height={playerDimensions.height} onMouseDown={() => handlePlayPause(false)}>
                        <ReactPlayer
                            ref={playerRef}
                            url={url}
                            playing={playing}
                            onProgress={handleProgress}
                            progressInterval={100}
                            onReady={handleVideoReady}
                            width={playerDimensions.width}
                            height={playerDimensions.height}
                        />
                        <Box position="absolute" display="flex" top="0px" left="0px" width={playerDimensions.width} height={playerDimensions.height} >
                            <BannersPreview ref={setrefBannersPreview} bgColor={"#00000010"} width={videoDimensions.width} height={videoDimensions.height} canvasWidth={playerDimensions.width} canvasHeight={playerDimensions.height} OnChange={onChangeBannersPreview} allowResize={true}/>
                        </Box>
                        {showGif && (
                            <Box position="absolute" display="flex" top="0px" left="0px" width={playerDimensions.width} height={playerDimensions.height} >
                                <Box className={classes.blackBackground} position="absolute" width={playerDimensions.width} height={playerDimensions.height}/>
                                <img className={classes.gif} src={"/LogoPowerAdsAnimated.gif"} alt="GIF animado" style={playerDimensions.width < playerDimensions.height ? { width: playerDimensions.width } : { height: playerDimensions.height } }  />
                            </Box>
                        )}
                    </Box>
                </div>
                <IconButton onClick={handlePlayPause}>
                    {playing ? <Pause /> : <PlayArrow />}
                </IconButton>
                <Box position="relative" style={{ height: "24px" }}>
                    <Slider
                        ref={sliderRef}
                        id="time-slider"
                        value={playedSeconds}
                        max={playerRef.current?.getDuration() || 100}
                        onChange={(e, value) => { setShowGif(false); setlastPlayedSeconds(value); playerRef.current.seekTo(value); setPlayedSeconds(value); }}
                    />
                    {cuts.map((cut) => {
                        const sliderW = 2;
                        const sliderH = 20;
                        const slider = document.getElementById("time-slider");
                        const cutPercentage = cut.cutTime / (playerRef.current?.getDuration() || 1);
                        const cutPositionX = (cutPercentage * slider.clientWidth) - (sliderW / 2);
                        const cutPositionY = (slider.clientHeight / 2) - (sliderH / 2);

                        return (
                            <Box
                                key={cut.id}
                                position="absolute"
                                bgcolor="red"
                                width={`${sliderW}px`}
                                height={`${sliderH}px`}
                                top={`${cutPositionY}px`}
                                left={`${cutPositionX}px`}
                                zIndex="1"
                                transform="translateY(-50%)"
                            />
                        );
                    })}
                </Box>
            </Box>
            <Typography variant="subtitle1">{formatTime(playedSeconds)}</Typography>
            <div ref={divRef} style={{ maxHeight: isOversize ? `${maxConfigDiv * 100}vh` : 'auto', overflowY: isOversize ? 'scroll' : 'visible', }}>
                {props.SponDelivery == undefined && <>
                    <Button
                        onClick={handleLayerAdd}
                        variant="outlined"
                        color="primary"
                        className={classes.actionButton}
                    >
                        {strs.get("AddBanner")}
                    </Button>
                    <ComTooltip text={<div dangerouslySetInnerHTML={{ __html: strs.get('BannersInfo') }} style={{ "padding": "5px" }} />} />
                    <Box id="myBox" sx={{ maxHeight: true ? '20vh' : 'auto', overflowY: true ? 'scroll' : 'visible', }}>
                        {layers.slice().reverse().map((layer) => (
                            <Box key={layer.id} display="flex" alignItems="center" mb={1}>
                                { !isMobile && <Typography variant="subtitle1" style={{ padding: "20px" }}>{layer.id}</Typography>}
                                <ConditionalComponent TagComponent={Grid} Condition={isMobile} bodyConditional={false} container spacing={2}>
                                    <ConditionalComponent TagComponent={Grid} Condition={isMobile} bodyConditional={false} item xs={12} sm={6}>
                                        <TextField
                                            label={strs.get("BannerStart")}
                                            style={styles.textNumbers}
                                            value={layer.startTime}
                                            onChange={(e) =>
                                                handleChange(e, e.target.value, "startTime", layer.id)
                                            }
                                            type="text"
                                            inputProps={{ pattern: "\d*:\d{2}" }}
                                            error={!isValidTime(layer.startTime)}
                                        />
                                        <IconButton onClick={(e) => { handleChange(e, formatTime(playedSeconds), "startTime", layer.id); }}>
                                            <CropFree />
                                        </IconButton>
                                    </ConditionalComponent>
                                    <ConditionalComponent TagComponent={Grid} Condition={isMobile} bodyConditional={false} item xs={12} sm={6}>
                                        <TextField
                                            label={strs.get("BannerEnd")}
                                            style={styles.textNumbers}
                                            value={layer.endTime}
                                            onChange={(e) =>
                                                handleChange(e, e.target.value, "endTime", layer.id)
                                            }
                                            type="text"
                                            inputProps={{ pattern: "\d*:\d{2}" }}
                                            error={!isValidTime(layer.endTime)}
                                        />
                                        <IconButton onClick={(e) => handleChange(e, formatTime(playedSeconds), "endTime", layer.id)}>
                                            <CropFree />
                                        </IconButton>
                                    </ConditionalComponent>
                                </ConditionalComponent>
                                {expandedLayerId === layer.id && (
                                    <Box>
                                        <ConditionalComponent TagComponent={Grid} Condition={isMobile} bodyConditional={false} container spacing={2}>
                                            <ConditionalComponent TagComponent={Grid} Condition={isMobile} bodyConditional={false} item xs={12} sm={6}>
                                                <TextField
                                                    label="X"
                                                    value={layer.posX}
                                                    style={styles.textNumbers}
                                                    onChange={(e) =>
                                                        handleChange(e, +e.target.value, "posX", layer.id)
                                                    }
                                                    type="number"
                                                />
                                            </ConditionalComponent>
                                            <ConditionalComponent TagComponent={Grid} Condition={isMobile} bodyConditional={false} item xs={12} sm={6}>
                                                <TextField
                                                    label="Y"
                                                    value={layer.posY}
                                                    style={styles.textNumbers}
                                                    onChange={(e) =>
                                                        handleChange(e, +e.target.value, "posY", layer.id)
                                                    }
                                                    type="number"
                                                />
                                            </ConditionalComponent>
                                            <ConditionalComponent TagComponent={Grid} Condition={isMobile} bodyConditional={false} item xs={12} sm={6}>
                                                <TextField
                                                    label={strs.get("Width")}
                                                    value={layer.sizeX}
                                                    style={styles.textNumbers}
                                                    onChange={(e) =>
                                                        handleChange(e, +e.target.value, "sizeX", layer.id)
                                                    }
                                                    type="number"
                                                />
                                            </ConditionalComponent>
                                            <ConditionalComponent TagComponent={Grid} Condition={isMobile} bodyConditional={false} item xs={12} sm={6}>
                                                <TextField
                                                    label={strs.get("Height")}
                                                    value={layer.sizeY}
                                                    style={styles.textNumbers}
                                                    onChange={(e) =>
                                                        handleChange(e, +e.target.value, "sizeY", layer.id)
                                                    }
                                                    type="number"
                                                />
                                            </ConditionalComponent>
                                            <ConditionalComponent TagComponent={Grid} Condition={isMobile} bodyConditional={false} item xs={12} sm={6}>
                                                <FormControl>
                                                    <InputLabel id={`layer-${layer.id}-color-label`}>{strs.get("Color")}</InputLabel>
                                                    <Select
                                                        labelId={`layer-${layer.id}-color-label`}
                                                        value={layer.color}
                                                        onChange={(e) =>
                                                            handleChange(e, e.target.value, "color", layer.id)
                                                        }
                                                    >
                                                        <MenuItem value="rgba(255, 0, 0, 0.5)">Rojo</MenuItem>
                                                        <MenuItem value="rgba(0, 255, 0, 0.5)">Verde</MenuItem>
                                                        <MenuItem value="rgba(0, 0, 255, 0.5)">Azul</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </ConditionalComponent>
                                        </ConditionalComponent>
                                    </Box>)}
                                <IconButton onClick={() => handleLayerDelete(layer.id)}>
                                    <Delete />
                                </IconButton>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => { setExpandedLayerId(expandedLayerId === layer.id ? null : layer.id); }}
                                >
                                    {expandedLayerId === layer.id ? strs.get("Hide") : strs.get("More")}
                                </Button>
                            </Box>
                        ))}
                    </Box >
                </>}
                <Button onClick={handleCutAdd} variant="outlined" color="primary" className={classes.actionButton} disabled={playedSeconds > (playerRef.current?.getDuration() || 100) * cutTimeAllowed}>
                    {props.SponDelivery ? strs.get("AddSegment") : strs.get("AddCut") }
                </Button>
                <ComTooltip text={<div dangerouslySetInnerHTML={{ __html: strs.get('CutsInfo') }} style={{ "padding": "5px" }} />} />
                {cuts.slice().reverse().map((cut) => (
                    <Box key={cut.id} display="flex" alignItems="center" mb={1}>
                        <TextField
                            label={strs.get("CutTime")}
                            value={formatTime(cut.cutTime)}
                            disabled
                        />
                        {props.SponDelivery == undefined && <TextField
                            label={strs.get("CutDuration")}
                            value={cut.duration}
                            onChange={(e) =>
                                handleCutChange(cut.id, "duration", e.target.value)
                            }
                            type="text"
                            inputProps={{ pattern: "\d*:\d{2}" }}
                            error={!isValidTime(cut.duration)}
                        />}
                        <IconButton onClick={() => handleCutDelete(cut.id)}>
                            <Delete />
                        </IconButton>
                    </Box>
                ))}
                <ModalDialog ref={(m) => { refModalAlert = m; }} windowsSyle={true} btnNOVisible={false} btnOKVisible={true} OnResult={() => setAlertError(undefined)}>
                    { alertError }
                </ModalDialog>            
            </div>
        </Box>        
    );
});