
import React from 'react';
import { connect } from 'react-redux';

import '../banner-list/banner-list.components'
import { Button, TableCell } from '@material-ui/core'
import { Delete, Visibility, AttachMoney, Edit, FileCopy } from '@styled-icons/material';
import MuiAlert from '@material-ui/lab/Alert'
import ContentNavigation from '../../structure/content-navigation/content-navigation.component'
import Streamers from '../../services/api/streamers';
import { commService } from '../../services/api/list';
import GlobalState from '../../store/globalState'
import DeleteItemDialog from '../common/deleteItemDialog/deleteItemDialog.component.jsx';
import AddNewButton from '../common/add-new-button/add-new-button.component.jsx';
import UserUtils, { UserTypes } from '../../utils/userUtils'
import { changeUrl, Urls } from '../../utils/routerUtils'
import { withStyles } from '@material-ui/core/styles'
import { CommListGen, CommLinkButton, CommDeleteButtonLink, confListField, confList, CommList, CommItemField, CommItemOfItems, CommButtonLinkItem, ListFieldButtonLink, confAddItem } from '../common/com-List/comm-list.components'
import { CommItemFieldLink, fieldNameFunction, CommSimpleTextButton, InputSource } from '../common/com'
import { myReactTaggedComponent, ReactComponentTag } from '../common/extComponents'
import { StreamingAccountAuthYoutube } from './streamingAccount-authYoutube'

import { Link as RouterLink } from 'react-router-dom';
import { StreamingAccountAuth } from './streamingAccount-auth';
import { InputSourceToStream } from '../generatevideo-form/generateVideo-form';

import { LangStrs, Lang } from '../../utils/languageUtils'
import Typography from '@material-ui/core/Typography';
import ComTooltip from '../../structure/common/comTooltip/comTooltip.jsx';

import { buttonPost } from '../common/com-List/comm-list-template-elements.jsx'

import { AddEntityLog } from '../../components/common/entityLog/entityLog.jsx'
import ServicesBase from '../../services/base';

import { isSandbox } from './../../index'

const strs: LangStrs = new LangStrs(new Map(
    [
        ['Platform', new Map(
            [
                [Lang.ES, 'Plataforma'],
                [Lang.EN, 'Platform']
            ])
        ],
        ['NameAccount', new Map(
            [
                [Lang.ES, 'Nombre de cuenta'],
                [Lang.EN, 'Name account']
            ])
        ],
        ['Actions', new Map(
            [
                [Lang.ES, 'Acciones'],
                [Lang.EN, 'Actions']
            ])
        ],
        ['NoVOD', new Map(
            [
                [Lang.ES, "Hemos detectado que el directo no es publico o no puede ser visto posteriormente, porfavor cambiar la configuracion"],
                [Lang.EN, "We have detected that the live broadcast is not public or cannot be viewed later, please change the settings"]
            ])
        ],
        ['NotVerify', new Map(
            [
                [Lang.ES, 'Cuenta sin verificar propiedad, agrega un video para lograrlo'],
                [Lang.EN, 'Account without verified ownership, add a video to achieve it']
            ])
        ],
        ['Verify', new Map(
            [
                [Lang.ES, 'Cuenta verificada'],
                [Lang.EN, 'Verified account']
            ])
        ],
        ['AddSA', new Map(
            [
                [Lang.ES, 'Agregar cuenta de streaming'],
                [Lang.EN, 'Add streaming account']
            ])
        ],
        ['NewVideo', new Map(
            [
                [Lang.ES, 'Nuevo Video'],
                [Lang.EN, 'New Video']
            ])
        ],
        ['MissInformation', new Map(
            [
                [Lang.ES, "Por favor, complete la información de perfil que falta para poder utilizar esta cuenta"],
                [Lang.EN, "Please complete the missing profile information to be able to use this account"]
            ])
        ],
        ['OBSLink', new Map(
            [
                [Lang.ES, "Obtener Link para stremear"],
                [Lang.EN, "Get Streaming Link"]
            ])
        ],
        ['COPYOBSLINK', new Map(
            [
                [Lang.ES, "Copiar link para stremear"],
                [Lang.EN, "Copy Streaming link"]
            ])
        ],
        ['Approve', new Map(
            [
                [Lang.ES, 'Aprobar'],
                [Lang.EN, 'Approve']
            ])
        ],
        ['Deny', new Map(
            [
                [Lang.ES, 'Denegar'],
                [Lang.EN, 'Deny']
            ])
        ]
    ]
));

export function ConfStreamingAccountListCommon(conf:[]=[]) {
    let commConf = [
        new confListField(strs.get('Platform'), CommItemField, { className: "list-field bi-platform" }, "streamingType"),
        new confListField(strs.get('NameAccount'), CommItemField, { className: "list-field bi-name" }, "name"),
        new confListField(saStrs.get('State'), StreamingAccountStateInfo, { className: "list-field bi-size" }),
        //new confListField("", CommItemField, { className: "list-field bi-name" }, new fieldNameFunction(null, null, (item) => {
        //    return item.verified === false ? <b>{strs.get('NotVerify')}</b> : item.authState === 0 ? StreamingAccountAuth.buttonRefreshLogin(item) : strs.get('Verify');
        //})),
    ];

    return commConf.concat(conf);    
}

export class StreamingAccountList extends React.Component {
    render = () => {
        const conf = ConfStreamingAccountListCommon(this.props.extraConf != undefined ? this.props.extraConf : []);

        return React.createElement(() => <CommList ref={(c) => this.props.refList && this.props.refList(c)} title={this.props.title != undefined ? this.props.title : "Streaming Accounts"} confComm={new confList(CommListGen, new commService("/streamers/:id/streamingAccount"), conf)} {...this.props.noItemsRedirect === false ? {} : { noItemsRedirect: Urls.StreamingAccountAdd }} />);
    }
}

export function getStreamingScene(scenekey) {
    if (isSandbox)
        return "http://sandbox:sandbox123@sandbox.powerads.tv/streamingScene/" + scenekey;
    return window.location.origin + "/streamingScene/" + scenekey;
}

const streamingAccountApproved = (item) => item.StreamerRequeriments && item.state == StreamingAccountState.Approved;

export function StreamingAccountToStreamActionCommon(conf: [] = [], scenekey) {
    let commConf =
        [
            ListFieldButtonLink(null, (item) => Urls.Profile, strs.get('MissInformation')).addField("Condition", (item) => !item.StreamerRequeriments),
            new confListField("LinkButton", CommLinkButton, null, (item) => { return { fieldName: getStreamingScene(`${scenekey}_${item.id}`) } }).addField("Condition", (item) => scenekey != undefined && item.authState !== 0 && item.LivePlatform).addField("tTitle", strs.get('COPYOBSLINK')).addField("disabled", (item) => !streamingAccountApproved(item))
        ];
    commConf = commConf.concat(conf);

    return new confListField(strs.get('Actions'), CommItemOfItems).addField("Comp", commConf);
}

export function SelStreamingAccountActionField(fromscene: string, callback = null) {
    const strCreateVideo = strs.get('NewVideo');
    const button = (buttonField, buttonFieldName, buttonFieldProps=null, addFields=null) => {
        let clf = new confListField("Widget URL", buttonField, buttonFieldProps, buttonFieldName).addField("disabled", (item) => !streamingAccountApproved(item));
        if (addFields != null) addFields(clf);
        return clf;
    }

    if (callback == null) {
        const genVideoURI = (item) => "/dashboard/genVideo/" + item.id + "/" + fromscene;

        return button(myReactTaggedComponent(CommItemFieldLink, [
            new ReactComponentTag("tInnerLink", <Button color="primary" variant="contained" className="list-button">{strCreateVideo}</Button>)
        ]), (item) => { return { to: genVideoURI(item), fieldName: window.location.origin + genVideoURI(item) } }, null, (clf) => { clf.addField("Condition", (item) => { return item.StreamerRequeriments && item.VideoPlatform })});
    } else {
        return [button(InputSource, strCreateVideo, { CaptionNewVideo: strCreateVideo, CaptionCopyOBS: strs.get("OBSLink"), OnSelect: (e, f, p, er) => { callback({ streamingAccount: e.props.item, videoInfo: p, file: f, error: er }) } }, (clf) => { clf.addField("OnClick", callback).addField("Condition", (item) => { return item.StreamerRequeriments && item.VideoPlatform;}); }),
            button(InputSourceToStream, strCreateVideo, { CaptionNewVideo: strCreateVideo, CaptionCopyOBS: strs.get("OBSLink"), OnSelect: (e, f, p, er) => { callback({ streamingAccount: e.props.item, videoInfo: p, file: f, error: er }) } }, (clf) => { clf.addField("OnClick", callback).addField("Condition", (item) => { return item.StreamerRequeriments && item.LivePlatform}) })];
    }
}

export class StreamingAccountToStream extends React.Component { //TODO: Podria ser StreamingAccountList
    render = () => {
        let cvaf = this.props.createVideoActionField != undefined ? this.props.createVideoActionField : [];
        const conf = ConfStreamingAccountListCommon(StreamingAccountToStreamActionCommon(cvaf, this.props.scenekey));

        return React.createElement(() => <CommList title={this.props.title != undefined ? this.props.title : "Streaming Accounts"} confComm={new confList(CommListGen, new commService("/streamers/:id/streamingAccount"), conf, new confAddItem(Urls.StreamingAccountAdd, strs.get('AddSA')))} noItemsRedirect={Urls.StreamingAccountAdd} />);
    }
}

export class StreamingAccountState {
    static None = -1;
    static InsufficientData = 0;
    static ToCheck = 1;
    static NoApproved = 2;
    static Approved = 3;
}

export const saStrs: LangStrs = new LangStrs(new Map(
    [
        ['SAStateNone', new Map(
            [
                [Lang.ES, 'Sin estado'],
                [Lang.EN, 'None']
            ])
        ],
        ['SAStateInsufficientData', new Map(
            [
                [Lang.ES, 'Datos insuficientes'],
                [Lang.EN, 'Insufficent data']
            ])
        ],
        ['SAStateToCheck', new Map(
            [
                [Lang.ES, 'Otorgando la asociación'],
                [Lang.EN, 'Granting partnership']
            ])
        ],
        ['SAStateNoApproved', new Map(
            [
                [Lang.ES, 'No aprobada'],
                [Lang.EN, 'Not approved']
            ])
        ],
        ['SAStateApproved', new Map(
            [
                [Lang.ES, 'Aprobada'],
                [Lang.EN, 'Approved']
            ])
        ],
        ['State', new Map(
            [
                [Lang.ES, 'Estado'],
                [Lang.EN, 'State']
            ])
        ],
        ['NoApprovedReasonIni', new Map(
            [
                [Lang.ES, "La cuenta no ha sido aprobado por la siguiente razón:"],
                [Lang.EN, "The acccount has not been approved for the following reason:"]
            ])
        ],
        ['NoApprovedReasonEnd', new Map(
            [
                [Lang.ES, "Ajustalo para que pueda ser revisado nuevamente."],
                [Lang.EN, "Adjust it so it can be reviewed again"]
            ])
        ]
    ]
));

export class StreamingAccountStateInfo extends React.Component {
    constructor(props) {
        super(props);
    }

    render = () => {
        this.isAdmin = UserUtils.GetUserData(true).usertype == UserTypes.Admin;

        const textResult = (text, colortext, more) => {
            return (
                <>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <p style={{ color: colortext }}>
                            {text}
                        </p>
                        {more}
                    </div>
                </>
            );
        }
        let toRender;

        const confField: confField = this.props.confFieldItem.confField;
        if (this.props.item.state == StreamingAccountState.InsufficientData) {
            toRender = textResult(saStrs.get('SAStateInsufficientData'), "red");           
        } else if (this.props.item.state == StreamingAccountState.Approved) {
            toRender = textResult(saStrs.get('SAStateApproved'), "green");
            if (this.props.item.NoVoD==="true") {
                toRender = textResult(strs.get('NoVOD'), "orange");
            }
            else if (this.props.item.verified === false) {
                toRender = textResult(strs.get('NotVerify'), "black");
            }
        } else if (this.props.item.state == StreamingAccountState.ToCheck) {
            toRender = textResult(saStrs.get('SAStateToCheck'), "orange");
        } else if (this.props.item.state == StreamingAccountState.NoApproved) {
            toRender = textResult(saStrs.get('SAStateNoApproved'), "red",
                <ComTooltip text={
                    <Typography variant="subtitle2">
                        <span style={{ fontSize: '.85em' }}>
                            {saStrs.get("NoApprovedReasonIni")}
                        </span>
                        <br />
                        <span style={{ fontSize: '1em' }}>
                            {this.props.item.NoApprovedReason}
                        </span>
                        <br />
                        <span style={{ fontSize: '.85em' }}>
                            {" " || saStrs.get("NoApprovedReasonEnd")}
                        </span>
                    </Typography>
                }
                />
            );
            //tooltipText = this.props.item.NoApprovedReason;
        }

        return (
            <TableCell style={{"border-bottom":"0px"}} className="list-field" {...confField.fieldprops}>
                {toRender}
            </TableCell>
        )
    }
}

export class StreamingAccountListAdmin extends React.Component {
    render = () => {
        const PostState = (i, state) => {
            return async () => {
                let sb: ServicesBase = new ServicesBase('#server#/streamers/' + i.streamerId + '/streamingAccount/' + i.id);
                await sb.ServiceRequest("", "PUT", { 'Content-Type': 'application/json' }, true, { state: state });
                await this.SAList.QueryItems();
            }
        }
        return (
            <StreamingAccountList refList={(rl) => this.SAList = rl} extraConf={[new confListField(strs.get('Actions'), CommItemOfItems).addField("Comp",
                [
                    new confListField(strs.get('Approve'), CommSimpleTextButton, {}, strs.get('Approve')).addField("itemprops", (i) => {
                        return {
                            OnClick: PostState(i, StreamingAccountState.Approved)
                        }
                    }),
                    new confListField(strs.get('Deny'), AddEntityLog, { buttonColor: "#AB0000" }).addField("itemprops", (i) => {
                        return {
                            button: strs.get('Deny'), entity: i.id, title: "DenyStreamingAccountReason", entityModel: "streamingAccounts", OnSave: PostState(i, StreamingAccountState.NoApproved)
                        }
                    }),
                ])]} noItemsRedirect={false}/>
        )
    }
}