import React, {useEffect} from 'react';
import SideMenu from '../../structure/sidemenu/sidemenu.component';
import DashboardHeader from '../../structure/dashboard-header/dashboard-header.component';
import DashboardContent from '../../structure/dashboard-content/dashboard-content.component';

import ActionTypes from '../../store/actions/actiontypes'

import {Redirect} from 'react-router-dom'
import Loading from '../../structure/common/loading/loading.component';
import { connect } from "react-redux";
import MenuOptions from "../../structure/sidemenu/menuoptions.utils";
import './dashboard.styles.scss';
import { mapGenericStateToProps } from '../../utils/routerUtils'

export class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menuOpen: false,
            loading: false
        }
        this.toggleState = (function() {
            this.setState({menuOpen: !this.state.menuOpen});
        }).bind(this);
        this.i18n = this.props.i18n;
    }

    render() {
        let role = this.props.userSession.role;
        
        return (
            <div>
                <div className="dashboard">
                    {!this.props.userSession.token && <Redirect to="/login" />}
                
                    <DashboardHeader className="topbar" toggle={this.toggleState}></DashboardHeader>
                    <SideMenu options={MenuOptions(role) ||[]} className="sidemenu" open={this.state.menuOpen}></SideMenu>
                    {<DashboardContent role={role} className="dashboard-content"></DashboardContent>}
                </div>
                {<Loading className="loading"></Loading>}
            </div>
        )
    }
}



export default connect(mapGenericStateToProps, null)(Dashboard);