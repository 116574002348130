import GlobalState from '../store/globalState';
import Cookies from 'universal-cookie';

import { ENVIRONMENTS } from './api/config'
import { ENV } from './../settings';

const env = ENVIRONMENTS[ENV];

export const getServerUrl = () => {
    return env.serverUrl;
}

let userSession;

let cookies = new Cookies();

class Response {
    constructor(error, body, code, response) {
        this.error = error;
        this.body = body;
        this.httpCode = code;
        this.response = response;
    }
};

const str = "string";

export function getAuthHeader(){
    return "Bearer " + cookies.get("userSession");
}

export default class ServicesBase {
    constructor(baseUrl: string) {
        this.changeBaseUrl(baseUrl);
    }

    changeBaseUrl(baseUrl) {
        baseUrl = baseUrl.replace("#server#", getServerUrl());
        this.baseUrl = baseUrl;
    }

    ServiceRequest = async function(endpoint, method, headers, loading = true, data = undefined, userId = undefined) {
        if(loading) GlobalState.set("loading", true);
        let response, body, error;
        if(!headers) headers = {};
        headers.Authorization = getAuthHeader();
        let url = this.baseUrl + endpoint;
        if(userId !== undefined) url = url.replace(":id",userId);
        console.log(method + " " + url);
        if (data instanceof FormData) delete headers["Content-Type"];
        response = await fetch(url, {
            method: method,
            headers: headers,
            //credentials: 'include',
            body: data instanceof FormData ? data : (method == "POST" || method == "PUT") && headers["Content-Type"] == "application/json" ? JSON.stringify(data) : data
        });
        if(loading) GlobalState.set("loading", false);
        try {
            let text: any = await response.text();
            response.rawText = text;
            try {
                text = JSON.parse(text);
            } catch (e) { }

            response.status < 400 ? (body = text) : (error = text);
            return (new Response(error, body, response.status, response));
        }
        catch(e) {
            error = {error:"An error happened while connecting to server, please try again later."};
            return (new Response(error, body, response.status, response));
        }
    };
}