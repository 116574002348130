import ServicesBase from "../../base"

class streamingAccount extends ServicesBase {
  constructor() {
      super("#server#" + "/streamers/:id/streamingAccount");
  }
  Add = async (userId, data, loading = true) => {
      return await this.ServiceRequest("/", 'POST', {
          'Content-Type': 'application/json',
        },
        loading,
        data, userId
      );
  };
  Update = async (userId, id, data, loading = true) => {
    return await (this.ServiceRequest("/"+id, 'PUT', {
      'Content-Type': 'application/json',
    }, loading, data, userId));
  };
  Get = async (userId, loading = true) => {
    return await (this.ServiceRequest("", 'GET', {}, loading, null, userId));
  };
  Delete = async (userId, idStreamingAccount, loading = true) => {
    return await (this.ServiceRequest("/"+idStreamingAccount, 'DELETE', {}, loading, null, userId));
  }
};

export default new streamingAccount();