import { getServerUrl } from '../services/base';
import UserUtils from '../../src/utils/userUtils'

export default {
    toBase64: file => new Promise((resolve, reject) => {
        console.log(file);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    }),
}

export const fileUrl = (url, streamer = true) => {
    let userType = streamer ? "streamers" : "advertisers";
    if (!url) return "";
    return url.startsWith("http") ? url : getServerUrl() + "/" + userType + "/" + UserUtils.GetUserData().id + "/media/" + url;
}

export const DownloadUrlVideo = (stream) => {
    return stream;
    //return getServerUrl() + "/streamers/" + UserUtils.GetUserData().id + "/mediaVideo/" + stream;
}