import React from 'react';
import { Button } from '@material-ui/core'
import { StreamingAccountAuth } from './streamingAccount-auth'

import { appendScript, removeScript } from '../../utils/jsscripts'

import { getFragmentParameter } from '../../utils/routerUtils'
import { handleAuthRedirect } from './handleAuthRedirect';

export class YoutubeAuthAccount extends handleAuthRedirect {    
    constructor(callback: (token) => any) {
        super(window.location.origin + "/api/users/redirectYoutbeAuth", "redirectYoutbeAuth", callback);
    }

    //TODO: Ara nomes cal a login, ja que es l'unica url que s'utilitza per recuperar el callback de google y postejarlo per event
    async componentDidMount() {
        let youtubeAccessToken = getFragmentParameter('YoutubeCode');
        if (youtubeAccessToken !== false) {
            this.postCredentials(JSON.parse(new Buffer(youtubeAccessToken, 'base64').toString("ascii")));
        }
    }

    componentDidUnmount() {}
}

export class StreamingAccountAuthYoutube extends StreamingAccountAuth {    
    render = () => {
        return <Button style={{ color: '#ffffff', backgroundColor: '#C43535' }} variant="contained" size="large" onClick={() => StreamingAccountAuthYoutube.AddYoutubeAccount(() => { window.location.reload(); })}>
                    This account requires you to login again                    
                </Button>
    }

    static AddYoutubeAccount(signcallback) {
        let youtubeAccount: YoutubeAuthAccount = new YoutubeAuthAccount(async (youtubeTokens) => { await StreamingAccountAuth.addStreamingAccount(youtubeTokens, "Youtube", signcallback); });
        youtubeAccount.OpenSignup();
    }
}