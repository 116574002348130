

import React from 'react';

import './deleteItemDialog.styles.scss'
import {Button, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText} from '@material-ui/core'
import {MuiThemeProvider} from '@material-ui/core/'
import ErrorWarnTheme from '../../../styles/ErrorWarnTheme';

import { LangStrs, Lang } from '../../../utils/languageUtils'

const strs: LangStrs = new LangStrs(new Map(
    [
        ['NotUndo', new Map(
            [
                [Lang.ES, 'Esta acción no podrá ser revertida.'],
                [Lang.EN, 'This action cannot be undone.']
            ])
        ],
        ['Cancel', new Map(
            [
                [Lang.ES, 'Cancelar'],
                [Lang.EN, 'Cancel']
            ])
        ],
        ['Delete', new Map(
            [
                [Lang.ES, 'Borrar'],
                [Lang.EN, 'Delete it']
            ])
        ]
    ]
));

class DeleteItemDialog extends React.Component {
    
    constructor(props) {
        super(props);
    };

    render = () => {
        return <Dialog
            open={true}
            keepMounted
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description">
                <DialogTitle id="alert-dialog-slide-title">{this.props.title}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    {this.props.labelMoreText || strs.get('NotUndo')}
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={()=>{
                        this.props.onCancel();
                }} color="primary">
                    {this.props.labelCancel || strs.get('Cancel')}
                </Button>
                <MuiThemeProvider theme={ErrorWarnTheme}>
                    <Button variant="contained" onClick={()=>{
                        this.props.onDelete();
                    }} color="primary">
                        {this.props.labelDelete || strs.get('Delete')}
                    </Button>
                </MuiThemeProvider>
                </DialogActions>
            </Dialog>
    }
}

export default DeleteItemDialog;