import React from 'react';
import { LangStrs, Lang } from '../../utils/languageUtils'
import { BrowserView, MobileView, isBrowser, isMobile } from '../../utils/platformsUtils';
import './homepage.styles.scss';
import { Button, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles'
import { changeUrl, Urls } from './../../utils/routerUtils';
//import X from '../../components/x/x.component';

const StyledButton = (selected) => withStyles({
    root: {
        backgroundColor: selected ? '#8CD0F1' : '#1347be',
        marginBottom: '15px',
        marginLeft: '15px',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#8CD0F1',
            color: '#fff',
        },
    }
})(Button);

const SButton = StyledButton(false);

const HomePage = () => {
    let strs: LangStrs = new LangStrs(new Map(
        [
            ['slogan', new Map(
                [
                    [Lang.ES, 'Eleva tu contenido a otro nivel!'],
                    [Lang.EN, 'Power up your content to another level!']
                ])
            ],
            ['knowMore', new Map(
                [
                    [Lang.ES, 'Quiero saber más'],
                    [Lang.EN, 'I want to know more']
                ])
            ],
            ['iAmStreamer', new Map(
                [
                    [Lang.ES, 'Soy streamer'],
                    [Lang.EN, 'I\'m a streamer']
                ])
            ],
            ['iAmBrand', new Map(
                [
                    [Lang.ES, 'Soy un anunciante'],
                    [Lang.EN, 'I\'m an advertiser']
                ])
            ],
            ['iAmAgency', new Map(
                [
                    [Lang.ES, `Soy una agencia`],
                    [Lang.EN, `I'm an agency`]
                ])
            ],
        ]
    ));

    const animation = () => 'float ' + (Math.random() * 2000 + 5000) + 'ms ease-in-out infinite';

    return (
        <div className='homepage' style={{'alignItems': 'center', width: '100%' }} >
            <div>
                <h1 style={{ 'textAlign': 'center' }}>{strs.get('slogan')}</h1>
                <Grid container xl={12} xs={12} direction="row" justify="center" alignItems="center" wrap style={{ "padding": "20px" }} >
                    <div class="avatar" style={{ 'animation': animation() }}>
                        <img src="./img/platforms/twitch-circled.png" width='60px' />
                    </div>
                    <div class="avatar" style={{ 'animation': animation() }}>
                        <img src="./img/platforms/youtube-circled.png" width='60px' />
                    </div>
                    <div class="avatar" style={{ 'animation': animation() }}>
                        <img src="./img/platforms/tiktok-circled.png" width='60px' />
                    </div>
                    <div class="avatar" style={{ 'animation': animation() }}>
                        <img src="./img/platforms/instagram-circled.png" width='60px' />
                    </div>
                    <div class="avatar" style={{ 'animation': animation() }}>
                        <img src="./img/platforms/XTwitter.png" width='60px' />
                    </div>
                </Grid>
                <img src="./img/home/streaming2.png" width='100%' style={{ 'max-width': '800px', 'display': 'block', 'margin': 'auto auto' }} />                
                <h2 style={{ 'textAlign': 'center' }}>{strs.get('knowMore')}</h2>
                <Grid container xl={12} xs={12} direction="row" justify="center" alignItems="center" wrap style={{ "padding": "0px" }} >
                    <SButton onClick={(e) => { changeUrl(Urls.InfoStreamer) }} style={{ width: isMobile?"33%": "10%" } } >
                        <Grid item justify="center">
                            <img src="./img/home/streamerIcon.png" width='80px' style={{ 'display': 'block', 'margin': 'auto auto' }}/>
                            <h3 style={{ 'textAlign': 'center' }}>{strs.get('iAmStreamer')}</h3>
                        </Grid>
                    </SButton>
                    <SButton onClick={(e) => { changeUrl(Urls.InfoAdvertiser) }} style={{ width: isMobile ? "33%" : "10%" }}>
                        <Grid item justify="center" >
                            <img src="./img/home/brandIcon.png" width='80px' style={{ 'display': 'block', 'margin': 'auto auto' }}/>
                            <h4 style={{ 'textAlign': 'center' }}>{strs.get('iAmAgency')}</h4>
                        </Grid>
                    </SButton>
                </Grid>
            </div>
        </div>
    )
}

export default HomePage;