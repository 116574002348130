import React, { useState, useEffect  } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Typography from '@material-ui/core/Typography';
import { isBrowser, isMobile, MobileView } from '../../../utils/platformsUtils';

function ComTooltip(props) {
    const [open, setOpen] = useState(false);

    const handleTooltipOpen = () => {
        if (isMobile) {
            setOpen(!open);
        }
    };

    const handleClickOutside = (event) => {
        if (isMobile && open && !event.target.closest("button")) {
            setOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    }, [open]);

    return (
        <Tooltip
            title={
                props.text
            }
            arrow            

            {...!isMobile ? {} : { open: open }}
            {...!isMobile ? {}  : {disableHoverListener: true}}
        >
            <IconButton onClick={handleTooltipOpen}>
                <HelpOutlineIcon />
            </IconButton>
        </Tooltip>
    );
}

export default ComTooltip;