import React from 'react';

import { Grid } from '@material-ui/core';

import GlobalState from '../../store/globalState'
import UserUtils, { UserTypes } from '../../utils/userUtils'

import ProfileDropdown from '../profile-dropdown/profile-dropdown.component'

import OptionsService from '../../services/api/options/';
import ModalDialog from '../../components/common/modal/modal'
import StreamerDescriptions from '../../services/api/streamerDescription/';
import StreamerAPI from '../../services/api/streamers'
import UserAPI from '../../services/api/users'
import { LangStrs, Lang } from '../../utils/languageUtils'
import { isSandbox } from '../..';

let strs: LangStrs = new LangStrs(new Map(
    [
        ['BackToCheck', new Map(
            [
                [Lang.ES, 'Si cambias las opciones que describen el banner, este deberá volver a ser aprobado'],
                [Lang.EN, 'If you change the options that describe the banner, it must be approved again']
            ])
        ]
    ]
));

export class OptionsToCheckRequired {
    Options: Options[] = [];
    addOption(o) {
        this.Options.push(o);
        this.firstCheckDone = false;
    }

    checkRequeriments() {
        var r = !this.Options.some(o => o != null && o.RequestCompleteRequeriments());
        console.log("checkRequeriments " + r);
        return r;
    }

    firstCheck() {
        if (!this.firstCheckDone) {
            window.setTimeout(() => this.checkRequeriments(), 1000);
        }
        this.firstCheckDone = true;
    }
}

const SelectableOnTypes = {
    NoSelectableByUser : 0, //El propio usuario no puede describirlo
    OnInitial : 1, //Aparece de inicio para selecionarse
    FromRecomendation : 2 //Aparece solo cuando otra opcion es marcada i esta es su especificacion o cuando ya se ha especificado anteriormente
};

class Options extends React.Component {    
    constructor(props) {
        super(props);
        this.state = {
            tags: [],
            loaded: false
        }

        this.reactTags = React.createRef()
        if (props.optionsToCheckRequiredref != undefined) props.optionsToCheckRequiredref.addOption(this);

        this.alertBackToCheck = props.alertBackToCheck === true;
        this.noReqComplete = undefined;
    }

    componentDidMount = () => {
        GlobalState.set("loading", true);
        if (!this.state.loaded) {
            this.GetTags();
        }
    }

    OnOptionClick = async (option) => {
        if (!isSandbox && !UserUtils.isUserType(UserUtils.UserTypes.Admin, true) && this.alertBackToCheck) {
            let r = await this.modalChangeToCheck.showAndGetResult();
            if (!r) return;
            this.alertBackToCheck = false;
        }
        let data = {};
        if (this.props.excludeOnSelection === true) data.selType = "NEQ";
        
        await OptionsService.changeOptionSelection(option, this.props.uriSel, !option.selected, data);
        await this.GetTags();

        if (this.props.OnChangedOption != undefined) this.props.OnChangedOption();
    }

    callOnRequerimentsComplete(req) {
        if (this.props.OnRequerimentsComplete != undefined) this.props.OnRequerimentsComplete(this, req==undefined, req);
    }

    RequestCompleteRequeriments() {
        if (this.noReqComplete != undefined) {
            this.doScroll(this.noReqComplete.id);
            return true;
        }
        return false;
    }

    doScroll(id) {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView();
        }
    }

    GetTags = async (loading = true) => {
        let rOptions = await OptionsService.Get(this.props.uriOptions, loading);        
        this.setState({
            bannerTypeError: {
                error: rOptions.response.error,
                code: rOptions.response.status
            }
        });
        if (rOptions.response.status < 400) {
            let b: [] = rOptions.body;
            if (b.length > 0) {
                let rOptionsSel = await OptionsService.GetSelOptions(this.props.uriSel, UserUtils.GetUserData().id, loading);
                
                if (this.props.filterType != "")
                    rOptions.body = rOptions.body.filter((s) => s.type == this.props.filterType);

                let OptionsToShow = [];

                for (let i = 0; i < b.length; i++) b[i].someSel = false;
                
                rOptionsSel.body.forEach((bf) => {
                    let selected: boolean = false;
                    for (let i = 0; i < b.length; i++) {
                        if (b[i].id == bf.optionSet.id) {
                            b[i].someSel = false;
                            for (let x = 0; x < b[i].options.length; x++) {
                                for (let y = 0; y < bf.option.length; y++) {
                                    if (b[i].options[x].id == bf.option[y].id) {
                                        selected = true;
                                        b[i].someSel = true;
                                        b[i].options[x].selected = true;
                                        OptionsToShow = OptionsToShow.concat(b[i].id);
                                        OptionsToShow = OptionsToShow.concat(b[i].options[x].recomendations);
                                    }
                                }
                            }
                        }
                    }
                });

                if (!this.props.noCheckRequerminet) this.noReqComplete = b.find(i => (i.required && (i.selectableOn == SelectableOnTypes.OnInitial || OptionsToShow.includes(i.id))) && !i.someSel);
                
                if (this.props.optionsToCheckRequiredref != undefined) this.props.optionsToCheckRequiredref.firstCheck();

                rOptions.body = rOptions.body.filter((s) => s.selectableOn != SelectableOnTypes.FromRecomendation || OptionsToShow.includes(s.id));

                if (!rOptions || rOptions.length == 0) rOptions = [];
                this.setState({ tags: rOptions.body });
                this.props.OnGetTags && this.props.OnGetTags(rOptions.body);
            }

            this.setState({ loaded: true });
        } else this.setState({ error: rOptions.error });
    }

    render() {
        return (
            this.state.tags.length > 0 &&
            <React.Fragment>
                <ProfileDropdown parent={this} viewOptions={this.props.viewOptions} OnTagClickCheck={this.OnOptionClick} tags={this.state.tags} title={this.props.title} noCheckRequerminet={this.props.noCheckRequerminet} />
                <ModalDialog ref={(m) => { this.modalChangeToCheck = m; }}>
                    {strs.get('BackToCheck')}
                </ModalDialog>
            </React.Fragment>
        );
    }
}

export default Options;