import React, { useState, useEffect, Fragment, useRef  } from 'react';
import { appendScript, removeScript } from '../../utils/jsscripts'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

import { commService } from '../../services/api/list';
import ServicesBase from '../../services/base';
import UserUtils from '../../utils/userUtils';
import { delay, DurationTime } from '../../utils/timeUtils';
import { mapGenericStateToProps } from '../../utils/routerUtils'

class banner {
    component = null;
    candidate = null;
    banner = null;
    constructor(candidate, banner) {
        this.candidate = candidate;
        this.banner = banner;
    }
}

class TimeoutWrapper {
    constructor() {
        this.timeouts = [];
    }

    setTimeout = (callback, delay) => {
        const timeoutId = setTimeout(() => {
            callback();
            this.removeTimeout(timeoutId);
        }, delay);

        this.timeouts.push(timeoutId);
        return timeoutId;
    };

    removeTimeout = (timeoutId) => {
        this.timeouts = this.timeouts.filter((id) => id !== timeoutId);
    };

    cancelAll = () => {
        this.timeouts.forEach((timeoutId) => clearTimeout(timeoutId));
    };
}

const getTime = () => {
    return parseInt(Date.now() / 1000);
}

const setTransparentBackGround = () => {
    document.body.style.backgroundColor = "rgba(0, 0, 0, 0)";
}

let LastOk = getTime();
let GiveUp = false;

let gBanners: StreamingBanner[] = [];

export class StreamingSceneComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            scene: null,
            banners: null,
            errorMSG: "",
            showError:false
        }
        this.sceneKey = this.props.match.params.sceneKey;
        this.sceneId = this.sceneKey.indexOf("_") != -1 ? this.sceneKey.split("_")[0] : this.sceneKey;
        this.timeoutWrapper = new TimeoutWrapper();
    }

    pathlibgif : "./libgif/libgif.js";
    banners: banner[];
    h1MSG: any;
    Banners: banner[];
    async componentDidMount() {        
        const setVisibleInt = async (b) => {
            await this.setState({ showError: b });
            setTimeout(() => setVisibleInt(!b), b ? 10 * 1000 : 30 * 1000);
        }
        setVisibleInt(true);

        let rScene = await new commService("/streamers/streamScene").Get(null, this.sceneKey);
        this.Banners = rScene.body[0].banners.map(b => {
            return new banner(null, b);
        });

        await this.setState({ scene: rScene.body, banners: this.Banners });

    
        this.controlFullScreen = setInterval(() => {
            checkFullScreen();
        }, 100);
    }
    medias = [];

    getMediaEntry(path) {
        for (let i = 0; i < this.medias.length; i++) {
            if (this.medias[i].path == path) return this.medias[i];
        }
        return null;
    }

    getMedia(path) {
        let m = this.getMediaEntry(path);
        if (m != null) {
            if (m.promise != null) {
                return m.promise;
            }
            return m.data; //No hace falta meterlo en un promesa?!
        }

        let p = new Promise((resolve, reject) => {
            var req = new XMLHttpRequest();
            console.log("get banner " + path);
            req.open('GET', path, true); //glee59g7y.mp4
            req.responseType = 'blob';
            req.onload = (resp) => {               
                if (resp.srcElement.status === 200) {
                    let m = this.getMediaEntry(path);

                    const data = URL.createObjectURL(resp.srcElement.response); //OJO PUTSE A IMG NO FUNCIONA
                    m.data = data;
                    m.promise = null;
                    resolve(data);
                } else reject("status " + resp.srcElement.status);
            }
            req.send();
        });

        m = { path: path, data: null, promise: p };
        this.medias.push(m);

        return p;
    }
    
    render = () => {        
        return (
            <parent>
                { this.state.showError && <p style={{ maxWidth: '200px', color: '#0077be', 'margin':'10px' }}> <h3 ref={(c) => { this.h1MSG = c; if (this.state.errorMSG) this.error(this.state.errorMSG); }}></h3></p> }
                {                    
                    this.state.banners != null && this.state.banners.map(b => {
                        return (<StreamingBanner ref={(c) => gBanners.push(c) } key={b.banner.streamSceneBannerStreamerId} posX={b.banner.posX} posY={b.banner.posY} parent={this} banner={b.banner} sceneKey={this.sceneKey}/>);
                    })
                }
            </parent>
        );
    }

    lErrorMSG = "";
    error(errorMSG = null) {
        if (errorMSG == null) {
            errorMSG = this.lErrorMSG;
        } else {
            this.lErrorMSG = errorMSG;
        }
        console.log(`errorMSG: "${errorMSG}"`);
        if (this.h1MSG != null) {
            let eMSG = "";
            if (errorMSG != "") {
                this.timeoutWrapper.cancelAll();

                if (errorMSG == "ENoVod") {
                    eMSG = "PowerAds alert: The banners on the scene are not rendering because you do not have the post-live viewing functionality of your stream enabled."
                } else
                    eMSG = "PowerAds alert: The scene banners are not rederizing, probably your streaming application scene is not properly configured or you are offline."

                if (GiveUp)
                    eMSG += "\n\nGiven that there is a persisting problem, we have deactivated the PowerAds component. When you believe the configuration issue has been resolved, enable/disable the browser scene or layer to get the component working again"
            }
            this.h1MSG.innerHTML = eMSG;
        }
    }
}

class BannerLayer {
    candidate = {};
    comp = {};
    div = {};

    setBanner(media, candidate) {
        this.candidate = candidate;

        this.comp.width = candidate.banner.sizeX;
        this.comp.height = candidate.banner.sizeY;
        this.div.style.display = "initial";

        setTimeout((c) => {
            if (c == this.candidate) this.hideLayer();
        }, candidate.banner.time * 1000, candidate);
    }

    hideLayer() {
        if (this.div!=null)
            this.div.style.display = "none";
    }
}

const checkFullScreen = () => {
    let e: boolean = false;
    for (let i = 0; i < gBanners.length; i++) {
        if (gBanners[i]!=null)
            e |= gBanners[i].layerVideo.executingFullScreen;
    }
    if (e)
        document.body.style.backgroundColor = "black";
    else
        setTransparentBackGround();
}

class BannerLayerVideo extends BannerLayer{
    executingFullScreen = false;
    isFullScreen = false;
    controlFullScreen = null;

    setBanner(media, candidate) {
        this.isFullScreen = candidate.banner.streamOnFullScreen === true;

        this.comp.src = media;
        this.comp.play();        

        //if (this.isFullScreen) {            
        //    const setBlack = () => document.body.style.backgroundColor = "black";
        //    setBlack();
        //    this.controlFullScreen = setInterval(() => {
        //        if (this.isFullScreen) setBlack();
        //    }, 100);
        //}
        this.executingFullScreen = this.isFullScreen;
        this.comp.muted = !this.isFullScreen;
        checkFullScreen();

        BannerLayer.prototype.setBanner.call(this, media, candidate);
    }

    hideLayer() {
        this.executingFullScreen = false;
        checkFullScreen();

        BannerLayer.prototype.hideLayer.call(this);
    }
}

class BannerLayerGif extends BannerLayer {
    GifInitialize() {
        this.comp = new window.SuperGif({ gif: this.comp, on_end: this.gif_OnEnd.bind(this) });
        this.comp.pause();
    }

    gif_OnEnd() {
        //console.log("END");
    }

    setBanner(media, candidate) {
        this.comp.load_url(media, () => {
            this.comp.play();
            BannerLayer.prototype.setBanner.call(this, media, candidate);
        });        
    }
}

class BannerLayerImg extends BannerLayer {
    async setBanner(media, candidate) {
        this.comp.src = media;
        BannerLayer.prototype.setBanner.call(this, media, candidate);
    }

    blobToBase64(blob) {
        return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
    }
}

const Layer = { Gif: 0, Video: 1, Img: 2 };

class StreamingBanner extends React.Component {     
    layerGif = new BannerLayerGif;
    layerVideo = new BannerLayerVideo;
    layerImg = new BannerLayerImg;
    layers: BannerLayer[] = [this.layerGif, this.layerVideo, this.layerImg];
    isShowing: boolean = false;

    parent: StreamingSceneComponent;
    constructor(props) {
        super(props);

        this.state = {
            display: -1
        }
        this.parentDiv = React.createRef();
    }

    async componentDidMount() {
        this.setBanner(this.props.parent, this.props.banner);
        appendScript("./libgif/libgif.js", this.OnLoadLibGif.bind(this)); //TODO: podria fer que el constructo em modifiques appendScript i componentDidUnmount
    }
    componentDidUnmount() {
        removeScript("./libgif/libgif.js")
    }

    OnLoadLibGif() {
        this.layers[Layer.Gif].GifInitialize();
    }
    
    render = () => {
        // http://localhost:8000/api/advertisers/4d1e83f0-f772-11eb-a426-effbd6766594/media/bcf4fcf25c5d7b33b65b0905bbc52c83

        return (
            <div ref={this.parentDiv} style={{ width: "100%", position: "absolute", zIndex: "10", left: +this.props.posX, top: this.props.posY }}>
                <div style={{ width: "100%", opacity: "1", position: "absolute", zIndex: "10", left: 0, top: 0, display: "none" }} ref={(c) => { this.layers[Layer.Img].div = c; }}>
                    <img id="img" ref={(c) => { this.layers[Layer.Img].comp = c; }} />
                </div>
                <div style={{ width: "100%", opacity: "1", position: "absolute", zIndex: "10", left: 0, top: 0, display: "none" }} ref={(c) => { this.layers[Layer.Gif].div = c; }}>
                    <img id="mygif" ref={(c) => { this.layers[Layer.Gif].comp = c; }} />
                </div>
                <div style={{ width: "100%", opacity: "1", position: "absolute", zIndex: "10", left: 0, top: 0, display: "none" }} ref={(c) => { this.layers[Layer.Video].div = c; }}>
                    <video id="video1" width="420" ref={(c) => { this.layers[Layer.Video].comp = c; }}/>
                </div>
                {/*
                <div style={{ width: "100%", opacity: "1", position: "absolute", zIndex: "10", left: +this.props.posX + 200, top: this.props.posY }} >
                    {this.state.displayStart}
                </div>
                */}
            </div>
        );
    }

    setDims(comp, candidate) {
        comp.width = candidate.banner.sizeX;
        comp.height = candidate.banner.sizeY;
    }

    async setBanner(parent, banner) {
        banner.component = this;
        this.parent = parent;
        this.banner = banner;

        console.log("[" + this.banner.streamSceneBannerStreamerId + "] " + "VAMOS ALLA");
        await this.getCandidate();
    }
    
    async getCandidate(displayStart = -2) {
        if ((getTime() - LastOk) > 15 * 60) {
            GiveUp = true;
            this.parent.error();
            return;
        }
        let sb: ServicesBase = new ServicesBase("#server#" + "/streamers/" + this.props.sceneKey + "/banner/" + this.banner.streamSceneBannerStreamerId + "/getToStream");
        let resp = await sb.ServiceRequest("", 'POST', { 'Content-Type': 'application/json' }, false, { displayStart: displayStart, streamerTime: Date.now() });

        if (resp.error != null) {            
            this.parent.error(resp.error.errorMSG != undefined ? resp.error.errorMSG : "Error: banner error");
            this.parent.timeoutWrapper.setTimeout(() => this.getCandidate(), 10 * 1000);
            return;
        } else {
            this.parent.error("");
        }
            

        LastOk = getTime();
        let candidate = resp.body;

        if (candidate==undefined || candidate.banner == undefined ){ // No hay candidatos
            this.parent.timeoutWrapper.setTimeout(() => this.getCandidate(), 10 * 1000);
            return;
        }
        this.setState({ displayStart: candidate.displayStart });
        this.diffServerTime = candidate.serverTime - getTime();
        //console.log("this.diffServerTime " + this.diffServerTime);

        const media = await this.parent.getMedia(candidate.banner.mediaUrl);

        for (let i = 0; resp.body.preBanners != undefined && i < resp.body.preBanners.length; i++) {
            await this.parent.getMedia(resp.body.preBanners[i].mediaUrl);
        }

        //await new Promise(async (resolve, reject) => { //En principio el anuncio anterior tendria que estar oculta antes de llegar aqui
        //    while (this.isShowing) await delay(1);
        //    resolve(true);
        //});

        const sTime = this.diffServerTime + getTime();
        if ((sTime + 1) > candidate.displayStart) {
            console.log("[" + this.banner.streamSceneBannerStreamerId + "] " + "CANDIDATE TIME OUT " + candidate.banner.name);
            this.parent.timeoutWrapper.setTimeout(() => this.getCandidate(), 10);
        } else {
            console.log("[" + this.banner.streamSceneBannerStreamerId + "] " + "RECIVED CANDIDATE " + candidate.banner.name);
            //console.log("DIFF TIME1 " + candidate.displayStart + " " + sTime +" = " + (((candidate.displayStart - sTime) * 1000) - 1000) / 1000);
            console.log("[" + this.banner.streamSceneBannerStreamerId + "] " + "SHOW ELAPSE " + Math.max(0, ((candidate.displayStart - sTime) * 1000) - 2000));
            this.parent.timeoutWrapper.setTimeout(() => { this.setCandidate(candidate, media) }, Math.max(0, ((candidate.displayStart - sTime) * 1000) - 2000)); // -1000 per donar temps a la peticio de set
        }
    }
    
    async setCandidate(candidate, media) {
        console.log("[" + this.banner.streamSceneBannerStreamerId + "] " + "setCandidate function with " + candidate.banner.name);
        let sb: ServicesBase = new ServicesBase("#server#" + "/streamers/" + this.props.sceneKey + "/banner/" + this.banner.streamSceneBannerStreamerId + "/selectToStream");
        let resp = await sb.ServiceRequest("", 'POST', { 'Content-Type': 'application/json' }, false, candidate);

        if (resp.httpCode == 200 && resp.body.result == "YES") {
            console.log("[" + this.banner.streamSceneBannerStreamerId + "] " + "CANDIDATE CONFIRMED " + candidate.banner.name);
            //console.log("DIFF TIME2 " + (candidate.displayStart - (this.diffServerTime + this.getTime())));
            this.parent.timeoutWrapper.setTimeout(() => this.showBanner(candidate, media), Math.max(0, (candidate.displayStart - (this.diffServerTime + getTime())) * 1000 ));
        } else {
            console.log("[" + this.banner.streamSceneBannerStreamerId + "] " + "CANDIDATE DENIED " + candidate.banner.name);
            this.parent.timeoutWrapper.setTimeout(() => this.getCandidate(), 10);
        }        
    }

    showBanner(candidate, media) {
        this.parentDiv.current.style.position = "absolute";
        this.parentDiv.current.style.left = candidate.posX + "px";
        this.parentDiv.current.style.top = candidate.posY + "px";

        const iLayer = candidate.banner.mediaType == "video" ? Layer.Video : Layer.Img;
        let s = 0;
        for (let l = 0; l < this.layers.length; l++) {
            if (l == iLayer) {
                s = l;
                this.layers[l].setBanner(media, candidate);
            }
            else this.layers[l].hideLayer();
        }        

        //this.isShowing = true;
        //this.parent.timeoutWrapper.setTimeout(() => {
        //    this.layers[s].hideLayer();
        //    this.isShowing = false;
        //}, candidate.banner.time * 1000);

        this.getCandidate(-candidate.banner.time);
    }
}

export default connect(mapGenericStateToProps, null)(withRouter(StreamingSceneComponent));
