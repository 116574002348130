
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import UserUtils from '../../utils/userUtils'
import { UserTypes } from '../../utils/userUtils'
import { changeUrl, Urls } from '../../utils/routerUtils'
import { toMStr } from '../../utils/moneyUtils.jsx'
import { Grid, Button, TableCell, TableRow } from '@material-ui/core'
import { Delete, Visibility, AttachMoney, Edit, FileCopy, CreditCard } from '@styled-icons/material';
import MuiAlert from '@material-ui/lab/Alert'
import AdItem from '../../components/advertiser/ad-item/ad-item.component'
import ContentNavigation from '../../structure/content-navigation/content-navigation.component'
import Advertisers from '../../services/api/advertisers/';
import GlobalState from '../../store/globalState'
import DeleteItemDialog from '../common/deleteItemDialog/deleteItemDialog.component.jsx';
import AddNewButton from '../common/add-new-button/add-new-button.component.jsx';

import { confAddItem, CommListGen, CommLinkButton, CommDeleteButtonLink, confListField, confList, CommList, CommItemField, CommItemOfItems, CommButtonLinkItem, CommItemFieldTextLink, ListFieldButtonLink } from '../common/com-List/comm-list.components'
import { confListStatic, CommListStatic } from '../common/com-List/com-List-Static/comm-list-static-component'
import { myReactTaggedComponent, ReactComponentTag, CompWithParent } from '../common/extComponents'
import { CommItemFieldLink, CommSimpleTextButton } from '../common/com'
import { commService } from '../../services/api/list';

import { withStyles } from '@material-ui/core/styles'
import AdsList from '../ads-list/ads-list.components';

import { mapGenericStateToProps } from '../../utils/routerUtils'

import { DurationTime } from '../../utils/timeUtils';

import { LangStrs, Lang } from '../../utils/languageUtils'
import { bannerStrs } from '../../templates/languages/banner-strs.js'

import SelectUser, { UsersFilterTypes } from '../../components/admin/select-user/select-user.component.jsx'
import { TransferDetailsItemField, strs as WalletStrs } from '../../structure/wallet-form/wallet-form.component'
import { buttonPost } from '../common/com-List/comm-list-template-elements.jsx'
import { } from '../../structure/transactions-ads-stream/transactions-ads-stream'

let strs: LangStrs = new LangStrs(new Map(
    [
        ['titlePendingPayments', new Map(
            [
                [Lang.ES, 'Pagos pendientes'],
                [Lang.EN, 'Pending payments']
            ])
        ],
        ['User', new Map(
            [
                [Lang.ES, 'Usuario'],
                [Lang.EN, 'User']
            ])
        ],
        ['MarkPaid', new Map(
            [
                [Lang.ES, 'Marcar como pagado'],
                [Lang.EN, 'Mark as paid']
            ])
        ]
    ]
));

class TransactionsPendingPayment extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};

        this.conf = [
            new confListField(WalletStrs.get('Date'), CommItemField, {}, "createdAt"),
            new confListField(strs.get('User'), CommItemField, {}, (item) => item.user.username),
            new confListField(WalletStrs.get('Quantity'), CommItemField, {}, (item) => toMStr(item.quantity)),
            TransferDetailsItemField,
            buttonPost(strs.get('MarkPaid'), (item) => { return '#server#/Transfers/pendingToPay/' + item.id; }, 'PUT', { }, false, () => { this.list.QueryItems(); })
        ];
    }

    render = () => {
        return (
            <Grid xs="12">
                <CommList ref={(l) => this.list = l } Owner={this} title={strs.get('titlePendingPayments')} confComm={new confList(CommListGen, new commService("/Transfers/pendingToPay"), this.conf)} />
            </Grid>
        )
    }
}

export default connect(mapGenericStateToProps, null)(withRouter(TransactionsPendingPayment));