
import React, { useState, useEffect, Fragment } from 'react';

//import './streams-grid-admin.styles.scss'
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import ServicesBase from '../../../services/base';

import { UserSelected } from '../../../utils/userUtils'
import { LangStrs, Lang, getUserLang } from '../../../utils/languageUtils'
import { Grid } from '@material-ui/core';
import UserUtils, { UserTypes } from '../../../utils/userUtils'
import { SelectFromConst, SelectFromGet } from '../../../components/common/commonComponents.jsx'

const strs: LangStrs = new LangStrs(new Map(
    [
        ['SelUser', new Map(
            [
                [Lang.ES, 'Selecciona un usuario'],
                [Lang.EN, 'Select a user']
            ])
        ],
        ['SelType', new Map(
            [
                [Lang.ES, 'Tipo de busqueda'],
                [Lang.EN, 'Search type']
            ])
        ],
        ['streamToPay', new Map(
            [
                [Lang.ES, 'Streams con dinero'],
                [Lang.EN, 'Money streams']
            ])
        ],
        ['SelAll', new Map(
            [
                [Lang.ES, 'Todos'],
                [Lang.EN, 'All']
            ])
        ],
        ['SelStreamers', new Map(
            [
                [Lang.ES, 'Streamers'],
                [Lang.EN, 'Streamers']
            ])
        ],
        ['SelStreamersSAToApprove', new Map(
            [
                [Lang.ES, 'Streamers con cuentas de streaming por aprobar'],
                [Lang.EN, 'Streamers with Streaming accounts to approve']
            ])
        ],
        ['SelAdvertisers', new Map(
            [
                [Lang.ES, 'Anunciantes'],
                [Lang.EN, 'Advertisers']
            ])
        ],
        ['SelAdmins', new Map(
            [
                [Lang.ES, 'Administradores'],
                [Lang.EN, 'Admins']
            ])
        ],
        ['ToApprove', new Map(
            [
                [Lang.ES, 'Por aprobat'],
                [Lang.EN, 'To approve']
            ])
        ],
        ['SelAdvertisersWithBannersToCheck', new Map(
            [
                [Lang.ES, 'Banners por aprobar'],
                [Lang.EN, 'Banners To check']
            ])
        ],
        ['SelAdvertisersWithSponsToCheck', new Map(
            [
                [Lang.ES, `Patrocinios por aprobar`],
                [Lang.EN, `Sponsorships to approve`]
            ])
        ]
    ]
));

export const StreamFilterTypes = [
    { label: strs.get('SelStreamers'), value: 'streamers' },
    { label: strs.get('streamToPay'), value: 'streamToPay' }
];

export const AdsFilterTypes = [
    { label: strs.get('SelAdvertisers'), value: 'advertisers' },
    { label: strs.get('SelAdvertisersWithBannersToCheck'), value: 'bannersToCheck' },
    { label: strs.get('SelAdvertisersWithSponsToCheck'), value: 'sponToCheck' },
];

export const UsersFilterTypes = [
    { label: strs.get('SelAll'), value: 'All' },
    { label: strs.get('ToApprove'), value: 'ToApprove' },
    { label: strs.get('SelStreamers'), value: 'streamers' },
    { label: strs.get('SelStreamersSAToApprove'), value: 'streamersSAToApprove' },
    { label: strs.get('SelAdvertisers'), value: 'advertisers' },
    { label: strs.get('SelAdmins'), value: 'admins' }
];

class SelectUser extends React.Component {
    render = () => {
        return (
            UserUtils.GetUserData(true).usertype == UserTypes.Admin && 
            <React.Fragment>
                <SelectUserAndFilter {...this.props}/>
            </React.Fragment>
        )
    }
}

class SelectUserAndFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFilterType: {
                value: this.props.filter[0].value
            }
        }
    }

    render = () => {
        const { selectedFilterType } = this.state;
        return (
            <React.Fragment>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <SelectFromConst defaultFirst Values={this.props.filter} onSelect={async (event, newValue) => { await this.setState({ selectedFilterType: newValue }); this.selectUser.update() }} />
                    </Grid>
                    <Grid item xs={9}>
                        <SelectUserGEN ref={(r) => this.selectUser = r} onSelect={(event, newValue) => this.props.OnSelectUser(newValue)} typeFilter={selectedFilterType ? selectedFilterType.value : ""} />
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }
}

class SelectUserGEN extends SelectFromGet {
    labelFields = ['username', 'email', 'id'];
    baseUrl = "/users/list/";

    handleOptionChange(event, newValue) {
        if (newValue != null) UserSelected.user = newValue.label;
        super.handleOptionChange(event, newValue);
    }
}

export default SelectUser;