import React, { useEffect, useState } from 'react';
import { LangStrs, Lang } from '../../utils/languageUtils'
import { Movie as MovieIcon } from "@material-ui/icons"
import { BrowserView, MobileView, isBrowser, isMobile } from '../../utils/platformsUtils';
import './streamerpage.styles.scss';
import { Button, Grid, Box, CircularProgress, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles'
import ReactPlayer from "react-player";
//import X from '../../components/x/x.component';

const StyledButton = (selected) => withStyles({
    root: {
        backgroundColor: selected ? '#8CD0F1' : '#1347be',
        marginBottom: '15px',
        marginLeft: '15px',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#8CD0F1',
            color: '#fff',
        },
    }
})(Button);

const SButton = StyledButton(false);

const descCard = (image, title, desc) => {
    return(
        <Grid item xl={2} justify="center" >
            <Grid container direction="column"  >
                <Grid item justify="center">
                    <img src={image} style={{ "max-height": "200px" }} />
                </Grid>
                <Grid item justify="center">
                    <h2 justify="center">{title}</h2>
                </Grid>
                <Grid item justify="center">
                    <h3>{desc}</h3>
                </Grid>
            </Grid>
        </Grid>
    )
}

const StreamerPage = () => {
    let strs: LangStrs = new LangStrs(new Map(
        [
            ['title1', new Map(
                [
                    [Lang.ES, 'Aumenta los beneficios de tu contenido ya!'],
                    [Lang.EN, 'Increase the benefits of your content now!']
                ])
            ],
            ['desc1', new Map(
                [
                    [Lang.ES, 'Agrega PowerAds a los contenidos de tus redes sociales y empieza a tener una poderosa nueva fuente de monetización de tu creatividad.'],
                    [Lang.EN, 'Add PowerAds to your social media content and get a new powerful source of monetization for your creativity.']
                ])
            ],
            ['howDo', new Map(
                [
                    [Lang.ES, '¿Cómo hacerlo?'],
                    [Lang.EN, 'How can I do it?']
                ])
            ],
            ['howDoTitle1', new Map(
                [
                    [Lang.ES, 'Crea tu cuenta'],
                    [Lang.EN, 'Sign up for an account']
                ])
            ],
            ['howDoDesc1', new Map(
                [
                    [Lang.ES, 'Puedes crear tu cuenta registrandote o usando tu cuenta de youtube/twitch'],
                    [Lang.EN, 'You can create your account by registering or using your youtube/twitch account.']
                ])
            ],
            ['howDoTitle2', new Map(
                [
                    [Lang.ES, 'Elije tus preferencias'],
                    [Lang.EN, 'Choose your preferences']
                ])
            ],
            ['howDoDesc2', new Map(
                [
                    [Lang.ES, 'Seleccionando las múltiples opciones que ofrecemos, nuestro selector solo mostrará los anuncios que sean de tu gusto.'],
                    [Lang.EN, 'By selecting between the multiple options we offer, our selector will show the ads that are to your liking.']
                ])
            ],
            ['howDoTitle3', new Map(
                [
                    [Lang.ES, 'Crea tu contenido'],
                    [Lang.EN, 'Produce your content']
                ])
            ],
            ['howDoDesc3', new Map(
                [
                    [Lang.ES, 'Ya sea creando contenido en directo o creando tus vídeos, continua creando tu contenido igual, solo debes enlazarte con PowerAds y nosotros nos encargamos de todo para enriquecerlo con visibilidad para las marcas.'],
                    [Lang.EN, "Whether streaming or creating videos, keep doing the same, just integrate it with PowerAds and we'll take care of everything and enrich it with the visualization of brands."]
                ])
            ],
            ['howDoTitle4', new Map(
                [
                    [Lang.ES, 'Obtén tus ganancias'],
                    [Lang.EN, 'Collect your profits']
                ])
            ],
            ['howDoDesc4', new Map(
                [
                    [Lang.ES, 'Transforma las visualizaciones de tu contenido en dinero desde el primer momento'],
                    [Lang.EN, "You'll transform the content views into money from the very start."]
                ])
            ]
        ]
    ));

    return (
        <div className='homepage' style={{'alignItems': 'center', width: '100%' }} >
            <div>                
                <Grid container xl={12} xs={12} direction="column" justify="center" alignItems="center" wrap style={{ "padding": "20px" }} >
                    <Grid item justify="center">
                        <Grid container xl={12} xs={12} direction="row" justify="center"  wrap style={{ "padding": "0px" }} >
                            <Grid item xl={6} justify="center">
                                <img src="./img/streamer/creator.png" width='100%' style={{ 'max-width': '600px', 'display': 'block', 'margin': 'auto auto' }} />
                            </Grid>
                            <Grid item xl={6} justify="center" style={{ "margin": "auto" }} >
                                <h1>{strs.get('title1')}</h1>
                                <h2>{strs.get('desc1')}</h2>
                            </Grid>
                        </Grid>
                        <Grid container xl={12} xs={12} direction="row" justify="center" wrap style={{ "padding": "0px" }} >
                            <h1>{strs.get('howDo')}</h1>
                        </Grid>
                        <Grid className='how-do-it' container xl={12} xs={12} direction="row" justify="center" wrap style={{ "padding": "0px" }} spacing={40}>
                            {descCard('./img/streamer/login.svg', strs.get('howDoTitle1'), strs.get('howDoDesc1'))}
                            {descCard('./img/streamer/select.svg', strs.get('howDoTitle2'), strs.get('howDoDesc2'))}
                            {descCard('./img/streamer/creating.png', strs.get('howDoTitle3'), strs.get('howDoDesc3'))}
                            {descCard('./img/streamer/earn1.png', strs.get('howDoTitle4'), strs.get('howDoDesc4'))}
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default StreamerPage;