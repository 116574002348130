
import React from 'react';

import { Grid, Link, Button, AppBar, Hidden } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom';
import { BrowserView, MobileView, isBrowser, isMobile } from '../../utils/platformsUtils';
import { LangStrs, Lang } from '../../utils/languageUtils'
import { Urls } from '../../utils/routerUtils';

export class Footer extends React.Component {
    render() {
        let strs: LangStrs = new LangStrs(new Map(
            [
                ['PrivacyPolicy', new Map(
                    [
                        [Lang.ES, 'Política de Privacidad y Aviso Legal'],
                        [Lang.EN, 'Privacy Policy and Legal Notice']
                    ])
                ],
                ['Cookies', new Map(
                    [
                        [Lang.ES, 'Política de Cookies'],
                        [Lang.EN, 'Cookies Policy']
                    ])
                ]
            ]
        ));

        return (
            <div style={{ "text-align": "center" }}>
                <Link component={RouterLink} to="/privacypolicy" style={{ "padding": "10px" }}>{strs.get('PrivacyPolicy')}</Link>
                |
                <Link component={RouterLink} to="/cookiespolicy" style={{ "padding": "10px" }}>{strs.get('Cookies')}</Link>
                <br/>
                Copyright 2022 © All rights Reserved. PowerAds.
            </div>
        )
    }
}

export default Footer;