
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

import UserUtils from '../../utils/userUtils'
import { Grid, Button, TableCell, TableRow } from '@material-ui/core'
import { Delete, Visibility, AttachMoney, Edit, FileCopy, CreditCard } from '@styled-icons/material';
import MuiAlert from '@material-ui/lab/Alert'
import AdItem from '../../components/advertiser/ad-item/ad-item.component'
import ContentNavigation from '../../structure/content-navigation/content-navigation.component'
import Advertisers from '../../services/api/advertisers/';
import GlobalState from '../../store/globalState'
import DeleteItemDialog from '../common/deleteItemDialog/deleteItemDialog.component.jsx';
import AddNewButton from '../common/add-new-button/add-new-button.component.jsx';

import { confAddItem, CommListGen, CommLinkButton, CommDeleteButtonLink, confListField, confList, CommList, CommItemField, CommItemOfItems, CommButtonLinkItem, CommItemFieldTextLink, ListFieldButtonLink } from '../common/com-List/comm-list.components'
import { confListStatic, CommListStatic } from '../common/com-List/com-List-Static/comm-list-static-component'
import { myReactTaggedComponent, ReactComponentTag, CompWithParent } from '../common/extComponents'
import { CommItemFieldLink, CommSimpleTextButton } from '../common/com'
import { buttonPost } from '../common/com-List/comm-list-template-elements.jsx'
import { commService } from '../../services/api/list';

import { withStyles } from '@material-ui/core/styles'
import AdsList from '../ads-list/ads-list.components';
import { rLinkNum } from '../ads-list/ads-list.components';

import { mapGenericStateToProps } from '../../utils/routerUtils'

import { DurationTime } from '../../utils/timeUtils';
import { fileUrl } from '../../utils/fileUtils';
import { toMStr } from '../../utils/moneyUtils.jsx'
import { isDevelop } from '../..';

import { adsStrs } from '../../templates/languages/ads-strs.js'
import { LangStrs, Lang } from '../../utils/languageUtils'
import { dateStrToReadDateStr } from '../../utils/timeUtils'
import { getEnumFieldName } from '../../utils/utils'
import { getServerUrl } from '../../services/base';

export let strs: LangStrs = new LangStrs(new Map(
    [
        ['StreamTitle', new Map(
            [
                [Lang.ES, 'Título del stream'],
                [Lang.EN, 'Stream Title']
            ])
        ],
        ['Streamer', new Map(
            [
                [Lang.ES, 'Streamer'],
                [Lang.EN, 'Streamer']
            ])
        ],
        ['Displayed', new Map(
            [
                [Lang.ES, 'Mostrado'],
                [Lang.EN, 'Displayed']
            ])
        ],
        ['Amount', new Map(
            [
                [Lang.ES, 'Cantidad'],
                [Lang.EN, 'Amount']
            ])
        ],        
        ['Details', new Map(
            [
                [Lang.ES, 'Detalles'],
                [Lang.EN, 'Details']
            ])
        ],
        ['Streams', new Map(
            [
                [Lang.ES, 'Streams'],
                [Lang.EN, 'Streams']
            ])
        ],
        ['HideDet', new Map(
            [
                [Lang.ES, 'Ocultar detalles'],
                [Lang.EN, 'Hide details']
            ])
        ],
        ['Date', new Map(
            [
                [Lang.ES, 'Fecha'],
                [Lang.EN, 'Date']
            ])
        ],
    ]
));

class TransactionsAdsStream extends React.Component {
    constructor(props) {
        super(props);

        this.state = { };

        this.url = this.props.stream ? "/Transfers/:id/banner/" + this.props.match.params.id : "/Transfers/stream/" + this.props.stream;
    }

    render = () => {
        return React.createElement(() =>
            <Grid xs="12">
                <AdsList title={adsStrs.get('TAdvertisement')} id={this.props.match.params.id}/>
                <BannerToStreamTransferList banner={this.props.match.params.id} url={"/Transfers/:id/banner/" + this.props.match.params.id}/>
            </Grid>
        );
    }
}

export class BannerToStreamTransferList extends React.Component { //TODO: el component de seleccio dusuari ja fa que els altres components tinguin lusuari seleccionat, pero aqui encara es fa de la manera antiga que es anar pasat el que sa seleccionat. Refero
    constructor(props) {
        super(props);        
        this.state = { details: null };

        this.CS = new commService(this.props.url);
        this.banner = this.props.banner;
        if (this.props.setRefItemSelector) this.props.setRefItemSelector(this);
    }

    render = () => {                
        let detailsComponents = [
            new confListField("", BannerToStream, { streamer: this.props.streamer != undefined }, "").addField("Condition", (item) => this.state.details == item.stream.id),
            new confListField("", CommSimpleTextButton, {}, strs.get('Details')).addField("Condition", (item) => item.transfers.adToStreams.find(t => t.transferType == "BannerToStreamer") && this.state.details != item.stream.id).addField("OnClick", async (item) => this.setState({ details: item.stream.id }))
        ];

        if (this.props.customButtons) this.props.customButtons(this, detailsComponents);
        
        this.conf = [
            new confListField(strs.get('StreamTitle'), CommItemFieldTextLink, {}, (item) => item.stream.title ?? "stream" ).addField("tUrl", (item) => item.stream.url),
            new confListField(strs.get('Streamer'), CommItemField, {}, (item) => item.stream.streamerName),
            new confListField(strs.get('Displayed'), CommItemField, {}, (item) => item.transfers.viewers),
            new confListField(strs.get('Amount'), CommItemField, {}, (item) => toMStr(item.transfers.amountNC)),
            rLinkNum((item) => `#server#/advertisers/${UserUtils.GetUserData().id}/advertisement/${this.props.banner}/linkStats/stream/${item.stream.id}`),
            new confListField(strs.get('Date'), CommItemField, {}, (item) => dateStrToReadDateStr(item.stream.createdAt)),
            new confListField(strs.get('Details'), CommItemOfItems).addField("Comp", detailsComponents)
        ]

        if (this.props.customConf) this.props.customConf(this, this.conf);

        if (this.url != this.props.url) {
            this.url = this.props.url;
            if (this.list != null) {                
                this.CS.changeBaseUrl(this.url)
                this.list.QueryItems();
            }
        }

        return (
            <CommList {...this.props.canSel ? { canSel: true } : {}} ref={(l) => this.list = l} Owner={this} title={strs.get('Streams')} confComm={new confList(CommListGen, this.CS, this.conf)} OnChangeSelection={this.props.OnChangeSelection}/>
        );
    }
}

export const StreamStates = {
    None : -1,
    ToValidate : 0,
    Validated : 1,
    Validating : 4,
    NoCompleteInformation: 2,
    NoValid: 3,
    ToPay : 5,
    Paied : 6
};

export const TransferStates = {
    NONE: -1,
    CREATED: 0,
    REVOKED: -2,
    TOAPROVE: 1,
    APPROVED:2
}

class BannerToStream extends React.Component {
    constructor(props) {
        super(props);

        const confFieldItem: confFieldItem = this.props.confFieldItem;
        const confField: confField = confFieldItem.confField;
        console.log(confField.fieldprops.streamer);
        this.conf = [
            new confListField(adsStrs.get('Viewers'), CommItemField, {}, "viewers"),
            new confListField(adsStrs.get('Amount'), CommItemField, {}, (i) => toMStr(i.quantityNC)),
            //new confListField("Stream time", CommItemField, {}, (item) => DurationTime(item.streamStart, new Date(item.displayStart * 1000).toString())),
            new confListField(adsStrs.get('StreamTime'), CommItemField, {}, (item) => item.stateDescription != TransferStates.APPROVED ? DurationTime(item.streamStart, new Date(item.displayStart * 1000).toString()) + "*" : DurationTime(0, item.aprovedTime * 1000) + (isDevelop ? " - " + DurationTime(item.streamStart, new Date(item.displayStart * 1000).toString()) : "")), //Se pone un * en el tiempo cuando es aproximado, no se a verificado y depende de quando se inicio la pedida de banners que puede ser distinto a el inicio del stream
            new confListField(adsStrs.get('Status'), CommItemField, {}, (i) => (i.stateDescription == TransferStates.CREATED || i.stateDescription == TransferStates.TOAPROVE) ? adsStrs.get('StateToApprove') : i.stateDescription == TransferStates.APPROVED ? <a href={i.mediaCheck} target="_blank">{adsStrs.get('StateApproved')}</a> : adsStrs.get('StateRevoked')),
        ];

        if (!confField.fieldprops.stream) {
            this.conf.splice(2, 0, new confListField('banner', CommItemField, {}, 'bannerName'));
        }
    }

    render = () => {
        let parent: COMMComp = this.props.parentComm;

        const s: [] = this.props.item.transfers.adToStreams.filter(a => a.transferType == "BannerToStreamer");
        for (let i = 0; i < s.length; i++) s[i].streamStart = this.props.item.stream.startTime;

        return React.createElement(() =>
            <React.Fragment>
                <Grid >
                    <Button variant="contained" size="large" color="primary" onClick={() => { parent.Owner.setState({details:null}); }}>
                        {strs.get('HideDet')}
                    </Button>                
                </Grid>
                <CommListStatic confComm={new confListStatic(CommListGen, s, this.conf)} />
            </React.Fragment>
        );
    }
}

export default connect(mapGenericStateToProps, null)(withRouter(TransactionsAdsStream));