
import React, { useState, useEffect, Fragment } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography, Link } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
//import './streams-grid-admin.styles.scss'
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Paper from "@material-ui/core/Paper";
import ServicesBase from '../../services/base';
import { LangStrs, Lang } from '../../utils/languageUtils'
import moment from 'moment';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import Badge from '@material-ui/core/Badge';
import { connect } from 'react-redux'
import { mapNotificationsToProps } from './../../utils/routerUtils'

export function compEncapsulation(WrappedComponent, EncapsulatingComponent, additionalProps = {}) {
    return function (props) {
        return (
            <EncapsulatingComponent {...additionalProps}>
                <WrappedComponent {...props} />
            </EncapsulatingComponent>
        );
    };
}

const CustomPaper = (props) => {
    return <Paper style={{ width: 800 }} elevation={8} {...props} />;
};

export class SelectComponent extends React.Component {
    labelFields = [];

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            selectedOption: null,
        }
        this.handleOptionChange = this.handleOptionChange.bind(this);
    }

    async componentDidMount() {
        await this.update();
    }

    async update() {
        let r = await this.updateItems()
        this.setState({ items: r });
    }

    async updateItems() {
        // Esta función será reemplazada en la clase hija
    }

    async handleOptionChange(event, newValue) {
        await this.setState({ selectedOption: newValue });
        if (this.props.onSelect) this.props.onSelect(event, newValue);
    };

    renderOption(option) {
        return (
            <div style={{ display: 'grid', gridTemplateColumns: `repeat(${this.labelFields.length || 1}, 1fr)`, gap: '16px', ...this.props.style }}>
                {(this.labelFields.length > 0 ? this.labelFields : [null]).map((field, index) => (
                    <div key={index}>{field ? option.label[field] : option.label}</div>
                ))}
            </div>
        );
    }

    getOptionLabel(option) {
        return (this.labelFields.length > 0 ? this.labelFields : [null]).map((field) => field ? option.label[field] : option.label).join(' - ');
    }

    render() {
        let { selectedOption, items } = this.state;

        if (this.props.defaultFirst && !selectedOption && items.length > 0) selectedOption = items[0];


        if (items && !items.some(i => i == selectedOption)) {
            selectedOption = items.find(i => i.value === selectedOption);            
        }

        const CustomPaper = (props) => {
            return <Paper style={{ minWidth: 'fit-content' }} elevation={8} {...props} />;
        };

        return (
            <Autocomplete
                PaperComponent={CustomPaper}
                key={selectedOption ? selectedOption.value : "empty-key"}
                style={{ ...this.props.style }}
                options={this.state.items}
                {...items ? { value: selectedOption } : {}}
                onChange={this.handleOptionChange}
                getOptionLabel={this.getOptionLabel.bind(this)}
                renderOption={this.renderOption.bind(this)}
                renderInput={(params) => (
                    <TextField {...params} label={this.props.label} variant="outlined" />
                )}
            />
        )
    }
}

export class SelectFromGet extends SelectComponent {
    async updateItems() {
        this.baseUrl = this.props.baseUrl || this.baseUrl;
        this.typeFilter = this.props.typeFilter || this.typeFilter;

        let sb = new ServicesBase("#server#" + this.baseUrl + this.typeFilter);
        let resp = await sb.ServiceRequest("", 'GET', {}, false, null);

        return resp.body;
    }
}

export class SelectFromConst extends SelectComponent {
    async updateItems() {
        return this.props.Values;
    }
}

const styles = {
    header: {
        backgroundColor: '#35749E',  // color primary.main predeterminado de Material-UI
        color: 'white',
        borderRadius: '4px 4px 0 0'
    },
    headerContent: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between'
    },
    text: {
        fontWeight: 'bold'
    },
    icon: {
        color: 'white'
    }
};

export class ExpandableComponent extends React.Component {
    render() {
        const { title, children } = this.props;

        return (
                <ExpansionPanel style={{ width: '100%' }} {...this.props}>
                    <ExpansionPanelSummary
                        style={styles.header}
                        expandIcon={<ExpandMoreIcon style={styles.icon} />}
                        aria-controls="panel-content"
                        id="panel-header"
                    >
                        <div style={styles.headerContent}>
                            <Typography style={styles.text}>{title}</Typography>
                        </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={styles.details}>
                        {children}
                    </ExpansionPanelDetails>
                </ExpansionPanel>
        );
    }
}

export function standarValueBroadcastTime(value) {
    if (!value || value == "1970-01-01 00:00:00.000 +00:00" || value == "1970-01-01T00:00:00.000Z") value = 0;
    else if (value == "1969-12-31 23:59:59.000 +00:00" || value == "1969-12-31T23:59:59.999Z") value = -1;
    else {
        value = moment(value, "YYYY-MM-DD HH:mm:ss.SSS Z");
        value = value.format("YYYY-MM-DDTHH:mm");
    }
    return value;
}

export function ScheduleTimeDiff(dateTime) {
    const now = moment();
    const selectedDateTime = moment(dateTime);
    return selectedDateTime.diff(now);
}

export let strsTimePicker: LangStrs = new LangStrs(new Map(
    [
        ['BroadcastOnDays', new Map(
            [
                [Lang.ES, 'días'],
                [Lang.EN, 'days']
            ])
        ],
        ['BroadcastOnHours', new Map(
            [
                [Lang.ES, 'horas'],
                [Lang.EN, 'hours']
            ])
        ],
        ['BroadcastOnMin', new Map(
            [
                [Lang.ES, 'minutos'],
                [Lang.EN, 'minutes']
            ])
        ],
        ['BroadcastOnSec', new Map(
            [
                [Lang.ES, 'segundos'],
                [Lang.EN, 'seconds']
            ])
        ],
    ]
));

export class CountDown extends React.Component {
    constructor(props) {
        super(props);

        let value = standarValueBroadcastTime(this.props.dateTime);

        this.remainingMsg = this.props.remainingMsg;
        this.passDateMsg = this.props.passDateMsg;
        this.shortText = this.props.shortText;

        const timeRemaining = this.updateRemainingText(value);
        this.handleRemain();

        this.style = this.props.style;

        this.state = {
            dateTime: value,
            timeRemaining: timeRemaining,
        };
    }

    componentWillUnmount() {
        this.intervalId && clearInterval(this.intervalId);
    }

    updateRemainingText(datetime = undefined) {
        datetime = datetime != undefined ? datetime : (this.state == undefined ? undefined : this.state.dateTime);
        if (datetime != undefined) {
            const diff = ScheduleTimeDiff(datetime);

            if (diff > 0) {
                const now = moment();
                const selectedDateTime = moment(datetime);
                const duration = moment.duration(selectedDateTime.diff(now));

                const days = Math.floor(duration.asDays());
                const hours = duration.hours();
                const minutes = duration.minutes();
                const seconds = duration.seconds();

                let timeRemaining = `${this.remainingMsg} ${days ? days + ' ' + strsTimePicker.get('BroadcastOnDays') + ', ' : ''}${hours ? hours + ' ' + strsTimePicker.get('BroadcastOnHours') + ', ' : ''}${minutes ? minutes + ' ' + strsTimePicker.get('BroadcastOnMin') + ', ' : ''}${seconds} ${strsTimePicker.get('BroadcastOnSec')}`;
                if (this.shortText) {
                    timeRemaining = `${this.remainingMsg} ` + (days ? `${days}d ` : '') + `${String(hours).padStart(2, '0')}:` + `${String(minutes).padStart(2, '0')}:` + `${String(seconds).padStart(2, '0')}`;
                }
                this.setState({ timeRemaining });
                return timeRemaining;

            } else {
                clearInterval(this.intervalId);
                this.setState({ timeRemaining: this.passDateMsg });
                return this.passDateMsg;
            }
        }
        return '';
    }

    handleRemain() {
        if (this.intervalId) clearInterval(this.intervalId);
        this.intervalId = setInterval(() => {
            this.updateRemainingText()
        }, 1000);
    }

    render() {
        const { timeRemaining } = this.state;
        return <>{this.props.errormsg ? this.props.errormsg : timeRemaining}</>;
    }
}

export class BroadCastTimePicker extends React.Component {
    constructor(props) {
        super(props);

        let value = standarValueBroadcastTime(this.props.dateTime);
        this.state = {
            dateTime: value,
        };

        this.title = this.props.title ?? "";
        this.style = this.props.style;
        this.remainingMsg = this.props.remainingMsg;
        this.passDateMsg = this.props.passDateMsg;
        this.handleDateTimeChange = this.handleDateTimeChange.bind(this);
    }

    render() {
        let parent: COMMComp = this.props.parentComm;
        const confFieldItem: confFieldItem = this.props.confFieldItem;
        const confField: confField = confFieldItem.confField;

        const now = new Date();
        const formattedNow = now.getFullYear() + "-" + String(now.getMonth() + 1).padStart(2, "0") + "-" + String(now.getDate()).padStart(2, "0") + "T" + String(now.getHours()).padStart(2, "0") + ":" + String(now.getMinutes()).padStart(2, "0");

        const { dateTime } = this.state;

        return (
            <div style={{ display: "flex", alignItems: "center", paddingBottom: "10px", ...this.style }}>
                <TextField
                    label={this.title}
                    type="datetime-local"
                    variant="outlined"
                    {...this.state.errormsg && { error: true }}
                    value={dateTime == 0 ? formattedNow : dateTime}
                    onChange={this.handleDateTimeChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />

                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginLeft: "8px" }}>
                    <CountDown key={this.state.dateTime + this.state.errormsg} dateTime={this.state.dateTime} remainingMsg={this.remainingMsg} passDateMsg={this.passDateMsg} errormsg={this.state.errormsg}/>
                </div>
            </div>)
    }

    async handleDateTimeChange(event) {
        await this.setState({ dateTime: event.target.value });
        this.onDataChange(event);
    }

    onDataChange(e) {
        if (this.props.onDataChange) {
            const momentUtc = moment(this.state.dateTime).utc();
            this.props.onDataChange(momentUtc.unix() * 1000);
        }
    }
}

export class RadioButtonGroupAsButtons extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedValue: this.props.selectedValue
        };
    }

    handleButtonClick = (value) => {
        this.setState({ selectedValue: value }, () => {
            if (this.props.onChangeValue) {
                this.props.onChangeValue(value);
            }
        });
    };

    render() {
        const { options } = this.props;
        const { selectedValue } = this.state;

        return (
            <ButtonGroup>
                {options.map((option) => (
                    <Button
                        key={option.value}
                        variant={selectedValue === option.value ? "contained" : "outlined"}
                        {...selectedValue === option.value ? { style: { backgroundColor: "rgb(53,116,158)" } } : {}}
                        color="primary"
                        onClick={() => this.handleButtonClick(option.value)}
                    >
                        {option.text}
                        {option.notification && <NotificationBadget notificationKey={option.notification} color="secondary" style={{ top: -15, right: -5 }} />}
                    </Button>                    
                ))}
            </ButtonGroup>
        );
    }
}

export const IconFromSVG = (svg, viewBox = "0 0 24 24") => {
    return React.forwardRef((props, ref) => (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={props.width || 32}
            height={props.height || 32}
            viewBox={viewBox}
            fill={props.color || 'currentColor'}
            className={props.className}
            style={{ /*transform: 'scale(2)',*/ ...props.style }}
            ref={ref}
        >
            {svg}
        </svg>
    ));
}

let InputVideoId: number = 0; //Chapuza: com que relacionu label amb el input per id amb la propietat htmlFor, tinc que generar ids diferents
export class InputSelectVideo extends React.Component {
    render = () => {
        return (<input ref={(r) => { this.inputFile = r; }} style={{ display: "none" }} id={this.props.idInput} name="media" type="file" accept={this.props.filetype} multiple={false} onClick={(e) => { e.target.value = ''; }} onInputCapture={(async (e) => {
            this.newVideoProps = {};

            var file = this.inputFile.files[0];
            if (file.size > 2 * 1024 * 1024 * 1024) {
                this.props.OnSelect(null, null, this.props.ETooSize ?? "ETooSize");

                return;
            }

            var video = document.createElement('video');
            video.preload = 'metadata';

            video.onloadedmetadata = (() => {
                window.URL.revokeObjectURL(video.src);
                if (video.videoWidth == 0 || video.videoHeight== 0) {
                    this.props.OnSelect(null, null, "EIncorrectFormat");
                    return;
                }
                this.newVideoProps = { width: video.videoWidth, height: video.videoHeight, duration: video.duration, size: file.size };

                //confField.fieldprops.OnSelect(this.newVideoProps);
                this.props.OnSelect(file, this.newVideoProps, null);
            }).bind(this)
            video.onerror = ((event) => {
                let error = event;
                // Chrome v60
                if (event.path && event.path[0]) {
                    error = event.path[0].error;
                }

                // Firefox v55
                if (event.originalTarget) {
                    error = error.originalTarget.error;
                }

                // Here comes the error message
                //alert(`Video error: ${error.message}`);

                this.newVideoProps = {};

                //confField.fieldprops.OnSelect(this.newVideoProps);
                this.props.OnSelect(file, this.newVideoProps, null);
            }).bind(this)
            video.src = URL.createObjectURL(file);
        }).bind(this)} />);
    }

    selectFile() {
        this.inputFile.click();
    }
}

export function ButtonExport({ url, str }) {
    return (
        <Link component={RouterLink} to={url} target="_blank">
            <Button color="primary" variant="contained" className="list-button">
                <SaveAltIcon style={{ marginRight: "5px" }} />
                {str}
            </Button>
        </Link>
    );
}

export class QueryData extends React.Component {
    constructor(props) {
        super(props);

        this.url = this.props.url;
        this.method = this.props.method;
        this.data = this.props.data;
        this.transformQuery = this.props.transformQuery;
        this.waitText = this.props.waitText ?? "loading";
        this.renderComp = this.props.renderComp;
        this.respOnError = this.props.respOnError;

        this.state = { value: this.waitText };
    }

    componentDidMount() {
        this.query();
    }

    async query() {        
        let sb: ServicesBase = new ServicesBase(this.url);
        let resp = await sb.ServiceRequest("", this.method ?? 'GET', { 'Content-Type': 'application/json' }, true, this.data);
        if (resp.httpCode == 200) {
            this.setState({ value: this.transformQuery(resp.body) });
        } else if (this.respOnError || this.respOnError == 0) {
            this.setState({ value: this.respOnError });
        }
    }

    render() {
        let parent: COMMComp = this.props.parentComm;
        if (parent) {
            const confFieldItem: confFieldItem = this.props.confFieldItem;
            const confField: confField = confFieldItem.confField;
        }

        console.log(this.state.value);
        return this.renderComp ? this.renderComp(this.state.value) : this.state.value;
    }
}

const CustomBadge = ({ notificationValue, notificationType, color, style }) => (
    <Badge badgeContent={!notificationValue ? undefined : notificationType != 'attention' ? notificationValue : '!'} color={notificationType == 'attention' ? 'error' : color} style={style} />
);

export const NotificationBadget = connect(mapNotificationsToProps)(CustomBadge);