import React, { Component }  from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

import GlobalState from '../../store/globalState'
import UserUtils from '../../utils/userUtils'

import { Hidden, Grid, Button, TextField, Fab, Select, InputLabel, MenuItem, TextareaAutosize, FormControlLabel, FormControl, Tabs, Tab, Box, Paper } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';

import { Redirect } from 'react-router-dom';
import MuiAlert from '@material-ui/lab/Alert';
import Advertisers from '../../services/api/advertisers/';
import ErrorPages from '../errors/';

import { commService } from '../../services/api/list';
import { bannerStreamerListService } from '../../services/api/bannerStreamer';
import { CommInputField, CommForm, CommFormGen, confForm, UploadVideoMedia } from '../common/com-Form/comm-form.components'
import { CommPrevalueComp, CommCheckBox, CommButton, confCOM, confField, CommTextField, fieldNameFunction, temporalItem, fnfRequireText, fnfRequireTextAndCheck, fnfRequireNumber, confGroup, strfieldvalue, EnumComboBox, CommSimpleTextButton, disabledContainerStyles } from '../common/com'
import { CommItemOfItems, confAddItem, CommListGen, CommLinkButton, CommDeleteButtonLink, confListField, confList, CommList, CommItemField, CommItemTextField } from '../common/com-List/comm-list.components'
import { BannersPreview, Rectangle, getCanvasSize } from '../banners-preview/banners-preview.component'

import { BroadCastTimePicker, standarValueBroadcastTime } from '../../components/common/commonComponents'

import './ads-form.styles.scss';

import Options from '../options/options.component'
import { OptionsToCheckRequired } from '../options/options.component'

import { mapGenericStateToProps } from '../../utils/routerUtils'

import { LangStrs, Lang } from '../../utils/languageUtils'
import { adsStrs, BannerState } from '../../templates/languages/ads-strs.js'

import Typography from '@material-ui/core/Typography';
import moment from 'moment';

import userUtils, { UserTypes } from '../../utils/userUtils'

import ModalDialog from '../../components/common/modal/modal'
import { EntityLog, AddEntityLog } from '../../components/common/entityLog/entityLog.jsx'
import { EntityLogList } from '../../components/common/entityLog/entityLog-list.jsx'
import ComTooltip from '../../structure/common/comTooltip/comTooltip.jsx';

import { isBrowser, isMobile, MobileView } from '../../utils/platformsUtils';
import { toMStr, simpleCurrencyHelpText } from '../../utils/moneyUtils.jsx';

import ServicesBase from "../../services/base"

import CommHelpLink, { getDocUrl } from '../../structure/common/comHelpLink/comHelpLink.jsx'

export let strs: LangStrs = new LangStrs(new Map(
    [
        ['StartBroadcast', new Map(
            [
                [Lang.ES, 'Inicio de emisión / No emitir por el momento'],
                [Lang.EN, 'Start of broadcasting / Hold off']
            ])
        ],
        ['StartBroadcastLater', new Map(
            [
                [Lang.ES, 'Más tarde'],
                [Lang.EN, 'Later']
            ])
        ],
        ['NoBroadcast', new Map(
            [
                [Lang.ES, 'No emitir'],
                [Lang.EN, 'Do not broadcast']
            ])
        ],
        ['StartBroadcastNow', new Map(
            [
                [Lang.ES, 'Inmediatamente'],
                [Lang.EN, 'Immediately']
            ])
        ],
        ['StartBroadcastOnSave', new Map(
            [
                [Lang.ES, 'La emisión empezara cuando guardes los cambios'],
                [Lang.EN, 'The broadcast will start when you save the changes']
            ])
        ],
        ['StartBroadcastOnSave', new Map(
            [
                [Lang.ES, 'La emisión empezara cuando guardes los cambios'],
                [Lang.EN, 'The broadcast will start when you save the changes']
            ])
        ],
        ['BroadcastOn', new Map(
            [
                [Lang.ES, 'Se emitira en'],
                [Lang.EN, 'It will be broadcasted on']
            ])
        ],
        ['broadcastDate', new Map(
            [
                [Lang.ES, 'Fecha de Emisión'],
                [Lang.EN, 'Broadcast Date']
            ])
        ],
        ['BroadcastOnDays', new Map(
            [
                [Lang.ES, 'días'],
                [Lang.EN, 'days']
            ])
        ],
        ['BroadcastOnHours', new Map(
            [
                [Lang.ES, 'horas'],
                [Lang.EN, 'hours']
            ])
        ],
        ['BroadcastOnMin', new Map(
            [
                [Lang.ES, 'minutos'],
                [Lang.EN, 'minutes']
            ])
        ],
        ['BroadcastOnSec', new Map(
            [
                [Lang.ES, 'segundos'],
                [Lang.EN, 'seconds']
            ])
        ],
        ['StateToNoApproved', new Map(
            [
                [Lang.ES, 'No aprobar'],
                [Lang.EN, 'No approved']
            ])
        ],
        ['Description', new Map(
            [
                [Lang.ES, 'Descripción'],
                [Lang.EN, 'Description']
            ])
        ],
        ['Details', new Map(
            [
                [Lang.ES, 'Ver'],
                [Lang.EN, 'View']
            ])
        ],
        ['Selected', new Map(
            [
                [Lang.ES, 'Seleccionada'],
                [Lang.EN, 'Selected']
            ])
        ],
        ['YES', new Map(
            [
                [Lang.ES, 'Si'],
                [Lang.EN, 'Yes']
            ])
        ],
        ['NO', new Map(
            [
                [Lang.ES, 'No'],
                [Lang.EN, 'No']
            ])
        ],
        ['Delete', new Map(
            [
                [Lang.ES, 'Borrar'],
                [Lang.EN, 'Delete']
            ])
        ],
        ['AddConf', new Map(
            [
                [Lang.ES, 'Agregar nueva configuración de emisión'],
                [Lang.EN, 'Add new broadcasting configuration']
            ])
        ],
        ['TitleConf', new Map(
            [
                [Lang.ES, 'Configuración de emisión'],
                [Lang.EN, 'Broadcasting configuration']
            ])
        ],
        ['TitleAd', new Map(
            [
                [Lang.ES, 'Anuncio'],
                [Lang.EN, 'Advertiser']
            ])
        ],
        ['PriorityInfo', new Map(
            [
                [Lang.ES, 'Ajusta el nivel de prioridad. Este valor determinará qué prioridad tendrá tu anuncio para emitirse y el precio por espectadores'],
                [Lang.EN, 'Adjust the priority level. This value will determine the priority your ad will have for broadcast and the price per viewer']
            ])
        ],
        ['ViewPayInfo', new Map(
            [
                [Lang.ES, 'Especifica la cantidad que deseas que cueste el anuncio cada vez que se muestre en la transmisión/video (opción pensada para un anuncio dirigido a un nicho muy específico o para ejecutar un contrato específico)'],
                [Lang.EN, 'Specify the amount you want the ad to cost each time it is displayed in the stream/video (option designed for an ad targeted at a very specific niche or to execute a specific contract)']
            ])
        ],
        ['PriorityTitle', new Map(
            [
                [Lang.ES, 'Por factor de prioridad'],
                [Lang.EN, 'By factor of priority']
            ])
        ],
        ['ViewPayTitle', new Map(
            [
                [Lang.ES, 'Cantidad por visualización'],
                [Lang.EN, 'Amount per display']
            ])
        ],
        ['AmountField', new Map(
            [
                [Lang.ES, 'Cantidad'],
                [Lang.EN, 'Amount']
            ])
        ],
        ['PriorityField', new Map(
            [
                [Lang.ES, 'Factor de prioridad'],
                [Lang.EN, 'Priority factor']
            ])
        ],
        ['PriorityCost1', new Map(
            [
                [Lang.ES, 'El factor de prioridad con este anuncio, establece que su costo será de '],
                [Lang.EN, 'The priority factor with this ad establishes that its cost will be ']
            ])
        ],
        ['PriorityCost2', new Map(
            [
                [Lang.ES, ' por cada 1000 vistas'],
                [Lang.EN, ' for every 1000 views']
            ])
        ],
        ['PriorityRecommend', new Map(
            [
                [Lang.ES, 'El factor de prioridad recomendado está entre {1} y {2}'],
                [Lang.EN, 'The recommended priority factor is between {1} and {2}']
            ])
        ],
        ['PriorityRecommendInfo', new Map(
            [
                [Lang.ES, 'La recomendación es una orientación para que el anuncio se emita según la configuración de emisión actual. El valor puede variar con el tiempo, dependiendo de la demanda de los creadores de contenido que se adaptan a esta configuración de emisión.<br/>Un valor por encima de {1} asegura la emisión inmediata en este momento.'],
                [Lang.EN, 'The recommendation is guidance for the ad to be broadcast according to the current emission configuration. The value can change over time, depending on the demand from content creators who adapt to this broadcast setting.<br/>A value above {1} ensures immediate broadcasting at this time']
            ])
        ],
        ['PriorityCalc', new Map(
            [
                [Lang.ES, 'El factor de prioridad recomendado está siendo calculado (esto puede tomar un tiempo)'],
                [Lang.EN, 'The recommended priority factor is being calculated (this might take some time)']
            ])
        ],
        ['SetMaxInSameStream', new Map(
            [
                [Lang.ES, `Establecer el número máximo de banners en los que puede aparecer a la vez`],
                [Lang.EN, `Set the maximum number of banners that can appear at the same time`]
            ])
        ],
        ['NoLimit', new Map(
            [
                [Lang.ES, `Sin límite`],
                [Lang.EN, `No limit`]
            ])
        ],
        ['SetMaxPlays', new Map(
            [
                [Lang.ES, `Establecer el número máximo de veces que puede aparecer en el mismo directo/video`],
                [Lang.EN, `Set the maximum number of times it can appear in the same live/video`]
            ])
        ],
    ]
));

let optionsBannerType = null;

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

export const StateEditor = (cmp) => {
    const isAdmin = UserUtils.GetUserData(true).usertype == UserTypes.Admin;
    const enumValues = [{ label: adsStrs.get('BannerStateNone'), value: BannerState.None }, { label: adsStrs.get('BannerStateInsufficientData'), value: BannerState.InsufficientData }, { label: adsStrs.get('BannerStateToCheck'), value: BannerState.ToCheck }, { label: adsStrs.get('BannerStateNoApproved'), value: BannerState.NoApproved, disabled: true }, { label: adsStrs.get('BannerStateApproved'), value: BannerState.Approved }];

    return [
        new confListField(adsStrs.get('State'), EnumComboBox, { enumValues: enumValues }, "state").addField("Condition", (item) => isAdmin),
        new confListField(strs.get('StateToNoApproved'), CommSimpleTextButton, { enumValues: enumValues, style: { marginBottom: "10px" } }, strs.get('StateToNoApproved')).addField("Condition", (item) => isAdmin).addField("OnClick", async (item) => cmp.modalAdLog.setOpen(true)),
    ];
}

export const textLinkFields = () => {
    return [
        new confField(adsStrs.get('AdLinkText'), CommTextField, { className: "list-field bi-name" }, fnfRequireTextAndCheck("linkText", (n) => n && n.length < 100)).addField("tDangerouslyInfo", adsStrs.get('InfoAdLinkText')),
        new confField(adsStrs.get('AdLink'), CommTextField, { className: "list-field bi-name" }, fnfRequireTextAndCheck("linkUrl", (n) => {
            if (!n) return false;
            var partes = n.split('://');

            if (partes.length !== 2 || partes[0].toLowerCase() !== 'https') {
                return false;
            }

            var restoDeLaURL = partes[1];
            var segmentosDeURL = restoDeLaURL.split('/');
            var dominio = segmentosDeURL[0];

            if (!dominio.includes('.')) {
                return false;
            }

            var caracteresValidos = /^[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=]*$/;
            if (!caracteresValidos.test(restoDeLaURL)) {
                return false;
            }

            return true;
        }
        )).addField("tDangerouslyInfo", adsStrs.get('InfoAdLink')),
    ];
}

export const AdEntityLogs = (cmp) => {
    const isAdmin = UserUtils.GetUserData(true).usertype == UserTypes.Admin;
    return (
        <>
            <AddEntityLog refModal={(m) => { cmp.modalAdLog = m; }} entityModel="advertisements" title="NoApprovedBanner" entity={cmp.props.match.params.id} OnSave={async () => {
                await cmp.formAd.UpdateData({ id: cmp.props.match.params.id, newData: { state: BannerState.NoApproved } }, true);
                await cmp.bannerLogs.QueryItems();
                }}
            />
            {
                isAdmin &&
                <Grid child container className={'banner-form ' + cmp.props.className} direction="row" justify="flex-start" alignItems="flex-start" xs="12">
                    <EntityLogList title="Logs" modelEntity="advertisements" idEntity={cmp.props.match.params.id} ref={(c) => cmp.bannerLogs = c} />
                </Grid>
            }
        </>
    );
}

class AdsForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            media: {},
            tabValue: 0,
            optionsEnabled: [[true, true, true, true], [true, true, true, true]]
        }

        this.isAdmin = UserUtils.GetUserData(true).usertype == UserTypes.Admin;        

        this.conf = [
            new confGroup("", 0, [
                new confField(adsStrs.get('AdName'), CommTextField, { className: "list-field bi-name" }, fnfRequireText("name")),
                new confField(adsStrs.get('SecDuration'), CommTextField, { className: "list-field bi-amount not-mobile" }, fnfRequireNumber("time", 1))
                    .addField("tPropsByItem", (i) => {
                        return { disabled: i.mediaType == "video" }
                    }).addField("Condition", (i) => {
                        let ti = temporalItem(i);
                        if (i.mediaType == undefined && (ti.media == undefined || ti.media.file == undefined || !ti.media.file.type.startsWith("image"))) {
                            return false;
                        }
                        return true;
                    }),                
                ...textLinkFields(),
                new confField(adsStrs.get('UpAd'), UploadVideoMedia, { style: { display: this.state.media.mediaUrl != undefined ? "none" : "" }, className: "list-field bi-size" }, "media").addField("Condition", (i) => i.id == undefined),
                ...StateEditor(this)
            ]),
            new confGroup("", 1, [
                new confField(adsStrs.get('AdW'), CommTextField, { disabled: "true" }, "sizeX").addField("tfullWidth", false),
                new confField(adsStrs.get('AdH'), CommTextField, { disabled: "true" }, "sizeY").addField("tfullWidth", false),
                new confField(<>{adsStrs.get('fullScreenAd')}<ComTooltip text={<div dangerouslySetInnerHTML={{ __html: adsStrs.get('fullScreenInfo') }} style={{ "padding": "5px" }} />} /></>, CommCheckBox, {}, "fullScreen").addField("disabled", (item) => {
                    return this.mustBeFullScreen(item.sizeX, item.sizeY);
                }).addField("noCommonDisabled", true),
                
            ])
        ];

        this.confBannerLogs = [
            new confListField('message', CommItemField, { className: "list-field bi-name" }, "message"),
            new confListField('Date', CommItemField, { className: "list-field bi-name" }, "createdAt")
        ];

        let confCol1 = [
            new confField(strs.get('Description'), CommTextField, { className: "list-field bi-name" }, fnfRequireText("description")),
            new confField(adsStrs.get('PriorityFact'), BannerPayment, { className: "list-field bi-amount not-mobile", pay1k: 12 }, fnfRequireNumber("priorityFactor", 1), 1).addField("ref", (c) => this.BannerPaymentForm = c),
        ];

        const newCommPrevalueComp = (strTextField, pvButtonText, pvCancelButtonText, noEditingValue, initialEditingValue, attribute, attributedef) => {
            return new confField(strTextField, CommPrevalueComp, { className: "list-field bi-amount not-mobile", buttonText: pvButtonText, cancelButtonText: pvCancelButtonText, noEditingValue: noEditingValue, initialEditingValue: initialEditingValue, EditComp: CommTextField, compStyle: { style: { minHeight: '50px' } }, buttonTextStyle: { style: { marginTop: '-20px' } }, cancelButtonTextStyle: { style: { marginTop: '-40px' } } }, attribute, attributedef);
        }

        this.confBannerConf = [
            new confGroup("", 0, confCol1),
            new confGroup("", 1, [
                new confListField('', BroadCastTimePickerBanner, { type: "datetime-local", InputLabelProps: { shrink: true } }, "broadcastTime"),                
                new confField(adsStrs.get('MinViewers'), CommTextField, { className: "list-field bi-amount not-mobile" }, fnfRequireNumber("minViewers", 0), 0),
                new confField(adsStrs.get('ETime'), CommTextField, { className: "list-field bi-amount not-mobile" }, fnfRequireNumber("elapseTime", 0), 0),
                newCommPrevalueComp(adsStrs.get('SameTime'), strs.get('SetMaxInSameStream'), strs.get('NoLimit'), -1, 1, "maxConcurrent", -1),
                newCommPrevalueComp(adsStrs.get('maxPlays'), strs.get('SetMaxPlays'), strs.get('NoLimit'), -1, 1, "maxPlays", -1),
            ])
        ];

        this.confListBannerConf = [
            new confListField(strs.get('Description'), CommItemField, { className: "list-field bi-name" }, "description"),
            new confListField(adsStrs.get('BroStrategy'), CommItemField, { className: "list-field bi-name" }, (item) => {
                if (item.amountForPlay > 0) return `${strs.get('ViewPayTitle')}: ${toMStr(item.amountForPlay, false)}`;
                return `${strs.get('PriorityTitle')}: ${item.priorityFactor}`;
            }),
            new confListField(strs.get("Delete"), CommDeleteButtonLink, null, "description"),
            new confListField("", CommItemOfItems).addField("Comp", [                
                new confListField("", CommSimpleTextButton, {}, strs.get('Selected')).addField("Condition", (item) => item.id == this.state.bannerConf).addField("color", '#f44336'),
                new confListField("", CommSimpleTextButton, {}, strs.get('Details')).addField("Condition", (item) => item.id != this.state.bannerConf).addField("OnClick", async (item) => { await this.setState({ bannerConf: item.id }); })
            ])
            //new confListField("", CommSimpleTextButton, {}, strs.get('Details')).addField("OnClick", async (item) => { await this.setState({ bannerConf: item.id }); await this.formBConfig?.QueryItem() }).addField("Condition", (item) => false),
        ];

        this.optionsToCheckRequired = new OptionsToCheckRequired();
    }

    setOptionsEnabled(tags, n, selDisables) {
        let optionsEnabled = JSON.parse(JSON.stringify(this.state.optionsEnabled));

        let isSel = tags.find(t => t.someSel) != undefined

        for (let i = 0; i < optionsEnabled[n].length; i++)
            optionsEnabled[n][i] = !isSel || !selDisables[i];

        this.setState({
            optionsEnabled: optionsEnabled
        });
    }

    async componentDidMount() {
        let conf = -1;
        let multiConf: boolean = false;

        if (this.props.match.params.id) {
            let sb: ServicesBase = new ServicesBase("#server#" + "/advertisers/" + UserUtils.GetUserData().id + "/advertisement/" + this.props.match.params.id + "/config/");
            let resp = await sb.ServiceRequest("", 'GET', { 'Content-Type': 'application/json' }, true);
            
            if (resp.body.length >= 1) conf = resp.body[0].id;
            if(resp.body.length > 1) multiConf = true;
        }
        this.setState({ loaded: true, bannerConf: conf, bannerConfMulti: multiConf });
    }

    async createBannerConfig() {
        let sb: ServicesBase = new ServicesBase("#server#" + "/advertisers/" + UserUtils.GetUserData().id + "/advertisement/" + this.props.match.params.id + "/config/");
        let resp = await sb.ServiceRequest("", 'POST', { 'Content-Type': 'application/json' }, true, { description: "Banner config", banner: this.props.match.params.id, elapseTime: 0, maxConcurrent: -1, priorityFactor: 1, minViewers: 0, broadcastTime: null });
        if (resp.httpCode == 201) {
            await this.setState({ bannerConf: resp.body.id, bannerConfMulti: true });
            this.commlistConfs.QueryItems();
        }
    }

    handleChange = (event, newValue) => {
        this.setState({ tabValue: newValue });
    };

    canvasResW = 1920;
    canvasResH = 1080;

    LastPF:number = 0;
    OnChangeFormBannerConfig = async (form) => {
        let data = form.getTemporalItem();

        if (this.LastPF != data.priorityFactor) {
            this.LastPF = data.priorityFactor;
            let sb: ServicesBase = new ServicesBase("#server#" + "/advertisers/" + UserUtils.GetUserData().id + "/banner/" + this.props.match.params.id + "/config/" + form.getItem().id + "/amount");
            let resp = await sb.ServiceRequest("", 'POST', { 'Content-Type': 'application/json' }, true, data);
            if (resp.httpCode == 200) {
                this.BannerPaymentForm && this.BannerPaymentForm.setPay1k(resp.body);
                //if (this.state.pay1k != resp.body) this.setState({ pay1k: resp.body });
            }
        }

        //setTimeout(async function () {
            const setPF = (resp, state) => {
                Object.assign(resp, { state: state });
                this.BannerPaymentForm && this.BannerPaymentForm.setPFRec(resp);
            }

            let sb: ServicesBase = new ServicesBase("#server#" + "/advertisers/" + UserUtils.GetUserData().id + "/banner/" + this.props.match.params.id + "/config/" + form.getItem().id + "/pfRecommend");
            setPF({}, "calc");
            let resp = await sb.ServiceRequest("", 'POST', { 'Content-Type': 'application/json' }, false, data);
            if (resp.httpCode == 200) {
                setPF(resp.body, "ok");
            }
        //}, 0);
    }

    render = () => {
        let canvasSize = getCanvasSize(this.canvasResW, this.canvasResH);

        return (
            <Grid direction="column" xs="12">
                <div>
                    <Tabs value={this.state.tabValue} onChange={this.handleChange} aria-label="simple tabs example">
                        <Tab label={ strs.get('TitleAd') } />
                        {this.props.match.params.id && <Tab label={strs.get('TitleConf')} />}
                        <CommHelpLink infoUrl={getDocUrl("como-crear-una-campana-de-publicidad-programatica")} />
                    </Tabs>
                    <TabPanel value={this.state.tabValue} index={0}>
                        {/*
                        <Grid className="form-column">
                            <h1>{this.props.title}</h1>
                        </Grid>
                        */}
                        {this.state.media.mediaUrl &&
                            <Grid child container direction="row" justify="flex-start" alignItems="stretch" xs="12" >
                                <Grid container xs="6" justify="center" alignItems="center">
                                    <div className="selected-media">
                                        {(this.state.media.mediaType == "img" || this.state.media.mediaType == "gif") && <img width={this.state.media.sizeX > 400 ? 400 : this.state.media.sizeX} src={this.state.media.mediaUrl} />}
                                        {this.state.media.mediaType == "video" && <video width={this.state.media.sizeX > 400 ? 400 : this.state.media.sizeX} controls poster="/images/w3html5.gif" poster={"data:image/png;base64," + this.state.media.thumbnailUrl}>
                                            <source src={this.state.media.mediaUrl} />
                                        </video>}
                                    </div>
                                </Grid>
                                <Grid child container xs="6" justify="center" alignItems="center">
                                    <Grid xs={isBrowser ? "6" : "12"} style={{ paddingBottom: "20px" }}>
                                        <BannersPreview ref={(c) => { this.bprev = c; if (this.bprev) this.bprev.setBanners(); }} width={this.canvasResW} height={this.canvasResH} canvasWidth={canvasSize.width} canvasHeight={canvasSize.height} banners={[new Rectangle(0, 0, +this.state.sizeX, +this.state.sizeY)]} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                        <Grid child container className={'banner-form ' + this.props.className} direction="row" justify="flex-start" alignItems="flex-start" xs="12">
                            <CommForm ref={(c) => this.formAd = c} confComm={new confForm(CommFormGen, new commService("/advertisers/:id/banner"), this.conf, this.props.match.params.id)} Owner={this} OnChange={async (comp) => await this.OnBannersChange.bind(this)(comp)} OnDataChange={async (item) => { let i = temporalItem(item); await this.setState({ sizeX: i.sizeX, sizeY: i.sizeY }); return true; }} OnQuery={this.onQuery.bind(this)} RedirectOnNew={"/dashboard/advertisements/edit/:new/" + this.state.fullScreen} BeforeSaveData={async () => { await this.setState({ fullScreen: this.formAd.getTemporalItem().fullScreen === true }); return this.optionsToCheckRequired.checkRequeriments() }}
                                GeneralValidation={(item) => {
                                    const ti = temporalItem(item);
                                    return this.state.media.mediaUrl || (ti.media != undefined && ti.media.file != undefined);
                                }} />
                        </Grid>
                        {AdEntityLogs(this)}
                        {this.props.match.params.id && RenderBannerType(this, this.props.match.params.id, this.state.bannerConf)}
                    </TabPanel>
                    <TabPanel value={this.state.tabValue} index={1}>
                        {this.props.match.params.id && <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <h1>{/*{strs.get('TitleConf')}*/}</h1>
                            <Button variant="contained" size="large" color="primary" onClick={(e) => { this.createBannerConfig() }}>
                                {strs.get('AddConf')}
                            </Button>
                        </div>
                        }
                        {
                            this.state.bannerConfMulti &&
                            <CommList ref={(c) => this.commlistConfs = c} maxPageItems={4} confComm={new confList(CommListGen, new commService(`/advertisers/:id/advertisement/${this.props.match.params.id}/config`), this.confListBannerConf)} onDelete={() => window.location.reload()} />
                        }
                        {this.state.bannerConf && this.state.bannerConf != -1 &&
                            <Grid child container className={'banner-form ' + this.props.className} direction="row" justify="flex-start" alignItems="flex-start" xs="12">
                            <CommForm key={`FormBannerConfig_${this.state.bannerConf}`} confComm={new confForm(CommFormGen, new commService(`/advertisers/:id/advertisement/${this.props.match.params.id}/config`), this.confBannerConf, this.state.bannerConf)} OnSave={() => this.commlistConfs?.QueryItems()} OnChange={this.OnChangeFormBannerConfig}/>
                            </Grid>
                        }
                        {this.props.match.params.id && <Grid className="profile-block" container direction="row">
                            <Grid container xs={12} direction="column">
                                <h2>{adsStrs.get('AdTarget')}</h2>
                            </Grid>
                            {RenderOptions(this, this.props.match.params.id, this.state.bannerConf, this.setOptionsEnabled.bind(this), this.state.optionsEnabled)}
                        </Grid>}
                    </TabPanel>                    
                </div>

                
                
                <ModalDialog ref={(m) => { this.refModalAlert = m; }} windowsSyle={true} btnNOVisible={false} btnOKVisible={true}>
                    {adsStrs.get('fullScreenAlert')}
                </ModalDialog>
            </Grid>
        );
    }

    async onQuery(comp, data) {
        if (data.state == BannerState.Approved)
            optionsBannerType.alertBackToCheck = true;

        if (this.props.match.params.fullscreen === "false" && comp.getTemporalItem().fullScreen === true) {
            this.refModalAlert.setOpen(true);
        }

        this.setState({ media: data });
    }

    mustBeFullScreen(sizeX, sizeY) {
        return sizeX * sizeY > 500 * 500;
    }

    async OnBannersChange(comp) {
        let i = comp.getItem();
        if (i != null) {
            i = temporalItem(i);
            if (i.fullScreen) {
                if (this.state.sizeX != this.canvasResW || this.state.sizeY != this.canvasResH) {
                    await this.setState({ sizeX: this.canvasResW, sizeY: this.canvasResH });
                }
            } else {
                if (i.sizeX != this.state.sizeX || i.sizeY != this.state.sizeY) {
                    await this.setState({ sizeX: i.sizeX, sizeY: i.sizeY });
                }
            }
        }

    }
}

export const RenderBannerType = (comp, bannerId = 0, props = {}) => {
    return <Options title={adsStrs.get('AdProduct')} uriOptions={"/advertisers/adType"} uriSel={"/advertisers/:id/advertisement/" + bannerId + "/adType"} filterType={""} optionsToCheckRequiredref={comp.optionsToCheckRequired} viewOptions={bannerId != 0} ref={(c) => optionsBannerType = c} {...props} />
}

const DisabledDiv = ({ children, disabled }) => {
    const style = disabled ? disabledContainerStyles : {};

    return (
        <div style={style}>
            {children}
        </div>
    );
};

const isOptionEnabled = (n, optionsEnabled: [][]) => {
    console.log(optionsEnabled);
    return optionsEnabled.every(o => o[n]);
}

export const RenderOptions = (comp, bannerId = 0, bannerConfig = 0, setOptionsEnabled = null, optionsEnabled: [[]] = [[true, true, true, true], [true, true, true, true]], extraprops = {}) => {
    return <Grid container xs={12} direction="column">
        <div id="OptionsLink" />
        {bannerId == 0 && RenderBannerType(comp, bannerId)}
        {userUtils.GetUserData().isAgent && <DisabledDiv><Options key={`OptionsSL_${bannerConfig}`} {...extraprops} title={""} uriOptions={"/advertisers/streamersGroupFilter"} uriSel={"/advertisers/:id/advertisement/" + bannerId + "/config/" + bannerConfig + "/streamersGroupFilter"} filterType={""} optionsToCheckRequiredref={comp.optionsToCheckRequired} noCheckRequerminet="true" viewOptions={bannerId != 0} OnGetTags={(tags) => setOptionsEnabled && setOptionsEnabled(tags, 0, [false, true, true, true])} /></DisabledDiv>}
        <DisabledDiv {...!isOptionEnabled(1, optionsEnabled) ? { disabled: true } : {}} ><Options {...extraprops} key={`OptionsSL_${bannerConfig}`} title={adsStrs.get('StreamerSel')} uriOptions={"/advertisers/userFilter"} uriSel={"/advertisers/:id/advertisement/" + bannerId + "/config/" + bannerConfig + "/userFilter"} filterType={""} optionsToCheckRequiredref={comp.optionsToCheckRequired} noCheckRequerminet="true" viewOptions={bannerId != 0} /*OnGetTags={(tags) => setOptionsEnabled && setOptionsEnabled(tags, 1, [false, false, true, true])}*//></DisabledDiv>
        <DisabledDiv {...!isOptionEnabled(2, optionsEnabled) ? { disabled: true } : {}} ><Options {...extraprops} key={`OptionsSD_${bannerConfig}`} title={adsStrs.get('StreamerDesc')} uriOptions={"/streamers/description"} uriSel={"/advertisers/:id/advertisement/" + bannerId + "/config/" + bannerConfig + "/streamerDescription"} filterType={"typeStreams"} optionsToCheckRequiredref={comp.optionsToCheckRequired} noCheckRequerminet="true" viewOptions={bannerId != 0} /></DisabledDiv>
        <DisabledDiv {...!isOptionEnabled(3, optionsEnabled) ? { disabled: true } : {}} ><Options {...extraprops} key={`OptionsAD_${bannerConfig}`} title={adsStrs.get('AudDesc')} uriOptions={"/streamers/description"} uriSel={"/advertisers/:id/advertisement/" + bannerId + "/config/" + bannerConfig + "/streamerDescription"} filterType={"typeAudience"} optionsToCheckRequiredref={comp.optionsToCheckRequired} noCheckRequerminet="true" viewOptions={bannerId != 0} /></DisabledDiv>
    </Grid>
}

class BroadCastTimePickerBanner extends BroadCastTimePicker {
    constructor(props) {
        super(props);

        let value = standarValueBroadcastTime(strfieldvalue(this.props.confFieldItem, this.props.item));       
        this.state = {
            option: value === 0 ? 'immediately' : value === -1 ? 'noBroadcast' : 'later',
            dateTime: value,
        };        

        this.remainingMsg = strs.get('BroadcastOn');
        this.passDateMsg = strs.get('StartBroadcastOnSave')
        this.title = strs.get('broadcastDate');
    }

    handleOptionChange = async (event) => {        
        await this.setState({ option: event.target.value, dateTime:0 });
        this.onDataChange(event);
    };

    render() {
        const { option, dateTime, timeRemaining } = this.state;

        const now = new Date();
        const formattedNow = now.getFullYear() + "-" + String(now.getMonth() + 1).padStart(2, "0") + "-" + String(now.getDate()).padStart(2, "0") + "T" + String(now.getHours()).padStart(2, "0") + ":" + String(now.getMinutes()).padStart(2, "0");

        return (
            <div style={{ "paddingBottom": "20px" }}>
                <FormControl fullWidth variant="outlined">
                    <InputLabel>{strs.get('StartBroadcast')}</InputLabel>
                    <Select
                        value={option}
                        onChange={this.handleOptionChange}
                        label={strs.get('StartBroadcast')}
                    >
                        <MenuItem value={'noBroadcast'}>{strs.get('NoBroadcast')}</MenuItem>
                        <MenuItem value={'immediately'}>{strs.get('StartBroadcastNow')}</MenuItem>
                        <MenuItem value={'later'}>{strs.get('StartBroadcastLater')}</MenuItem>
                    </Select>
                </FormControl>

                {option === 'later' && super.render() }
            </div>
        );
    }

    onDataChange = (e) => {
        let parent: COMMComp = this.props.parentComm;
        let valid = false;
        if (this.state.option === 'later') {
            const momentUtc = moment(this.state.dateTime).utc();
            valid = parent.DataChange(this, momentUtc.unix() * 1000);
        } else 
            valid = parent.DataChange(this, this.state.option === 'immediately' ? 0 : -1);
    }
}

const BannerPayment = React.forwardRef((props, ref) => {
    React.useImperativeHandle(ref, () => ({
        setPay1k: (p: number) => {
            if (p != pay1k)
                setPay1k(p)
        },
        setPFRec: (Rec) => {
            if (Rec.state != pfRec.state || Rec.top != pfRec.top) {
                setPFRec(Rec)
            }
        }
    }));

    const [tabValue, setTabValue] = React.useState(
        props.item.amountForPlay <= 0 ? 0 : 1
    );

    const [priority, setPriority] = React.useState(props.item.priorityFactor);
    const [amount, setAmount] = React.useState(props.item.amountForPlay);
    const [pay1k, setPay1k] = React.useState(0);
    const [pfRec, setPFRec] = React.useState({});

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        if (newValue === 0) {
            //props.setItem((prev) => ({
            //    ...prev,
            //    amountForPlay: 0
            //}));
        }
    };

    const handlePriorityChange = (event) => {
        setPriority(event.target.value);
        onDataChange(event.target.value, "priorityFactor");
    };

    const handleAmountChange = (event) => {
        setAmount(event.target.value);
        onDataChange(event.target.value, "amountForPlay");
    };

    const onDataChange = (value, field) => {
        let parent: COMMComp = props.parentComm;
        parent.addData({ [field]: value, ...field == "priorityFactor" ? { amountForPlay: 0 } : {} });
    }

    return (
        <div style={{ borderRadius: '4px', boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)' }}>
            <Tabs
                value={tabValue}
                onChange={handleTabChange}
                variant="fullWidth" // Añade esta línea
            >
                <Tab label={strs.get('PriorityTitle')} />
                <Tab label={strs.get('ViewPayTitle')} />
            </Tabs>
            <div style={{ padding: '10px' }}>
                {tabValue === 0 && (
                    <>
                        <p>{strs.get('PriorityInfo')}</p>
                        <p>
                            {(pfRec.state == undefined || pfRec.state == "calc") ? strs.get('PriorityCalc') : 
                                <>
                                    {strs.get('PriorityRecommend', pfRec.top, pfRec.median)}
                                    <ComTooltip text={<div dangerouslySetInnerHTML={{ __html: strs.get('PriorityRecommendInfo', pfRec.top) }} style={{ "padding": "5px" }} />} style={{ "padding": "5px" }} />
                                </>
                            }
                        </p>
                        <TextField
                            label={strs.get('PriorityField')}
                            type="number"
                            variant="outlined"
                            inputProps={{ min: '1', step: '1' }}
                            value={priority}
                            onChange={handlePriorityChange}
                        />
                        {pay1k != 0 && <p>                            
                            {strs.get('PriorityCost1')} {toMStr(pay1k, false, 10)} {strs.get('PriorityCost2')}
                        </p>
                        }                        
                    </>
                )}
                {tabValue === 1 && (
                    <>
                        <p>{strs.get('ViewPayInfo')}</p>
                        <TextField
                            label={strs.get('AmountField')}
                            type="number"
                            variant="outlined"
                            InputProps={{
                                endAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            value={amount}
                            onChange={handleAmountChange}
                            helperText={simpleCurrencyHelpText(amount)}
                        />
                    </>
                )}
            </div>
        </div>
    );
});

export default connect(mapGenericStateToProps, null)(withRouter(AdsForm));