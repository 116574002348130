import React from 'react';

import './contactpage.styles.scss';
import {Grid, Button, Link, TextField} from '@material-ui/core'
import {Mail} from '@styled-icons/material';

import { Link as RouterLink } from 'react-router-dom';

import ServicesBase from '../../services/base';
//import X from '../../components/x/x.component';
import MuiAlert from '@material-ui/lab/Alert';
import { validateEmail } from '../../structure/common/com'
import { LangStrs, Lang } from '../../utils/languageUtils'

class ContactPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            text: undefined,
            error: undefined
        };
    }

    strs: LangStrs = new LangStrs(new Map(
        [
            ['noMail', new Map(
                [
                    [Lang.ES, 'Correo incorrecto'],
                    [Lang.EN, 'Incorrect mail']
                ])
            ],
            ['mailOk', new Map(
                [
                    [Lang.ES, 'Mensaje enviado, contestaremos tan pronto como sea posible'],
                    [Lang.EN, 'Message sent, we will reply as soon as possible']
                ])
            ],
            ['mailProblem', new Map(
                [
                    [Lang.ES, 'Ha ocurrido un problema, prueba mas tarde'],
                    [Lang.EN, 'A problem has occurred, try again later']
                ])
            ],
            ['question', new Map(
                [
                    [Lang.ES, 'Tiene alguna pregunta?'],
                    [Lang.EN, 'Do you have any questions?']
                ])
            ],
            ['question2', new Map(
                [
                    [Lang.ES, 'Estaremos encantados de responderle'],
                    [Lang.EN, 'We will be pleased in answering it']
                ])
            ],
            ['btnSend', new Map(
                [
                    [Lang.ES, 'Enviar mensaje'],
                    [Lang.EN, 'Send message']
                ])
            ],
            ['yourMail', new Map(
                [
                    [Lang.ES, 'Su dirección de correo electrónico'],
                    [Lang.EN, 'Your email address']
                ])
            ],
            ['message', new Map(
                [
                    [Lang.ES, 'Mensaje'],
                    [Lang.EN, 'Message']
                ])
            ]
        ]
    ));

    sendNotification = async () => {
        await this.setState({ error: undefined });

        let from = document.getElementById("iFrom").value;
        let message = document.getElementById("iMessage").value;

        if (!validateEmail(from)) {
            await this.setState({ error: this.strs.get('noMail') });
            return;
        }

        let tSendNotification: ServicesBase = new ServicesBase("#server#" + "/notifications/contact");
        let rtSingUp = await tSendNotification.ServiceRequest("", 'POST', { 'Content-Type': 'application/json' }, false, { from: from, message: message });

        if (rtSingUp.httpCode == 201) {
            await this.setState({ text: this.strs.get('mailOk') });
            //setTimeout(() => { this.waitvideocreated(videoid); }, 5000);
        } else
            await this.setState({ error: this.strs.get('mailProblem') });
    }

    render() {
        return (
            <div className='contact-page full-with-header'>
                {this.state.text != undefined && <MuiAlert severity="success"><strong>{this.state.text}</strong></MuiAlert>}
                {this.state.error != undefined && <MuiAlert severity="error"><strong>{this.state.error}</strong></MuiAlert>}
                <Grid className="z10 full-height" container
                    alignItems="center"
                    direction="column"
                    justify="center">
                    <Grid className="contact-form" container direction="column" justify="center" alignItems="center">
                        <h2>{ this.strs.get('question') } </h2>
                        <h3>{ this.strs.get('question2') }</h3>
                        <TextField
                            margin="normal"
                            className="contact-input"
                            margin="light"
                            fullWidth
                            label={this.strs.get('yourMail')}
                            variant="outlined"
                            type="Email"
                            id="iFrom"
                        />
                        <TextField
                            className="contact-input"
                            margin="light"
                            fullWidth
                            variant="outlined"
                            label={this.strs.get('message')}
                            multiline
                            rows={4}
                            id="iMessage"
                        />

                        <Button disabled={this.state.text != undefined} className="contact-btn" variant="contained" size="large" color="primary" onClick={this.sendNotification}> Send message <Mail className="mail-icon" size="25"></Mail></Button>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default ContactPage;