import fetch from 'cross-fetch';

const baseURL = ("#server#" + "/");

const Streams = {
    Get: () => {
        return fetch(baseURL+"streamers/stream", {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        })
    }
};

export default Streams;