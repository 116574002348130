import * as React from 'react';

import { Hidden, Grid, Button, TextField, Fab, Select, InputLabel, MenuItem, TextareaAutosize, FormControlLabel, FormControl } from '@material-ui/core';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { LangStrs, Lang } from '../../../utils/languageUtils'
import { strs as strPrivacyPolicy } from '../../../pages/privacypolicy/privacypolicypage.component'
import { strs as strCookiesPolicy } from '../../../pages/cookiespolicy/cookies.component'
import { withStyles } from '@material-ui/core/styles'

import ModalDialog from './modal';

export class ModalPolicy extends ModalDialog {

    constructor(props) {
        props = Object.assign({ btnOKDisable: true }, props);
        props = Object.assign({ btnNODisable: true }, props);
        
        super(props);        
    }

    render() {
        const strs: LangStrs = new LangStrs(new Map(
            [
                ['read', new Map(
                    [
                        [Lang.ES, "Porfavor lee atentamente nuestros términos legales"],
                        [Lang.EN, "Please read our legal terms carefully."]
                    ])
                ],
                ['btnOK', new Map(
                    [
                        [Lang.ES, "He entendido y acepto los términos"],
                        [Lang.EN, "I understand and accept the terms"]
                    ])
                ],
                ['btnNO', new Map(
                    [
                        [Lang.ES, "No estoy de acuerdo"],
                        [Lang.EN, "I disagree"]
                    ])
                ],
                ['howread', new Map(
                    [
                        [Lang.ES, "Desplaza el texto para leerlo"],
                        [Lang.EN, "Swipe the text to read it"]
                    ])
                ]
            ]
        ));

        this.props = Object.assign({ btnOKText: strs.get('btnOK') }, this.props);
        this.props = Object.assign({ btnNOText: strs.get('btnNO') }, this.props);

        this.modalBody = (
            <div>
                <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ paddingBottom: "30px" }}>
                    <b>{strs.get('read')}</b>
                </Typography>
                <div style={{ height: "200px", width: "100%", border: "1px solid #ccc", font: "16px/26px Georgia, Garamond, Serif", overflow: "auto" }} onScroll={() => { this.setState({ btnOKDisable: false, btnNODisable: false }); }} >
                    <div dangerouslySetInnerHTML={{ __html: strPrivacyPolicy.get('info') }} style={{ "padding": "20px" }} />
                    <div dangerouslySetInnerHTML={{ __html: strCookiesPolicy.get('info') }} style={{ "padding": "20px" }} />
                </div>
                <p style={{ "color": "rgb(121, 27, 11)" }}>* {strs.get('howread')}</p>
            </div>
        );
        return super.render();
    }
}