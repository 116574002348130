import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

import { Hidden, Grid, Button, TextField, Fab, Select, InputLabel, MenuItem, TextareaAutosize, FormControlLabel, FormControl } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles'

import { Redirect } from 'react-router-dom';
import MuiAlert from '@material-ui/lab/Alert';

import { FormFrame } from '../formframe/formframe.jsx'
import { ChangePasswordForm } from '../changepassword/changepassword.component'

import { commService } from '../../services/api/list';
import { CommInputField, CommForm, CommFormGen, confForm, UploadVideoMedia, CreatePasswordComponent } from '../../structure/common/com-Form/comm-form.components'
import { CommButton, confCOM, confField, CommTextField, fieldNameFunction, temporalItem, fnfRequireText, fnfRequireText2, checkMailField } from '../../structure/common/com'
import { CommItemOfItems, confAddItem, CommListGen, CommLinkButton, CommDeleteButtonLink, confListField, confList, CommList, CommItemField, CommItemTextField } from '../../structure/common/com-List/comm-list.components'
import { mapGenericStateToProps } from '../../utils/routerUtils'
import { LangStrs, Lang, getUserLang } from '../../utils/languageUtils'
import Loading from '../../structure/common/loading/loading.component';

import ModalDialog from '../../components/common/modal/modal'

import { HashAndEncrypt } from '../../utils/UtilsEnc.js'

import { ModalPolicy } from '../../components/common/modal/modalpolicy'

let strs: LangStrs = new LangStrs(new Map(
    [
        ['TitleActivateAccount', new Map(
            [
                [Lang.ES, 'Activar cuenta'],
                [Lang.EN, 'Activate Account']
            ])
        ],
        ['Activate', new Map(
            [
                [Lang.ES, 'Activar'],
                [Lang.EN, 'Activate']
            ])
        ],
    ]
));

const StyledButton = (selected) => withStyles({
    root: {
        backgroundColor: selected ? '#8CD0F1' : '#35749E',
        marginBottom: '15px',
        marginLeft: '1px',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#8CD0F1',
            color: '#fff',
        },
    }
})(Button);

export class ActivateAccount extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {        
        return (            
            <FormFrame>
                <h2>{strs.get('TitleActivateAccount')}</h2>
                <ChangePasswordForm {...this.props} uri={"/users/activateAccount"} btnStr={strs.get('Activate')} beforeSend={async () => { return await this.modal.showAndGetResult() }} />
                <ModalPolicy ref={(m) => { this.modal = m; }} />
            </FormFrame>
        )
    }
}

export default connect(mapGenericStateToProps, null)(withRouter(ActivateAccount));