
import React from 'react';
import {Route} from 'react-router-dom'

import UserWallet from '../wallet-form/wallet-form.component'
import TransactionForm from '../../structure/transaction-form/transaction-form.component'
import TransactionsPendingPayment from '../../structure/transactions-pending-payment/transactions-pending-payment'
import PlatformWallet from '../../structure/platform-wallet/platform-wallet.component'
import { TransferClauseList } from '../../structure/transfer-clause-list/transfer-clause-list.components'

const TransactionsRouter = (props) => (    
    <React.Fragment>
        <Route exact path='/dashboard/transactions' component={() => <UserWallet title="Transactions" />} />
        <Route exact path='/dashboard/transactions/addFounds/:resource/:id' component={() => <TransactionForm/>} />
        <Route exact path='/dashboard/transactions/pendingPayments' component={() => <TransactionsPendingPayment title={props.title}></TransactionsPendingPayment>} />
        <Route exact path='/dashboard/transactions/platformWallet' component={() => <PlatformWallet title={props.title}></PlatformWallet>} />
        <Route exact path='/dashboard/transactions/clauses' component={() => <TransferClauseList title={props.title}></TransferClauseList>} />
    </React.Fragment>
)

export default TransactionsRouter;