import React, { Component } from 'react';
import { CommInputField, CommForm, CommFormGen, confForm, UploadVideoMedia } from '../../../structure/common/com-Form/comm-form.components'
import { confListField } from '../../../structure/common/com-List/comm-list.components'
import { CommButton, confCOM, confField, CommTextField, fieldNameFunction, temporalItem, fnfRequireText, fnfRequireNumber, confGroup, strfieldvalue, EnumComboBox, CommSimpleTextButton } from '../../../structure/common/com'
import { commService } from '../../../services/api/list';
import ModalDialog from '../../common/modal/modal'
import { Button } from '@material-ui/core'

export class EntityLog extends React.Component {
    constructor(props) {
        super(props);

        this.confForm = [
            new confField("title", props.title == undefined ? CommTextField : null, {}, fnfRequireText("title"), props.title != undefined ? props.title: ""),
            new confListField("Reason", CommTextField, {}, fnfRequireText("message")),
            new confField("", null, {}, "entityModel", props.entityModel),
            new confField("", null, {}, "entity", props.entity)
        ];

    }

    componentDidMount = async () => {}

    render = () => {
        return (
            <CommForm title="" confComm={new confForm(CommFormGen, new commService("/common/entityLog"), this.confForm, undefined)} OnSave={() => { if (this.props.OnSave != undefined) this.props.OnSave() } }/*Owner={this} */ />
        )
    }
}

export class AddEntityLog extends React.Component {
    render = () => {
        this.props = confField.concatProps(this.props);

        const buttonStyle = this.props.buttonColor ? { backgroundColor: this.props.buttonColor, color: 'white' } : {};

        return (
            <>
                {this.props.button && <Button color={"primary"} style={buttonStyle} variant="contained" className="list-button" onClick={(e) => { this.modalLog.setOpen(true) }}>
                    {this.props.button}
                </Button>
                }
                <ModalDialog ref={(m) => { this.modalLog = m; if (this.props.refModal) this.props.refModal(m) }} windowsSyle={true} btnNOVisible={false} btnOKVisible={false}>
                    <EntityLog entityModel={this.props.entityModel} title={this.props.title} entity={this.props.entity}
                        OnSave={async () => {
                                this.modalLog.setOpen(false);
                                if (this.props.OnSave) this.props.OnSave();
                            }
                        }
                    />
                </ModalDialog>
            </>
        );
    }
}