import { call, put, takeLatest } from 'redux-saga/effects'
import { push } from 'react-router-redux';

import Auth from '../../services/api/auth'
import ActionTypes from '../actions/actiontypes'

import { forceUpdateNotifications } from './notifications';


// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* loginUser(action) {
    console.log(action.payload);
   try {
      if (!action.payload.userId || !action.payload.password) {
            throw new Error("NOUSERORPASS");
      }
      const response = yield call(Auth.Login, action.payload);
      const responseText = yield response.text();
      if(response.status < 400) {
          yield put({ type: ActionTypes.WWW_USER_LOGIN_RESPONSE, payload: { status: 1, response: JSON.parse(responseText).jwt } });
          yield put(forceUpdateNotifications());
      } else {
         throw new Error(JSON.parse(responseText).error);
      }
   } catch (e) {
      console.log(e.message);
      yield put({type: ActionTypes.WWW_USER_LOGIN_RESPONSE, payload: {status: 0, response: e.message}});
   }
   yield put({type: ActionTypes.TOGGLE_LOADING, payload: false});
}

/*
  Alternatively you may use takeLatest.

  Does not allow concurrent fetches of user. If "USER_FETCH_REQUESTED" gets
  dispatched while a fetch is already pending, that pending fetch is cancelled
  and only the latest one will be run.
*/
function* LoginSaga() {
  yield takeLatest("WWW_USER_LOGIN", loginUser);
}

export default LoginSaga;