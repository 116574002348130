
import React, { useState } from 'react';

import './dashboard-header.styles.scss';
import {Grid,Link, Button, AppBar, Hidden} from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom';
import {Menu} from '@styled-icons/material'
import GlobalState from '../../store/globalState';
import { LangStrs, Lang } from '../../utils/languageUtils'

function LinkAbs({ url, text, linkColor = '#1347be' }) { // Añade url y text como props y establece un color predeterminado
    const [isHovered, setIsHovered] = useState(false);

    // Define tus estilos en línea
    const linkStyle = {
        textDecoration: isHovered ? 'underline' : 'none', // Cambia según el estado isHovered
        color: linkColor, // Usa el color proporcionado o el predeterminado
        cursor: 'pointer', // Cambia el cursor a una mano para indicar que es un enlace
    };

    return (
        <div>
            <a
                href={url} // Usa la URL proporcionada
                style={linkStyle}
                rel="noopener noreferrer"
                onMouseEnter={() => setIsHovered(true)} // Cambia isHovered a true cuando el cursor entra
                onMouseLeave={() => setIsHovered(false)} // Cambia isHovered a false cuando el cursor sale
            >
                {text}
            </a>
        </div>
    );
}

export class DashboardHeader extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            menuOpen: false
        }
        GlobalState.add("sidemenu", (val ) => { 
            this.setState({menuOpen: val});
        })
    }

    render() {
        let strs: LangStrs = new LangStrs(new Map(
            [
                ['Home', new Map(
                    [
                        [Lang.ES, 'Inicio'],
                        [Lang.EN, 'Home']
                    ])
                ],
                ['Contact', new Map(
                    [
                        [Lang.ES, 'Contacto'],
                        [Lang.EN, 'Contact']
                    ])
                ],
                ['Documetation', new Map(
                    [
                        [Lang.ES, 'Documentación'],
                        [Lang.EN, 'Documetation']
                    ])
                ],
                ['Logout', new Map(
                    [
                        [Lang.ES, 'Cerrar sesión'],
                        [Lang.EN, 'Logout']
                    ])
                ]
            ]
        ));

        return (
            <AppBar className={"home-header z15 " + this.props.className} style={{backgroundColor: "white"}}>
                <Grid container direction="row" justify="flex-start" alignItems="center">
                    <Grid item md={3} xs={11}>
                        <Grid container justify="flex-start" alignItems="center" direction="row" >
                            <Button className="menu-button" onClick={()=>{GlobalState.set("sidemenu", !this.state.menuOpen)}}><Menu size={30} /></Button>
                            <img src="/LogoPowerAdsPubOld3.svg" width="100%" alt="PowerAds logo" />
                        </Grid>
                    </Grid>
                    <Grid item md={8} xs={0}>
                        <Hidden only={['sm', 'xs']}>
                            <Grid container style={{height:"100%"}}>
                                <Grid container justify="flex-end" alignContent="center" direction="row">
                                    <LinkAbs url="https://home.powerads.tv/" text={strs.get('Home')} />
                                    <LinkAbs url="https://home.powerads.tv/docs/index.php" text={strs.get('Documetation')} />
                                    <Link component={RouterLink} to="/contact">{strs.get('Contact')}</Link>
                                    <Link className="red-link" component={RouterLink} to="/dashboard/logout">{strs.get('Logout')}</Link>
                                </Grid>
                            </Grid>
                        </Hidden>
                    </Grid>
                </Grid>
            </AppBar>
        )
    }
}

export default DashboardHeader;