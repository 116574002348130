export let Params = {

    "particles": {
        "emmiter": {
            "life": {
                "duration": 1,
            },
        },
        "number": {
            "value": 7,
            "density": {
                "enable": true,
                "value_area": 800
            }
        },
        "color": {
            "value": "#04B2F2"
        },
        "shape": {
            "type": "edge",
            "stroke": {
                "width": 0,
                "color": "#000"
            },
            "polygon": {
                "nb_sides": 4
            },
            "image": {
                "src": "img/github.svg",
                "width": 100,
                "height": 100
            }
        },
        "opacity": {
            "value": 0.05,
            "random": false,
            "anim": {
                "enable": false,
                "speed": 10,
                "opacity_min": 1,
                "sync": false
            }
        },
        "size": {
            "value": 160,
            "random": true,
            "anim": {
                "enable": true,
                "speed": 100,
                "size_min": 40,
                "sync": false
            }
        },
        "line_linked": {
            "enable": false,
            "distance": 200,
            "color": "#ffffff",
            "opacity": 1,
            "width": 2
        },
        "move": {
            "enable": true,
            "speed": 8,
            "direction": "none",
            "random": true,
            "straight": false,
            "out_mode": "out",
            "bounce": false,
            "attract": {
                "enable": false,
                "rotateX": 600,
                "rotateY": 1200
            }
        }
    },
    "interactivity": {
        "detect_on": "window",
        "events": {
            "onhover": {
                "enable": false,
                "mode": "grab"
            },
            "onclick": {
                "enable": true,
                "mode": "push"
            },
            "resize": true
        },
        "modes": {
            "grab": {
                "distance": 400,
                "line_linked": {
                    "opacity": 1
                }
            },
            "bubble": {
                "distance": 400,
                "size": 40,
                "duration": 2,
                "opacity": 8,
                "speed": 3
            },
            "repulse": {
                "distance": 200,
                "duration": 0.4
            },
            "push": {
                "particles_nb": 4
            },
            "remove": {
                "particles_nb": 8
            }
        }
    },
    "retina_detect": true
}