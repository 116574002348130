
import React, { PureComponent, useState, useEffect, Fragment } from 'react';

import './streams-grid-admin.styles.scss'
import { Grid, CardContent, Card, Typography, CardActions, Button, Link } from '@material-ui/core'
import Box from "@material-ui/core/Box";
import { MultiSelect } from "react-multi-select-component";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import ContentNavigation from '../../structure/content-navigation/content-navigation.component'
import { CommItemListAux, confAddItem, CommButtonSave, CommItemTextField, CommListGen, CommLinkButton, CommDeleteButtonLink, confListField, confList, CommList, CommItemField, CommItemOfItems, CommButtonLinkItem } from '../common/com-List/comm-list.components'
import { commService } from '../../services/api/list';
import { getServerUrl } from '../../services/base';
import './../../components/streamer/stream-card/stream-card.styles.scss';
import { ConfStreamingAccountListCommon } from './../streamingAccount/streamingAccount-toStream.components'
import { myReactTaggedStateComponent, InLineComponent, ConditionalComponent } from '../common/extComponents'
import { CommButton, fieldNameFunction } from '../common/com'
import AddNewButton from '../common/add-new-button/add-new-button.component';
import { DurationTime } from '../../utils/timeUtils'
import { changeUrl, Urls } from '../../utils/routerUtils'

import ServicesBase from '../../services/base';
import UserUtils from '../../../src/utils/userUtils'
import { toMStr } from '../../utils/moneyUtils.jsx'
import { DownloadUrlVideo } from '../../utils/fileUtils'

import { Link as RouterLink } from 'react-router-dom';
import { isBrowser } from '../../utils/platformsUtils';

import { StreamingAccountToStream, SelStreamingAccountActionField } from '../streamingAccount/streamingAccount-toStream.components.jsx'
import ModalDialog from '../../components/common/modal/modal'
import { StreamSceneListSelector } from '../../structure/streamScene/streamScene-list.components'
import { GenerateVideoForm } from '../../structure/generatevideo-form/generateVideo-form.jsx'
import { LangStrs, Lang } from '../../utils/languageUtils'
import { BannerToStreamTransferList, StreamStates, TransferStates } from '../../structure/transactions-ads-stream/transactions-ads-stream'
import SelectUser, { StreamFilterTypes,  } from '../../components/admin/select-user/select-user.component.jsx'
import { SelectFromConst } from '../../components/common/commonComponents.jsx'
import { buttonPost } from '../common/com-List/comm-list-template-elements.jsx'
import { getEnumFieldName } from '../../utils/utils'
import ExternalTransferPayment from '../external-transfer-payment/external-transfer-payment.component'

const buttonStyles = () => ({
    root: {
      '& > *': {
        margin: "0.5em"
      },
    },
});

const strs: LangStrs = new LangStrs(new Map(
    [
        ['AllStreams', new Map(
            [
                [Lang.ES, 'Todos los streams'],
                [Lang.EN, 'All Streams']
            ])
        ],
        ['AlreadyValidated', new Map(
            [
                [Lang.ES, 'Comprobar streams ya validados'],
                [Lang.EN, 'Check already validated streams']
            ])
        ],
        ['StreamsToPay', new Map(
            [
                [Lang.ES, 'Streams por pagar'],
                [Lang.EN, 'Streams to pay']
            ])
        ],
        ['INVALIDATE', new Map(
            [
                [Lang.ES, 'INVALIDAR'],
                [Lang.EN, 'INVALIDATE']
            ])
        ],
        ['VALIDATE', new Map(
            [
                [Lang.ES, 'VALIDAR'],
                [Lang.EN, 'VALIDATE']
            ])
        ],
        ['ToPAY', new Map(
            [
                [Lang.ES, 'MARCAR PARA PAGAR'],
                [Lang.EN, 'MARCAR PARA PAGAR']
            ])
        ],
        ['ToStreamer', new Map(
            [
                [Lang.ES, 'Al Streamer'],
                [Lang.EN, 'To Streamer']
            ])
        ]
    ]
));

export const StreamsFilterTypes = [
    { label: strs.get('AlreadyValidated'), value: 'Validation1' },
    { label: strs.get('StreamsToPay'), value: 'streamsToPay' }
];

class StreamsGridAdmin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: StreamsFilterTypes[0],
            user: null
        }

        this.conf = [
            new confListField('id', CommItemField, {}, "id"),
            new confListField('url', CommItemField, {}, "url"),
            new confListField('state', CommItemField, { className: "list-field bi-date not-mobile" }, (item) => { return <b>{this.stateToStr(item.state)} </b> }),
        ];
    }

    //export enum StreamStates {
    //    None = -1,
    //    ToValidate = 0,
    //    Validated = 1,
    //    Validating = 4,
    //    NoCompleteInformation = 2,
    //    NoValid = 3,
    //};

    stateToStr = (state) => {
        if (state == 0) return "ToValidate"
        if (state == 1) return "Validated"
        if (state == 4) return "Validating"
        if (state == 2) return "NoCompleteInformation"
        if (state == 3) return "NoValid"
        return "None";
    }

    customButtons = (selectedOption) => (comp, detailsComponents) => {
        const validateButton = (label, state) => buttonPost(label, (item) => { return '#server#/validations/stream/' + item.stream.id; }, 'PUT', { state: state }, false, () => { comp.list.QueryItems(); })
        if (selectedOption == StreamsFilterTypes[0].value) {
            detailsComponents.splice(0, 0, validateButton(strs.get('ToPAY'), StreamStates.ToPay));
            detailsComponents.splice(0, 0, validateButton(strs.get('INVALIDATE'), StreamStates.NoValid).addField("color", "#AB0000"));
            detailsComponents.splice(0, 0, validateButton(strs.get('VALIDATE'), StreamStates.Validated).addField("color", "#00AB00"));
        }
    }

    customConf = (comp, conf) => {
        conf.splice(4, 0, new confListField('State', CommItemField, {}, (item) => (item.stream.reValidated && item.stream.state == TransferStates.TOAPROVE) ? "BLOCKED" : getEnumFieldName(StreamStates, item.stream.state)));
        conf.splice(4, 0, new confListField(strs.get('ToStreamer'), CommItemField, {}, (item) => toMStr(item.transfers.amount)));        
    }

    list = {};
    createBannerToStreamTransferList = (paymentComp, selectedOption) => <BannerToStreamTransferList ref={(l) => this.list[selectedOption] = l} {...paymentComp ? { canSel: true, setRefItemSelector: paymentComp.setRefItemSelector, OnChangeSelection: paymentComp.OnChangeItemSelection } : {}} url={"/Transfers/streamerFromBanners/" + this.state.user + "/" + selectedOption} streamer={this.state.user} customConf={this.customConf} customButtons={this.customButtons(selectedOption)} />

    render = () => {
        return (
            <React.Fragment>                
                <SelectUser filter={StreamFilterTypes} OnSelectUser={async (user) => { await this.setState({ user: user.value }); }} />
                { this.state.user &&
                    <React.Fragment>
                        <SelectFromConst style={{ width: "100%" }} defaultFirst Values={StreamsFilterTypes} onSelect={async (event, newValue) => { this.setState({ selectedOption: newValue, selectedStreams: [], selectedMoneyAccount:[] }) }} />
                        {this.state.selectedOption == StreamsFilterTypes[0] && this.createBannerToStreamTransferList(null, StreamsFilterTypes[0].value)}
                        {this.state.selectedOption == StreamsFilterTypes[1] && <ExternalTransferPayment key={this.state.user} CompListTransactionSelection={(paymentComp) => this.createBannerToStreamTransferList(paymentComp, StreamsFilterTypes[1].value)} apiUrl={"#server#/Transfers/StreamsPay"} itemAmount={item => item.transfers.amount}/> }
                    </React.Fragment>
                }
            </React.Fragment>
        )
    }
}

export default withStyles(buttonStyles)(StreamsGridAdmin);                                        