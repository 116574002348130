
import React, { PureComponent, useState, useEffect, Fragment } from 'react';

import './external-transfer-payment.styles.scss'
import InputAdornment from "@material-ui/core/InputAdornment";
import { Grid, CardContent, Card, Typography, CardActions, Button, Link } from '@material-ui/core'
import Box from "@material-ui/core/Box";
import { MultiSelect } from "react-multi-select-component";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import ContentNavigation from '../../structure/content-navigation/content-navigation.component'
import { CommItemListAux, confAddItem, CommButtonSave, CommItemTextField, CommListGen, CommLinkButton, CommDeleteButtonLink, confListField, confList, CommList, CommItemField, CommItemOfItems, CommButtonLinkItem } from '../common/com-List/comm-list.components'
import { commService } from '../../services/api/list';
import { getServerUrl } from '../../services/base';
import './../../components/streamer/stream-card/stream-card.styles.scss';
import { ConfStreamingAccountListCommon } from './../streamingAccount/streamingAccount-toStream.components'
import { myReactTaggedStateComponent, InLineComponent, ConditionalComponent } from '../common/extComponents'
import { CommButton, fieldNameFunction } from '../common/com'
import AddNewButton from '../common/add-new-button/add-new-button.component';
import { DurationTime } from '../../utils/timeUtils'
import { changeUrl, Urls } from '../../utils/routerUtils'

import ServicesBase from '../../services/base';
import UserUtils from '../../../src/utils/userUtils'
import { toMStr } from '../../utils/moneyUtils.jsx'
import { DownloadUrlVideo } from '../../utils/fileUtils'

import { Link as RouterLink } from 'react-router-dom';
import { isBrowser } from '../../utils/platformsUtils';

import { StreamingAccountToStream, SelStreamingAccountActionField } from '../streamingAccount/streamingAccount-toStream.components.jsx'
import ModalDialog from '../../components/common/modal/modal'
import { StreamSceneListSelector } from '../../structure/streamScene/streamScene-list.components'
import { GenerateVideoForm } from '../../structure/generatevideo-form/generateVideo-form.jsx'
import { LangStrs, Lang } from '../../utils/languageUtils'
import { BannerToStreamTransferList, StreamStates, TransferStates } from '../../structure/transactions-ads-stream/transactions-ads-stream'
import SelectUser, { StreamFilterTypes,  } from '../../components/admin/select-user/select-user.component.jsx'
import { SelectFromConst } from '../../components/common/commonComponents.jsx'
import { buttonPost } from '../common/com-List/comm-list-template-elements.jsx'
import { getEnumFieldName } from '../../utils/utils'
import { PaymentMethodsList } from '../payment-methods-list/payment-methods-list.component.jsx'

const buttonStyles = () => ({
    root: {
      '& > *': {
        margin: "0.5em"
      },
    },
});

const strs: LangStrs = new LangStrs(new Map(
    [
        ['AllStreams', new Map(
            [
                [Lang.ES, 'Todos los streams'],
                [Lang.EN, 'All Streams']
            ])
        ],
        ['AlreadyValidated', new Map(
            [
                [Lang.ES, 'Comprobar streams ya validados'],
                [Lang.EN, 'Check already validated streams']
            ])
        ],
        ['StreamsToPay', new Map(
            [
                [Lang.ES, 'Streams por pagar'],
                [Lang.EN, 'Streams to pay']
            ])
        ],
        ['INVALIDATE', new Map(
            [
                [Lang.ES, 'INVALIDAR'],
                [Lang.EN, 'INVALIDATE']
            ])
        ],
        ['VALIDATE', new Map(
            [
                [Lang.ES, 'VALIDAR'],
                [Lang.EN, 'VALIDATE']
            ])
        ],
        ['ToPAY', new Map(
            [
                [Lang.ES, 'MARCAR PARA PAGAR'],
                [Lang.EN, 'MARCAR PARA PAGAR']
            ])
        ],
        ['ToStreamer', new Map(
            [
                [Lang.ES, 'Al Streamer'],
                [Lang.EN, 'To Streamer']
            ])
        ]
    ]
));

class ExternalTransferPayment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            itemsSelected: [],
            selectedMoneyAccount: []
        }

        this.conf = [
            new confListField('id', CommItemField, {}, "id"),
            new confListField('url', CommItemField, {}, "url"),
            new confListField('state', CommItemField, { className: "list-field bi-date not-mobile" }, (item) => { return <b>{this.stateToStr(item.state)} </b> }),
        ];
    }

    OnChangeItemSelection = (items) => {
        this.setState({ itemsSelected: items });
        console.log(items);
    }

    OnChangeMoneyAccountSelection = (items) => {
        this.setState({ selectedMoneyAccount: items });
        console.log(items);
    }

    setRefItemSelector = (itemSelector) => {
        this.itemSelector = itemSelector;
    }

    handlePAY = async () => {
        //let url = "#server#/Transfers/StreamsPay";
        let url = this.props.apiUrl;
        let sb: ServicesBase = new ServicesBase(url);
        const data: any = { items: this.state.itemsSelected, MoneyAccount: this.state.selectedMoneyAccount[0], amount: this.state.amount }
        let resp = await sb.ServiceRequest("", "POST", { 'Content-Type': 'application/json' }, true, data);
        await this.setState({ itemsSelected: [] });
        await this.itemSelector.list.QueryItems();        
    }

    handleAmount = async (e) => {
        await this.setState({ amount: e.target.value });
    }

    render = () => {
        let selAmount = this.state.itemsSelected.reduce((total, item) => total + this.props.itemAmount(item), 0);

        return (
            <React.Fragment>
                {this.props.CompListTransactionSelection(this)}
                <PaymentMethodsList key={UserUtils.GetUserData().id} OnChangeSelection={this.OnChangeMoneyAccountSelection} />
                <Box display="flex" flexDirection="column">
                    <Box display="flex" alignItems="flex-start">
                        <p style={{ marginRight: "8px" }}>Total selected : <b>{toMStr(selAmount)}</b></p>
                    </Box>
                    <Box display="flex" alignItems="center">
                        <TextField
                            InputProps={{
                                endAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            label={"Amount to pay"}
                            onChange={this.handleAmount}
                            value={this.state.amount || selAmount}
                            type="number"
                            disabled={UserUtils.isUserType(UserUtils.UserTypes.Streamer)}
                        />
                        <Button disabled={(this.state.itemsSelected.length == 0 && (!this.state.amount || this.state.amount < 0)) || this.state.selectedMoneyAccount.length == 0} color={"primary"} variant="contained" className="list-button" onClick={this.handlePAY} >PAGAR</Button>
                    </Box>
                </Box>
            </React.Fragment>
        )
    }
}

export default withStyles(buttonStyles)(ExternalTransferPayment);