
import Login from './login';
import Register from './register';
import Streams from './streams';
import Banners from './banners';
import notifications from './notifications';


export default (middleware) => {
    middleware.run(Login);
    middleware.run(Register);
    middleware.run(Streams);
    middleware.run(Banners);
    middleware.run(notifications);
}