import { call, put, takeLatest } from 'redux-saga/effects'
import { push } from 'react-router-redux';

import Auth from '../../services/api/auth'
import ActionTypes from '../actions/actiontypes'
 
// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* registerUser(action) {
   try {
      if(!action.payload.username && !action.payload.password && !action.payload.email && !action.payload.usertype && !action.payload.name && !action.payload.city) {
         throw new Error("Missing param username, password, email, usertype, city or name");
      }
      const response = yield call(Auth.Register, action.payload);
      const responseText = yield response.text();
      if(response.status < 400) {
         yield put({type: ActionTypes.WWW_USER_REGISTER_RESPONSE, payload: {status: 1, response: responseText}});
      } else {
         throw new Error(JSON.parse(responseText).error);
      }
   } catch (e) {
      console.log(e.message);
      yield put({type: ActionTypes.WWW_USER_REGISTER_RESPONSE, payload: {status: 0, response: e.message}});
   }
   yield put({type: ActionTypes.TOGGLE_LOADING, payload: false});
}

/*
  Alternatively you may use takeLatest.

  Does not allow concurrent fetches of user. If "USER_FETCH_REQUESTED" gets
  dispatched while a fetch is already pending, that pending fetch is cancelled
  and only the latest one will be run.
*/
function* RegisterSaga() {
  yield takeLatest("WWW_USER_REGISTER", registerUser);
}

export default RegisterSaga;