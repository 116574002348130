import React from 'react';

import './content-navigation.styles.scss';
import {Grid, Button} from '@material-ui/core'

const ContentNavigation = (props) => {
    let paginationElements = [];
    let pageCount = Math.ceil(props.itemCount / props.itemsPerPage);
    if(pageCount > 1) {
        if(props.currentPage > 1) {
            paginationElements.push((<Button key="page-<" onClick={() => {props.event(parseInt(props.currentPage)-1)}}  className="width-auto navigation-button" size="small" color="primary">&lt;</Button>));
        }
        if(props.currentPage > 1) {
            if(props.currentPage > 2) {
                paginationElements.push((<Button key={"page-"+(props.currentPage-2)} onClick={() => {props.event(parseInt(props.currentPage)-2)}}  className="width-auto navigation-button" size="small" color="primary">{props.currentPage-2}</Button>));
            }   
            paginationElements.push((<Button key={"page-"+(props.currentPage-1)} onClick={() => {props.event(parseInt(props.currentPage)-1)}}  className="width-auto navigation-button" size="small" color="primary">{props.currentPage-1}</Button>));
        }
        paginationElements.push((<Button key={"page-"+props.currentPage} onClick={() => {props.event(parseInt(props.currentPage))}}  className="width-auto navigation-button" size="small" color="secondary"><strong>{props.currentPage}</strong></Button>));
        if(pageCount - props.currentPage > 0) {
            paginationElements.push((<Button key={"page-" + (props.currentPage + 1)} onClick={() => { props.event(parseInt(props.currentPage)+1)}}  className="width-auto navigation-button" size="small" color="primary">{parseInt(props.currentPage)+1}</Button>));
            if(pageCount - props.currentPage > 1) {
                paginationElements.push((<Button key={"page-"+(props.currentPage+2)} onClick={() => {props.event(parseInt(props.currentPage)+2)}}  className="width-auto navigation-button" size="small" color="primary">{parseInt(props.currentPage)+2}</Button>));
            }   
        }
        if(props.currentPage < pageCount) {
            paginationElements.push((<Button key={"page->"} onClick={() => {props.event(parseInt(props.currentPage)+1)}} className="width-auto navigation-button" size="small" color="primary">&gt;</Button>));
        }
    }

    return (<Grid className="content-navigation" container item xs={12} justify="flex-end" alignItems="flex-end">
        {paginationElements}
    </Grid>)
}

export default ContentNavigation;