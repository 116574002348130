
import React from 'react';

import './profile-dropdown.styles.scss'
import {Grid, Button} from '@material-ui/core';
import {} from '@styled-icons/material'
import {Visibility, VisibilityOff} from '@styled-icons/material';
import ProfileTag from '../profile-tag/profile-tag.component.jsx';

import UserUtils from '../../utils/userUtils'

import OptionsService from '../../services/api/options/';
import StreamerAPI from '../../services/api/streamers'
import { getBackText } from '../../utils/languageUtils';

import { LangStrs, Lang } from '../../utils/languageUtils'
import userUtils, { UserTypes } from '../../utils/userUtils';

let strs: LangStrs = new LangStrs(new Map(
    [
        ['OptionRequired', new Map(
            [
                [Lang.ES, ' (es necesario seleccionar una de las siguientes opciones) '],
                [Lang.EN, ' (it is required to select one of the following options) ']
            ])
        ]
    ]
));

class ProfileDropdown extends React.Component {    
    constructor(props) {
        super(props);

        this.state = {
            visible: true
        }
    }

    render = () => <Grid>
            {this.props.title && this.props.title != "" &&
                <h3>
                    <Button onClick={() => { this.setState({ visible: !this.state.visible }) }} className="tags-toggle">
                        {!this.state.visible ?
                            <VisibilityOff size={25} /> :
                            <Visibility size={25} />}
                    </Button>
                    {this.props.title}
                </h3>
            }
            <Grid className="" style={!this.state.visible ? { height: 0 } : {}} data-section="ad-tags">
            {
                this.props.tags.map((tag) => {
                    return (userUtils.GetUserData().usertype != UserTypes.Admin || tag.options.length > 0) &&
                        <parent>
                                <div style={(!tag.changeable && userUtils.GetUserData(true).usertype != UserTypes.Admin) ? { opacity: 0.5, backgroundColor: "gray", pointerEvents: "none" } : {}}>
                                    <div id={tag.id} style={{ position: "relative", top: "-60px" }} />
                                    <ProfileTag parent={this.props.parent} viewOptions={this.props.viewOptions} onExpand={() => { }} key={tag.id} title={(this.props.noCheckRequerminet == undefined && tag.required) ? (getBackText(tag.title) + strs.get('OptionRequired')) : getBackText(tag.title)} description={getBackText(tag.description)} expanded={true} items={tag.options} onToggle={() => { }} tag={tag} onToggleCheck={this.props.OnTagClickCheck} />
                                </div>
                        </parent>
                })                
            }
            </Grid>
        </Grid>
}

export default ProfileDropdown;