import ServicesBase from "../../base"

export class PermissionService extends ServicesBase {
    constructor(permissionType:string) {
        super("#server#");
        this.permissionType = permissionType;
    }
    Toggle = async (add, data, loading = true) => { //refer segons get
        return await this.ServiceRequest("/common/permissions/" + this.permissionType, add?'POST' : 'DELETE', {
            'Content-Type': 'application/json',
        },
            loading,
            add ? data : JSON.stringify(data)
        );
    }

    Change = async (idPermission, data, loading = true) => {
        return await this.ServiceRequest("/common/permissions/" + this.permissionType, 'PUT', {
            'Content-Type': 'application/json',
        },
            loading,
            { id: idPermission, up: data }
        );
    }
};