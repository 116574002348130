
import React from 'react';
import {Route} from 'react-router-dom'

import Profile from '../profile/profile.component'
import MailNotificacionConfig from '../mailNotificacionConfig/mailNotificacionConfig.component'
import PaymentMethodsList from '../payment-methods-list/payment-methods-list.component.jsx'
import AddPaymentMethod from '../add-payment-method/add-payment-method.component.jsx'
import LinkedAccounts from '../accounts-list/accounts-list.component.jsx'

import { LangStrs, Lang } from '../../utils/languageUtils'

let strs: LangStrs = new LangStrs(new Map(
    [
        ['Profile', new Map(
            [
                [Lang.ES, 'Perfil'],
                [Lang.EN, 'Profile']
            ])
        ],
        ['TAddPayment', new Map(
            [
                [Lang.ES, 'Agregar metodo de pago'],
                [Lang.EN, 'Add Payment Method']
            ])
        ],
        ['TPaymentMathod', new Map(
            [
                [Lang.ES, 'Metodo de pago'],
                [Lang.EN, 'Payment Methods']
            ])
        ]
    ]
));

const SettingsRouter = (props) => (    
    <React.Fragment>
        <Route exact path='/dashboard/settings/profile' component={() => <Profile title={strs.get('Profile')} />} />
        <Route exact path='/dashboard/settings/mailNotifications' component={() => <MailNotificacionConfig/>} />
        <Route exact path='/dashboard/settings/payment/add' component={() => <AddPaymentMethod title={strs.get('TAddPayment')} />} />
        <Route exact path='/dashboard/settings/payment' component={() => <PaymentMethodsList title={strs.get('TPaymentMathod')} />} />
    </React.Fragment>
)

export default SettingsRouter;