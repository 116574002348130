import React, { useEffect, useState, useRef } from 'react';
import { LangStrs, Lang } from '../../utils/languageUtils'
import { BrowserView, MobileView, isBrowser, isMobile } from '../../utils/platformsUtils';
import { Button, Grid, Box, CircularProgress, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles'
import PDFObject from 'pdfobject';

const PdfPage = (props) => {
    return (
        <div className='homepage' style={{'alignItems': 'center', width: '100%' }} >         
            <Grid container xl={12} xs={12} direction="column" justify="center" alignItems="center" wrap style={{ "padding": "20px" }} >
                <Box display="flex" justifyContent="center" alignItems="center" position="relative" width="80%" height="90vh">
                    <PDFViewer url={props.url} />
                </Box>
            </Grid>
        </div>
    )
}

const PDFViewer = ({ url }) => {
    const containerRef = useRef(null);

    useEffect(() => {
        if (containerRef.current) {
            PDFObject.embed(url, containerRef.current, {
                pdfOpenParams: { toolbar:0 }
            });
        }
    }, [url]);

    return (
        <div ref={containerRef} style={{ width: '100%', height: '100%' }}/>
    );
};


export default PdfPage;