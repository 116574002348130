
import React, { useState } from 'react';
import { withRouter, Link as RouterLink } from 'react-router-dom'

import './../dashboard-header/dashboard-header.styles.scss';

import { Grid, Link, Button, AppBar, Hidden } from '@material-ui/core'
import { BrowserView, MobileView, isBrowser, isMobile } from '../../utils/platformsUtils';
import { LangStrs, Lang } from '../../utils/languageUtils'
import { Urls } from '../../utils/routerUtils';

function LinkAbs({ url, text, linkColor = '#1347be' }) { // Añade url y text como props y establece un color predeterminado
    const [isHovered, setIsHovered] = useState(false);

    // Define tus estilos en línea
    const linkStyle = {
        textDecoration: isHovered ? 'underline' : 'none', // Cambia según el estado isHovered
        color: linkColor, // Usa el color proporcionado o el predeterminado
        cursor: 'pointer', // Cambia el cursor a una mano para indicar que es un enlace
    };

    return (
        <div>
            <a
                href={url} // Usa la URL proporcionada
                style={linkStyle}
                rel="noopener noreferrer"
                onMouseEnter={() => setIsHovered(true)} // Cambia isHovered a true cuando el cursor entra
                onMouseLeave={() => setIsHovered(false)} // Cambia isHovered a false cuando el cursor sale
            >
                {text}
      </a>
        </div>
    );
}


export class HomeHeader extends React.Component {
    render() {
        let strs: LangStrs = new LangStrs(new Map(
            [
                ['Home', new Map(
                    [
                        [Lang.ES, 'Inicio'],
                        [Lang.EN, 'Home']
                    ])
                ],
                ['Documetation', new Map(
                    [
                        [Lang.ES, 'Documentación'],
                        [Lang.EN, 'Documetation']
                    ])
                ],
                ['Contact', new Map(
                    [
                        [Lang.ES, 'Contacto'],
                        [Lang.EN, 'Contact']
                    ])
                ],
                ['DashBoard', new Map(
                    [
                        [Lang.ES, 'Panel de control'],
                        [Lang.EN, 'Control panel']
                    ])
                ],
            ]
        ));

        return (
            <AppBar className={"home-header z15 " + this.props.className} style={{ backgroundColor: "white" }}>
                <Grid container direction="row">
                    <Grid container item xs={3} justify="center" alignItems="center" direction="column" >
                        <BrowserView>
                            <Link component={RouterLink} to="/">
                                <img src="/LogoPowerAdsPubOld3.svg" width="100%" style={{ font: "3em 'MyFont'" }}/>
                            </Link>
                        </BrowserView>
                    </Grid>
                    <Grid {...isBrowser ? { container: true, item: true, xs: 9, justify: "flex-end", alignItems: "center", spacing: 10 } : { container: true, item: true, xs: 12, alignItems: "center" }}>
                        <LinkAbs url="https://home.powerads.tv/" text={strs.get('Home')}/>
                        <LinkAbs url="https://home.powerads.tv/docs/index.php" text={strs.get('Documetation')} />
                        <Link component={RouterLink} to="/contact">{strs.get('Contact')}</Link>
                        <Link component={RouterLink} to="/login">{strs.get('DashBoard')}</Link>
                    </Grid>
                </Grid>
            </AppBar>
        )
    }
}

export default HomeHeader;