import React, { useState } from 'react';
import utils from './userUtils.js'

function convertToStr(n, signed = true, ndec = 5) {
    return ((signed && +n > 0) ? '+' : '') + (+n.toFixed(ndec));
}

export const toMStr = (n, signed = false, ndec = 5) => {
    if (n == undefined) return undefined;

    let r2 = convertUSDToCurrency(n, signed, ndec);
    const r1 = convertToStr(n, signed, ndec) + '$';

    return <MultiCurrency c1={r1} c2={r2}/>
}

export const simpleCurrencyHelpText = (n) => {
    const cuc = convertUSDToCurrency(n);
    if (cuc == "") return "";
    return <>{+n ? "~ " : ""}<strong>{cuc}</strong></>
}

export const CurrencySuper = ({ value }) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);
    
    return (
        <span
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <sup style={{ cursor: 'pointer', color: 'blue', fontSize: '0.75em', }}>
                $⇔€
            </sup>
            {isHovered && (
                <span
                    style={{
                        position: 'absolute',
                        backgroundColor: 'black',
                        color: 'white',
                        padding: '5px',
                        borderRadius: '5px',
                        fontSize: '0.75em',
                        marginLeft: '5px',
                    }}
                >
                    {value}
                </span>
            )}
        </span>
    );
};

export const MultiCurrency = ({ c1, c2 }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [iChangeRate] = useState(changeValue.rate!=null);

    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);

    return (
        <span
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {c1}
            {getCurrCurrency() != "USD" && iChangeRate && isHovered && (
                <span
                    style={{
                        position: 'absolute',
                        backgroundColor: 'black',
                        color: 'white',
                        padding: '5px',
                        borderRadius: '5px',
                        fontSize: '0.75em',
                        marginLeft: '5px',
                        zIndex:100
                    }}
                >
                    aprox. {c2}
                </span>
            )}
        </span>
    );
};

let currCurrency = null;
function getCurrCurrency() {
    if (!currCurrency) {
        currCurrency = utils.GetUserData().currency;
    }
    return currCurrency;
}

export function setCurrCurrency(c) {
    currCurrency = c;
    changeValue.rate = null;
    getCurrencyExchangeRate(currCurrency);
}

let changeValue = { rate: null, lastUpdated: null };

function getCurrencyExchangeRate(toCurrency) {
    toCurrency = toCurrency.toLowerCase();
    //https://github.com/fawazahmed0/currency-api

    return fetch(`https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/usd/${toCurrency}.json`)
        .then(response => response.json())
        .then(data => {
            changeValue.rate = data[toCurrency];
            changeValue.lastUpdated = Date.now();
            return changeValue.rate;
        });
}

function shouldUpdateRate() {
    return !changeValue.rate || (Date.now() - changeValue.lastUpdated) > 8 * 60 * 60 * 1000;
}

export function convertUSDToCurrency(amount, signed = false, ndec = 5) {
    if (getCurrCurrency() == "USD") return "";

    let currency = getCurrCurrency();
    if (shouldUpdateRate()) {
        getCurrencyExchangeRate(currency);
    }
    if (!changeValue.rate) return "";

    let n = +amount * changeValue.rate;
    //const sym = currencies.find(currency => currency.abbreviation === getCurrCurrency()).symbol;
    const sym = " "+getCurrCurrency();
    return convertToStr(n, signed, ndec) + sym;
}

export const currencies = [
    { "abbreviation": "USD", "name": "US Dollar", "symbol": "$" },
    { "abbreviation": "EUR", "name": "Euro", "symbol": "€" },
    { "abbreviation": "JPY", "name": "Japanese Yen", "symbol": "¥" },
    { "abbreviation": "GBP", "name": "British Pound", "symbol": "£" },
    { "abbreviation": "AUD", "name": "Australian Dollar", "symbol": "A$" },
    { "abbreviation": "CAD", "name": "Canadian Dollar", "symbol": "C$" },
    { "abbreviation": "CHF", "name": "Swiss Franc", "symbol": "CHF" },
    { "abbreviation": "CNY", "name": "Chinese Yuan Renminbi", "symbol": "¥" },
    { "abbreviation": "SEK", "name": "Swedish Krona", "symbol": "kr" },
    { "abbreviation": "NZD", "name": "New Zealand Dollar", "symbol": "NZ$" },
    { "abbreviation": "MXN", "name": "Mexican Peso", "symbol": "$" },
    { "abbreviation": "SGD", "name": "Singapore Dollar", "symbol": "S$" },
    { "abbreviation": "HKD", "name": "Hong Kong Dollar", "symbol": "HK$" },
    { "abbreviation": "NOK", "name": "Norwegian Krone", "symbol": "kr" },
    { "abbreviation": "KRW", "name": "South Korean Won", "symbol": "₩" },
    { "abbreviation": "TRY", "name": "Turkish Lira", "symbol": "₺" },
    { "abbreviation": "RUB", "name": "Russian Ruble", "symbol": "₽" },
    { "abbreviation": "INR", "name": "Indian Rupee", "symbol": "₹" },
    { "abbreviation": "BRL", "name": "Brazilian Real", "symbol": "R$" },
    { "abbreviation": "ZAR", "name": "South African Rand", "symbol": "R" },
    { "abbreviation": "PHP", "name": "Philippine Peso", "symbol": "₱" },
    { "abbreviation": "CZK", "name": "Czech Koruna", "symbol": "Kč" },
    { "abbreviation": "IDR", "name": "Indonesian Rupiah", "symbol": "Rp" },
    { "abbreviation": "MYR", "name": "Malaysian Ringgit", "symbol": "RM" },
    { "abbreviation": "HUF", "name": "Hungarian Forint", "symbol": "Ft" },
    { "abbreviation": "ISK", "name": "Icelandic Krona", "symbol": "kr" },
    { "abbreviation": "HRK", "name": "Croatian Kuna", "symbol": "kn" },
    { "abbreviation": "RON", "name": "Romanian Leu", "symbol": "lei" },
    { "abbreviation": "BGN", "name": "Bulgarian Lev", "symbol": "лв" },
    { "abbreviation": "PLN", "name": "Polish Zloty", "symbol": "zł" },
    { "abbreviation": "DKK", "name": "Danish Krone", "symbol": "kr" },
    { "abbreviation": "THB", "name": "Thai Baht", "symbol": "฿" },
    { "abbreviation": "NGN", "name": "Nigerian Naira", "symbol": "₦" },
    { "abbreviation": "CLP", "name": "Chilean Peso", "symbol": "$" },
    { "abbreviation": "PKR", "name": "Pakistani Rupee", "symbol": "₨" },
    { "abbreviation": "COP", "name": "Colombian Peso", "symbol": "$" },
    { "abbreviation": "SAR", "name": "Saudi Arabian Riyal", "symbol": "﷼" },
    { "abbreviation": "ARS", "name": "Argentine Peso", "symbol": "$" },
    { "abbreviation": "BDT", "name": "Bangladeshi Taka", "symbol": "৳" },
    { "abbreviation": "UAH", "name": "Ukrainian Hryvnia", "symbol": "₴" },
    { "abbreviation": "EGP", "name": "Egyptian Pound", "symbol": "£" },
    { "abbreviation": "MAD", "name": "Moroccan Dirham", "symbol": "MAD" },
    { "abbreviation": "VND", "name": "Vietnamese Dong", "symbol": "₫" },
    { "abbreviation": "KES", "name": "Kenyan Shilling", "symbol": "Sh" },
    { "abbreviation": "PEN", "name": "Peruvian Sol", "symbol": "S/" },
    { "abbreviation": "LKR", "name": "Sri Lankan Rupee", "symbol": "Rs" },
    { "abbreviation": "BHD", "name": "Bahraini Dinar", "symbol": ".د.ب" },
    { "abbreviation": "JOD", "name": "Jordanian Dinar", "symbol": "JD" },
    { "abbreviation": "QAR", "name": "Qatari Riyal", "symbol": "﷼" },
    { "abbreviation": "KZT", "name": "Kazakhstani Tenge", "symbol": "₸" }
];