
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

import './banner-list.styles.scss'
import { Grid, Button, TableCell } from '@material-ui/core'
import { Delete, Visibility, AttachMoney, Edit, FileCopy, CreditCard } from '@styled-icons/material';
import MuiAlert  from '@material-ui/lab/Alert'
import ContentNavigation from '../../structure/content-navigation/content-navigation.component'
import Streamers from '../../services/api/streamers';
import { bannerStreamerListService } from '../../services/api/bannerStreamer';
import GlobalState from '../../store/globalState'
import DeleteItemDialog from '../common/deleteItemDialog/deleteItemDialog.component.jsx';
import AddNewButton from '../common/add-new-button/add-new-button.component.jsx';
import UserUtils from '../../utils/userUtils'
import { withStyles } from '@material-ui/core/styles'
import { confAddItem, CommListGen, CommLinkButton, CommDeleteButtonLink, confListField, confList, CommList, CommItemField, CommItemOfItems, CommButtonLinkItem, ListFieldButtonLink } from '../common/com-List/comm-list.components'
import { myReactTaggedComponent, ReactComponentTag, CompWithParent } from '../common/extComponents'
import { CommItemFieldLink } from '../common/com'
import BannersPreview from '../banners-preview/banners-preview.component'
import { commService } from '../../services/api/list';
import { toMStr } from '../../utils/moneyUtils.jsx'

import { Link as RouterLink } from 'react-router-dom';
import { mapGenericStateToProps } from '../../utils/routerUtils'

import { changeUrl, Urls } from '../../utils/routerUtils'

import { bannerStrs } from '../../templates/languages/banner-strs.js'

import CommHelpLink, { getDocUrl } from '../../structure/common/comHelpLink/comHelpLink.jsx'

class BannerList extends React.Component {
    constructor(props) {
        super(props);
        const headProps = { xs: 6 };
        this.conf = [
            new confListField(bannerStrs.get('Name'), CommItemField, { className: "list-field bi-name" }, "name", null, headProps),
            new confListField(bannerStrs.get('Size'), CommItemField, { className: "list-field bi-size" }, (item) => item.sizeX + "x" + item.sizeY, null, headProps),
            new confListField(bannerStrs.get('MAmount'), CommItemField, { className: "list-field bi-amount not-mobile" }, (item) => toMStr(+item.minimalBannerAmount), null, headProps),
            new confListField(bannerStrs.get('Actions'), CommItemOfItems).addField("Comp", [
                ListFieldButtonLink(Edit, (item) => "/dashboard/banners/edit/" + item.id),
                new confListField(bannerStrs.get('Delete'), CommDeleteButtonLink, null, "name")
            ])
        ];
    }

    render = () => {
        return React.createElement(() =>
            <CommList title={<>{bannerStrs.get('Banners')}<CommHelpLink infoUrl={getDocUrl("como-crear-un-banner-para-publicidad-programatica")} /></>} confComm={new confList(BannerListComponent, new commService("/streamers/:id/banner"), this.conf, new confAddItem("/dashboard/banners/add/", bannerStrs.get('NewBanner')))} noItemsRedirect={Urls.BannerAdd}/>
        );
    }
}

class BannerListComponent extends React.Component {
    constructor(props) {
        super(props);
    }

    render = () => (
        <React.Fragment>
            <Grid xs="12">
                <CommListGen {...this.props} />
            </Grid>
            {
                //<Grid>
                //    <BannersPreview className="form-column" width={1920} height={1080} banners={this.props.items} />
                //</Grid>
            }
        </React.Fragment>
    )
}

export default connect(mapGenericStateToProps, null)(withRouter(BannerList));