import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {BrowserRouter} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux';
import store from './store'

export var isDevelop = process.env.NODE_ENV != "production";
export var isSandbox = Sandbox();

export function Sandbox() { return process.env.REACT_APP_SANDBOX == "TRUE"; }

if (!isDevelop) {
    console.log = () => { }
    console.error = () => { }
    console.debug = () => { }
}

ReactDOM.render(
  <React.Fragment>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.Fragment>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
