import CommonStrings from './strings/main';
import DashboardStrings from './strings/dashboard';

let stringsObject = {};

function processString(el) {
    Object.keys(el.translations).forEach(langkey => {
        if(!stringsObject[langkey]) stringsObject[langkey] = {};
        if(!stringsObject[langkey][el.key]) stringsObject[langkey][el.key] = {};
        
        stringsObject[langkey][el.key] = el['translations'][langkey];
    });
}

CommonStrings.forEach(processString);
DashboardStrings.forEach(processString);

export default stringsObject;