
import React from 'react';

import { connect } from 'react-redux';
import ServicesBase from '../../services/base';

import './brandedOffer-list.styles.scss'
import ModalDialog from '../../components/common/modal/modal.jsx'
import InputAdornment from "@material-ui/core/InputAdornment";
import UserUtils, { UserTypes } from '../../utils/userUtils'
import { changeUrl, Urls } from '../../utils/routerUtils'
import { Grid, Button, TableCell, TextField, Box } from '@material-ui/core'
import { Delete, Visibility, AttachMoney, Edit, FileCopy, CreditCard, Insights } from '@styled-icons/material';
import MuiAlert from '@material-ui/lab/Alert'
import AdItem from '../../components/advertiser/ad-item/ad-item.component'
import ContentNavigation from '../../structure/content-navigation/content-navigation.component'
import Advertisers from '../../services/api/advertisers/';
import GlobalState from '../../store/globalState'
import DeleteItemDialog from '../common/deleteItemDialog/deleteItemDialog.component.jsx';
import AddNewButton from '../common/add-new-button/add-new-button.component.jsx';

import { addFoundsUrl } from '../../structure/transaction-form/transaction-form.component.jsx'
import { CommItemFieldTextLink, confAddItem, CommListGen, CommLinkButton, CommDeleteButtonLink, confListField, confList, CommList, CommItemField, CommItemOfItems, CommButtonLinkItem, ListFieldButtonLink, CommItemListCountDown } from '../common/com-List/comm-list.components'

import { myReactTaggedComponent, ReactComponentTag, CompWithParent } from '../common/extComponents'
import { CommItemFieldLink, CommSimpleTextButton } from '../common/com'
import { commService } from '../../services/api/list';

import { RadioButtonGroupAsButtons } from '../../components/common/commonComponents'

import { mapGenericStateToProps } from '../../utils/routerUtils'
import { toMStr } from '../../utils/moneyUtils.jsx'

import { isDevelop } from '../../index'

import { adsStrs, BannerState } from '../../templates/languages/ads-strs.js'

import SelectUser, { AdsFilterTypes } from '../../components/admin/select-user/select-user.component.jsx'

import ComTooltip from '../../structure/common/comTooltip/comTooltip.jsx';

import { LangStrs, Lang } from '../../utils/languageUtils'
import Typography from '@material-ui/core/Typography';

import { confDateLimitInList, confCollaborationsInList } from '../common/branded/commonBranded.jsx'

import { NotificationEntityType } from '../../store/sagas/notifications'

let strs: LangStrs = new LangStrs(new Map(
    [
        ['NoApprovedReasonIni', new Map(
            [
                [Lang.ES, "Este banner no ha sido aprobado por la siguiente razón:"],
                [Lang.EN, "This banner has not been approved for the following reason:"]
            ])
        ],
        ['NoApprovedReasonEnd', new Map(
            [
                [Lang.ES, "Ajustalo para que pueda ser revisado nuevamente."],
                [Lang.EN, "Adjust it so it can be reviewed again"]
            ])
        ],
        ['WithdrawFunds', new Map(
            [
                [Lang.ES, "Retirar fondos"],
                [Lang.EN, "Withdraw funds"]
            ])
        ],
        ['BrandedContent', new Map(
            [
                [Lang.ES, 'Campañas de contenido de Marca'],
                [Lang.EN, 'Branded Content Campaigns']
            ])
        ],
        ['NewBranded', new Map(
            [
                [Lang.ES, 'Crear nuevo contenido de marca'],
                [Lang.EN, 'Create New Branded Content']
            ])
        ],
        ['ProgAd', new Map(
            [
                [Lang.ES, 'Publicidad Programática'],
                [Lang.EN, 'Programmatic Advertising']
            ])
        ],
        ['NewProgAd', new Map(
            [
                [Lang.ES, 'Crear nueva publicidad Programática'],
                [Lang.EN, 'Create New Programmatic Advertising']
            ])
        ],
        ['cName', new Map(
            [
                [Lang.ES, 'Nombre'],
                [Lang.EN, 'Name']
            ])
        ],
        ['moneyPerCollaboration', new Map(
            [
                [Lang.ES, 'Recompensa por Colaboración'],
                [Lang.EN, 'Reward per Collaboration']
            ])
        ],
        ['duration', new Map(
            [
                [Lang.ES, 'Duración'],
                [Lang.EN, 'Duration']
            ])
        ],
        ['dateLimit', new Map(
            [
                [Lang.ES, 'Termina en'],
                [Lang.EN, 'Ends on']
            ])
        ],
        ['channel', new Map(
            [
                [Lang.ES, 'Canal'],
                [Lang.EN, 'Channel']
            ])
        ],
        ['collaborationTimes', new Map(
            [
                [Lang.ES, 'Número de Colaboraciones'],
                [Lang.EN, 'Number of Collaborations']
            ])
        ],
        ['SponsoredContent', new Map(
            [
                [Lang.ES, 'Contenido Patrocinado'],
                [Lang.EN, 'Sponsored Content']
            ])
        ],
        ['SponsoredSegment', new Map(
            [
                [Lang.ES, 'Segmento Patrocinado'],
                [Lang.EN, 'Sponsored Segment']
            ])
        ],
        ['ContentType', new Map(
            [
                [Lang.ES, 'Tipo'],
                [Lang.EN, 'Type']
            ])
        ],
        ['titleOffer', new Map(
            [
                [Lang.ES, 'Ver propuestas'],
                [Lang.EN, 'View proposals']
            ])
        ],
        ['titleOnGoing', new Map(
            [
                [Lang.ES, 'Ver campañas en curso'],
                [Lang.EN, 'View ongoing campaigns']
            ])
        ],
        ['titleDone', new Map(
            [
                [Lang.ES, 'Campañas terminadas'],
                [Lang.EN, 'Finished campaigns']
            ])
        ],
        ['View', new Map(
            [
                [Lang.ES, 'Ver'],
                [Lang.EN, 'View']
            ])
        ],
        ['NoData', new Map(
            [
                [Lang.ES, 'No hay campañas para mostrar'],
                [Lang.EN, 'No campaigns to show']
            ])
        ],
    ]
));

class BrandedOfferList extends React.Component {
    options = ["ongoing", "offer", "done"];
    valueToOption = (value) => this.options[value];
    optionToValue = (option) => option == undefined ? undefined : this.options.map((v, i) => { return { v: v, i: i } }).find(v=> v.v == option).i;

    constructor(props) {
        super(props);
        console.log(this.props.match?.params.option);
        this.state = { option: this.props.match?.params.option ?? "offer" };

        this.isAdmin = UserUtils.GetUserData(true).usertype == UserTypes.Admin;

        const types = [strs.get('SponsoredContent'), strs.get('SponsoredSegment')];

        this.confBrandedList = [
            new confListField(strs.get('cName'), CommItemField, {}, "sName"),
            new confListField(strs.get('channel'), CommItemFieldTextLink, {}, (item) => item.cPlatform).addField("tUrl", (item) => item.cUrl),
            new confListField(strs.get('ContentType'), CommItemField, {}, (item) => types[item.type]),
            new confListField(strs.get('moneyPerCollaboration'), CommItemField, {}, (item) => toMStr(item.moneyPerCollaboration, false)),
            confCollaborationsInList(),
            new confListField(strs.get('duration'), CommItemField, {}, (item) => `${item.duration}''`),
            confDateLimitInList(),
            new confListField(adsStrs.get('Actions'), CommItemOfItems).addField("Comp", [
                ListFieldButtonLink(undefined, (item) => Urls.StreamersBranded + "/view/" + item.id, strs.get("View"), "Widget URL", (item) => { return { notificationKey: [NotificationEntityType.SponsorshipContractNewOffer, NotificationEntityType.SponsorshipContractMsg, NotificationEntityType.SponsorshipDeliveryAcceped, NotificationEntityType.SponsorshipDeliveryDeclined], notificationId2: item.id } }),
            ])
        ];
        this.handleChangeValue = this.handleChangeValue.bind(this)
    }
    
    render() {
        return <Grid direction="column">
                <Grid child container className={'brandedContent-form ' + this.props.className} direction="row" justify="flex-start" alignItems="flex-start">
                    <Grid child container direction="row" xs="12" style={{ width: "100%" }}>
                <div>
                    <RadioButtonGroupAsButtons
                            key={"OptionsComp"}
                            options={[
                                { text: strs.get("titleOnGoing"), value: 0, notification: [NotificationEntityType.SponsorshipDeliveryAcceped, NotificationEntityType.SponsorshipDeliveryDeclined] },
                                { text: strs.get("titleOffer"), value: 1, notification: [NotificationEntityType.SponsorshipContractNewOffer] },
                                { text: strs.get("titleDone"), value: 2 },
                            ]}
                            onChangeValue={this.handleChangeValue}
                            selectedValue={this.optionToValue(this.state.option)}
                    />
                    </div>
                </Grid>
                </Grid>
                <Grid child container direction="row" xs="12" style={{ width: "100%" }}>
                {this.state && this.state.noData === true && <div><h3 style={{ color: "rgb(53, 116, 158)" }}>{strs.get("NoData")}</h3></div>}
                    {this.state && this.state.option && <CommList key={UserUtils.GetUserData().id + "contract" + this.state.option} ref={(r) => this.adsListBranded = r} itemId={this.props.id} confComm={new confList(CommListGen, new commService("/streamers/:id/sponsorship/list/" + this.state.option), this.confBrandedList)} OnQuery={(comp, data) => data && data.length === 0 && this.setState({ noData: true })} />}
                </Grid>
            </Grid>
    }
    handleChangeValue(value) {
        this.setState({ noData: undefined, option: this.valueToOption(value) })
    }
}

export default connect(mapGenericStateToProps, null)(BrandedOfferList);