import React, { Component } from 'react';
import { CommInputField, CommForm, CommFormGen, confForm, UploadVideoMedia } from '../../../structure/common/com-Form/comm-form.components'
import { CommButton, confCOM, confField, CommTextField, fieldNameFunction, temporalItem, fnfRequireText, fnfRequireNumber, confGroup, strfieldvalue, EnumComboBox, CommSimpleTextButton } from '../../../structure/common/com'
import { CommItemOfItems, confAddItem, CommListGen, CommLinkButton, CommDeleteButtonLink, confListField, confList, CommList, CommItemField, CommItemTextField } from '../../../structure/common/com-List/comm-list.components'
import { commService } from '../../../services/api/list';

export class EntityLogList extends React.Component {
    constructor(props) {
        super(props);

        this.confList = [
            new confListField('message', CommItemField, { className: "list-field bi-name" }, "message"),
            new confListField('Date', CommItemField, { className: "list-field bi-name" }, "createdAt")
        ];

    }

    componentDidMount = async () => {}

    QueryItems = async () => {
        await this.commlist.QueryItems();
    }

    render = () => {
        const maxPageItems = this.props.maxPageItems == undefined ? 3 : this.props.maxPageItems;
        return (
            <CommList ref={(c) => this.commlist = c} title={this.props.title} maxPageItems={maxPageItems} confComm={new confList(CommListGen, new commService("/common/entityLog/" + this.props.modelEntity + "/" + this.props.idEntity), this.confList)} />
        )
    }
}