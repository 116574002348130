import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import LinearProgress from '@material-ui/core/LinearProgress';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DownloadIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/Delete';
import FileIcon from '@material-ui/icons/InsertDriveFile';

import { InputSource } from '../../structure/common/com/index.jsx'

import { LangStrs, Lang } from '../../utils/languageUtils'
export let strs: LangStrs = new LangStrs(new Map(
    [
        ['File', new Map(
            [
                [Lang.ES, 'Archivo'],
                [Lang.EN, 'File']
            ])
        ],
        ['SelectFile', new Map(
            [
                [Lang.ES, 'Subir nuevo archivo'],
                [Lang.EN, 'Upload new file']
            ])
        ],
        ['FileUploaded', new Map(
            [
                [Lang.ES, 'Archivo subido'],
                [Lang.EN, 'File uploaded']
            ])
        ],
        ['Uploading', new Map(
            [
                [Lang.ES, 'Subiendo: '],
                [Lang.EN, 'Uploading: ']
            ])
        ],
        ['UpError', new Map(
            [
                [Lang.ES, 'Error al subir, intenta más tarde'],
                [Lang.EN, 'Upload error, try again later']
            ])
        ],
        ['UpErrorSize', new Map(
            [
                [Lang.ES, 'Archivo demasiado grande'],
                [Lang.EN, 'File too large']
            ])
        ],
        ['NotAllowed', new Map(
            [
                [Lang.ES, 'Operación no permitida'],
                [Lang.EN, 'Operation not allowed']
            ])
        ],
        ['BadFile', new Map(
            [
                [Lang.ES, 'Tipo de archivo incorrecto'],
                [Lang.EN, 'Incorrect file type']
            ])
        ],
        ['ETooSize', new Map(
            [
                [Lang.ES, 'Lo siento, ahora mismo no se aceptan archivos de mas de 2 Gigabyes'],
                [Lang.EN, 'Sorry currently, files over 2 Gigabytes are not accepted']
            ])
        ],
        ['EIncorrectFormat', new Map(
            [
                [Lang.ES, `No se puede leer el video. Asegúrate de que su generación o sus codecs sean estándares.`],
                [Lang.EN, `The video cannot be read. Ensure that its generation or codecs are standard.`]
            ])
        ],
    ]
));

export class PostFileButton extends React.Component {
    constructor(props) {        
        super(props);
        this.state = {};
        this.postUrl = this.props.postUrl;
    }

    render() {
        return (
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px', width: '100%', justifyContent: "flex-start" }}>
                <InputSource buttonProps={this.props.buttonProps} filetype={this.props.filetype || "*/*"} OnSelect={async (comp, file, props, error) => {
                    if (error != undefined) {
                        this.setState({ formCorrect: false });
                        await this.setState({ error: strs.get('ETooSize') });
                    } else {
                        if (this.props.checkFile && !await this.props.checkFile(file)) return;
                        await this.setState({ error: null });
                        await this.postFile(file, props);
                    }
                }}>
                    {this.props.children || this.props.SelectFileText || strs.get('SelectFile')}
                </InputSource>
                <div style={{ display: 'flex', alignItems: 'center', width: '200px', gap: '10px' }}>
                    {this.state.upError !== undefined ? (
                        <>
                            <ErrorOutlineIcon color="error" style={{ marginRight: 10 }} />
                            <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                {
                                    //alert(this.state.upError) || 
                                    this.state.upError == "BAD_FILE" && strs.get("BadFile") ||
                                    strs.get('UpError')
                                }
                            </Typography>
                        </>)
                    : this.state.uploading !== undefined && (
                        this.state.uploading === 1
                            ? (
                                this.props.showUploaded!==false && <>
                                    <CheckCircleOutlineIcon color="primary" style={{ marginRight: 10 }} />
                                    <Typography variant="body1" style={{ fontWeight: 'bold' }}>{strs.get('FileUploaded')}</Typography>
                                </>
                            )
                            : (
                                <>
                                    <BorderLinearProgress variant="determinate" value={this.state.uploading * 100} style={{ flexGrow: 1 }} />
                                    <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                                        {strs.get('Uploading')} {Math.round(this.state.uploading * 100)}%
                                    </Typography>
                                </>
                            )
                    )}
                </div>
            </div>
            );
    }

    async postFile(file, props, tries = 0, OnSuccess = null, OnError = null) {
        if (tries > 10) {
            OnError && OnError();
            return;
        }
        await this.setState({ upError: undefined });

        let formData = new FormData();
        if (this.props.customFormData) {
            formData = this.props.customFormData(formData, file);
        }

        const xhr = new XMLHttpRequest();
        const success = await new Promise((resolve) => {
            xhr.upload.addEventListener("progress", async (event) => {
                if (event.lengthComputable) {
                    await this.setState({ uploading: event.loaded / event.total });
                }
            });
            xhr.addEventListener("loadend", () => {
                resolve(xhr.readyState === 4 && xhr.status === 201);
            });
            xhr.addEventListener("error", () => {
                //alert("error");
            });

            xhr.open("POST", this.postUrl, true);
            xhr.withCredentials = true;
            xhr.send(formData);
        });

        if (success) {
            OnSuccess && OnSuccess();
            this.props.OnSuccess && this.props.OnSuccess();            
        } else {
            console.log(xhr);
            if (xhr.status == 401) {
                await this.setState({ upError: strs.get("NotAllowed") });
            } else {
                if (xhr.response == "")
                    setTimeout(() => { this.postFile(file, props, ++tries, OnSuccess, OnError); }, 1000);
                else {
                    let customError = false;
                    try {
                        let e = JSON.parse(xhr.response);
                        if (e.error) {
                            await this.setState({ upError: e.error });
                            customError = true;
                        }
                    } catch (e) { }

                    if (!customError) await this.setState({ upError: "error" });
                }
            }
        }
    }
}

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 10,
        borderRadius: 5,
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
        borderRadius: 5,
        backgroundColor: '#1a90ff',
    },
}))(LinearProgress);

const styles = theme => ({
    button: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    centeredIcon: {
        justifyContent: 'center',
    },
    icon: {
        marginRight: theme.spacing(1),
    },
});

export class FileButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hover: false,
        };

        this.canDownload = props.canDownload;
        this.canDelete = props.canDelete;
        this.fileName = props.fileName;
        this.url = props.url;
        this.onDelete = props.onDelete;
        this.style = props.style;
    }

    handleMouseEnter = () => {
        this.setState({ hover: true });
    };

    handleMouseLeave = () => {
        this.setState({ hover: false });
    };

    handleDownloadClick = () => {
        window.open(this.url, '_blank');
    };

    render() {        
        const { hover } = this.state;
        
        const maxLength = 25;
        const hasExtension = this.fileName.includes('.');
        const extension = hasExtension ? this.fileName.split('.').pop() : '';
        const baseName = hasExtension ? this.fileName.slice(0, -extension.length - 1) : this.fileName;
        const displayFileName =
            baseName.length > maxLength
                ? `${baseName.slice(0, maxLength - (hasExtension ? extension.length + 3 : 3))}...${hasExtension ? extension : ''}`
                : this.fileName;

        const marginsWidth = 24;
        const iconWidth = 24;
        const lowerCaseChars = displayFileName.split('').filter((ch) => ch === ch.toLowerCase()).length;
        const otherChars = displayFileName.length - lowerCaseChars;
        const minWidth = `${Math.max(160, lowerCaseChars * 10 + otherChars * 12 + iconWidth + marginsWidth)}px`;

        let bStyle = {
            minWidth: minWidth,
            minHeight: '50px',
            display: 'flex',
            justifyContent: hover ? 'space-around' : 'flex-start',
            alignItems: 'center',
        };
        if (this.style) bStyle = Object.assign(bStyle, this.style);

        return (
            <Tooltip title={this.fileName}>
                <Button
                    color="primary"
                    variant="contained"
                    onMouseEnter={this.handleMouseEnter}
                    onMouseLeave={this.handleMouseLeave}
                    style={bStyle}
                >
                    {!hover ? (
                        <>
                            <FileIcon style={{ flex: '0 0 auto' }} />
                            <div style={{ textAlign: 'center', flex: '1 1 auto' }}>
                                {displayFileName}
                            </div>
                        </>
                    ) : (
                        <>
                            {this.canDownload !==false &&
                                <Button
                                    variant="outlined"
                                    onClick={this.handleDownloadClick}
                                    style={{ width: this.canDelete ? 'auto' : '90%' }}
                                >
                                    <DownloadIcon />
                                </Button>
                            }
                            {this.canDelete && (
                                    <Button variant="outlined" onClick={() => { this.onDelete && this.onDelete() }}>
                                    <DeleteIcon style={{ color: '#8B0000' }} />
                                </Button>
                            )}
                        </>
                    )}
                </Button>
            </Tooltip>
        );
    }
}