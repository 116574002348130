import { call, put, takeLatest } from 'redux-saga/effects'
import { push } from 'react-router-redux';

import Streamers from '../../services/api/streamers'
import ActionTypes from '../actions/actiontypes'
 
// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* GetStreams(action) {
   try {
      const response = yield call(Streamers.Streams.Get);
      const responseText = yield response.text();
      if(response.status < 400) {
         yield put({type: ActionTypes.WWW_STREAMERS_STREAM_GET_RESPONSE, payload: {status: 1, response: responseText}});
      } else {
         throw new Error(JSON.parse(responseText).error);
      }
   } catch (e) {
      console.log(e.message);
      yield put({type: ActionTypes.WWW_STREAMERS_STREAM_GET_RESPONSE, payload: {status: 0, response: e.message}});
   }
   yield put({type: ActionTypes.TOGGLE_LOADING, payload: false});
}

/*
  Alternatively you may use takeLatest.

  Does not allow concurrent fetches of user. If "USER_FETCH_REQUESTED" gets
  dispatched while a fetch is already pending, that pending fetch is cancelled
  and only the latest one will be run.
*/
function* StreamsSaga() {
  yield takeLatest(ActionTypes.WWW_STREAMERS_STREAMS_GET, GetStreams);
}

export default StreamsSaga;