import React from 'react';
import {connect} from 'react-redux'
import ActionTypes from "../../store/actions/actiontypes";

import './loginpage.styles.scss';
import './font-awesome.min.css';
import {Grid, Button, Link} from '@material-ui/core'

import { Link as RouterLink, Redirect } from 'react-router-dom';
import LoginForm from '../../components/loginform/loginform.component'
//import X from '../../components/x/x.component';

import { ToggleLoading } from "../../store/actions";
import { mapGenericStateToProps, getFragmentParameter } from '../../utils/routerUtils'
import { BrowserView, MobileView, isBrowser, isMobile } from '../../utils/platformsUtils';

import { LangStrs, Lang, getUserLang } from '../../utils/languageUtils'

function mapDispatchToProps(dispatch) {
    return {
        ToggleLoading: isLoading => dispatch(ToggleLoading(isLoading)),
        DoLogin: userData => dispatch({ type: ActionTypes.WWW_USER_LOGIN, payload: userData }),
        dispacher: dispatch
    };
}

const strs: LangStrs = new LangStrs(new Map(
    [
        ['NEWACCOUNT', new Map(
            [
                [Lang.ES, 'Crear una cuenta nueva'],
                [Lang.EN, 'Create a new account']
            ])
        ],
    ]
));

//getFragmentParameter('YoutubeCode') => perque quan m'estic autentificant amb youtube i twitch, faig servi el login com una paguina "temporal" que l'unic que fa es postejar el token, i per postejar el token no em puc reedirigir ja que mu fa quan estic agregant una conta, xk ja estic logejat (que u tindria que fer a una paguina part? pues si #TODO)
const LoginPage = (props) => {
    return (
    <div className='login-page full-with-header'>
        { props.userSession.token && getFragmentParameter('YoutubeCode') === false && getFragmentParameter('access_token') === false && <Redirect to="/dashboard" push />}
        <Grid className="z10 full-height" container
        alignItems="center"
                direction="column"
                {...!isMobile ? { justify: "center" } : { style: { marginTop:"10px" } }}>
                <LoginForm submitEvent={props.DoLogin} dispacher={props.dispacher} loadingEvent={props.ToggleLoading} loading={props.loading} loginError={props.loginError} ></LoginForm>
            <Link style={props.loading ? {visibility:"hidden"} : {}} margin="normal" component={RouterLink} to="/register">
                    <Button disabled={props.loading} className="register-btn" variant="contained" size="large" color="secondary">{strs.get('NEWACCOUNT')}</Button>
            </Link>
        </Grid>
    </div>
)};

export default connect(mapGenericStateToProps, mapDispatchToProps)(LoginPage);