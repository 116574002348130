import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { Grid, Button, TextField, TextareaAutosize, FormControlLabel, FormControl } from '@material-ui/core';
import { SelectFromConst, SelectFromGet } from '../../components/common/commonComponents.jsx'

import { mapGenericStateToProps } from '../../utils/routerUtils'

import { useParams, Redirect } from 'react-router-dom';
import Cookies from 'universal-cookie';

export function cesarCipher(text, shift) {
    return text.split('').map(char => {
        let charCode = char.charCodeAt(0);
        if ((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122)) {
            let base = (charCode < 97) ? 65 : 97;
            let shiftedPosition = ((charCode - base + shift) % 26);
            shiftedPosition = shiftedPosition < 0 ? shiftedPosition + 26 : shiftedPosition;
            return String.fromCharCode(shiftedPosition + base);
        } else {
            return char;
        }
    }).join('');
}

export function decode(text, shift=-5) {
    return atob(cesarCipher(text, -5));
}

export function decodeUrl(eUrl) {    
    // Extrae la id y la url cifradas de la URL
    const encodedId = eUrl.split('/')[4];
    const encodedUrl = eUrl.split('/')[5];

    const id = decode(encodedId);
    const url = decode(encodedUrl);

    // Devuelve la id y la url
    return { id, url, encodedId };
}

const RedirectUrl = () => {
    const { encodedId, encodedUrl } = useParams();
    const cookies = new Cookies();
    const decoded = decodeUrl(window.location.href);

    
    cookies.set('guestId', decoded.encodedId, { path: '/' });

    //setTimeout(() => {
        window.location.href = decoded.url;
    //}, 1000);       

    return null;
}

export class ConditionalGuestContent extends React.Component {
    render = () => {
        if (new Cookies().get('guestId')) {
            return (
                <>{this.props.children}</>
            )
        } else if (this.props.redirectTo) window.location.href = this.props.redirectTo;
    }
}

export default connect(mapGenericStateToProps, null)(withRouter(RedirectUrl));