
import React, { useState } from 'react';
import { Button, Grid, Snackbar, Table, TableCell, TableHead, TableRow, Checkbox, Box } from '@material-ui/core';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { MuiThemeProvider } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import { Delete, FileCopy, Link as LinkIcon, Save } from '@styled-icons/material';
import { isBrowser, isMobile, MobileView } from '../../../utils/platformsUtils';
import { commService } from '../../../services/api/list';
import GlobalState from '../../../store/globalState';
import ContentNavigation from '../../../structure/content-navigation/content-navigation.component';
import ErrorWarnTheme from '../../../styles/ErrorWarnTheme';
import { changeUrl } from '../../../utils/routerUtils';
import UserUtils from '../../../utils/userUtils';
import AddNewButton from '../../common/add-new-button/add-new-button.component.jsx';
import DeleteItemDialog from '../../common/deleteItemDialog/deleteItemDialog.component.jsx';
import { ConditionalComponent, InLineComponent, myReactTaggedComponent, myReactTaggedStateComponent, ReactComponentTag } from '../../common/extComponents';
import { FileButtonComm, CommButton, COMMComp, CommItemFieldLink, CommTextField, confCOM, confField, confFieldItem, strfieldvalue, temporalItem, disabledContainerStyles, CountDownComm, CommSimpleTextButton, CommCheckBox } from '../com';
import { NotificationBadget } from '../../../components/common/commonComponents'
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import './comm-list.styles.scss';

import { LangStrs, Lang } from '../../../utils/languageUtils'

let strs: LangStrs = new LangStrs(new Map(
    [
        ['SureDelete', new Map(
            [
                [Lang.ES, 'Estas seguro que quieres borrar '],
                [Lang.EN, 'Are you sure you want delete ']
            ])
        ]
    ]
));

export class confListField extends confField {
    constructor(name, field, fieldprops, fieldName, headField=null, headProps) {
        super(name, field, fieldprops, fieldName);
        this.headField = headField;
        this.headProps = headProps;
    }
}

export class confList extends confCOM {
    addItem: confAddItem;
    fields: confListField[];

    constructor(listComponentBase, service: commService, fields: confListField[], addItem: confAddItem = null) {
        super(listComponentBase, service, fields);
        this.addItem = addItem;
    }
}

export class confAddItem {
    float = false;
    constructor(link = null, caption = null, defaultData = null, addEnd:boolean = true) {
        this.caption = caption;
        this.link = link;
        this.defaultData = defaultData;
        this.addEnd = addEnd;
    }
    setFloat() {
        this.float = true;
        return this;
    }
}

export const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        width: '100%',
        borderBottom: '1px solid #E0E0E0',
    },
}))(TableRow);

export class CommList extends COMMComp {
    confList: confList;
    checkItems: [] = [];
    checkItemsComp: [] = [];

    constructor(props) {
        super(props);
        this.confList = this.props.confComm;

        Object.assign(this.state, {
            currentPage: 1,
            items: [],
            loaded: false,
            itemToDelete: null,
            error: false,
            opentoast: false
        });

        this.options = {
            maxPageItems: props.maxPageItems == undefined ? 10 : props.maxPageItems
        }

        this.changePage = ((pageNum) => {
            this.setState({ currentPage: pageNum });
        }).bind(this);
    }  

    componentDidMount = () => {
        GlobalState.set("loading", true);
        if (!this.state.loaded) {
            this.QueryItems();
        }
    }

    getItem() {
        return this.state.items;
    }

    getTemporalItem() {
        let item: [] = this.getItem();
        let ir: [] = [];
        if (item == undefined) return undefined;

        for (let i = 0; i < item.length; i++) {
            ir.push(temporalItem(item[i]));
        }

        return ir;
    }

    DelItem = (item) => {
        this.setState({ itemToDelete: item });
    }

    DelItemResult = async (result) => {
        if (result) {
            let serviceResponse = await this.confList.service.Del(UserUtils.GetUserData().id, this.state.itemToDelete);
            await this.QueryItems();
            this.props.onDelete && await this.props.onDelete();
        }
        this.setState({ itemToDelete: null });
    }

    QueryItems = async () => {
        this.checkItemsComp = [];
        this.checkItems = [];

        let serviceResponse = await this.confList.service.Get(UserUtils.GetUserData().id, this.props.itemId);

        let data = serviceResponse.body;
        if (this.props.itemFilter != undefined) {
            data = data.filter(f => this.props.itemFilter(f));
        }
        this.setState({ error: serviceResponse.error, items: data || [], loaded: true });

        this.checkItems = []; //Si quiero que los que esten marcados sigan estandolo, mirar su equivalencia usando JSON.stringify ==        

        this.OnQuery(data);

        if (data == undefined || data.length == 0) {
            this.props.noItemsRedirect && changeUrl(this.props.noItemsRedirect);
        }
        return data;
    }

    externalDataChange = () => {
        this.setState({ dataSave: true });
    }

    //componentDidUpdate(prevProps) {
    //    if (prevProps.confComm.fields !== this.props.confComm.fields) {
    //        return true;
    //    }
    //    return false;
    //}

    render = () => {
        //COMMComp.prototype.render.call(this);
        super.render();

        const BaseComponent = this.confList.listComponentBase;

        return (
            <BaseComponent {...this.props} ref={(c)=>this.compBase = c }fields={this.confList.fields} confList={this.confList} currentPage={this.state.currentPage} parentComm={this} items={this.state.items} loaded={this.state.loaded} itemToDelete={this.state.itemToDelete} error={this.state.error} opentoast={this.state.opentoast} />
        )
    }

    getState = () => {
        return this.state;
    }

    newItem = async () => {
        let defaultData = typeof this.confList.addItem.defaultData === 'function' ? this.confList.addItem.defaultData() : this.confList.addItem.defaultData;
        
        let serviceResponse = await this.confList.service.Add(UserUtils.GetUserData().id, defaultData);
        if (this.confList.addItem.addEnd)
            this.state.items.push(serviceResponse.body);
        else
            this.state.items = [serviceResponse.body, ...this.state.items];
        this.setState({ error: serviceResponse.error, items: this.state.items || [], loaded: true });
    }

    UpdateItems = async () => {
        for (let i = 0; i < this.state.items.length; i++) {
            await this.UpdateData(this.state.items[i]);
        }
        this.confFieldAllItemsClean();
        await this.setState({ items: this.state.items });
    }

    handleCheckAllItem = (event) => {
        const isChecked = event.target.checked;
        this.checkItemsComp.forEach((checkbox) => {
            checkbox.setChecked(isChecked);
        });
    };    

    handleCheckItem = (checkComp, isChecked) => {
        if (this.props.optionalSelect) {
            this.checkItemsComp.forEach((checkbox) => {
                if (checkbox!=checkComp) checkbox.setChecked(false);
            });
        }

        if (isChecked) {
            if (!this.props.optionalSelect)
                this.checkItems = [...this.checkItems, checkComp.props.item];
            else
                this.checkItems = [checkComp.props.item];
        } else {
            if (!this.props.optionalSelect)
                this.checkItems = this.checkItems.filter(checkItem => checkItem != checkComp.props.item);
            else
                this.checkItems = [];
        }

        if (this.props.OnChangeSelection) this.props.OnChangeSelection(this.checkItems);
    };
}

const theme = createMuiTheme({
    overrides: {
        MuiCheckbox: {
            colorPrimary: {
                '&$checked': {
                    color: 'black',
                },
            },
            colorSecondary: {
                '&$checked': {
                    color: 'black',
                },
            },
        },
        MuiSvgIcon: {
            root: {
                backgroundColor: 'white',
                borderRadius: '2px', // A�ade un borde redondeado para que coincida con la forma del CheckBox
            },
        },
    },
});

function MyCheckbox(props) {
    return (
        <ThemeProvider theme={theme}>
            <Checkbox onChange={props.onChange} color="primary" />
        </ThemeProvider>
    );
}

const deleteItemDialogOnList = (list) => list.props.itemToDelete && <DeleteItemDialog title={strs.get('SureDelete') + " " + list.props.itemToDelete.FieldName + " ?"} onDelete={() => { list.parent.DelItemResult(true) }} onCancel={() => { list.parent.DelItemResult(false) }} />

export class CommListGen extends React.Component {
    confList: confList;
    parent: CommList;

    constructor(props) {
        super(props);
        this.state = {};
        this.confList = this.props.confList;
        this.parent = this.props.parentComm;
    }

    render = () => {
        return (
            <React.Fragment>
                <Grid item xs={12}>
                    <Grid item xs={12}>
                        <h1>{(this.props.items.length>0 || this.props.noItemsNoTile !== true) && this.props.title}</h1>
                    </Grid>
                    {/*this.props.error && <MuiAlert severity="error">{this.props.error.toString()}</MuiAlert>*/}
                    <ConditionalComponent TagComponent={Grid} Condition={this.props.items.length} bodyConditional={true} {...isBrowser && { className: "table-container" }} item xs={12}>
                        <Table>
                            <ConditionalComponent TagComponent={TableHead} Condition={isBrowser && this.props.headers!==false} item xs={9} className="list-details" bodyConditional={true}>
                                    <StyledTableRow container className="banner-item" alignItems="flex-start" justify="flex-start" direction="row">
                                        {this.props.canSel && <StyledTableCell>{!this.props.optionalSelect && <MyCheckbox onChange={this.props.parentComm.handleCheckAllItem} />}</StyledTableCell>}
                                        {

                                            this.confList.fields && this.confList.fields.map(c => {
                                                let conf: confListField = c;
                                                const HeadField = conf.headField == null ? StyledTableCell : conf.headField;

                                                return <HeadField {...CommItemOfItems == conf.field && { style: { "text-align": "center" } }} {...conf.headProps}  > {conf.name} </HeadField>
                                            })
                                        }
                                    </StyledTableRow>
                            </ConditionalComponent>
                            <ConditionalComponent TagComponent={Grid} Condition={this.props.headers === false} bodyConditional={false} item xs={12} container justify="space-between">
                                <ConditionalComponent TagComponent={Grid} Condition={this.props.headers === false} bodyConditional={false} container child justify="center" alignItems="flex-start" direction="row" spacing={12}>
                                    {
                                        this.props.items.length > 0 ? (() => {
                                            let Items = [];
                                            for (let i = (this.props.currentPage - 1) * this.parent.options.maxPageItems; i < this.props.items.length && i < (this.props.currentPage * this.parent.options.maxPageItems); i++) {
                                                let ItemList = CommItemList;
                                                if (this.props.ItemList) ItemList = this.props.ItemList;
                                                Items.push(<ItemList parentComm={this.parent} {...this.props} className="dashboard-item" key={this.props.genItemKey ? this.props.genItemKey(this.props.items[i]) : "banner-" + this.props.items[i].id+"-"+(this.state.updateKey??"")} item={this.props.items[i]}></ItemList>);
                                            }
                                            return Items;
                                        })() : (this.props.loaded && <p>No data found.</p>)
                                    }
                                </ConditionalComponent>
                            </ConditionalComponent>
                        </Table>                       
                    </ConditionalComponent>
                    <ConditionalComponent TagComponent={Grid} Condition={this.props.loadingMSG || this.props.noDataMSG} bodyConditional={true} {...isBrowser && { className: "table-container" }} item xs={12}>
                        {(!this.props.loaded || this.props.items.length==0) &&
                            <Box display="flex" justifyContent="center" alignItems="center" width="100%">
                                {!this.props.loaded && <CircularProgress />}
                                <Typography variant="body1" style={{ marginLeft: '10px' }}>
                                    {!this.props.loaded && this.props.loadingMSG}
                                    {(this.props.loaded && this.props.items.length == 0) && this.props.noDataMSG}
                                </Typography>
                            </Box>
                        }
                    </ConditionalComponent>
                    <Grid item xs={12}>
                        <ContentNavigation itemsPerPage={this.parent.options.maxPageItems} itemCount={this.props.items.length} currentPage={this.props.currentPage} event={this.parent.changePage}></ContentNavigation>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    {
                        this.props.SaveAll && this.parent.SomeItemToSave() && 
                        <Button variant="contained" size="large" color="primary" onClick={() => { this.parent.UpdateItems.call(this.parent); }}>
                            SAVE                            
                        </Button>
                    }
                </Grid>
                {
                    (() => {
                        let confField = this.confList;
                        return (this.confList.addItem?.defaultData &&
                            <Grid container xs={12} {...{ aligment: "center", alignItems: "center", justify: "center" }} >
                                <InLineComponent parentComm={this.parent} confList={this.props.confList} newComp={myReactTaggedStateComponent(CommButton, function () {
                                    this.tInnerLink =
                                        <AddNewButton NoLink={true} onClick={(e) => { const cList: CommList = this.props.parentComm; cList.newItem() }} float={this.props.confList.addItem.float} caption={confField.addItem.caption} />
                                })
                                } />
                            </Grid>
                        )
                    })()
                       
                }
                {this.confList.addItem?.link &&
                    <Grid container xs={12} aligment="center" alignItems="center" justify="center">
                        <AddNewButton caption={this.confList.addItem.caption} NoLink={false} routeTo={this.confList.addItem.link} float={this.props.confList.addItem.float} />
                    </Grid>
                }
                {deleteItemDialogOnList(this)}
            </React.Fragment>
        )
    }
}


const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: 'rgb(53,116,158)',
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

export class CommHeadItemList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            widgetUrl: "",
            toastOpen: false
        }
    }

    render = () => {
        return (
            <React.Fragment>
                <StyledTableCell/>
            </React.Fragment>
        )
    }
}

export class CommItemListAux extends React.Component {
    static getFieldTag(config, props) {
        const conf: confFieldItem = props.parentComm.newconfFieldItem(config, props.item);
        const FTag = conf.confField.field;
        return { FTag: FTag, conf: conf };
    }
    static rNewComp(config, props, extraprops = null) {
        return CommItemListAux.rNewCompWithTag(CommItemListAux.getFieldTag(config, props), props, extraprops);
    }
    static rNewCompWithTag(FieldTag, props, extraprops = null) {
        const disabled = FieldTag.conf.confField.disabled != undefined && FieldTag.conf.confField.disabled(FieldTag.conf.item);

        let result;
        if (FieldTag.conf.confField.fieldprops?.ComponentToItemListComp === true)
            result = componentToItemFieldListRender(<FieldTag.FTag ref={(c) => FieldTag.conf.component = c} parentComm={props.parentComm} item={props.item} confFieldItem={FieldTag.conf} {...extraprops} />);
        else
            result = <FieldTag.FTag ref={(c) => FieldTag.conf.component = c} parentComm={props.parentComm} item={props.item} confFieldItem={FieldTag.conf} {...extraprops} />

        return <ConditionalComponent TagComponent={"table"} Condition={disabled} bodyConditional={false} style={{ width: "100%" }}>
                    <ConditionalComponent TagComponent={"tr"} Condition={disabled} bodyConditional={false} style={disabledContainerStyles}>
                        {result}
                    </ConditionalComponent>
                </ConditionalComponent>
    }
    static setFieldsItems(props: any, funcSet: (conf: confField) => any = null) {
        return props.confList.fields.map(i => {
            const conf: confField = i;
            //if (conf.field != null && (conf.Condition == undefined || conf.Condition(props.items[i]))) {
            if (conf.field != null) {
                return funcSet != null ? funcSet(conf) : CommItemListAux.rNewComp(conf, props);
            }
        });
    }
}

export class CommListFlat extends React.Component {
    confList: confList;
    parent: CommList;

    constructor(props) {
        super(props);
        this.confList = this.props.confList;
        this.parent = this.props.parentComm;
    }

    render = () => {        
        return this.props.items.map((i, x) => {
            let props = { ...this.props, item: this.props.items[x] };
            return (<>
                <div style={{ display: 'flex', flexWrap: 'nowrap', marginRight:'5px' }}>
                    {CommItemListAux.setFieldsItems(props)}
                </div>
                {deleteItemDialogOnList(this)}
            </>);
        });
    }
}

export class ItemSelCheckBox extends React.Component {
    constructor(props) {
        super(props);
        //this.state = { checked: this.props.parentComm.checkItems.some(i => i == this.props.item) };
        this.state = { checked: false, disabled: false, checkCustomCalled:false, item:this.props.item };

        this.props.parentComm.checkItemsComp.push(this);
    }

    async setCheckedReal(isChecked) {
        if (isChecked != this.state.checked) {
            this.props.parentComm.handleCheckItem(this, isChecked);
            await this.setState({ checked: isChecked });
        }
    };

    async setChecked(val) {
        await this.setCheckedReal(val);        
    }

    handleCheckItem = (event) => {
        this.setCheckedReal(event.target.checked);
    };

    disable = (b: boolean) => {
        if (this.state.disabled != b) {
            this.setCheckedReal(false);
            this.props.parentComm.checkItemsComp = this.props.parentComm.checkItemsComp.filter(c=>c!=this);
            if (!b) {
                this.props.parentComm.checkItemsComp.push(this);
            }
            this.setState({ disabled: b });
        }
    }

    render = () => {
        if (this.state.item != this.props.item) { //Per una rao que a mi ara sem escapa els checkboxs son resiclats entre items enlloc de tornarse a crear
            this.props.customCheck && this.props.customCheck(this.props.item, this);
        }

        if (this.state.disabled) return;
        return (
            <Checkbox {...this.state.disabled ? { disabled: true } : {}} ref={(c) => { const first = this.state.checkCustomCalled; if (!first) this.setState({ checkCustomCalled: true }); !first && this.props.customCheck && this.props.customCheck(this.props.item, this) } } checked={this.state.checked} className="list-field" onChange={this.handleCheckItem}/>
        );
    }
}

export class CommItemList extends React.Component {
    addFields() {
        return CommItemListAux.setFieldsItems(this.props, (confField) => {
            if (isMobile) {
                const FieldTag = CommItemListAux.getFieldTag(confField, this.props);
                let headXS = 4;
                let infoXS = 8;
                if (confField.headProps != undefined && confField.headProps.xs != undefined) {
                    headXS = confField.headProps.xs;
                    infoXS = 12 - headXS;
                }
                return (
                    <React.Fragment>
                        <Grid container direction="row" justify="center" alignItems="center" wrap style={{ "padding": "2px" }} >
                            <Grid item xs={headXS} {...confField.headProps} {...FieldTag.FTag.propsToHead != undefined ? FieldTag.FTag.propsToHead() : {}}>
                                <b>{confField.name}</b>
                            </Grid>
                            <Grid item xs={infoXS} justify="center" alignItems="center">
                                {CommItemListAux.rNewComp(confField, this.props)}
                            </Grid>
                        </Grid>
                    </React.Fragment>
                )
            } else return (
                <React.Fragment>
                    { CommItemListAux.rNewComp(confField, this.props) }
                </React.Fragment>
            );
        })
    }

    render() {
        return (
            <React.Fragment>
                <ConditionalComponent TagComponent={StyledTableRow} Condition={isBrowser} bodyConditional={true} className="banner-item" container alignItems="flex-start" direction="row">
                    {this.props.canSel && <TableCell className="list-field"><ItemSelCheckBox customCheck={ this.props.customCheck } item={this.props.item} parentComm={this.props.parentComm}/></TableCell>}
                    {this.addFields()}
                </ConditionalComponent>
                <MobileView>
                    <Grid container direction="row" justify="center" alignItems="center" style={{ "margin-bottom": "15px", "border-radius": "5px", "background-color": "#8dd0ff", "padding": "20px", "box-shadow": "2px 2px 4px #ccc" }}>
                        {this.addFields()}
                    </Grid>
                </MobileView>
            </React.Fragment>
        )
    }
}

export class CommItemListCountDown extends CountDownComm {
    render() {
        const confField: confField = this.props.confFieldItem.confField;
        return <TableCell className="list-field" {...confField.fieldprops}> { super.render() } </TableCell>
    }
}

export class CommItemField extends React.Component {
    constructor(props) {
        super(props);
    }

    fitTextToMobile(text, max=15) {
        if (isMobile && (typeof text === 'string' || text instanceof String)) {
            while (true) {                
                let ntext = text;
                let i1 = text.lastIndexOf(" ");
                let i2 = text.lastIndexOf("-");
                let i = i1 > i2 ? i1 : i2;
                if (text.length <= max || text.length - i <= max) break;
                if (i != -1) {
                    ntext = text.substr(i, text.length);
                }
                if (ntext.length > max) ntext = ntext.substr(0, max) + "-" + ntext.substr(max, ntext.length);
                if (i != -1) {
                    text = text.substr(0,i) + ntext;
                } else
                    text = ntext;
            }
        }
        return text;
    }

    render = () => {
        const confField: confField = this.props.confFieldItem.confField;
        return (
            <ConditionalComponent TagComponent={TableCellItem} Condition={isBrowser} bodyConditional={false} className="list-field" {...confField.fieldprops}> <p {...confField.textStyle && confField.textStyle(this.props.item)} > {this.fitTextToMobile(strfieldvalue(this.props.confFieldItem, this.props.item))} </p></ConditionalComponent>
        )
    }
}

export class CommItemTextLink extends React.Component {
    constructor(props) {
        super(props);
    }

    render = () => {
        const confField: confField = this.props.confFieldItem.confField;
        return (
            //TODO: confField.tUrl que pueda ser un string y una funcion
            <a style={{ display: 'inline-flex', alignItems: 'center', ...this.props.confFieldItem.confField.fieldprops.aStyle }} href={confField.tUrl(this.props.item)} target="_blank"> {strfieldvalue(this.props.confFieldItem, this.props.item)} </a>
        )
    }
}

export class CommItemFieldTextLink extends React.Component {
    constructor(props) {
        super(props);
    }

    render = () => {
        const confField: confField = this.props.confFieldItem.confField;
        return (
            //TODO: confField.tUrl que pueda ser un string y una funcion
            <TableCell className="list-field" {...confField.fieldprops}> <CommItemTextLink {...this.props} /></TableCell>
        )
    }
}

export class CommListCheckBox extends React.Component {
    render = () => <CommItemTextFieldInList {...this.props} innerTextComp={CommCheckBox} />
}


export class CommItemTextField extends React.Component {
    render = () => <CommItemTextFieldInList {...this.props} innerTextComp={CommTextField} />
}

export class CommItemTextFieldInList extends React.Component {
    static propsToHead = () => {
        return {
            style: { "margin-top": "-22px" }
        }
    }
    render = () => {
        const confField: confField = this.props.confFieldItem.confField;

        let InnerTextComp = this.props.innerTextComp || confField.fieldprops.innerTextComp || CommTextField;

        let noTable = this.props.noTable || confField.fieldprops.noTable; //Si el elemento no esta dentro de una tabla, usar TableCell nos va a complicar la vida (visualmente ya no ocupara todo el width que deberia)
        //confField.tLabel = false;
        return (
            <ConditionalComponent TagComponent={TableCellItem} Condition={isBrowser && !noTable} bodyConditional={false} className="list-field" {...confField.fieldprops} >
                <InnerTextComp {...this.props} />
            </ConditionalComponent>
        )
    }
}

export class CommDeleteButtonLink extends CommButton {
    constructor(props) {
        super(props);
        this.tInnerLink =
            <MuiThemeProvider theme={ErrorWarnTheme}>
                <Button color="primary" variant="contained" className="list-button" onClick={(e) => { this.SelItemToDelete(); }}>
                    <Delete size={25}></Delete>
                </Button>
            </MuiThemeProvider>
    }

    SelItemToDelete = () => {
        this.props.item.FieldName = strfieldvalue(this.props.confFieldItem, this.props.item);
        this.props.parentComm.DelItem(this.props.item);
    }
}

export class CommButtonSave extends CommButton {
    update = () => {
        let parent: CommList = this.props.parentComm;
        const confField: confField = this.props.confFieldItem.confField;

        this.tInnerLink = !parent.newDataToSave(this.props.item) ? " " : //The king of the cutres
            <Button color="primary" variant="contained" className="list-button" onClick={(e) => { parent.UpdateData(this.props.item) }} {...confField.fieldprops}>
                <Save size={25}></Save>
            </Button>
    }
}

export class CommListSimpleTextButton extends CommSimpleTextButton {
    render = () => {
        const confField: confField = this.props.confFieldItem.confField;

        let InnerTextComp = this.props.innerTextComp || confField.fieldprops.innerTextComp || CommTextField;

        let noTable = this.props.noTable || confField.fieldprops.noTable; //Si el elemento no esta dentro de una tabla, usar TableCell nos va a complicar la vida (visualmente ya no ocupara todo el width que deberia)
        //confField.tLabel = false;
        return (
            <ConditionalComponent TagComponent={TableCellItem} Condition={isBrowser && !noTable} bodyConditional={false} className="list-field" {...confField.fieldprops} >
                {super.render()}
            </ConditionalComponent>
        )
    }
}

export class CommLinkButton extends CommButton {
    constructor(props) {
        super(props);        

        this.state = {
            widgetUrl: "",
            toastOpen: false
        }
    }

    update = () => {
        const confField: confField = this.props.confFieldItem.confField;

        this.tInnerLink =
            <React.Fragment>
            <Button color="secondary" variant="contained" className="list-button" onClick={() => { navigator.clipboard.writeText(this.strfield(this.props.item).fieldName); this.setState({ toastOpen: true }); confField.OnClick&&confField.OnClick(this.props.item) }}>
                {this.props.confFieldItem.confField.tTitle ||
                    <parent>
                        <FileCopy size={15}></FileCopy>
                        <LinkIcon size={25}></LinkIcon>
                    </parent>
                }
            </Button>
            <Snackbar open={this.state.toastOpen} autoHideDuration={6000} onClose={() => { this.setState({ toastOpen: false }) }}>
                <MuiAlert severity="success">
                    Link was copied to clipboard!
                </MuiAlert>
            </Snackbar>
            </React.Fragment>
    }
}

export class CommItemOfItems extends React.Component {

    render = () => {
        const confField: confField = this.props.confFieldItem.confField;

        let comps = confField.Comp.map(c => {
            if (c.Condition == undefined || c.Condition(this.props.item))
                return CommItemListAux.rNewComp(c, this.props);
        });

        return (
            (confField.fieldprops?.BoxFlex && <Box display="flex" justifyContent="space-between"> {comps} </Box>) ||
            <ConditionalComponent TagComponent={TableCellItem} Condition={isBrowser} bodyConditional={false} className="list-actions" {...confField.fieldprops}>
                <Grid container justify="center" direction="row">
                    {
                        comps
                    }
                </Grid>
            </ConditionalComponent>
        )
    }
}

export const ListFieldButtonLink = (Icon = undefined, url, text = undefined, titleText = "Widget URL", notificationBadget = null) => {
    return new confListField(titleText, myReactTaggedComponent(CommItemFieldLink, [
        new ReactComponentTag("tInnerLink", (item) => <Button color="primary" variant="contained" className="list-button">{Icon && <Icon size={25}></Icon>}{typeof text === 'function' ? text(item) : text} {notificationBadget && <NotificationBadget {...notificationBadget(item)} color="secondary" style={{ top: -15, right: -15 }} />}</Button>)
    ]), null, (item) => { return { to: url(item), fieldName: url(item) } })
}

export const componentToItemFieldListRender = (comp, divStyle=null) => {
    return (
        <ConditionalComponent  TagComponent={TableCellItem} Condition={isBrowser} bodyConditional={false} className="list-actions" {...confField.fieldprops}>
            <div style={divStyle ? divStyle : { "margin-top": "-20px" }}>
                {comp}
            </div>
        </ConditionalComponent>
    );
}

const TableCellItem = withStyles((theme) => ({
    root: {
        border: 0,
    }
}))(TableCell);

export class ListFilesComp extends React.Component {
    constructor(props) {
        super(props);

        this.confResources = [
            new confListField(strs.get("Delete"), FileButtonComm, { canDelete: props.canDelete}, (item) => { return { fileName: item.name, url: item.path }; })
        ];
    }

    render() {
        return (
            <CommList ref={(c) => this.comp = c } confComm={new confList(CommListFlat, new commService(this.props.resourcesURL), this.confResources)} />
        );
    }
}