
import React from 'react';

import Particles from 'react-particles-js';

import './particles-background.styles.scss';
import {Params} from './particles-background.params';


export class ParticlesBg extends React.Component {
    render() {
        return (
            <Particles width='100vw' height="100vh" className="particles-background" params={Params}/>
        )
    }
}

export default ParticlesBg;