import React from 'react';
import ReactDOM from 'react-dom';

import jwtDecode from 'jwt-decode';
import Cookies from 'universal-cookie';
import ServicesBase from '../services/base';

let cookies = new Cookies();

export const UserTypes = {
    Streamer: "streamer",
    Advertiser: "advertiser",
    Admin:"admin"
}

export const UserSelected = {
    user: null
}

const utils = {
    SetUser: (user) => {
        UserSelected.user = user;
    },
    GetUserData: (strict: boolean = false) => {
        if (!strict && UserSelected.user) {
            return UserSelected.user;
        }
        if(cookies.get("userSession")) return jwtDecode(cookies.get("userSession"));
        return {}; 
    },
    isUserType: (type: string, strict: boolean = false) => {
        return utils.GetUserData().usertype == type;
    },
    UserTypes: UserTypes
}

export default utils;

export async function requestChangePassword(userId) {
    let tSingIn: ServicesBase = new ServicesBase("#server#" + "/users/requestChangePassword");
    let rtSingIn = await tSingIn.ServiceRequest("", 'POST', { 'Content-Type': 'application/json' }, true, { user: userId });
}