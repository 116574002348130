import React from 'react';
import { connect } from 'react-redux';

import { appendScript, removeScript } from '../../utils/jsscripts'
import '../banner-list/banner-list.components'
import { Button, Link } from '@material-ui/core'
import { Delete, Visibility, AttachMoney, Edit, FileCopy } from '@styled-icons/material';
import MuiAlert from '@material-ui/lab/Alert'
import ContentNavigation from '../../structure/content-navigation/content-navigation.component'
import Streamers from '../../services/api/streamers';
import { commService } from '../../services/api/list';
import GlobalState from '../../store/globalState'
import DeleteItemDialog from '../common/deleteItemDialog/deleteItemDialog.component.jsx';
import AddNewButton from '../common/add-new-button/add-new-button.component.jsx';
import UserUtils from '../../utils/userUtils'
import { changeUrl, Urls } from '../../utils/routerUtils'
import { withStyles } from '@material-ui/core/styles'
import { CommListGen, CommLinkButton, CommDeleteButtonLink, confListField, confList, CommList, CommItemField, CommItemOfItems, CommButtonLinkItem } from '../common/com-List/comm-list.components'
import { CommItemFieldLink } from '../common/com'
import { myReactTaggedComponent, ReactComponentTag } from '../common/extComponents'

import { Link as RouterLink } from 'react-router-dom';

import { bannerStreamerListService } from '../../services/api/bannerStreamer';
import { CommInputField, CommForm, CommFormGen, confForm } from '../common/com-Form/comm-form.components'
import { CommButton, confCOM, confField, CommTextField, fieldNameFunction, temporalItem, fnfRequireText, checkMailField } from '../common/com'
import { StreamingAccountAuthYoutube } from './streamingAccount-authYoutube'
import { TwitchAuthAccount } from './streamingAccount-authTwitch'
import { StreamingAccountAuth } from './streamingAccount-auth';

import { isDevelop, isSandbox } from './../../index'

import { LangStrs, Lang } from '../../utils/languageUtils'

import './../../pages/login/loginpage.styles.scss';
import './../../pages/login/font-awesome.min.css';

const strs: LangStrs = new LangStrs(new Map(
    [
        ['TitleAddSA', new Map(
            [
                [Lang.ES, `Añadir nueva red social o cuenta de streaming`],
                [Lang.EN, `Add new social network or streaming account`]
            ])
        ],
        ['InvalidSA', new Map(
            [
                [Lang.ES, `Cuenta de streaming inválida`],
                [Lang.EN, `Invalid Streaming account`]
            ])
        ],
        ['AlreadySA', new Map(
            [
                [Lang.ES, `Ya has registrado esta cuenta de streaming`],
                [Lang.EN, `You have already registered this Streaming account`]
            ])
        ],
        ['AddSA', new Map(
            [
                [Lang.ES, `Agregar cuenta {1}`],
                [Lang.EN, `Add {1} account`]
            ])
        ],
        ['userNameSA', new Map(
            [
                [Lang.ES, `Nombre de usuario en {1}`],
                [Lang.EN, `{1} username`]
            ])
        ],
        ['AddSAInfo', new Map(
            [
                [Lang.ES, `La cuenta no será aprobada hasta que se haya verificado un video/directo`],
                [Lang.EN, `the account will not be approved until a video/stream has been verified`]
            ])
        ],
    ]
));

let AddForm: CommForm = null;

class NoImplementedStreamingAccountService extends commService { //TODO: Utilitzar StramingAccount-Auth.addStreamingAccount el tema es que no recordu com pasarme platform
    Add = async (userId, data, loading = true) => {
        let resp =  await this.ServiceRequest("", 'POST', {
            'Content-Type': 'application/json',
        }, loading, {
            idPlatform: data.platformId
        }, userId);

        await OnRespAddStreamingAccount(resp);        

        return resp;
    };
};

async function OnRespAddStreamingAccount(resp: Response) {
    if (resp.httpCode == 201 || AddForm==null) {
        changeUrl(Urls.StreamsList);
    } else if (resp.response.rawText.includes("Streaming account updated")) {
        await AddForm.showError(strs.get('AlreadySA'));
    } else if (resp.response.rawText.includes("Streaming account already registred")) {
        await AddForm.showError(strs.get('AlreadySA'));
    } else {
        await AddForm.showError(strs.get('InvalidSA'));
    }
}

export default class StreamingAccountAdd extends React.Component {
    twitchAccount: TwitchAuthAccount = new TwitchAuthAccount((access_token) => { StreamingAccountAuth.addStreamingAccount(access_token, "Twitch", OnRespAddStreamingAccount); })

    async componentDidMount() {        
        
    }

    componentDidUnmount() {
        
    }

    AddExPlatformForm(platform:string, title:string, idFieldTitle:string) {
        this.confExPlatform = [
            new confListField(idFieldTitle, CommTextField, {}, fnfRequireText("platformId"))
        ];
        return this.state.extPlatform != null &&
            <parent>
                <CommForm ref={(c) => { AddForm = c; }} title={title} confComm={new confForm(CommFormGen, new NoImplementedStreamingAccountService("/streamers/" + UserUtils.GetUserData().id + "/streamingAccount/platform/" + platform), this.confExPlatform, undefined)} /*Owner={this} OnChange={this.OnNewAccount.bind(this)} RedirectOnNew={Urls.MoneyAccountsList}*/ BeforeSaveData={() => {
                    if (isSandbox && this.state.extPlatform == "Twitch" && AddForm.state.item.newData.platformId == "el_exito_del_streamer") {
                        this.twitchAccount.OpenSignup();
                        return false;
                    }
                    return true;
                }} />
                <font face="verdana" color="red">* {strs.get('AddSAInfo') } </font>
            </parent>
    }

    render = () => {
        if (this.state != null && this.state.extPlatform != null) {
            const platformName = this.state.extPlatform;
            return this.AddExPlatformForm(this.state.extPlatform, strs.get('AddSA', platformName), strs.get('userNameSA', platformName));
        } else {
            return React.createElement(
                () =>
                    <parent>
                        <h1>{strs.get('TitleAddSA')}</h1>
                        {!isSandbox &&
                            <Link onClick={() => this.twitchAccount.OpenSignup()}>
                                <div id="gSignInWrapper">
                                    <div id="customBtn" class="customGPlusSignIn" style={{ "background": "white", "color": "#6441a5" }} >
                                        <span class="icon2">
                                            <i class="fa fa-1x fa-twitch" style={{ "font-size": "23px", padding: "10px" }}></i>
                                        </span>
                                        <span class="buttonText" style={{ padding: "0px" }}>Twitch</span>
                                    </div>
                                </div>
                            </Link>
                        }
                        {isSandbox &&
                            <Link onClick={() => this.setState({ extPlatform: "Twitch" })} style={{ textDecoration: 'none' }}>
                                <div id="gSignInWrapper">
                                    <div id="customBtn" class="customGPlusSignIn" style={{ "background": "white", "color": "#6441a5" }} >
                                        <span class="icon2">
                                            <i class="fa fa-1x fa-twitch" style={{ "font-size": "23px", padding: "10px" }}></i>
                                        </span>
                                        <span class="buttonText" style={{ padding: "0px" }}>Twitch</span>
                                    </div>
                                </div>
                            </Link>
                        }
                        <br />
                        {!isSandbox &&
                            <Link onClick={() => { StreamingAccountAuthYoutube.AddYoutubeAccount(OnRespAddStreamingAccount) }}>
                                <div id="gSignInWrapper">
                                    <div id="customBtn" class="customGPlusSignIn">
                                        <span class="icon"></span>
                                        <span class="buttonText" style={{ padding: "0px" }}>Google</span>
                                    </div>
                                </div>
                            </Link>
                        }
                        {isSandbox &&
                            <Link onClick={() => this.setState({ extPlatform: "Youtube" })} style={{ textDecoration: 'none' }}>
                                <div id="gSignInWrapper">
                                    <div id="customBtn" class="customGPlusSignIn">
                                        <span class="icon"></span>
                                        <span class="buttonText" style={{ padding: "0px" }}>Google</span>
                                    </div>
                                </div>
                            </Link>
                        }
                        <br />
                        <Link onClick={() => this.setState({ extPlatform: "Tiktok" })} style={{ textDecoration: 'none' }}>
                            <div id="gSignInWrapper">
                                <div id="customBtn" class="customGPlusSignIn" style={{ "background": "white", "color": "black", display: "flex" }} >
                                    <span class="icon2" background style={{ display: "flex" }}><img src="/img/platforms/tiktok.png" style={{ width: "23px", height: "23px", margin: "auto" }} /></span>
                                    <span class="buttonText" style={{ padding: "0px", margin: "auto 0", fontFamily:"Arial" }}>TikTok</span>
                                </div>
                            </div>
                        </Link>
                        <br />
                        <Link onClick={() => this.setState({ extPlatform: "Instagram" })} style={{ textDecoration: 'none' }}>
                            <div id="gSignInWrapper">
                                <div id="customBtn" class="customGPlusSignIn" style={{ "background": "white", "color": "black", display: "flex" }} >
                                    <span class="icon2" background style={{ display: "flex" }}><img src="/img/platforms/instagramlogo.png" style={{ width: "23px", height: "23px", margin: "auto" }} /></span>
                                    <span class="buttonText" style={{ padding: "0px", margin: "auto 0", fontFamily: "Helvetica, Arial, sans-serif;" }}>Instragram</span>
                                </div>
                            </div>
                        </Link>
                        <br />
                        <Link onClick={() => this.setState({ extPlatform: "X (Twitter)" })} style={{ textDecoration: 'none' }}>
                            <div id="gSignInWrapper">
                                <div id="customBtn" class="customGPlusSignIn" style={{ "background": "white", "color": "black", display: "flex" }} >
                                    <span class="icon2" background style={{ display: "flex" }}><img src="/img/platforms/TwitterX.svg" style={{ width: "27px", height: "27px", margin: "auto" }} /></span>
                                    <span class="buttonText" style={{ padding: "0px", margin: "auto 0", fontFamily: "Arial" }}>X (Twitter)</span>
                                </div>
                            </div>
                        </Link>
                    </parent>
            );
        }
    }
}