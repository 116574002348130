import React from 'react';
import { Route } from 'react-router-dom';
import { Grid, Button } from '@material-ui/core'
import './main.styles.scss';
import HomePage from './../../pages/home/homepage.component'
import StreamerPage from './../../pages/streamer/streamerpage.component'
import AdvertiserPage from './../../pages/advertiser/advertiserpage.component'
import AgencyPage from './../../pages/agency/agencypage.component'
import LoginPage from './../../pages/login/loginpage.component'
import ChangePassowrd from './../../pages/changepassword/changepassword.component'
import ActivateAccount from './../../pages/activeaccount/activeaccount.component'
import RegisterPage from './../../pages/register/registerpage.component'
import ContactPage from './../../pages/contact/contactpage.component'
import PrivacyPolicyPage from './../../pages/privacypolicy/privacypolicypage.component'
import CookiesPage from './../../pages/cookiespolicy/cookies.component'
import AuthMail from './../../pages/authMail/authMail.component'
import HomeHeader from '../../structure/home-header/home-header.component';
import Footer from '../../structure/footer/footer.component';
import ParticlesBg from '../../structure/common/particles-background/particles-background.component';
import { ConditionalGuestContent } from '../../structure/redirect-url/redirect-url.component';
import PdfPage from '../../components/pdf/pdfPage'
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { Link } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom';
import { LangStrs, Lang } from '../../utils/languageUtils'
import { DeliveryOngoingFromToken } from '../../structure/brandedContent-form/brandedContentOnGoig-list.component.jsx'

function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

export class Main extends React.Component {
    state = {
        dashboard : false,
        particles : true
    }
    render() {
        let strs: LangStrs = new LangStrs(new Map(
            [
                ['cookiesMsg', new Map(
                    [
                        [Lang.ES, "Este sitio web utiliza cookies para mejorar la experiencia de usuario."],
                        [Lang.EN, "This website uses cookies to enhance the user experience."]
                    ])
                ],
                ['cookiesMore', new Map(
                    [
                        [Lang.ES, "Aprenda más sobre nuestra politica de cookies o modificar configuración"],
                        [Lang.EN, "Learn more about our cookie policy or modify cookies settings"]
                    ])
                ],
                ['cookiesOk', new Map(
                    [
                        [Lang.ES, "Aceptar"],
                        [Lang.EN, "Accept"]
                    ])
                ],
                ['cookiesDecline', new Map(
                    [
                        [Lang.ES, "Cerrar"],
                        [Lang.EN, "Close"]
                    ])
                ],
            ]
        ));

        return (            
            <home>
                { this.state.particles ? <ParticlesBg></ParticlesBg> : null }
                { this.state.dashboard ? null : <HomeHeader></HomeHeader>}
                <div className="main-container" style={{ height: "100%", width: "100%", "padding-top": "5vh" }}>
                    <div style={{ zIndex: "2", position: "relative" }}>
                        <ScrollToTop/>
                        <Route exact path='/' component={HomePage} />
                        <Route exact path='/streamer' component={StreamerPage} />
                        <Route exact path='/advertiser' component={AdvertiserPage} />
                        <Route exact path='/agency' component={AgencyPage} />
                        <Route exact path='/login' component={LoginPage} />
                        <Route exact path='/register' component={RegisterPage} />
                        <Route exact path='/contact' component={ContactPage} />
                        <Route exact path='/privacypolicy' component={PrivacyPolicyPage} />
                        <Route exact path='/cookiespolicy' component={CookiesPage} />
                        <Route exact path='/authMail/:userId/:token' component={AuthMail} />
                        <Route exact path='/changepassword/:userId/:token' component={ChangePassowrd} />
                        <Route exact path='/activateAccount/:userId/:token' component={ActivateAccount} />
                        <Route exact path='/agencyInfo' component={() => <ConditionalGuestContent redirectTo="/"><PdfPage url={"PowerAdsAgencias.pdf"} /></ConditionalGuestContent>} />
                        <Route exact path='/sponsorshipContract/:adv/:id/:contract/:token' component={DeliveryOngoingFromToken} />
                        <Footer />
                    </div>
                </div>
                <CookieConsent setDeclineCookie={false} enableDeclineButton buttonText={strs.get('cookiesOk')} declineButtonText={strs.get('cookiesDecline') }>
                    {strs.get('cookiesMsg')}
                    <Link component={RouterLink} to="/cookiespolicy" style={{ "padding": "10px" }}>{strs.get('cookiesMore')}</Link>
                </CookieConsent>
            </home>
        )
    }
}

export default Main;