import React from 'react';

import { MobileView as MV, BrowserView as BV, isBrowser as iB, isMobile as iM } from "react-device-detect";

let forceMobile:boolean = false;

export class BrowserView extends React.Component {
    render() {
        if (forceMobile) return <nop></nop>;
        else return <BV {...this.props}></BV>;
    }    
}

export class MobileView extends React.Component {
    render() {
        if (forceMobile) {
            if (iB) return <BV {...this.props}></BV>;
            else return <MV {...this.props}></MV>;
        }
        return MV;
    }
}

export let isBrowser = iB;
export let isMobile = iM;

function init() {
    if (window.innerWidth <= 760)
        forceMobile = true;
    if (forceMobile) {
        isMobile = true;
        isBrowser = false;
    }
}
init();