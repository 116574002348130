import Streams from './streams'
import Banners from './banners'
import StreamingAccount from './streamingAccount'

const StreamerServices = {
    Streams: Streams,
    Banners: Banners,
    StreamingAccount: StreamingAccount
}

export default StreamerServices;