import actionTypes from '../actions/actiontypes';
import literals from './../../i18n';
import jwtDecode from 'jwt-decode';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const initialState = {
    i18n: {},
    isLoading: false,
    userSession: cookies.get("userSession") ? {
        id: jwtDecode(cookies.get("userSession")).id,
        role: jwtDecode(cookies.get("userSession")).usertype,
        token: cookies.get("userSession")} : {},
    existingSession: false,
    loginError: false,
    registerResponse : {},
    i18nStrings: literals["en"],
    lang: "en",

    notifications: [],
    Notification_lastFetched: null,
    Notification_loading: false,
    Notification_error: null,
}


  
const reducer = (state = initialState, action) => {
    if (action.type === actionTypes.TOGGLE_LOADING) {
        return Object.assign({}, state, {
            isLoading: action.payload
        })
    } else if (action.type === actionTypes.CHANGE_LANGUAGE) {
        if(!literals[action.payload]) {
            console.error("Selected language is not registered in the system");
            return state;
        };
        return Object.assign({}, state, {
            lang: action.payload,
            i18nStrings: literals[action.payload]
        })
    } else if (action.type === actionTypes.WWW_USER_LOGIN_RESPONSE) {
        if (action.payload.status == 1) {
            //cookies.set("userSession", action.payload.response);
            document.cookie = "userSession" + "=" + action.payload.response+"; path=/";
            return Object.assign({}, state, {
                existingSession: true,
                userSession: {
                    id: jwtDecode(action.payload.response).id,
                    role: jwtDecode(action.payload.response).usertype,
                    token: action.payload.response
                },
                loginError: false,
            })
        } else {
            return Object.assign({}, state, {
                existingSession: false,
                userSession: {},
                loginError: action.payload.response
            })
        }
    } else if (action.type === actionTypes.WWW_EXISTING_SESSION) {
        if(action.payload.status == 1) {
            cookies.set("userSession", JSON.stringify({
                id: jwtDecode(action.payload.response).id,
                role: jwtDecode(action.payload.response).usertype,
                token: action.payload.response
            }));
            return Object.assign({}, state, {
                existingSession: true,
                userSession: {
                    id: jwtDecode(action.payload.response).id,
                    role: jwtDecode(action.payload.response).usertype,
                    token: action.payload.response
                },
                loginError: false
            })
        } else {
            return Object.assign({}, state, {
                existingSession: true,
                userSession: {},
                loginError: action.payload.response
            })
        }
    } else if (action.type === actionTypes.WWW_USER_REGISTER_RESPONSE) {
        return Object.assign({}, state, {
            registerResponse: action.payload
        })
    }

    if (action.type === 'FETCH_NOTIFICATIONS_REQUEST') {
        return {
            ...state,
            Notification_loading: true,
        };
    }

    if (action.type === 'FETCH_NOTIFICATIONS_SUCCESS') {        
        return {
            ...state,
            Notification_loading: false,
            notifications: action.payload,
            Notification_lastFetched: Date.now(),
        };
    }

    if (action.type === 'FETCH_NOTIFICATIONS_FAILURE') {
        return {
            ...state,
            Notification_loading: false,
            Notification_error: action.payload,
        };
    }

    return state
}

export default reducer;