import ServicesBase from "../../base"

class StreamerDescription extends ServicesBase {
  constructor() {
    super("#server#" + "/streamers/descriptions");
  }
  Add = async (data, loading = true) => {
      return await this.ServiceRequest("/", 'POST', {
          'Content-Type': 'application/json',
        },
        loading,
        data
      );
  };
  Update = async (id, data, loading = true) => {
    return await (this.ServiceRequest("/"+id, 'PUT', {
      'Content-Type': 'application/json',
    }, loading, data));
  };
  Get = async (id = false, loading = true) => {
    return await (this.ServiceRequest(id ? "/"+id : "", 'GET', {}, loading));
  };
  Delete = async (id, loading = true) => {
    return await (this.ServiceRequest("/"+id, 'DELETE', {}, loading));
  }
};

export default new StreamerDescription();