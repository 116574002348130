import { forceUpdateNotifications } from '../store/sagas/notifications';

export const Urls = {
    Profile: "/dashboard/settings/profile",
    AddFounds: "/dashboard/transactions/addFounds",
    StreamsList: "/dashboard/streams",

    Ads: "/dashboard/advertisements/",
    AdvStats: "/dashboard/advertisements/stats/",
    AdvEdit: "/dashboard/advertisements/edit/",
    AdvAdd: "/dashboard/advertisements/add/",
    AdvBrandedEdit: "/dashboard/advertisements/brandedContent/edit/",
    AdvBrandedStreamers: "/dashboard/advertisements/brandedContent/streamers/",
    AdvBrandedOnGoing: "/dashboard/advertisements/brandedContent/ongoing/",

    StreamersBranded: "/dashboard/streamers/sponsorships",

    MoneyAccountsList: "/dashboard/settings/payment",
    addPaymentMethod: "/dashboard/settings/payment/add",

    Login: "/login",

    BannerAdd: "/dashboard/banners/add/",

    StreamingAccountAdd: "/dashboard/streamingAccount/add",

    InfoStreamer: "/streamer",
    InfoAdvertiser: "/advertiser",
    InfoAgency: "/agency"
}

//Recarrega tota la aplicacio o marxo della
export const changeUrl = (url) => {
    window.location = url;
}

//Es crida desde un component/funcio React rederitzat per Route
export const changeUrlByReact = (props, url) => {
    props.history.push(url);
}

export const refreshByReact = (props) => {
    props.history.push(window.location);
}

export const goBackUrl = (props = null) => {
    const urlBack = window.UrlPrev || document.referrer;
    if(props==null)
        changeUrl(urlBack);
    else
        changeUrlByReact(props, urlBack);
}

export const openTab = (url) => window.open(url, '_blank');

export const mapGenericStateToProps = (state, ownProps) => {
    let notificationValue = 0;

    if (state.notifications && state.notifications.length)
        notificationValue = state.notifications.filter(n =>
            (Array.isArray(ownProps.notificationKey) ? ownProps.notificationKey.includes(n.notificationEntityType) : ownProps.notificationKey == n.notificationEntityType || ownProps.notificationKey === undefined) &&
            (Array.isArray(ownProps.notificationId) ? ownProps.notificationId.includes(n.entityId) : ownProps.notificationId == n.entityId || ownProps.notificationId === undefined) &&
            (Array.isArray(ownProps.notificationId2) ? ownProps.notificationId2.includes(n.entity2Id) : ownProps.notificationId2 == n.entity2Id || ownProps.notificationId2 === undefined)
        ).length;
    return { i18n: state.i18nStrings, loginError: state.loginError, userSession: state.userSession };
};

export const mapNotificationsToProps = (state, ownProps) => {
    let notificationValue = 0;

    if (state.notifications && state.notifications.length)
        notificationValue = state.notifications.filter(n =>
            (Array.isArray(ownProps.notificationKey) ? ownProps.notificationKey.includes(n.notificationEntityType) : ownProps.notificationKey == n.notificationEntityType || ownProps.notificationKey === undefined) &&
            (Array.isArray(ownProps.notificationId) ? ownProps.notificationId.includes(n.entityId) : ownProps.notificationId == n.entityId || ownProps.notificationId === undefined) &&
            (Array.isArray(ownProps.notificationId2) ? ownProps.notificationId2.includes(n.entity2Id) : ownProps.notificationId2 == n.entity2Id || ownProps.notificationId2 === undefined)
        ).length;
    return { ...mapGenericStateToProps(state, ownProps), notificationValue: notificationValue, notificationsUpdate: forceUpdateNotifications };
};


export const getFragmentParameter = (name: string) => {
    let fragment = window.location.hash.substr(1);
    if (fragment) {
        let fParameters = new URLSearchParams(fragment);
        if (fParameters.has(name))
            return fParameters.get(name);
    }
    return false;
}