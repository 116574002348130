import ServicesBase from "../../base"
import UserUtils from '../../../utils/userUtils'

export class commService extends ServicesBase {
    constructor(uri, customGet = undefined, bodyParams = {}) { //customGet WTF? hacer como en ToCheckStreamsService para Update
        super(uri)

        this.bodyParams = bodyParams;
        this.customGet = customGet;
    }

    changeBaseUrl(uri) {
        if (!(uri.startsWith("http") || uri.startsWith("#server#")))
            uri = "#server#" + uri;

        super.changeBaseUrl(uri)
    }

    async Get(userId = "", itemId = false, loading = true) {
        let httpMethod = 'GET';
        let headers = {};
        let body = null;

        if (JSON.stringify(this.bodyParams) !== JSON.stringify({})) {
            httpMethod = 'POST';
            headers = { 'Content-Type': 'application/json' };
            body = this.bodyParams;
        }

        let orgBaseUrl = this.baseUrl;
        let itemIdUrl: boolean = false;
        if (this.baseUrl.indexOf(":itemId") >= 0) {
            itemIdUrl = true;
            this.baseUrl = this.baseUrl.replace(":itemId", itemId);
        }
        let r = await (this.ServiceRequest((!itemIdUrl && itemId) ? "/" + itemId : "/", httpMethod, headers, loading, body, userId));
        this.baseUrl = orgBaseUrl;

        if (this.customGet != undefined) {
            this.customGet(r);
        }
        return r;
    };
    Del = async (userId, item, loading = true) => {
        return await (this.ServiceRequest("/" + item.id, 'DELETE', {}, loading, null, userId));
    };
    async Update(userId, item, loading = true) {
        return await (this.ServiceRequest("/" + item.id, 'PUT', {
            'Content-Type': 'application/json',
        }, loading, item.newData, userId));
    };
    Add = async (userId, data, loading = true) => {
        return await this.ServiceRequest("/", 'POST', {
            'Content-Type': 'application/json',
        }, loading, data, userId );
    };
};