import React from 'react';
import { LangStrs, Lang } from '../../utils/languageUtils'
import { BrowserView, MobileView, isBrowser, isMobile } from '../../utils/platformsUtils';
import './agencypage.styles.scss';
import { Button, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles'
//import X from '../../components/x/x.component';

const StyledButton = (selected) => withStyles({
    root: {
        backgroundColor: selected ? '#8CD0F1' : '#1347be',
        marginBottom: '15px',
        marginLeft: '15px',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#8CD0F1',
            color: '#fff',
        },
    }
})(Button);

const SButton = StyledButton(false);

const descCard = (image, title, desc) => {
    return(
        <Grid item xl={2} justify="center" style={{ "padding": "2px" }}>
            <Grid container direction="column"  >
                <Grid item justify="center" style={{ "width": "250px", "height": "150px", margin:"auto" }}>
                    <img src={image} justify="center" style={{ "width": "100%", "height": "100%" }}/>
                </Grid>
                <Grid item justify="center">
                    <h2 justify="center">{title}</h2>
                </Grid>
                <Grid item justify="center">
                    <h3>{desc}</h3>
                </Grid>
            </Grid>
        </Grid>
    )
}

const AgencyPage = () => {
    let strs: LangStrs = new LangStrs(new Map(
        [
            ['title1', new Map(
                [
                    [Lang.ES, `Optimiza la Gestión entre Creadores y Marcas con PowerAds`],
                    [Lang.EN, `Optimize Management between Creators and Brands with PowerAds`]
                ])
            ],
            ['AgencyText', new Map(
                [
                    [Lang.ES, `En la dinámica y compleja industria de la publicidad y el marketing de influencers, las agencias se enfrentan constantemente al reto de gestionar y coordinar las relaciones entre creadores de contenido y marcas. El equilibrio perfecto se encuentra en una gestión eficiente que no solo se traduzca en resultados, sino también en la satisfacción de todas las partes involucradas.<br><br>
Aquí es donde PowerAds destaca. Diseñada específicamente para agencias, esta herramienta simplifica todo el proceso de gestión. Desde la concepción de campañas publicitarias hasta la administración y seguimiento de colaboraciones, PowerAds se presenta como un aliado indispensable.<br><br>
Más allá de ser un simple software de gestión, PowerAds se convierte en un puente que facilita la comunicación, el entendimiento y la colaboración entre creadores y marcas. Al optimizar las operaciones, no solo es posible manejar un mayor número de campañas y creadores, sino también mejorar la calidad de los servicios ofrecidos.<br><br>
El resultado es una gestión más fluida, menos errores, tiempos de respuesta más rápidos y, sobre todo, campañas más exitosas. Al final del día, PowerAds no solo simplifica la gestión, sino que potencia las posibilidades de las agencias para conectar, de manera efectiva, a creadores y marcas en un mercado cada vez más competitivo.`],
                    [Lang.EN, `In the dynamic and complex industry of advertising and influencer marketing, agencies constantly face the challenge of managing and coordinating relationships between content creators and brands. The perfect balance is found in efficient management that translates not only into results but also into the satisfaction of all involved parties.<br><br>
This is where PowerAds stands out. Specifically designed for creator agencies, this tool simplifies the entire management process. From the conception of advertising campaigns to the administration and tracking of collaborations, PowerAds presents itself as an indispensable ally.<br><br>
Beyond being just management software, PowerAds becomes a bridge that facilitates communication, understanding, and collaboration between creators and brands. By optimizing operations, it is not only possible to handle a larger number of campaigns and creators but also to improve the quality of the services offered.<br><br>
The result is smoother management, fewer mistakes, faster response times, and, above all, more successful campaigns. At the end of the day, PowerAds not only simplifies management but enhances the possibilities for agencies to effectively connect creators and brands in an increasingly competitive market.`]
                ])
            ],
            ['howDo', new Map(
                [
                    [Lang.ES, '¿Qué me ofrece PowerAds?'],
                    [Lang.EN, 'What Does PowerAds offer me ?']
                ])
            ],
            ['howDoTitle1', new Map(
                [
                    [Lang.ES, `Ejecución Ágil de Campañas`],
                    [Lang.EN, `Agile Campaign Execution`]
                ])
            ],
            ['howDoDesc1', new Map(
                [
                    [Lang.ES, `PowerAds te permite materializar rápidamente las campañas propuestas por marcas, coordinando de manera eficaz a todos tus representados involucrados.`],
                    [Lang.EN, `PowerAds allows you to quickly materialize campaigns proposed by brands, effectively coordinating all your involved representatives.`]
                ])
            ],
            ['howDoTitle2', new Map(
                [
                    [Lang.ES, `Espacio de Cocreación`],
                    [Lang.EN, `Co-creation Space`]
                ])
            ],
            ['howDoDesc2', new Map(
                [
                    [Lang.ES, `Un entorno donde marcas y creadores tienen todo lo disponible para entenderse y llegar a la máxima sintonía.`],
                    [Lang.EN, `An environment where brands and creators have everything available to understand each other and achieve maximum harmony.`]
                ])
            ],
            ['howDoTitle3', new Map(
                [
                    [Lang.ES, `Informe Detallado de Campaña`],
                    [Lang.EN, `Detailed Campaign Report`]
                ])
            ],
            ['howDoDesc3', new Map(
                [
                    [Lang.ES, `Obtén un completo informe de la campaña, proporcionando a la marca todas las métricas e información relevante de su colaboración con cada creador que ha participado.`],
                    [Lang.EN, `Get a comprehensive campaign report, providing the brand with all the relevant metrics and information about their collaboration with each creator who has participated.`]
                ])
            ],
            ['howDoTitle4', new Map(
                [
                    [Lang.ES, `Verificación Automatizada de Campañas`],
                    [Lang.EN, `Automated Campaign Verification`]
                ])
            ],
            ['howDoDesc4', new Map(
                [
                    [Lang.ES, `Asegura que las campañas en redes sociales o plataformas de streaming cumplan exactamente con lo acordado mediante una verificación automática de todas las etapas.`],
                    [Lang.EN, `Ensure that campaigns on social networks or streaming platforms comply exactly with what was agreed upon through an automatic verification of all stages.`]
                ])
            ],
        ]
    ));

    return (
        <div className='homepage' style={{'alignItems': 'center', width: '100%' }} >
            <div>                
                <Grid container xl={12} xs={12} direction="column" justify="center" alignItems="center" wrap style={{ "padding": "20px" }} >
                    <Grid item justify="center">
                        <Grid container xl={12} xs={12} direction="row" justify="center"  wrap style={{ "padding": "0px" }} >
                            <Grid item xl={6} justify="center">
                                <img src="./img/advertiser/broadcast.png" width='100%' style={{ 'max-width': '600px', 'display': 'block', 'margin': 'auto auto' }} />
                            </Grid>
                            <Grid item xl={6} justify="center" style={{ "margin": "auto" }} >
                                <h1>{strs.get('title1')}</h1>
                                <h2><div dangerouslySetInnerHTML={{ __html: strs.get('AgencyText') }} /></h2>
                            </Grid>
                        </Grid>
                        <Grid container xl={12} xs={12} direction="row" justify="center" wrap style={{ "padding": "0px" }} >
                            <h1>{strs.get('howDo')}</h1>
                        </Grid>
                        <Grid className='how-do-it' container xl={12} xs={12} direction="row" justify="center" wrap style={{ "padding": "0px" }} spacing={40}>
                            {descCard('./img/advertiser/rocket.svg', strs.get('howDoTitle1'), strs.get('howDoDesc1'))}
                            {descCard('./img/advertiser/selection.svg', strs.get('howDoTitle2'), strs.get('howDoDesc2'))}
                            {descCard('./img/advertiser/graphAnalisys.svg', strs.get('howDoTitle3'), strs.get('howDoDesc3'))}
                            {descCard('./img/advertiser/eyeCheck.svg', strs.get('howDoTitle4'), strs.get('howDoDesc4'))}
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default AgencyPage;