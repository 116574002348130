import { call, put, take, fork, cancel, takeLatest, all  } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import ServicesBase from '../../services/base';
import UserUtils from '../../utils/userUtils'

export const NotificationEntityType = {
    None: 0,
    SponsorshipContractNewOffer: 1,
    SponsorshipContractAcceped: 2,
    SponsorshipContractDeclined: 3,
    SponsorshipContractMsg: 4,
    SponsorshipDeliveryNew: 5,
    SponsorshipDeliveryNewPlatformContent: 6,
    SponsorshipDeliveryAcceped: 7,
    SponsorshipDeliveryDeclined: 8,
    SponsorshipDeliveryValidated: 9,
    SponsorshipDeliveryInvalidated: 10,
    InvalidContent: 11,
    BannerNoMoney: 12,
};

export const fetchNotificationsRequest = () => ({
    type: 'FETCH_NOTIFICATIONS_REQUEST',
});

export const fetchNotificationsSuccess = (notifications) => ({
    type: 'FETCH_NOTIFICATIONS_SUCCESS',
    payload: notifications,
});

export const fetchNotificationsFailure = (error) => ({
    type: 'FETCH_NOTIFICATIONS_FAILURE',
    payload: error,
});

export const FORCE_UPDATE_NOTIFICATIONS = 'FORCE_UPDATE_NOTIFICATIONS';

export const forceUpdateNotifications = () => ({
    type: FORCE_UPDATE_NOTIFICATIONS,
});

async function queryNot() {
    if (UserUtils.GetUserData().id == undefined) return {};

    let sbNot: ServicesBase = new ServicesBase("#server#" + "/notifications/" + UserUtils.GetUserData().id);
    let rNot = await sbNot.ServiceRequest("", 'GET', null, false);
    if (rNot.httpCode < 400) {
        return rNot.body;  // Retorna las notificaciones en lugar de emitirlas
    }
    throw new Error('Failed to fetch notifications');  // Lanza un error en caso de fallo
}

function createNotificationChannel() {
    return eventChannel(emitter => {
        let intervalId;

        const startPolling = async () => {
            intervalId = setInterval(async () => {
                try {
                    const notifications = await queryNot();
                    emitter({ notifications });
                } catch (error) {
                    emitter({ error });
                }
            }, 60000);
            try {
                const notifications = await queryNot();
                emitter({ notifications });
            } catch (error) {
                emitter({ error });
            }
        };

        const stopPolling = () => {
            clearInterval(intervalId);
        };

        // Iniciar polling inicialmente
        startPolling();

        // Escuchar eventos de visibilidad
        document.addEventListener('visibilitychange', () => {
            if (document.hidden) {
                stopPolling();
            } else {
                startPolling();
            }
        });

        return () => {
            clearInterval(intervalId);
            document.removeEventListener('visibilitychange', () => { });
        };
    });
}

function* watchNotifications() {
    const channel = yield call(createNotificationChannel);

    while (true) {
        const { notifications, error } = yield take(channel);
        if (notifications) {
            yield put(fetchNotificationsSuccess(notifications));
        } else {
            yield put(fetchNotificationsFailure(error));
        }
    }
}

function* forceUpdateHandler() {
    try {
        const notifications = yield call(queryNot);
        yield put(fetchNotificationsSuccess(notifications));
    } catch (error) {
        yield put(fetchNotificationsFailure(error));
    }
}

function* watchForceUpdate() {
    yield takeLatest(FORCE_UPDATE_NOTIFICATIONS, forceUpdateHandler);
}

export default function* rootSaga() {
    yield all([
        fork(watchNotifications),
        fork(watchForceUpdate),
    ]);
}
