
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { MultiSelect } from "react-multi-select-component";
import { Button, TextField, Box, Grid, TableCellItem, TableCell } from '@material-ui/core'
import { Send, Delete, Visibility, AttachMoney, Edit, FileCopy } from '@styled-icons/material';
import { commService } from '../../services/api/list';
import AddNewButton from '../../structure/common/add-new-button/add-new-button.component.jsx';
//\\192.168.2.186\powerads\Sandbox\Frontend\src\structure\common\add-new-button\add-new-button.component.jsx
import UserUtils from '../../utils/userUtils'
import { confAddItem, CommButtonSave, CommItemTextField, CommItemTextFieldInList, CommListGen, CommLinkButton, CommDeleteButtonLink, confListField, confList, CommList, CommItemField, CommItemOfItems, CommButtonLinkItem, ListFieldButtonLink } from '../../structure/common/com-List/comm-list.components'
import { fieldNameFunction, CommItemFieldLink, CommSimpleTextButton, strfieldvalue } from '../../structure/common/com'
import { getStreamingScene } from '../../structure/streamingAccount/streamingAccount-toStream.components'
import { BrowserView, MobileView, isBrowser, isMobile } from '../../utils/platformsUtils';
import ComTooltip from '../../structure/common/comTooltip/comTooltip.jsx';
import ServicesBase from "../../services/base"

import CommHelpLink, { getDocUrl } from '../../structure/common/comHelpLink/comHelpLink.jsx'

import { LangStrs, Lang } from '../../utils/languageUtils'

const strs: LangStrs = new LangStrs(new Map(
    [
        ['Title', new Map(
            [
                [Lang.ES, 'Título'],
                [Lang.EN, 'Title']
            ])
        ],
        ['TitleListStreamersGroup', new Map(
            [
                [Lang.ES, 'Grupos de creadores de contenido'],
                [Lang.EN, 'Content creators groups']
            ])
        ],
        ['NewGroup', new Map(
            [
                [Lang.ES, 'Nuevo grupo'],
                [Lang.EN, 'New group']
            ])
        ],
        ['TitleMembers', new Map(
            [
                [Lang.ES, 'Miembros'],
                [Lang.EN, 'Members']
            ])
        ],
        ['Actions', new Map(
            [
                [Lang.ES, 'Acciones'],
                [Lang.EN, 'Actions']
            ])
        ],
    ]
));

export class StreamerGroupList extends React.Component {
    constructor(props) {
        super(props);

        this.conf = [
            new confListField(strs.get('Title'), CommItemTextField, { className: "list-field bi-name", ...isMobile ? {} : { style: { paddingTop: "25px" } } }, "title").addField("tLabel", false).addField("disabled", (i) => i.type == "Main").addField("autoSave", true),
            new confListField(strs.get('TitleMembers'), GroupDropdown, {}, "id"),
            new confListField(strs.get('Actions'), CommItemOfItems).addField("Comp", [
                new confListField(strs.get("Save"), CommButtonSave),
                new confListField(strs.get("Delete"), CommDeleteButtonLink, null, "name").addField("Condition", (i) => i.type!="Main")
            ])
        ];
    }

    render = () => {
        return (
            <>
                <CommList title={<>{strs.get('TitleListStreamersGroup')}<CommHelpLink infoUrl={getDocUrl("como-crear-grupos-con-mis-creadores-de-contenido")} /></>} confComm={new confList(CommListGen, new commService("/advertisers/:id/streamersGroup"), this.conf, new confAddItem(null, strs.get('NewGroup'), { "advertiser": UserUtils.GetUserData().id, "title": strs.get('NewGroup') }))} />
            </>
        );
    }
}

class GroupDropdown extends React.Component {
    constructor(props) {
        super(props);
        this.item = this.props.item;
        this.state = {
            selected: this.getSelecteds(),
        };
    }

    getSelecteds() {
        return this.item.Streamers.filter(s => s.selected);
    }

    setDataFromQuery(data) {
        this.item = data.find(d => d.id == this.props.item.id);
        this.setState({ selected: this.getSelecteds() });
    }

    handleOnChange = async (newSelected) => {        
        const deselected = this.state.selected.find(option => !newSelected.includes(option));
        const selectedNew = newSelected.find(option => !this.state.selected.includes(option));
        
        if (deselected) {            
            let sb: ServicesBase = new ServicesBase("#server#" + "/advertisers/" + UserUtils.GetUserData().id + "/streamerStreamersGroup/" + deselected.selected);
            let resp = await sb.ServiceRequest("", 'DELETE', { 'Content-Type': 'application/json' }, true);
            if (resp.httpCode == 200) {
                this.setDataFromQuery(await this.props.parentComm.QueryItems());
            }
        }

        if (selectedNew) {
            let sb: ServicesBase = new ServicesBase("#server#" + "/advertisers/" + UserUtils.GetUserData().id + "/streamerStreamersGroup/");
            let data = { streamersGroup: this.props.item.id }
            if (selectedNew.isSA === true) 
                data.streamingAccount = selectedNew.value;
            else
                data.streamer = selectedNew.value;
            let resp = await sb.ServiceRequest("", 'POST', { 'Content-Type': 'application/json' }, true, data);
            if (resp.httpCode == 201) {
                this.setDataFromQuery(await this.props.parentComm.QueryItems());
            }
        }

        //this.setState({ selected: newSelected });
    }

    render = () => {
        return (
            <TableCell>
                <MultiSelect width="600px"
                    options={this.item.Streamers}
                    value={this.state.selected}
                    onChange={this.handleOnChange}
                    labelledBy="Select"
                    hasSelectAll={false}
                    ClearSelectedIcon={null}
                    overrideStrings={{
                        allItemsAreSelected: this.state.selected.map(item => item.label).join(', ')
                    }}
                />
            </TableCell>
        );
    }
}