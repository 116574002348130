
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'

import UserUtils, { UserTypes } from '../../utils/userUtils'
import { isSandbox } from './../../index'
import { UuidCRC } from '../../utils/utils'
import { Grid, Button, TableCell, TableRow, TextField, CurrencyInput } from '@material-ui/core'
import InputAdornment from "@material-ui/core/InputAdornment";
import { Delete, Visibility, AttachMoney, Edit, FileCopy, CreditCard } from '@styled-icons/material';
import MuiAlert from '@material-ui/lab/Alert'
import AdItem from '../../components/advertiser/ad-item/ad-item.component'
import ContentNavigation from '../../structure/content-navigation/content-navigation.component'
import Advertisers from '../../services/api/advertisers/';
import GlobalState from '../../store/globalState'
import DeleteItemDialog from '../common/deleteItemDialog/deleteItemDialog.component.jsx';
import AddNewButton from '../common/add-new-button/add-new-button.component.jsx';

import { confAddItem, CommListGen, CommLinkButton, CommDeleteButtonLink, confListField, confList, CommList, CommItemField, CommItemOfItems, CommButtonLinkItem, CommItemFieldTextLink, ListFieldButtonLink } from '../common/com-List/comm-list.components'
import { confListStatic, CommListStatic } from '../common/com-List/com-List-Static/comm-list-static-component'
import { myReactTaggedComponent, ReactComponentTag, CompWithParent } from '../common/extComponents'
import { CommItemFieldLink, CommSimpleTextButton } from '../common/com'
import { commService } from '../../services/api/list';
import { getAuthHeader } from '../../services/base';
import { convertUSDToCurrency, simpleCurrencyHelpText, toMStr } from '../../utils/moneyUtils.jsx'

import { withStyles } from '@material-ui/core/styles'
import AdsList from '../ads-list/ads-list.components';

import { mapGenericStateToProps } from '../../utils/routerUtils'

import { DurationTime } from '../../utils/timeUtils';

import ServicesBase from '../../services/base';
import { MoneyAccountTypeComponent } from '../payment-methods-list/payment-methods-list.component'

import { appendScript, removeScript } from '../../utils/jsscripts'
import { isDevelop } from '../../index';

import { changeUrl, Urls, goBackUrl } from '../../utils/routerUtils'

import { LangStrs, Lang } from '../../utils/languageUtils'

const strs: LangStrs = new LangStrs(new Map(
    [
        ['TitleMoneyAccounts', new Map(
            [
                [Lang.ES, 'Carteras del usuario'],
                [Lang.EN, "User's Wallets"]
            ])
        ],
        ['TitleTransferMethods', new Map(
            [
                [Lang.ES, 'Métodos de Transferencia'],
                [Lang.EN, "Transfer Methods"]
            ])
        ],
        ['AddWallet', new Map(
            [
                [Lang.ES, 'Agregar cartera'],
                [Lang.EN, 'Add Wallet']
            ])
        ],
        ['Selected', new Map(
            [
                [Lang.ES, 'Seleccionada'],
                [Lang.EN, 'Selected']
            ])
        ],
        ['MAmount', new Map(
            [
                [Lang.ES, 'Cantidad de dinero'],
                [Lang.EN, 'Amount of Money']
            ])
        ],
        ['Deposit', new Map(
            [
                [Lang.ES, 'Depositar dinero'],
                [Lang.EN, 'Deposit Money']
            ])
        ],
        ['Account', new Map(
            [
                [Lang.ES, 'Cuenta'],
                [Lang.EN, 'Account']
            ])
        ],
        ['YES', new Map(
            [
                [Lang.ES, 'Si'],
                [Lang.EN, 'Yes']
            ])
        ],
        ['NO', new Map(
            [
                [Lang.ES, 'No'],
                [Lang.EN, 'No']
            ])
        ],
        ['BankTransferIntructions1', new Map(
            [
                [Lang.ES, 'Realice una transferencia a la siguiente cuenta bancaria'],
                [Lang.EN, 'Make a transfer to the following bank account']
            ])
        ],
        ['BankTransferIntructions2', new Map(
            [
                [Lang.ES, 'con la referencia'],
                [Lang.EN, 'with the following reference']
            ])
        ],
        ['BankTransferIntructions3', new Map(
            [
                [Lang.ES, 'Tan pronto como se reciba el pago, agregaremos el importe'],
                [Lang.EN, 'As soon as the payment is received, we will add the amount']
            ])
        ],
        ['UWalletAmount', new Map(
            [
                [Lang.ES, 'Cantidad total en la cartera del usuario: '],
                [Lang.EN, "Total amount in the user's wallet: "]
            ])
        ],
        ['AddUserWallet', new Map(
            [
                [Lang.ES, 'Depositar dinero en la cartera del usuario'],
                [Lang.EN, "Deposit money into the user's wallet"]
            ])
        ],
    ]
));

export const addFoundsUrl = (resource, itemId) => `${Urls.AddFounds}/${resource}/${itemId}`;

class TransactionForm extends React.Component {
    paypalScript = "";

    constructor(props) {
        super(props);

        this.paypalScript = isDevelop ?     "https://www.paypal.com/sdk/js?client-id=AUJ3rNFhpSaE7u41oQlmAIH2sj1GI-G56lVNjusRLgXJiOk-QuY4cgpSDePlj0Kp6k9ahgKOeWl-Lqz1&currency=USD" :
                                            "https://www.paypal.com/sdk/js?client-id=ARyGQC6PNzRIdYyq_IFV16Jfu-1gVNScUKWD2iV-1wt7uveN2CkqrRk5vLNu2MgY_5XXMvOcY7q9yI-X&currency=USD";

        this.state = { MAccounts: null, money: 0 };

        this.conf = [
            new confListField(strs.get('Account'), MoneyAccountTypeComponent, {}, ""),
            new confListField(strs.get('Selected'), CommItemOfItems).addField("Comp", [
                new confListField("", CommSimpleTextButton, {}, strs.get('YES')).addField("Condition", (item) => this.state.selAccount != null && this.state.selAccount.id == item.id),
                new confListField("", CommSimpleTextButton, {}, strs.get('NO')).addField("Condition", (item) => this.state.selAccount == null || this.state.selAccount.id != item.id).addField("OnClick", async (item) => this.setState({ selAccount: item }))
            ])
        ];
    }

    componentDidUnmount() {
        removeScript(this.paypalScript);
    }

    async componentDidMount() {
        let mAccounts: [] = (await new commService("/users/:id/moneyAccount").Get(UserUtils.GetUserData().id)).body || []; //Ni idea de xk buscu quines contes bancaries tinc si despres les filtro

        if (this.props.match.params.resource == "wallet" && (isSandbox || UserUtils.GetUserData(true).usertype == UserTypes.Admin)) {
            mAccounts.push({ id: -3, moneyAccountType: "Admin", address: "" });
        }

        if (this.props.match.params.resource != "wallet")
            mAccounts.push({ id: -2, moneyAccountType: "User wallet", address: "" });

        mAccounts = mAccounts.filter(ma => ma.moneyAccountType != "Paypal");
        mAccounts.push({ id: -1, moneyAccountType: "Paypal", address: "" });

        let sb: ServicesBase = new ServicesBase("#server#" + "/Transfers/asset/users/" + UserUtils.GetUserData().id);
        let resp = await sb.ServiceRequest("", 'GET', {}, false, null);

        mAccounts = mAccounts.filter(ma => ma.moneyAccountType != "Bank");

        mAccounts.push({ id: -4, moneyAccountType: "Bank", address: "" });

        await this.setState({ MAccounts: mAccounts, selAccount: null, userMoney: resp.body });        

        appendScript(this.paypalScript); //TODO: podria fer que el constructo em modifiques appendScript i componentDidUnmount        
    }

    CanDeposit(value) {
        let c = !isNaN(value) && parseFloat(value) > 0;
        this.setState({ money: value, canDeposit: c });
    }

    render = () => {
       return <div>
               <h1>{strs.get('TitleTransferMethods')}</h1><Button color="primary" variant="contained" className="list-button" onClick={() => changeUrl(Urls.addPaymentMethod)}>{strs.get('AddWallet')}</Button>
                <CommListStatic key={JSON.stringify(this.state.MAccounts)} title="" confComm={new confListStatic(CommListGen, this.state.MAccounts, this.conf)} />
                {
                    this.state.selAccount != null && this.state.selAccount.moneyAccountType == "User wallet" && <p> {strs.get('UWalletAmount')} <b>{toMStr(this.state.userMoney)}</b> </p>
                }
                {
                   this.state.selAccount != null && this.state.selAccount.moneyAccountType != "Bank" &&
                   <div>
                   <TextField key={"moneyInput"} InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment>, }} helperText={simpleCurrencyHelpText(this.state.money)} className="form-input" label={strs.get('MAmount')} InputLabelProps={{ shrink: true }} id="name-input" variant="outlined" value={this.state.money} onChange={(e) => { this.CanDeposit(e.target.value); document.getElementById('paypal-button-container').innerHTML = ''; }} />
                       {this.state.selAccount.moneyAccountType == "Admin" && <p><TextField fullWidth={true} className="form-input" label={strs.get('MAmount')} InputLabelProps={{ shrink: true }} id="name-input" variant="outlined" label="Reference" onChange={(e) => { this.transferReference = e.target.value; }} /></p>}
                       <p>
                           <Button disabled={this.state.canDeposit===false} id="DepositMoney" color="primary" variant="contained" className="list-button" onClick={(e) => { this.onDepositButton(e) }}>
                               {strs.get('Deposit')}
                            </Button>
                           <div id="paypal-button-container" />                           
                       </p>                       
                   </div>
               }
               {
                   this.state.selAccount != null && this.state.selAccount.moneyAccountType == "Bank" &&
                   <div>
                       <p>
                           {strs.get('BankTransferIntructions1')} <b>ES26 0081 0036 4500 03143220</b><br/>
                           {strs.get('BankTransferIntructions2')} <b>{this.props.match.params.resource == "wallet" ? UuidCRC(UserUtils.GetUserData().id) : UuidCRC("AD-" + UuidCRC(UserUtils.GetUserData().id + "-" + this.props.match.params.id))}</b><br/><br/>
                           {strs.get('BankTransferIntructions3')}
                       </p>
                   </div>
               }
            </div>
    }

    CreatePaypalButtons() {
        let thisobj = this;
        window.paypal.Buttons({
            createOrder: (data, actions) => {
                // This function sets up the details of the transaction, including the amount and line item details.
                return actions.order.create({
                    purchase_units: [{
                        amount: {
                            currency_code: 'USD',
                            value: ""+this.state.money
                        }
                    }]
                });
            }
            ,
            onApprove: function (data) {
                console.log(data);
                return fetch('/api/Transfers/paypalTransaction', {
                    method: 'post',
                    headers: {
                        Authorization: getAuthHeader(),
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        orderID: data.orderID
                    })
                }).then(function (res) {
                    return res.json();
                }).then(async (details) => {
                    if (thisobj.props.match.params.resource != "wallet")
                        await thisobj.doTransferToResource(details.quantity);
                    else
                        goBackUrl(thisobj.props);
                })
            }
        }).render('#paypal-button-container');        
    }

    async onDepositButton(value) {
        if (this.state.selAccount.moneyAccountType == "User wallet" || this.state.selAccount.moneyAccountType == "Admin") await this.doTransferToResource(this.state.money, this.transferReference); //Si se usa wallet metemos directamente al resource
        if (this.state.selAccount.moneyAccountType == "Paypal") this.CreatePaypalButtons();
    }

    async doTransferToResource(value) {
        await doTransferToResource(value, this.props.match.params.resource, this.props.match.params.id, this.transferReference);

        goBackUrl(this.props);
    }
}

export async function doTransferToResource(value, resource, resourceId, transferReference) {
    let data = {}
    if (resource == "banner" || resource == "sponsorship") {
        data = {
            "quantity": value,
            "advertiser": UserUtils.GetUserData().id,
            [resource]: resourceId,
        }
        data.transfertype = resource == "banner" ? "advertiserToBanner" : "advertiserToSponsorship";

        //if (resp.httpCode == 201) {
        //    this.props.history.goBack();
        //}
    } else if (resource == "wallet") {
        data = {
            "user": UserUtils.GetUserData().id,
            "quantity": value,
            "TransferDirection": 0,
            "Address": transferReference,
            "transfertype": "BankTransfer"
        }
    }

    let sb: ServicesBase = new ServicesBase("#server#" + "/Transfers");
    return await sb.ServiceRequest("", 'POST', { 'Content-Type': 'application/json' }, false, data);
}

export default connect(mapGenericStateToProps, null)(withRouter(TransactionForm));