
import React from 'react';

import './profile.styles.scss'
import { Grid, Avatar, Button, Box, TextField, Checkbox, Select, InputLabel, FormControl, FormControlLabel, Typography} from '@material-ui/core';
import {Save} from '@styled-icons/material'

import GlobalState from '../../store/globalState'
import UserUtils from '../../utils/userUtils'
import userUtils, { UserTypes } from '../../utils/userUtils'

import UserAPI from '../../services/api/users'
import Options from '../options/options.component'

import { OptionsToCheckRequired } from '../options/options.component'
import { RenderOptions } from '../ads-form/ads-form.component.jsx'

import { commService } from '../../services/api/list';
import { getAuthHeader } from '../../services/base';
import { CommInputField, CommForm, CommFormGen, confForm, ChangePasswordComponent, EndPointSelectorComponent, EndPointSelectorConf, ToggleButtonComm } from '../common/com-Form/comm-form.components'
import { CommButton, confCOM, confField, CommTextField, fieldNameFunction, fieldNameInOut, temporalItem, confGroup, strfieldvalue, fnfRequireText, EnumComboBox, strs as ComStrs, CommSimpleTextButton } from '../common/com'
import { CommItemOfItems, confAddItem, CommListGen, CommLinkButton, CommDeleteButtonLink, confListField, confList, CommList, CommItemField, CommItemTextField } from '../common/com-List/comm-list.components'
import { fileUrl } from '../../utils/fileUtils';

import { currencies, setCurrCurrency } from '../../utils/moneyUtils.jsx'

import { LangStrs, Lang, getUserLang } from '../../utils/languageUtils'

import ModalDialog, { ModalButtons } from '../../components/common/modal/modal'

import SelectUser, { UsersFilterTypes } from '../../components/admin/select-user/select-user.component.jsx'

import { StreamingAccountListAdmin } from '../streamingAccount/streamingAccount-toStream.components.jsx'

import ServicesBase from "../../services/base"

import { AgencyStatus } from "../../components/StreamersGroup/AgencyStreamers-list.component.jsx"

import CommHelpLink, { getDocUrl } from '../../structure/common/comHelpLink/comHelpLink.jsx'


const strs: LangStrs = new LangStrs(new Map(
    [
        ['TAccount', new Map(
            [
                [Lang.ES, 'Ajustes de cuenta'],
                [Lang.EN, 'Account settings']
            ])
        ],
        ['Avatar', new Map(
            [
                [Lang.ES, 'Avatar'],
                [Lang.EN, 'Avatar']
            ])
        ],
        ['Name', new Map(
            [
                [Lang.ES, 'Nombre'],
                [Lang.EN, 'Name']
            ])
        ],
        ['Username', new Map(
            [
                [Lang.ES, 'Nombre de usuario'],
                [Lang.EN, 'User name']
            ])
        ],
        ['State', new Map(
            [
                [Lang.ES, 'Estado'],
                [Lang.EN, 'State']
            ])
        ],
        ['EMail', new Map(
            [
                [Lang.ES, 'E-Mail'],
                [Lang.EN, 'E-Mail']
            ])
        ],
        ['Description', new Map(
            [
                [Lang.ES, 'Descripción'],
                [Lang.EN, 'Description']
            ])
        ],
        ['Birthdate', new Map(
            [
                [Lang.ES, 'Fecha de nacimiento'],
                [Lang.EN, 'Birthdate']
            ])
        ],
        ['Password', new Map(
            [
                [Lang.ES, 'Contraseña'],
                [Lang.EN, 'Password']
            ])
        ],
        ['PSettings', new Map(
            [
                [Lang.ES, 'Preferencias personales'],
                [Lang.EN, 'Personal settings']
            ])
        ],
        ['NIF', new Map(
            [
                [Lang.ES, 'NIF'],
                [Lang.EN, 'NIF']
            ])
        ],
        ['Country', new Map(
            [
                [Lang.ES, 'Pais'],
                [Lang.EN, 'Country']
            ])
        ],
        ['City', new Map(
            [
                [Lang.ES, 'Ciudad'],
                [Lang.EN, 'City']
            ])
        ],
        ['ZipCode', new Map(
            [
                [Lang.ES, 'Codigo Postal'],
                [Lang.EN, 'Zip Code']
            ])
        ],
        ['Address', new Map(
            [
                [Lang.ES, 'Dirección'],
                [Lang.EN, 'Address']
            ])
        ],
        ['Phone', new Map(
            [
                [Lang.ES, 'Numero de teléfono'],
                [Lang.EN, 'Phone Number']
            ])
        ],
        ['StreamsDesc', new Map(
            [
                [Lang.ES, 'Mis streams tratan de'],
                [Lang.EN, 'My streams are about']
            ])
        ],
        ['AudienceDesc', new Map(
            [
                [Lang.ES, 'Mi audiencia es'],
                [Lang.EN, 'My audience is']
            ])
        ],
        ['NoAds', new Map(
            [
                [Lang.ES, 'Anuncios que no quiero que aparezcan'],
                [Lang.EN, "Ads you don't want to allow in your stream"]
            ])
        ],
        ['StreamerDesc', new Map(
            [
                [Lang.ES, 'Mi Descripción'],
                [Lang.EN, 'My description']
            ])
        ],
        ['ECon', new Map(
            [
                [Lang.ES, 'Se a producido un error, inténtalo mas tarde'],
                [Lang.EN, 'An error happened while connecting to server, please try again later.']
            ])
        ],
        ['ChanAvatar', new Map(
            [
                [Lang.ES, 'Cambiar avatar'],
                [Lang.EN, 'Change avatar']
            ])
        ],
        ['Profile', new Map(
            [
                [Lang.ES, 'Perfil'],
                [Lang.EN, 'Profile']
            ])
        ],
        ['CHANGE_PASSW_OK', new Map(
            [
                [Lang.ES, 'Recibirás un correo electrónico para cambiar tu contraseña'],
                [Lang.EN, 'You will receive an email to change your password']
            ])
        ],
        ['NO_AGENT', new Map(
            [
                [Lang.ES, 'Esta ID de agencia no existe'],
                [Lang.EN, 'This agency ID does not exist']
            ])
        ],
        ['UnVerify', new Map(
            [
                [Lang.ES, 'Sin verificar'],
                [Lang.EN, 'UnVerify']
            ])
        ],
        ['Banned', new Map(
            [
                [Lang.ES, 'Baneado'],
                [Lang.EN, 'Banned']
            ])
        ],
        ['ToApprove', new Map(
            [
                [Lang.ES, 'Por aprobar'],
                [Lang.EN, 'ToApprove']
            ])
        ],
        ['Active', new Map(
            [
                [Lang.ES, 'Activo'],
                [Lang.EN, 'Active']
            ])
        ],
        ['LinkAgent', new Map(
            [
                [Lang.ES, 'Vincularme a una agencia'],
                [Lang.EN, 'Link me to an agency']
            ])
        ],
        ['MakeAgency', new Map(
            [
                [Lang.ES, 'Establecer como agencia'],
                [Lang.EN, 'Set as agency']
            ])
        ],
        ['RemoveAgency', new Map(
            [
                [Lang.ES, 'Eliminar estatus de agencia'],
                [Lang.EN, 'Remove agency status']
            ])
        ],
        ['Accept', new Map(
            [
                [Lang.ES, 'Aceptar'],
                [Lang.EN, 'Accept']
            ])
        ],
        ['BreakAgent', new Map(
            [
                [Lang.ES, 'Desvincular'],
                [Lang.EN, 'Break']
            ])
        ],
        ['TitleAgent', new Map(
            [
                [Lang.ES, 'Agencia a la que estás vinculado'],
                [Lang.EN, 'Agency with which you are associated']
            ])
        ],
        ['AgencyAds', new Map(
            [
                [Lang.ES, 'Solo usar anuncios de mi agencia'],
                [Lang.EN, 'Only use ads from my agency']
            ])
        ],
        ['AgencyNotApprovedYet', new Map(
            [
                [Lang.ES, 'La agencia aún no te ha aprobado'],
                [Lang.EN, 'The agency has not approved you yet']
            ])
        ],
        ['AgencyApproved', new Map(
            [
                [Lang.ES, 'Aprobado'],
                [Lang.EN, 'Approved']
            ])
        ],
        ['AccountActivationNotification', new Map(
            [
                [Lang.ES, `Informar de activación de cuenta`],
                [Lang.EN, `Account Activation Notification`]
            ])
        ],
        ['ActivationFrom', new Map(
            [
                [Lang.ES, `Nombre de quien ha configurado la cuenta`],
                [Lang.EN, `Name of the person who configured the account`]
            ])
        ],
        ['ActivationSend', new Map(
            [
                [Lang.ES, `Enviar correo de activación`],
                [Lang.EN, `Send Activation Email`]
            ])
        ],
        ['DailyCurrency', new Map(
            [
                [Lang.ES, `Moneda Diaria`],
                [Lang.EN, `Daily Currency`]
            ])
        ],

    ]
));

const isAdmin = () => UserUtils.GetUserData(true).usertype == UserTypes.Admin;
const isStreamer = () => UserUtils.GetUserData().usertype == UserTypes.Streamer;
const isAdvertiser = () => UserUtils.GetUserData().usertype == UserTypes.Advertiser;

class ProfileService extends commService {
    Update = async (userId, item, loading = true) => {
        let data = Object.assign({}, item.newData);
        data.state = data.stateId;
        return await (this.ServiceRequest("/" + UserUtils.GetUserData().id, 'PUT', {
            'Content-Type': 'application/json',
        }, loading, data, userId));
    };
};

const checkCountry = (n, cf) => {
    return n != "none";
    if (n == undefined || n == "none") {
        cf.tInvalidInfo = n == undefined ? "" : "field required";
        return false;
    }
    return true;
}

const ButtonCreateAgentAction = {
    INIT: 'Init',
    Created: 'CREATED',
    REINIT: 'REInit',
};
class ButtonCreateAgent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            action: ButtonCreateAgentAction.INIT
        }
    }

    render = () => {
        if (!isAdmin() || !isAdvertiser()) return (<></>)

        return (
            <>
                {((!this.props.item.isAgent && this.state.action == ButtonCreateAgentAction.INIT) || this.state.action == ButtonCreateAgentAction.REINIT) && <Button style={{}} className="profile-btn" size="large" color="secondary" variant="contained" onClick={(async () => { this.onCreateAgent() }).bind(this)}>{strs.get("MakeAgency")}</Button>}
                {((this.props.item.isAgent && this.state.action == ButtonCreateAgentAction.INIT) || this.state.action == ButtonCreateAgentAction.CREATED) && <Button style={{ "backgroundColor": "#b30000" }} className="profile-btn" size="large" color="secondary" variant="contained" onClick={(async () => { this.onDestroyAgent() }).bind(this)}>{strs.get("RemoveAgency")}</Button>}
            </>
        )
    }

    onCreateAgent = async () => {
        let r = await this.onChangeAdv(true);
        if (r.httpCode == 201) {
            this.AgencyName = r.body[0].AgencyName;
            this.setState({ action: ButtonCreateAgentAction.CREATED })
        }
    }
    onDestroyAgent = async () => {
        let r = await this.onChangeAdv(false);
        if (r.httpCode == 201) {
            this.setState({ action: ButtonCreateAgentAction.REINIT })
        }
    }
    onChangeAdv = async (value) => {
        let sb: ServicesBase = new ServicesBase("#server#" + "/users/" + UserUtils.GetUserData().id);
        return await sb.ServiceRequest("", 'PUT', { 'Content-Type': 'application/json' }, true, { isAgent: value });
    }
}

const ButtonAgentLinkAction = {
    INIT: 'Init',
    TYPE: 'type',
    WRITTED: 'writted',
    REINIT: 'REInit',
};

class ButtonAgentLink extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            action: ButtonAgentLinkAction.INIT
        }
    }

    render = () => {
        if (!isStreamer()) return (<></>)

        let parent: COMMComp = this.props.parentComm;
        let cItem = parent.getTemporalItem();
        
        return (
            <>
                {((!this.props.item.agencyId && this.state.action == ButtonAgentLinkAction.INIT) || this.state.action == ButtonAgentLinkAction.REINIT) && <Button style={{}} className="profile-btn" size="large" color="secondary" variant="contained" onClick={(async () => { this.setState({ action: ButtonAgentLinkAction.TYPE }) }).bind(this)}>{strs.get("LinkAgent")}</Button>}
                {this.state.action == ButtonAgentLinkAction.TYPE &&
                    <Box display="flex" justifyContent="space-between">
                        <TextField 
                        className="form-input"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        id="name-input" label={'Agency id'}
                        variant="outlined"
                        onChange={this.onDataChange}
                        />
                    <Button style={{ marginLeft: "10px" }} className="profile-btn" size="large" color="secondary" variant="contained" onClick={(async () => { this.onLink() }).bind(this)}>{strs.get("Accept")}</Button>
                    </Box>
                }
                {((this.props.item.agencyId && this.state.action == ButtonAgentLinkAction.INIT) || this.state.action == ButtonAgentLinkAction.WRITED) &&
                    <Box >
                        <Box display="flex" justifyContent="space-between">
                            <TextField
                            className="form-input"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            id="name-input" label={strs.get('TitleAgent')}
                            variant="outlined"
                            onChange={this.onDataChange}
                            disabled
                            value={`${this.props.item.AgencyName || this.AgencyName}`}
                            helperText={strs.get(parent.getItem().agencyState == AgencyStatus.Approved ? "AgencyApproved" : "AgencyNotApprovedYet")}
                            />
                            <Button style={{ marginLeft: "10px", "backgroundColor": "#b30000" }} className="profile-btn" size="large" color="secondary" variant="contained" onClick={(async () => { this.onUnLink() }).bind(this)}>{strs.get("BreakAgent")}</Button>
                        </Box>                    
                        <FormControlLabel
                        control={<Checkbox onChange={this.AgencyAdsChange.bind(this)} checked={cItem.agencyAds === true ? true : false} />}
                        label={strs.get('AgencyAds')}
                    />
                    {parent.getItem().newData?.agencyAds != undefined && <Typography variant="caption">{ComStrs.get('NOTSAVED')}</Typography>}
                    </Box>
                }
            </>
        )
    }

    AgencyAdsChange(ev) {
        let parent: COMMComp = this.props.parentComm;
        parent.addData({ agencyAds: ev.target.checked});
    }

    onDataChange = (e) => {
        this.value = e.target.value;
    }

    onLink = async () => {
        let r = await this.onChangeAgency(this.value);
        if (r.httpCode == 201) {
            this.AgencyName = r.body[0].AgencyName;
            this.setState({ action: ButtonAgentLinkAction.WRITED })
        } else {
            const confField: confField = this.props.confFieldItem.confField;
            confField.fieldprops.modalNoAgency();
        }
    }
    onUnLink = async () => {
        let r = await this.onChangeAgency("");
        if (r.httpCode == 201) {
            this.setState({ action: ButtonAgentLinkAction.REINIT })
        }
    }
    onChangeAgency = async (value) => {
        let sb: ServicesBase = new ServicesBase("#server#" + "/users/" + UserUtils.GetUserData().id);
        return await sb.ServiceRequest("", 'PUT', { 'Content-Type': 'application/json' }, true, { agency: value });
    }
}

export class RequestActiveAccountModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render = () => {
        this.confForm = [
            new confField(strs.get("ActivationFrom"), CommTextField, {}, fnfRequireText("requestFrom"), this.state.from),
        ];

        return (
            <>
                <ModalDialog ref={(m) => { this.modal = m; }} windowsSyle={true} btnNOVisible={false} btnOKVisible={false}>
                    <CommForm ref={(c) => this.form = c} title="" customSubmitButton={ModalButtons(true)} submitButtonTitle={strs.get("ActivationSend")} confComm={new confForm(CommFormGen, new commService(`/users/requestActivateAccount`), this.confForm)} OnSave={() => { this.setOpen(false); }} BeforeSaveData={() => { this.form.addData({ user:this.user  }); return true; }} />
                </ModalDialog>
            </>
        );
    }

    setOpen(open, user, from) {
        this.user = user;
        this.setState({ from: from });
        this.from = from;
        
        this.modal.setOpen(open);
        if(!open)
            this.props.OnSubmit && this.props.OnSubmit();
    }
}

class Profile extends React.Component {
    optionsToCheckRequired: OptionsToCheckRequired;


    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            country:"none"
        }        
        this.confListFieldState = new confListField("State", EndPointSelectorComponent, {}, "stateId").addField("epsConf", new EndPointSelectorConf("/utils/state/" + this.state.country));

        const enumValues = [{ label: strs.get('UnVerify'), value: 0 }, { label: strs.get('Active'), value: 1 }, { label: strs.get('Banned'), value: 2 }, { label: strs.get('ToApprove'), value: 3 }];        

        const enumCurrencies = currencies.map(c => { return { label: c.name, value: c.abbreviation } });

        const fnfRequireTextOrAdmin = (name) => isAdmin() ? name : fnfRequireText(name);

        this.conf = [
            new confGroup(strs.get('TAccount'), 0, [                
                new confListField(strs.get('Avatar'), AvatarComponent, {}, "Avatar"),
                new confListField(strs.get('Name'), CommTextField, {}, fnfRequireTextOrAdmin("name")),
                new confListField(strs.get('Username'), CommTextField, {}, fnfRequireText("username")),
                new confListField(strs.get('State'), EnumComboBox, { enumValues: enumValues }, "userState").addField("Condition", (item) => isAdmin() ),
                new confListField(strs.get('EMail'), CommTextField, { disabled: "true" }, fnfRequireText("email")),
                new confListField(strs.get('Description'), CommTextField, { multiline: true, fullWidth: true, rows: 4 }, "description"),
                new confListField(strs.get('Birthdate'), CommTextField, { type: "date", InputLabelProps: { shrink: true } }, "birthDate"),
                new confListField(strs.get('DailyCurrency'), EnumComboBox, { enumValues: enumCurrencies }, "currency"),
                new confListField(strs.get('AccountActivationNotification'), CommSimpleTextButton, {}, strs.get('AccountActivationNotification')).addField("OnClick", async (item) => { this.requestActivate.setOpen(true, item.email, item.AgencyName || 'PowerAds'); }).addField("Condition", (item) => isAdmin() && !(item.userState == 1 || item.userState == 2)),
                new confListField(strs.get('Password'), ChangePasswordComponent, { style: { paddingTop: "250px" } }, "password").addField("onRequestDone", this.OnRequestChangePasswordDone.bind(this)),
            ]),
            new confGroup(strs.get('PSettings'), 1, [
                new confListField(strs.get('NIF'), CommTextField, {}, "nif"),
                new confListField(strs.get('Country'), EndPointSelectorComponent, {}, new fieldNameFunction(new fieldNameInOut("countryCode", "country"), checkCountry)).addField("epsConf", new EndPointSelectorConf("/utils/country", "code")),
                //this.confListFieldState,
                new confListField(strs.get('City'), CommTextField, {}, "city"),
                new confListField(strs.get('ZipCode'), CommTextField, {}, "zipCode"),
                new confListField(strs.get('Address'), CommTextField, {}, "address"),
                new confListField(strs.get('Phone'), CommTextField, {}, "phoneNumber"),
                new confListField('agency', ButtonAgentLink, { modalNoAgency: this.showNoAgency }, "agency"),
                new confListField('agency', ButtonCreateAgent, {}, "agency"),                
            ])
        ];

        this.optionsToCheckRequired = !isAdmin() ? new OptionsToCheckRequired() : undefined;
    }

    componentDidMount = () => {
        GlobalState.set("loading", true);
    }

    reloadData = () => {
        this.profileComp.QueryItem();
    }

    showNoAgency = () => {
        this.modalNoAgent.showAndGetResult();
    }

    render() {        
        this.confListFieldState.epsConf = new EndPointSelectorConf("/utils/state/" + this.state.country);

        return (
            <Grid container className={"profile "} direction="column">
                <SelectUser filter={UsersFilterTypes} OnSelectUser={async (user) => { await this.setState({ user: user }); this.reloadData(); }} />
                <ModalDialog ref={(m) => { this.modalChangePassword = m; }} btnNOVisible={false}>
                    {strs.get('CHANGE_PASSW_OK')}
                </ModalDialog>
                <ModalDialog ref={(m) => { this.modalNoAgent = m; }} btnNOVisible={false}>
                    {strs.get('NO_AGENT')}
                </ModalDialog>
                <RequestActiveAccountModal ref={(r) => this.requestActivate = r}  />
                <CommForm ref={(t) => this.profileComp = t} title={<>{strs.get('Profile')}{UserUtils.GetUserData().usertype == UserTypes.Streamer && <CommHelpLink infoUrl={getDocUrl("como-completar-mi-informacion-personal")} />}</>} confComm={new confForm(CommFormGen, new ProfileService("/users"), this.conf, UserUtils.GetUserData().id)} Owner={this} OnDataChange={this.OnProfileChangeData.bind(this)} OnSave={(i) => { this.OnProfileSave(i) }} OnChange={async (comp) => await this.OnCompChange(comp)} BeforeSaveData={() => UserUtils.GetUserData(true).usertype == UserTypes.Admin || this.optionsToCheckRequired.checkRequeriments()} />
                { UserUtils.GetUserData().usertype == UserTypes.Streamer && isAdmin() &&
                    <Grid className="profile-block" container direction="row">
                        <StreamingAccountListAdmin/>
                    </Grid>
                }
                { UserUtils.GetUserData().usertype == UserTypes.Streamer &&
                    <Grid className="profile-block" container direction="row">
                        <Grid container xs={12} direction="column">
                            <h2>{strs.get('StreamerDesc')}</h2>
                        </Grid>
                        <Grid container xs={12} direction="column">
                            <Options optionsToCheckRequiredref={this.optionsToCheckRequired} title={strs.get('StreamsDesc')} uriOptions={"/streamers/description"} uriSel={"/streamers/:id/streamerDescription"} filterType={"typeStreams"} />
                            <Options optionsToCheckRequiredref={this.optionsToCheckRequired} title={strs.get('AudienceDesc')} uriOptions={"/streamers/description"} uriSel={"/streamers/:id/streamerDescription"} filterType={"typeAudience"} />
                            <Options optionsToCheckRequiredref={this.optionsToCheckRequired} title={strs.get('NoAds')} uriOptions={"/advertisers/adType"} uriSel={"/streamers/:id/bannerFilter"} filterType={""} excludeOnSelection={true} noCheckRequerminet="true" />
                        </Grid>
                    </Grid>
                }
                { UserUtils.GetUserData().usertype == UserTypes.Advertiser && isAdmin() &&
                    <Grid className="profile-block" container direction="row">
                        {RenderOptions(this, 0)}
                    </Grid>
                }
            </Grid>
        );
    }

    async OnCompChange(comp) {
        if (comp.state.item != null && this.state.country == "none" && comp.state.item.country != undefined && this.state.country != comp.state.item.country) {
            await this.setState({ country: comp.state.item.country });
        }
    }

    async OnProfileChangeData(comp, ndata) {
        if (comp.props.confFieldItem.confField.fieldName == "country") {
            await this.setState({ country: ndata });
        }        
    }

    async OnRequestChangePasswordDone() {
        await this.modalChangePassword.showAndGetResult();
    }

    OnProfileSave(item) {
        setCurrCurrency(item.currency);
    }
}

export class AvatarComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = { avatar: null };
    }

    render = () => {
        let parent: COMMComp = this.props.parentComm;
        const confFieldItem: confFieldItem = this.props.confFieldItem;
        const confField: confField = confFieldItem.confField;
        return (
            <Grid container direction="row" alignItems="center">
                <Avatar className="avatar" alt={this.props.item.name} src={this.getAvatarUrl()} />
                <label className="upload-ad-media" htmlFor="upload-photo">
                    <input style={{ display: "none" }} id="upload-photo" name="upload-photo" value={this.props.item.media} onChange={(e) => {this.OnImageChange(e)}} type="file" />
                        <Button color="secondary" variant="contained" component="span">
                            {strs.get('ChanAvatar')}
                        </Button>
                </label>
            </Grid>
        )
    }

    getAvatarUrl() {
        let url:string = this.props.item.avatar;
        if (this.state.avatar != null) url = this.state.avatar;
        return fileUrl(url);
    }

    OnImageChange = async (event) => {
        if (event.target.files && event.target.files[0]) {
            //let reader = new FileReader();
            //reader.onload = (e) => {
            //    this.setState({ selectedImage: e.target.result });
            //};
            //reader.readAsDataURL();

            const formData = new FormData();
            formData.append("media", event.target.files[0]);

            const options = {                
                method: "POST",
                body: formData
            };
            let response, body, error;
            response = await fetch("/api/users/" + UserUtils.GetUserData().id + "/avatar", options);

            try {
                let text = await response.text();
                response.status < 400 ? (body = text.length != 0 ? JSON.parse(text) : "") : (error = JSON.parse(text));
                if (response.status == 200) {
                    await this.setState({ avatar: body.avatar });
                }
            }
            catch (e) {
                error = { error: strs.get('ECon') };
            }
        }
    }
}

export default Profile;