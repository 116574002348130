import ServicesBase from "../../base"

class Ads extends ServicesBase {
  constructor() {
      super("#server#" + "/advertisers/:id/advertisement");
  }
  Add = async (userId, data, loading = true) => {
      var form_data = new FormData();
      Object.keys(data).forEach( key => {
          form_data.append(key, data[key]);
      });
      for (var key of form_data.entries()) {
        console.log(key[0] + ', ' + key[1])
      }
      form_data.append("caca", "devaca");
      for (var key of form_data.entries()) {
        console.log(key[0] + ', ' + key[1])
      }
      return await this.ServiceRequest("/", 'POST', null,
        loading,
        form_data,
        userId
      );
  };
  Update = async (userId, Adid, data, loading = true) => {
    return await (this.ServiceRequest("/"+Adid, 'PUT', {
      'Content-Type': 'application/json',
    }, loading, data, userId));
  };
  Get = async (userId, bannerId = false, loading = true) => {
    return await (this.ServiceRequest(bannerId ? "/"+bannerId : "", 'GET', {}, loading, null, userId));
  };
  Delete = async (userId, bannerId = false, loading = true) => {
    return await (this.ServiceRequest(bannerId ? "/"+bannerId : "", 'DELETE', {}, loading, null, userId));
  };

  GetBannerTypes = async (userId, bannerId, loading = true) => {
    return await this.ServiceRequest("/"+bannerId+"/adType", 'GET', {
        'Content-Type': 'application/json',
      },
      loading,
      null,
      userId
    );
  };

  AddBannerType = async (userId, bannerId, bannerTypeId, optionId, loading = true) => {
    return await this.ServiceRequest("/"+bannerId+"/adType", 'POST', {
        'Content-Type': 'application/json',
      },
      loading,
      {
        bannerType: bannerTypeId,
        selectOption: optionId,
      },
      userId
    );
  };
  RemoveBannerType = async (userId, bannerId, bannerTypeId, loading = true) => {
    return await this.ServiceRequest("/"+bannerId+"/adType/"+bannerTypeId, 'DELETE', {
        'Content-Type': 'application/json',
      },
      loading,
      null,
      userId
    );
  };

  GetStreamerDescription = async (userId, bannerId, loading = true) => {
    return await this.ServiceRequest("/"+bannerId+"/streamerDescription", 'GET', {
        'Content-Type': 'application/json',
      },
      loading,
      null,
      userId
    );
  };

  AddStreamerDescription = async (userId, bannerId, bannerTypeId, optionId, loading = true) => {
    return await this.ServiceRequest("/"+bannerId+"/streamerDescription", 'POST', {
        'Content-Type': 'application/json',
      },
      loading,
      {
        streamDescription: bannerTypeId,
        selectOption: optionId,
      },
      userId
    );
  };
  RemoveStreamerDescription = async (userId, bannerId, bannerTypeId, loading = true) => {
    return await this.ServiceRequest("/"+bannerId+"/streamerDescription/"+bannerTypeId, 'DELETE', {
        'Content-Type': 'application/json',
      },
      loading,
      null,
      userId
    );
  };
  
};

export default new Ads();