import React from 'react';

import { Button} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import { changeUrl, changeUrlByReact, Urls } from '../../../utils/routerUtils'
import ListIcon from '@material-ui/icons/List';
import DescriptionIcon from '@material-ui/icons/Description';
import { IconFromSVG } from '../../../components/common/commonComponents.jsx'
import { CommListSimpleTextButton, CommButtonSave, CommItemOfItems, confAddItem, CommListGen, CommLinkButton, CommDeleteButtonLink, confListField, confList, CommList, CommItemField, CommItemTextField, CommItemFieldTextLink, CommListFlat, CommItemTextLink, CommItemListCountDown } from '../com-List/comm-list.components'
import { CommButton, confCOM, confField, CommTextField, fieldNameFunction, temporalItem, fnfRequireText, CommDateTimePicker, InputSource, CommSimpleTextButton, fnfRequireNumber, FileButtonComm } from '../com'
import { NotificationBadget } from '../../../components/common/commonComponents'
import { NotificationEntityType } from '../../../store/sagas/notifications'

import { LangStrs, Lang } from '../../../utils/languageUtils'

export let strs: LangStrs = new LangStrs(new Map(
    [
        ['SDeliveryStates_Delivered', new Map(
            [
                [Lang.ES, 'Pendiente'],
                [Lang.EN, 'Pending']
            ])
        ],
        ['SDeliveryStates_Accepted', new Map(
            [
                [Lang.ES, 'Aceptado'],
                [Lang.EN, 'Accepted']
            ])
        ],
        ['SDeliveryStates_Rejected', new Map(
            [
                [Lang.ES, 'No aprobado'],
                [Lang.EN, 'Not Approved']
            ])
        ],
        ['SelBrandedCreators', new Map(
            [
                [Lang.ES, 'Seleccionar creadores de contenido'],
                [Lang.EN, 'Select Content Creators']
            ])
        ],
        ['ViewCampaigns', new Map(
            [
                [Lang.ES, 'Ver campañas en marcha'],
                [Lang.EN, 'View Ongoing Campaigns']
            ])
        ],
        ['DateLimit', new Map(
            [
                [Lang.ES, 'Fecha de Expiración'],
                [Lang.EN, 'Expiration Date']
            ])
        ],
        ['CollaborationTimes', new Map(
            [
                [Lang.ES, 'Número de Colaboraciones'],
                [Lang.EN, 'Number of Collaborations']
            ])
        ],
        ['moneyPerCollaboration', new Map(
            [
                [Lang.ES, 'Recompensa por Colaboración'],
                [Lang.EN, 'Reward per Collaboration']
            ])
        ],
        ['RejectByStreamer', new Map(
            [
                [Lang.ES, 'Cancelado porque el creador de contenido ha realizado una actualización'],
                [Lang.EN, 'Cancelled because the content creator has made an update']
            ])
        ],
        ['RejectNoValid', new Map(
            [
                [Lang.ES, 'Cancelado porque no se ha podido validar el contenido en la red social'],
                [Lang.EN, 'Cancelled because the content could not be validated on the social network']
            ])
        ],
        ['SelBrandedCreators', new Map(
            [
                [Lang.ES, 'Seleccionar creadores de contenido'],
                [Lang.EN, 'Select Content Creators']
            ])
        ],
        ['SelBrandedCreatorsNoInfo', new Map(
            [
                [Lang.ES, 'Completa la información para poder seleccionar'],
                [Lang.EN, 'Complete the information to be able to select']
            ])
        ],
        ['BrandedDesc', new Map(
            [
                [Lang.ES, 'Ir a descripción'],
                [Lang.EN, 'Go to description']
            ])
        ],
        ['SectionChat', new Map(
            [
                [Lang.ES, 'Chat'],
                [Lang.EN, 'Chat']
            ])
        ],
        ['InfoRejectReason', new Map(
            [
                [Lang.ES, 'Motivo de desestimación'],
                [Lang.EN, 'Reason for Rejection']
            ])
        ],
    ]
));

export const ContractStates = {
    Offer: 0,
    Canceled: -1,
    StreamerAccepted: 1,
    Done: -2,
    Expired: -3,
    CanceledByStreamer: -4,
};

export const SDeliveryStates = {
    Delivered : 0,
    Accepted : 1,
    Rejected : 2,
};

export const SDeliveryStatesToStr = (state) => {
    const sstrs = [strs.get('SDeliveryStates_Delivered'), strs.get('SDeliveryStates_Accepted'), strs.get('SDeliveryStates_Rejected')];
    return sstrs[state];
}

export const SponsorshipType = {
    SponsoredContent : 0,
    SponsoredSegment : 1
};

const Diversity3Icon = IconFromSVG(<><path fill="currentColor" d="M6.32 13.01c.96.02 1.85.5 2.45 1.34A3.961 3.961 0 0 0 12 16c1.29 0 2.5-.62 3.23-1.66c.6-.84 1.49-1.32 2.45-1.34c-.72-1.22-3.6-2-5.68-2c-2.07 0-4.96.78-5.68 2.01zM4 13c1.66 0 3-1.34 3-3S5.66 7 4 7s-3 1.34-3 3s1.34 3 3 3zm16 0c1.66 0 3-1.34 3-3s-1.34-3-3-3s-3 1.34-3 3s1.34 3 3 3zm-8-3c1.66 0 3-1.34 3-3s-1.34-3-3-3s-3 1.34-3 3s1.34 3 3 3z" /><path fill="currentColor" d="M21 14h-3.27c-.77 0-1.35.45-1.68.92c-.04.06-1.36 2.08-4.05 2.08c-1.43 0-3.03-.64-4.05-2.08c-.39-.55-1-.92-1.68-.92H3c-1.1 0-2 .9-2 2v4h7v-2.26c1.15.8 2.54 1.26 4 1.26s2.85-.46 4-1.26V20h7v-4c0-1.1-.9-2-2-2z" /></>, "0 0 24 24");

export const NotificationBadgetViewCampaignsContent = (item) => <NotificationBadget notificationKey={[NotificationEntityType.SponsorshipContractAcceped, NotificationEntityType.SponsorshipContractMsg, NotificationEntityType.SponsorshipDeliveryNew, NotificationEntityType.SponsorshipDeliveryValidated]} notificationId2={item.ContractsList} color="secondary" style={{ top: -20, right: -15 }} />

export const ViewCampaignsButtonContent = (item) => {
    return <><Diversity3Icon width={24} height={24} style={{ marginRight: '8px' }} />{strs.get('ViewCampaigns')}{NotificationBadgetViewCampaignsContent(item)}</>
}
export const SelBrandedCreatorsButtonContent = <><GroupAddIcon style={{ marginRight: '8px' }} />{strs.get('SelBrandedCreators')}</>

export const MoveSponsorButtons = (props, item, itemid, from, cantSelCreators) => {
    const contracts = item ? item.Contracts : false;
    return <div>
        {(from == "fromDescription" || from == "fromSelectStreamers") && contracts &&
            <Button variant="contained" size="large" color="primary" onClick={(e) => { changeUrlByReact(props, Urls.AdvBrandedOnGoing + itemid); }} style={{ marginRight: '8px' }}>
                {ViewCampaignsButtonContent(itemid)}
            </Button>            
        }
        {(from == "fromDescription" || from == "fromContracts") && 
            <Tooltip title={!cantSelCreators ? "" : strs.get('SelBrandedCreatorsNoInfo')}>
                <Button variant="contained" size="large" color="primary" onClick={(e) => { changeUrlByReact(props, Urls.AdvBrandedStreamers + itemid); }} disabled={cantSelCreators} style={{ marginRight: '8px' }}>
                    {SelBrandedCreatorsButtonContent}
                </Button>
            </Tooltip>
        }
        {(from == "fromSelectStreamers" || from == "fromContracts") &&
            <Button variant="contained" size="large" color="primary" onClick={(e) => { changeUrlByReact(props, Urls.AdvBrandedEdit + itemid); }}>
                <DescriptionIcon style={{ marginRight: '8px' }} />
                {strs.get('BrandedDesc')}
            </Button>
        }
    </div>    
}

export const showReject = (reject) => {
    if (reject == "BYSTREAMER") return <p>{strs.get('RejectByStreamer')}</p>
    else if (reject == "NOVALIDOREXPIRED") return <p>{strs.get('RejectNoValid')}</p>
    else return <p>{strs.get("InfoRejectReason")}: {reject}</p>
}

export const confDateLimitInList = () => new confField(strs.get('DateLimit'), CommItemListCountDown, { remainingMsg: "", passDateMsg: "", shortText: true }, (item) => item.state < 0 ? -1 : item.dateLimit);
export const confCollaborationsInList = () => new confField(strs.get('CollaborationTimes'), CommItemField, { className: "list-field bi-name" }, (i) => `${i.collaborationTimesDone} / ${i.collaborationTimes}`);