import React from 'react';
import { Grid } from '@material-ui/core';
import { CommListSimpleTextButton, CommButtonSave, CommItemOfItems, confAddItem, CommListGen, CommLinkButton, CommDeleteButtonLink, confListField, confList, CommList, CommItemField, CommItemTextField, CommItemFieldTextLink, CommListFlat, CommItemTextLink, CommItemListCountDown, CommListCheckBox, ListFilesComp } from '../../structure/common/com-List/comm-list.components'
import { CommInputField, CommForm, CommFormGen, confForm, RadioButtonList, CommRadioButtonList, BroadCastTimePickerComm } from '../../structure/common/com-Form/comm-form.components'
import { CommButton, confCOM, confField, CommTextField, fieldNameFunction, temporalItem, fnfRequireText, CommDateTimePicker, CommSimpleTextButton, fnfRequireNumber, FileButtonComm, checkMailField, CommCheckBox, InputSource } from '../../structure/common/com'
import UserUtils from '../../utils/userUtils'
import { commService } from '../../services/api/list';
import MessageCard from './message-card.component.jsx'
import AttachFileIcon from '@material-ui/icons/AttachFile';
import SendIcon from '@material-ui/icons/Send';
import { strs as upfStrs, FileButton, PostFileButton } from '../../components/common/commonUpFile.jsx'
import { LangStrs, Lang } from '../../utils/languageUtils'
import ModalDialog, { ModalAlert, ModalButtons, StyledButtons } from '../../components/common/modal/modal'

export let strs: LangStrs = new LangStrs(new Map(
    [
        ['SelectFile', new Map(
            [
                [Lang.ES, 'Seleccionar archivo'],
                [Lang.EN, 'Select File']
            ])
        ],
        ['SendMsg', new Map(
            [
                [Lang.ES, 'Enviar mensaje'],
                [Lang.EN, 'Send message']
            ])
        ],
        ['message', new Map(
            [
                [Lang.ES, 'Nuevo mensaje'],
                [Lang.EN, 'New message']
            ])
        ],
    ]
));

export class Chat extends React.Component {
    constructor(props) {
        super(props);
        this.state = { toUpFiles: [] };

        this.confNewMsg = [
            new confField(strs.get('message'), CommTextField, { className: "list-field bi-name", AutoSize: true }, "message", "").addField("rows", 4),
        ];

        this.confChatDialeg = [
            new confListField("", CommItemField, { className: "list-field bi-name" }, ""),
        ];
    }

    async sendFiles(item) {
        const files = this.state.toUpFiles.filter(f => f.deleted !== false);
        this.postFileComp.postUrl = this.props.postResourcesUrl.replace(':idMsg', item.id);
        for (let i = 0; i < files.length; i++) {
            if (files[i].deleted !== true)
                await this.sendFile(files[i]);
        }
        this.chatList.QueryItems();
    }

    async sendFile(file) {
        await this.postFileComp.postFile(file);
        file.deleted = true;
        await this.setState({ toUpFiles: this.state.toUpFiles });
    }

    OkButton = ModalButtons(true);
    CancelButton = ModalButtons(false);

    UpButton = StyledButtons('#009CDD', '#8CD0F1');

    render() {
        return (
            <>
                <Grid child container direction="row" xs="12" style={{ width: "100%" }}>
                    <CommList key={this.props.messagesUrl} ref={(c) => this.chatList = c} confComm={new confList(CommListGen, new commService(this.props.messagesUrl), this.confChatDialeg)} ItemList={MessageCard} headers={false}/>
                </Grid>
                <Grid child container direction="row" xs="12" style={{ width: "100%" }}>
                    <CommForm ref={(r) => this.newMSGForm = r} confComm={new confForm(CommFormGen, new commService(this.props.messagesUrl), this.confNewMsg)} OnNewItem={(item) => { this.sendFiles(item); }} submitButtonTitle={
                        <div style={{ display: 'grid', gridTemplateColumns: 'auto 1fr', alignItems: 'center' }}>
                            <SendIcon style={{ padding: "10px" }} />
                            {strs.get("SendMsg")}
                        </div>}
                    />
                    <InputSource buttonProps={this.props.buttonProps} filetype={this.props.filetype || "*/*"} OnSelect={async (comp, file, props, error) => { if (error != undefined) { this.setState({ formCorrect: false }); await this.setState({ error: strs.get('ETooSize') }); } else { this.state.toUpFiles.push(file); await this.setState({ toUpFiles: this.state.toUpFiles, error: null }); } }}>
                        <this.UpButton style={{ marginTop: "10px", padding: "10px" }} startIcon={<AttachFileIcon />}>{strs.get("SelectFile")}</this.UpButton>
                    </InputSource>
                    <PostFileButton ref={(r) => this.postFileComp = r} showUploaded={false}
                        customFormData={(formData, file) => {
                            formData.append("media", file);
                            return formData;
                        }}
                        ><></>
                    </PostFileButton>
                    {
                        this.state.toUpFiles.map((f) => {
                            return f.deleted!==true && <FileButton fileName={f.name} canDelete={true} canDownload={false} style={{ maxHeight: "40px", marginTop: "10px", marginRight: "10px" }} onDelete={() => { f.deleted = true; this.setState({ toUpFiles: this.state.toUpFiles }); }}/>
                        })
                    }                            
                </Grid>
            </>
        );
    }
}