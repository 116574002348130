import React from 'react';

import './account-card.styles.scss';
import ErrorWarnTheme from '../../styles/ErrorWarnTheme';
import {MuiThemeProvider} from '@material-ui/core/'
import {Grid, CardContent, Card, Typography, CardActions, Button} from '@material-ui/core'

const StreamCard = (props) => {
    return (
    <Card className={'account-card ' + props.className}>
        <CardContent>
            <div className="sc-figure">
                <img src={props.service.image} alt="Service image" title={props.service.name}></img>
            </div>
            <div className="sc-desc">
                <Typography color="textSecondary" variant="h5" component="h1" gutterBottom>
                    {props.service.name}
                </Typography>
                { props.account &&
                    (<React.Fragment>
                        <Typography component="h2">
                            Registered as <strong>{props.account.name}</strong>
                        </Typography>
                        <MuiThemeProvider theme={ErrorWarnTheme}>
                            <Button className="accountBtn" color="primary" variant="contained" onClick={() => {props.OnUnlink(props.account)}}>Unlink</Button>
                        </MuiThemeProvider>
                    </React.Fragment>)
                }
                { !props.account &&
                    <Button color="primary" className="accountBtn" variant="contained">Link account</Button>
                }
            </div>
        </CardContent>
    </Card>
    )
}

export default StreamCard;