
import React from 'react';
import {Route} from 'react-router-dom'

import BannerList from '../../structure/banner-list/banner-list.components'
import BannerForm from '../../structure/banner-form/banner-form.component'

import { bannerStrs } from '../../templates/languages/banner-strs.js'

const BannersRouter = (props) => (    
    <React.Fragment>
        <Route exact path='/dashboard/banners/add/' component={() => <BannerForm title={bannerStrs.get("TNewBanner")}></BannerForm>} />
        <Route exact path='/dashboard/banners/edit/:id' component={() => <BannerForm title={bannerStrs.get("TEditBanner")}></BannerForm>} />
        <Route exact path='/dashboard/banners/' component={() => <BannerList title={bannerStrs.get("Banners")}></BannerList>} />
    </React.Fragment>
)

export default BannersRouter;