
import React from 'react';

import './profile-admin.styles.scss'
import {Grid, Avatar, Button, Box, TextField, Select, InputLabel, FormControl} from '@material-ui/core';
import {Save} from '@styled-icons/material'

import GlobalState from '../../store/globalState'
import UserUtils from '../../utils/userUtils'
import { UserTypes } from '../../utils/userUtils'

import UserAPI from '../../services/api/users'
import Options from '../options/options.component'

import { OptionsToCheckRequired } from '../options/options.component'

import { commService } from '../../services/api/list';
import { getAuthHeader } from '../../services/base';
import { CommInputField, CommForm, CommFormGen, confForm, ChangePasswordComponent, EndPointSelectorComponent, EndPointSelectorConf } from '../common/com-Form/comm-form.components'
import { CommButton, confCOM, confField, CommTextField, fieldNameFunction, fieldNameInOut, temporalItem, confGroup, strfieldvalue, fnfRequireText } from '../common/com'
import { CommItemOfItems, confAddItem, CommListGen, CommLinkButton, CommDeleteButtonLink, confListField, confList, CommList, CommItemField, CommItemTextField } from '../common/com-List/comm-list.components'
import { fileUrl } from '../../utils/fileUtils';

import { LangStrs, Lang, getUserLang } from '../../utils/languageUtils'

import ModalDialog from '../../components/common/modal/modal'

import Profile from '../profile/profile.component.jsx'
import SelectUser, { UsersFilterTypes } from '../../components/admin/select-user/select-user.component.jsx'

class ProfileAdmin extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            user: null
        }
    }

    render() {
        return (
            <React.Fragment>
                <SelectUser filter={UsersFilterTypes} OnSelectUser={async (user) => { await this.setState({ user: user }); if (this.profileComp) this.profileComp.reloadData(); }} />
                { this.state.user && <Profile ref={(p) => this.profileComp = p} user={this.state.user} />}
            </React.Fragment>
        );
    }
}

export default ProfileAdmin;