
import React from 'react';

import './accounts-list.styles.scss'
import {Grid} from '@material-ui/core';
import DeleteItemDialog from '../common/deleteItemDialog/deleteItemDialog.component.jsx';
import AccountCard from '../account-card/account-card.component.jsx';

class AccountsList extends React.Component {    
    constructor(props) {
        super(props);
        this.state = {
            accounts: [
                {
                    id: 1, 
                    name: "xaviscript", 
                    email:"xaviscript@xs.com",
                    service: "twitch"
                },
                {
                    id: 2, 
                    name: "xaviscript2", 
                    email:"xaviscript2@xs.com",
                    service: "twitch"
                }
            ],
            services: {
                twitch: {
                    name: "Twitch",
                    image: "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fwww.twitch.tv%2Fp%2Fassets%2Fuploads%2Fcombologo_474x356.png&f=1&nofb=1"
                },
                youtube: {
                    name: "YouTube",
                    image: "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fcdn.pixabay.com%2Fphoto%2F2016%2F07%2F03%2F18%2F36%2Fyoutube-1495277__340.png&f=1&nofb=1"
                }
            },
            accountToDelete: null
        }
    }

    OnUnLink (acc, svc) {
        this.setState({
            accountToDelete: acc
        });
    }

    DialogDelete () {
        let accounts = this.state.accounts;
        let indexToDelete;
        for(let i = 0; i < accounts.length; i++) {
            if(accounts[i].id == this.state.accountToDelete.id) {
                indexToDelete = i;
                break;
            }
        }
        accounts.splice(indexToDelete,1);
        this.setState({accounts: accounts});
        this.setState({accountToDelete: null});
    }

    render() {
        return <React.Fragment>
            <Grid container className={"user-account-list " + this.props.className} direction="column">
            <h1>{this.props.title}</h1>     
            <Grid container direction="column">
                <h2>Add new account</h2>
                <Grid container direction="row">
                {Object.keys(this.state.services).map((svc) => {
                    return <AccountCard className="to-link" service={this.state.services[svc]} OnUnlink={this.OnUnLink.bind(this)} />
                })}
                </Grid>
                <h2>Linked Accounts</h2>
                <Grid container direction="row">
                {this.state.accounts.map((acc) => {
                    return <AccountCard service={this.state.services[acc.service]} account={acc} OnUnlink={this.OnUnLink.bind(this)} />
                })}
                </Grid>
            </Grid>
        </Grid>
        {this.state.accountToDelete && <DeleteItemDialog title={"Unlinking " + this.state.accountToDelete.service.toUpperCase() + " account '" + (this.state.accountToDelete.name ? this.state.accountToDelete.name : "")  + "'"} onDelete={this.DialogDelete.bind(this)} onCancel={(()=>{this.setState({accountToDelete: null})}).bind(this)} />}
        </React.Fragment>
    }
}

export default AccountsList;