import React from 'react';
import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom'
import ReactPlayer from "react-player";

import GlobalState from '../../store/globalState'
import { isBrowser, isMobile, MobileView } from '../../utils/platformsUtils';

import { ExpandableComponent, ButtonExport } from '../../components/common/commonComponents'

import ModalDialog, { ModalAlert, ModalButtons, StyledButtons } from '../../components/common/modal/modal'
import { Grid, Button, TextField, TextareaAutosize, FormControlLabel, FormControl, Typography, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, makeStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Tooltip from '@material-ui/core/Tooltip';
import DescriptionIcon from '@material-ui/icons/Description';
import LinearProgress from '@material-ui/core/LinearProgress';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import {Redirect} from 'react-router-dom';
import MuiAlert from '@material-ui/lab/Alert';
import Streamers from '../../services/api/streamers';
import { rLinkNum } from '../../structure/ads-list/ads-list.components.jsx'
import UserUtils from '../../utils/userUtils'
import { toMStr } from '../../utils/moneyUtils'
import { commService } from '../../services/api/list';
import { CommInputField, CommForm, CommFormGen, confForm, RadioButtonList, CommRadioButtonList, BroadCastTimePickerComm } from '../common/com-Form/comm-form.components'
import { CommButton, confCOM, confField, CommTextField, fieldNameFunction, temporalItem, fnfRequireText, CommDateTimePicker, CommSimpleTextButton, fnfRequireNumber, FileButtonComm, checkMailField, CommCheckBox, InputSource } from '../common/com'
import { CommListSimpleTextButton, CommButtonSave, CommItemOfItems, confAddItem, CommListGen, CommLinkButton, CommDeleteButtonLink, confListField, confList, CommList, CommItemField, CommItemTextField, CommItemFieldTextLink, CommListFlat, CommItemTextLink, CommItemListCountDown, CommListCheckBox, ListFilesComp } from '../common/com-List/comm-list.components'
import { mapGenericStateToProps, changeUrlByReact, Urls, openTab } from '../../utils/routerUtils'
import { RenderOptions } from '../../structure/ads-form/ads-form.component.jsx'
import { withStyles } from '@material-ui/core/styles';
import { LangStrs, Lang } from '../../utils/languageUtils'
import { OptionsToCheckRequired } from '../options/options.component'
import { doTransferToResource, addFoundsUrl } from '../../structure/transaction-form/transaction-form.component.jsx'
import { ContractStates, strs as bStrs, SDeliveryStatesToStr, SDeliveryStates, MoveSponsorButtons, SponsorshipType, confDateLimitInList, confCollaborationsInList, showReject } from '../../structure/common/branded/commonBranded.jsx'
import VisibilityIcon from '@material-ui/icons/Visibility';
import { dateStrToReadDateStr, DurationTime } from '../../utils/timeUtils'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { Chat } from '../../components/chat/chat-component.jsx'
import Cookies from 'universal-cookie';
import { NotificationEntityType } from '../../store/sagas/notifications'

import CommHelpLink, { getDocUrl } from '../../structure/common/comHelpLink/comHelpLink.jsx'

import moment from 'moment';

export let strs: LangStrs = new LangStrs(new Map(
    [
        ['Title', new Map(
            [
                [Lang.ES, 'Colaboraciones en Curso'],
                [Lang.EN, 'Ongoing Collaborations']
            ])
        ],
        ['View', new Map(
            [
                [Lang.ES, 'Ver'],
                [Lang.EN, 'View']
            ])
        ],
        ['State', new Map(
            [
                [Lang.ES, 'Estado'],
                [Lang.EN, 'State']
            ])
        ],
        ['BrandedDesc', new Map(
            [
                [Lang.ES, 'Ir a descripción'],
                [Lang.EN, 'Go to description']
            ])
        ],
        ['streamerName', new Map(
            [
                [Lang.ES, 'Streamer'],
                [Lang.EN, 'Streamer']
            ])
        ],
        ['SAccount', new Map(
            [
                [Lang.ES, 'Cuenta'],
                [Lang.EN, 'Account']
            ])
        ],
        ['Platform', new Map(
            [
                [Lang.ES, 'Plataforma'],
                [Lang.EN, 'Platform']
            ])
        ],
        ['Actions', new Map(
            [
                [Lang.ES, 'Acciones'],
                [Lang.EN, 'Actions']
            ])
        ],
        ['StateOffer', new Map(
            [
                [Lang.ES, 'Esperando que el creador de contenido acepte la oferta'],
                [Lang.EN, 'Waiting for the content creator to accept the offer']
            ])
        ],
        ['StateCanceled', new Map(
            [
                [Lang.ES, 'Oferta de campaña cancelada'],
                [Lang.EN, 'Campaign offer canceled']
            ])
        ],
        ['StateStreamerAccepted', new Map(
            [
                [Lang.ES, 'Campaña aceptada'],
                [Lang.EN, 'Campaign accepted']
            ])
        ],
        ['StateDone', new Map(
            [
                [Lang.ES, 'Campaña finalizada'],
                [Lang.EN, 'Campaign finished']
            ])
        ],
        ['StateExpired', new Map(
            [
                [Lang.ES, 'Fecha límite de la campaña superada'],
                [Lang.EN, 'Campaign deadline exceeded']
            ])
        ],
        ['StateCanceledByStreamer', new Map(
            [
                [Lang.ES, 'El creador de contenido no ha aceptado la oferta'],
                [Lang.EN, 'The content creator did not accept the offer']
            ])
        ],
        ['AdActionFeedback', new Map(
            [
                [Lang.ES, 'Debes confirmar la entrega'],
                [Lang.EN, 'You must confirm the delivery']
            ])
        ],
        ['Accept', new Map(
            [
                [Lang.ES, 'Aceptar'],
                [Lang.EN, 'Accept']
            ])
        ],
        ['NoAccept', new Map(
            [
                [Lang.ES, 'No Aceptar'],
                [Lang.EN, 'Do Not Accept']
            ])
        ],
        ['RejectReason', new Map(
            [
                [Lang.ES, 'Agrega el motivo de la desestimación'],
                [Lang.EN, 'Add the reason for rejection']
            ])
        ],
        ['TitleDeliveries', new Map(
            [
                [Lang.ES, 'Entregas'],
                [Lang.EN, 'Deliveries']
            ])
        ],
        ['ColDesc', new Map(
            [
                [Lang.ES, 'Instrucciones para la colaboración'],
                [Lang.EN, 'Instructions for Collaboration']
            ])
        ],
        ['SectionDescription', new Map(
            [
                [Lang.ES, 'Descripción'],
                [Lang.EN, 'Description']
            ])
        ],
        ['WaitUpdate', new Map(
            [
                [Lang.ES, 'Esperando la subida del creador de contenido'],
                [Lang.EN, 'Waiting for the content creator to upload']
            ])
        ],
        ['email', new Map(
            [
                [Lang.ES, 'E-Mail'],
                [Lang.EN, 'E-Mail']
            ])
        ],
        ['name', new Map(
            [
                [Lang.ES, 'Nombre'],
                [Lang.EN, 'Name']
            ])
        ],
        ['canAccept', new Map(
            [
                [Lang.ES, 'Permitir aceptar'],
                [Lang.EN, 'Allow Acceptance']
            ])
        ],
        ['AddGuest', new Map(
            [
                [Lang.ES, 'Agregar un supervisor'],
                [Lang.EN, 'Add a Supervisor']
            ])
        ],
        ['Supervisors', new Map(
            [
                [Lang.ES, 'Supervisores'],
                [Lang.EN, 'Supervisors']
            ])
        ],
        ['add', new Map(
            [
                [Lang.ES, 'agregar'],
                [Lang.EN, 'add']
            ])
        ],
        ['emailAlready', new Map(
            [
                [Lang.ES, 'Este E-Mail ya ha sido agregado anteriormente'],
                [Lang.EN, 'This E-Mail has already been added before']
            ])
        ],
        ['Export', new Map(
            [
                [Lang.ES, 'Exportar datos'],
                [Lang.EN, 'Export data']
            ])
        ],
    ]
));

const styles = {
    header: {
        backgroundColor: '#35749E',  // color primary.main predeterminado de Material-UI
        color: 'white',
        borderRadius: '4px 4px 0 0'
    },
    headerContent: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between'
    },
    text: {
        fontWeight: 'bold'
    },
    icon: {
        color: 'white'
    }
};

class BrandedContentOnGoing extends React.Component {
    constructor(props) {
        super(props);

        this.state = { showVideo: [], selItem: this.props.match.params.contract ? { id: this.props.match.params.contract } : undefined }

        this.confListContracts = [
            new confField(strs.get('streamerName'), CommItemField, { className: "list-field bi-name" }, "strUsername"),
            new confField(strs.get('Platform'), CommItemField, { className: "list-field bi-name" }, "cPlatform"),
            new confListField(strs.get('SAccount'), CommItemFieldTextLink, {}, (item) => item.cName).addField("tUrl", (item) => item.cUrl),
            new confField(bStrs.get('moneyPerCollaboration'), CommItemField, { className: "list-field bi-name" }, (i) => toMStr(i.moneyPerCollaboration)),
            confCollaborationsInList(),
            confDateLimitInList(),
            new confField(strs.get('State'), CommItemField, { className: "list-field bi-name" }, (item) => this.stateToStr(item.state)),
            rLinkNum((item) => `#server#/advertisers/${UserUtils.GetUserData().id}/sponsorship/${this.props.match.params.id}/ongoing/${item.id}/visits`, (data, item) => { return (data && data.length>0) ? data[0].visits : undefined; })
        ];
        if (!this.props.supervisor) {
            const actionsVisible = (item) => item.state != ContractStates.CanceledByStreamer;
            this.confListContracts.push(new confListField(strs.get('Actions'), CommItemOfItems).addField("Comp", [
                new confListField("", CommSimpleTextButton, { disabled: (i) => this.state.selItem && this.state.selItem.id == i.id && !this.state.selItem.supervisor }, strs.get('View')).addField("OnClick", async (item) => { item.supervisor = false; await this.setState({ selItem: item }); }).addField("Condition", actionsVisible).addField("NotificationBadget", (item) => { return { notificationKey: [NotificationEntityType.SponsorshipContractAcceped, NotificationEntityType.SponsorshipContractMsg, NotificationEntityType.SponsorshipDeliveryNew, NotificationEntityType.SponsorshipDeliveryValidated], notificationId2:item.id }}),
                new confListField("", CommSimpleTextButton, { disabled: (i) => this.state.selItem && this.state.selItem.id == i.id && this.state.selItem.supervisor }, strs.get('Supervisors')).addField("OnClick", async (item) => { item.supervisor = true; await this.setState({ selItem: item }); }).addField("Condition", actionsVisible)
            ]));
        }

        this.confContract = [
            new confField(strs.get('ColDesc'), CommTextField, { className: "list-field bi-name", readOnly: true }, "sDesc").addField("rows", 4),
        ];

        const textProps = {
            className: "list-field bi-name", style: { maxWidth: "400px", marginRight : "20px" } };
        this.confGuestsForm = [
            new confListField(strs.get('email'), CommTextField, textProps, new fieldNameFunction("email", checkMailField)),
            new confListField(strs.get('name'), CommTextField, textProps, "name"),
            new confField(strs.get('canAccept'), CommCheckBox, { className: "list-field bi-name" }, "canAccept", true),            
        ];

        this.confGuestsList = [
            new confListField(strs.get('email'), CommItemField, { className: "list-field bi-name" }, "email"),
            new confListField(strs.get('name'), CommItemField, { className: "list-field bi-name" }, "name"),
            new confField(strs.get('canAccept'), CommListCheckBox, { className: "list-field bi-name"}, "canAccept", true),
            new confListField(strs.get('Actions'), CommItemOfItems, {}).addField("Comp", [
                new confListField(strs.get("Save"), CommButtonSave),
                new confListField(strs.get("Delete"), CommDeleteButtonLink, null, "name")
            ])
        ];

    }

    stateToStr(state) {
        if (state == ContractStates.Offer) return strs.get("StateOffer");
        if (state == ContractStates.Canceled) return strs.get("StateCanceled");
        if (state == ContractStates.StreamerAccepted) return strs.get("StateStreamerAccepted");
        if (state == ContractStates.Done) return strs.get("StateDone");
        if (state == ContractStates.Expired) return strs.get("StateExpired");
        if (state == ContractStates.CanceledByStreamer) return strs.get("StateCanceledByStreamer");
    }

    render() {
        return (            
            <Grid direction="column">                
                <Grid className="form-column">
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <h1>{(this.state.sponName == undefined ? "" : this.state.sponName) + (!this.props.supervisor ? (": " + strs.get("Title")) : "")}<CommHelpLink infoUrl={getDocUrl("como-gestionar-una-campana-de-contenido-de-marca-en-marcha")} /></h1>
                        {!this.props.supervisor && MoveSponsorButtons(this.props, null, this.props.match.params.id, "fromContracts", false)}
                    </div>
                </Grid>
                <Grid child container className={'brandedContent-form ' + this.props.className} direction="row" justify="flex-start" alignItems="flex-start">                    
                    <Grid child container direction="row" xs="12" style={{ width: "100%" }}>
                        <ButtonExport url={`/api/advertisers/${UserUtils.GetUserData().id}/sponsorship/${this.props.match.params.id}/ongoingexport`} str={strs.get('Export')} />
                        <CommList ref={(c) => this.commlistStreamers = c} confComm={new confList(CommListGen, new commService("/advertisers/:id/sponsorship/" + this.props.match.params.id + "/ongoing"), this.confListContracts)}
                            OnQuery={async (comp, data) => {
                                if (data && data.length) {                                    
                                    this.setState({ sponName: data[0].sName });
                                    if (this.props.supervisor) {
                                        await this.setState({ selItem: data[0] });
                                    }
                                }
                            }
                        } />
                    </Grid>
                    {this.state.selItem && this.state.selItem.supervisor && <>                        
                        <CommList ref={(r) => this.guestList = r} confComm={new confList(CommListGen, new commService(`/advertisers/:id/sponsorship/${this.props.match.params.id}/ongoing/${this.state.selItem.id}/guest`), this.confGuestsList)} OnQuery={(comp, data) => this.guests = data} />
                        <CommForm ref={(r) => this.guestForm = r} confComm={new confForm(CommFormGen, new commService(`/advertisers/:id/sponsorship/${this.props.match.params.id}/ongoing/${this.state.selItem.id}/guest`), this.confGuestsForm)} OnQuery={(comp, data) => this.setState({ item: data })} OnSave={() => this.guestList.QueryItems()} submitButtonTitle={strs.get("add")} BeforeSaveData={() => { if (this.guests.find(g => g.email == this.guestForm.getItem().newData.email) != undefined) { this.mAlert.show(strs.get("emailAlready")); return false; } return true; }} />
                    </>}
                    {this.state.selItem && !this.state.selItem.supervisor && <DeliveryOngoing key={"contract" + this.state.selItem} sponId={this.props.match.params.id} selItem={this.state.selItem} className={this.props.className} />}
                </Grid>
                <ModalAlert ref={(r) => this.mAlert = r} />
            </Grid>
        )
    }
}

export class DeliveryOngoingFromToken extends React.Component {
    constructor(props) {
        super(props);
        
        const cookies = new Cookies();
        cookies.set('supervisor', this.props.match.params.token, { path: '/' });        
    }
    render() {
        UserUtils.SetUser({ id: this.props.match.params.adv });
        const selItem = { id: this.props.match.params.contract };
        return (
            <div style={{ display: "flex", justifyContent: "center", marginTop: "100px" }}>
                <div style={{ width: "90%" }}>
                    <BrandedContentOnGoing {...this.props} supervisor={true} />
                </div>
            </div>
        )
    }
}

export class DeliveryOngoing extends React.Component {
    constructor(props) {
        super(props);
        this.state = { showVideo: [] };

        this.confContract = [
            new confField(strs.get('ColDesc'), CommTextField, { className: "list-field bi-name", readOnly: true }, "sDesc").addField("rows", 4),
        ];
    }

    showVideo = (item) => this.state.showVideo.find((i) => i == item.id);

    DeliveryDetails(item) {
        return <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <div>
                {this.DeliveryDetailsText(item)}
            </div>
            {this.showVideo(item) && this.videoplayer(item)}
        </div>
    }

    videoplayer(item) {
        return <ReactPlayer
            //ref={playerRef}
            config={{ file: { attributes: { controlsList: 'nodownload' } } }}
            url={`/api/advertisers/${UserUtils.GetUserData().id}/sponsorship/${this.props.sponId}/ongoing/${this.props.selItem.id}/delivery/${item.id}/video`}
            playing={true}
            controls={true}
        //onProgress={handleProgress}
        //progressInterval={100}
        //onReady={handleVideoReady}
        //width={playerDimensions.width}
        //height={playerDimensions.height}
        />
    }

    checkForDelivery(data) {
        const d = data.find(i => i.state == SDeliveryStates.Delivered);
        this.setState({ showVideo: d ? [d.id] : [] });
    }

    OkButton = ModalButtons(true);
    CancelButton = ModalButtons(false);

    DeliveryDetailsText(item) {
        if (item.state == SDeliveryStates.Rejected) {
            return showReject(item.rejectReason);
        } else if (item.state == SDeliveryStates.Delivered) {
            return <>
                <p>{strs.get("AdActionFeedback")}</p>
                <this.OkButton onClick={async (e) => { await this.submissionsList.UpdateData({ id: item.id, newData: { state: SDeliveryStates.Accepted } }); this.submissionsList.QueryItems(); }}>{strs.get("Accept")}</this.OkButton>
                <this.CancelButton onClick={async (e) => { await this.setState({ selDelivery: item }); this.rejectModal.setOpen(true); }} >{strs.get("NoAccept")}</this.CancelButton>
            </>
        } else if (item.state == SDeliveryStates.Accepted) {
            if (item.streamsDelivered.length == 0)
                return strs.get("WaitUpdate");
            else
                return item.streamsDelivered.map((s, i) => {
                    return <div key={s.url} style={{ marginBottom: item.streamsDelivered.length == 1 ? '' : '10px' }}>
                        <span style={{ marginRight: '10px' }}>{dateStrToReadDateStr(s.createdAt, true)}</span> <a href={s.url} target="_blank" rel="noopener noreferrer">{s.title ?? "video"}</a> {s.segmentTime != -1 && <>({DurationTime(0, s.segmentTime * 1000)})</>}
                    </div>
                });
        }
        return <></>
    }

    render() {
        this.confSubmissions = [
            new confListField("", CommListSimpleTextButton, {}, strs.get('View')).addField("OnClick", async (item) => { this.setState({ showVideo: this.showVideo(item) ? this.state.showVideo.filter(i => i != item.id) : [...this.state.showVideo, item.id] }) }).addField("Icon", (item) => this.showVideo(item) ? VisibilityOffIcon : VisibilityIcon),
            new confListField(strs.get('state'), CommItemField, {}, (item) => SDeliveryStatesToStr(item.state)),
            new confListField(strs.get('DeliveryDate'), CommItemField, {}, (item) => dateStrToReadDateStr(item.createdAt, true)),
            new confListField("", CommItemField, {}, (item) => this.DeliveryDetails(item)),
        ];

        return (
            <React.Fragment key={UserUtils.GetUserData().id + "contract" + this.props.selItem.id}>
                <ExpandableComponent title={strs.get("TitleDeliveries")} style={{ marginBottom: "10px", width: '100%', display: this.state.showDeliveris ? "block" : "none" }} defaultExpanded>
                    <Grid child container direction="row" xs="12">
                        <CommList key={UserUtils.GetUserData().id + "submissions" + this.props.selItem.id} ref={(r) => this.submissionsList = r} confComm={new confList(CommListGen, new commService(`/advertisers/:id/sponsorship/${this.props.sponId}/ongoing/${this.props.selItem.id}/delivery`), this.confSubmissions)} headers={false} OnQuery={(comp, data) => { this.setState({ showDeliveris: data && data.length }); this.checkForDelivery(data) }} />
                    </Grid>
                </ExpandableComponent>
                <ExpandableComponent title={strs.get("SectionDescription")} style={{ marginBottom: "10px", width: '100%' }}>
                    <Grid child container className={'brandedContent-form ' + this.props.className} direction="row" justify="flex-start" alignItems="flex-start" style={{ marginTop: "10px" }}>
                        <Grid child container direction="row" xs="12" style={{ width: "100%" }}>
                            <CommForm ref={(r) => this.contractForm = r} confComm={new confForm(CommFormGen, new commService(`/advertisers/:id/sponsorship/${this.props.sponId}/ongoing`), this.confContract, this.props.selItem.id)} OnQuery={(comp, data) => this.setState({ item: data })} noSubmitButton />
                        </Grid>
                        <Grid child container direction="row" xs="12" style={{ width: "100%" }}>
                            <ListFilesComp ref={(c) => this.commlistResources = c} resourcesURL={"/advertisers/:id/sponsorship/" + this.props.sponId + "/resource"} />
                        </Grid>
                    </Grid>
                </ExpandableComponent>
                <ExpandableComponent title={bStrs.get("SectionChat")} style={{ marginBottom: "10px", width: '100%' }} defaultExpanded>
                    <Grid child container className={'brandedContent-form ' + this.props.className} direction="row" justify="flex-start" alignItems="flex-start" style={{ marginTop: "10px" }}>
                        <Chat messagesUrl={`/advertisers/:id/sponsorship/${this.props.sponId}/ongoing/${this.props.selItem.id}/message`} postResourcesUrl={`/api/advertisers/${UserUtils.GetUserData().id}/sponsorship/${this.props.sponId}/ongoing/${this.props.selItem.id}/message/:idMsg/resource`} />
                    </Grid>
                </ExpandableComponent>
                <RejectDeliveryModal ref={(r) => this.rejectModal = r} sponsorship={this.props.sponId} contract={this.props.selItem ? this.props.selItem.id : undefined} delivery={this.state.selDelivery ? this.state.selDelivery.id : undefined} OnSubmit={() => this.submissionsList.QueryItems()} />
            </React.Fragment>
        );
    }
}

export class RejectDeliveryModal extends React.Component {
    constructor(props) {
        super(props);

        this.confForm = [
            new confListField(strs.get("RejectReason"), CommTextField, { style: { minWidth: "400px" } }, fnfRequireText("rejectReason")).addField("rows", 4),
        ];
    }

    render = () => {
        return (
            <>
                <ModalDialog ref={(m) => { this.modal = m; }} windowsSyle={true} btnNOVisible={false} btnOKVisible={false}>
                    <CommForm ref={(c) => this.form = c} title="" customSubmitButton={ModalButtons(false)} submitButtonTitle={strs.get("NoAccept")} confComm={new confForm(CommFormGen, new commService(`/advertisers/:id/sponsorship/${this.props.sponsorship}/ongoing/${this.props.contract}/delivery`), this.confForm, this.props.delivery)} OnSave={() => { this.setOpen(false); }} BeforeSaveData={() => { this.form.addData({ state: SDeliveryStates.Rejected }); return true; }} />
                </ModalDialog>
            </>
        );
    }

    setOpen(open) {
        this.modal.setOpen(open); 
        this.props.OnSubmit && this.props.OnSubmit(); //vols dir que aixo no nomes s'hauria de executar quan es false?
    }
}



export default connect(mapGenericStateToProps, null)(withRouter(BrandedContentOnGoing));